import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BaseService } from './base.service';
//import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AcctSdmService extends BaseService {

  constructor(private inject: HttpClient) {
    super(inject);
  }

  getAccountAcctSdmInfo(account_no:string): Observable<any> {
   
    let resource = "/accounts/get_account_sdim_history/" + account_no;
   
    return this.getRequest<any>(resource);
  }
  copyHistoryACCTRecord(accountinfo: any){
    let resource = "/accounts/copy_history_acct_sdim";
    //console.log(resource);
    return this.postRequest(resource, accountinfo);
  }
  deleteHistoryACCTRecord(acct_dim_key: string){
   let resource = "/accounts/delete_history_acct_sdim/" + acct_dim_key;
  
   return this.deleteRequest(resource);
  }
  saveACCTAttributes(accountObject: any){
    let resource = "/accounts/update_history_acct_sdim";
    //console.log(resource);
    return this.putRequest(resource, accountObject);
  }
  getgroupmgrList(): Observable<any> {
    let resource = "/attributes/groupmgr/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  getAdvisorList(): Observable<any> {
    let resource = "/attributes/investadv/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  validateACCTHistory(account_no:string): Observable<any> {
   
    let resource = "/accounts/validate_history_acct_sdim?acct_number="+account_no;
   
    return this.getRequest<any>(resource);
  }
}
