


<mat-drawer-container class="sidenav-container" autosize>


    <mat-drawer #drawer class="example-sidenav"
    [@onSideNavChange]="sideNavState ? 'open' : 'close'"
    fxLayout="column" 
    mode="side" opened>

        <mat-nav-list>

        <mat-nav-list>
          <!-- <mat-list-item routerLink="/final-asset" class="nav-item">
            <mat-icon mat-list-icon  matTooltip={{sideNav.FinalAssetAmountAdjustment}} matTooltipPosition="right">local_atm</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.FinalAssetAmountAdjustment}}</span>

          </mat-list-item> -->
          <button mat-icon-button (click)=onSidenavToggle()>
            <mat-icon mat-list-icon>menu</mat-icon>
          </button>
          <mat-list-item routerLink="/account-attributes" class="nav-item">
            <mat-icon mat-list-icon  matTooltip={{sideNav.AccountAttributes}} matTooltipPosition="right">edit_attributes</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.AccountAttributes}}</span>
            
          </mat-list-item>
          <mat-list-item routerLink="/share-class-attributes" class="nav-item">
            <mat-icon mat-list-icon  matTooltip={{sideNav.ShareClassAttributes}} matTooltipPosition="right">share</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.ShareClassAttributes}}</span>
            
          </mat-list-item>
          <!-- <mat-list-item routerLink="/snapshot" class="nav-item">
            <mat-icon mat-list-icon  matTooltip={{sideNav.MonthEndSnapshot}} matTooltipPosition="right">camera_alt</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.MonthEndSnapshot}}</span>
            
          </mat-list-item> -->

          <mat-list-item routerLink="/data-loads" class="nav-item">
            <mat-icon mat-list-icon  matTooltip={{sideNav.DataLoads}} matTooltipPosition="right">view_list</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.DataLoads}}</span>
            
          </mat-list-item>


       
        <mat-list-item routerLink="/attributes-management" class="nav-item" >
          <mat-icon mat-list-icon   matTooltip={{sideNav.AttributesManagement}} matTooltipPosition="right"> fact_check</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.AttributesManagement}}</span>
        </mat-list-item>

        <mat-list-item routerLink="/crossholding-rules-management" class="nav-item" >
          <mat-icon mat-list-icon   matTooltip={{sideNav.CrossholdingRulesManagement}} matTooltipPosition="right"> task_alt</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.CrossholdingRulesManagement}}</span>
        </mat-list-item>
        <mat-list-item routerLink="/report-rules-mgmt" class="nav-item">
          <mat-icon mat-list-icon  matTooltip={{sideNav.ReportRulesManagement}} matTooltipPosition="right">stacked_bar_chart</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.ReportRulesManagement}}</span>
        </mat-list-item>
      </mat-nav-list>

        <!-- UNCOMMENT THESE ARE THE COMPONENTS ARE CREATED  --> 
        <!-- <mat-list-item routerLink="/attributes-mgmt" class="nav-item">
          <mat-icon mat-list-icon  matTooltip={{sideNav.AttributesManagement}} matTooltipPosition="right">perm_data_setting</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.AttributesManagement}}</span>
        </mat-list-item>
        <mat-list-item routerLink="/report-rules-mgmt" class="nav-item">
          <mat-icon mat-list-icon  matTooltip={{sideNav.ReportRulesManagement}} matTooltipPosition="right">stacked_bar_chart</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.ReportRulesManagement}}</span>
        </mat-list-item>
        <mat-list-item routerLink="/xhold-rules-mgmt" class="nav-item">
          <mat-icon mat-list-icon  matTooltip={{sideNav.XholdRulesManagement}} matTooltipPosition="right">stacked_bar_chart</mat-icon>
          <span [@animateText]="linkText ? 'show' : 'hide'">{{sideNav.XholdRulesManagement}}</span>
        </mat-list-item> -->


      </mat-nav-list>
    </mat-drawer>
  
    <div class="sidenav-content">
      <!-- This handles URL behavior -->
      <router-outlet></router-outlet>
    </div>
  
  </mat-drawer-container>