import { formatDate, Location } from '@angular/common';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { AccountclassService } from 'src/app/services/accountclass.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { DialogService } from 'src/app/services/dialog.service';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/services/auth.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-account-attributes',
  templateUrl: './account-attributes.component.html',
  styleUrls: ['./account-attributes.component.css']
})
export class AccountAttributesComponent implements OnInit {
  display = false;
  default = 0;
  isShowButton: boolean = false;
  accountinfo: any = {};
  accountNo: string = "";
  direction: string = "1";
  accountType: string = "";
  accountName: string = "";
  subAdvisor: string = "";
  subSubAdvisor: string = "";
  transferAgent: string = "";
  businessManager: string = "";
  taxbility: string = "";
  adminLocation: string = "";
  iciInvestmentObjective: string = "";
  lastUpdatedDate: string = "";
  lastUpdatedBy: string = "";
  fiscalYearEnd: string = "";
  xaumFlag: boolean = false;
  investmentObjectid: number = 0;
  //investmentAdvid: number = 0;
  investmentAdvname: string = ""
  brandid: number = 0;
  vehicleTypeid: number = 0;
  regionid: number = 0;
  groupManagerid: number = 0;
  groupManagername: string = "";
  businessUnitid: number = 0;
  leadPortfolioMgmtGrpid: number = 0;
  portfolioMandateid: number = 0;
  clientTypeParentid: number = 0;
  salesOfficeid: number = 0;
  crossHoldingIndicatorid: number = 0;
  productTypeid: number = 0;
  canadianMandateid: number = 0;
  domicile: string = "Share Class1";
  backupPortfoliomgmt: string = "Share Class1";
  brandlist: any = [];
  brandfilterList:any=[];
  vehicletypelist: any = [];
  regionList: any = [];
  salesOfficeList: any = [];
  invstObjList: any = [];
  invstAdvList: any = [];
  portmanList: any = [];
  portmanFilteredList:any=[];
  pctpList: any = [];
  xhldindList: any = [];
  xhldindFilterList:any=[];
  producttypeList: any = [];
  canadamanList: any = [];
  businessList: any = [];
  lpmgList: any = [];
  grpmgrList: any = [];
  accountdetails: any = [];
  isLoading = false;
  isChecked = false;
  send_date = new Date();
  public groupmgrLoading: boolean = false;
  editGrpmgr: boolean = false;
  public isFromLand: string = "false";
  public pageDirty: boolean = false;
  investmentObjectid1; investmentAdvname1; brandid1; vehicleTypeid1; regionid1; groupManagerid1; businessUnitid1;
  leadPortfolioMgmtGrpid1; portfolioMandateid1; clientTypeParentid1; salesOfficeid1; crossHoldingIndicatorid1;
  productTypeid1; canadianMandateid1
  xaumFlag1: boolean;
  showRecords: boolean = false;  
  isReadOnly: boolean =false;

  //search pipe

  varInvestText;
  varBrandText;
  varVechText;
  varpctpText;
  varBussText;
  varlpmgText;
  varportmanText;
  varRegionText;
  varSalesText;
  varIndText;
  varProdText;
  varCanadianText;
  vargrpmgrText;

  constructor(private router: Router,
    private accountservice: AccountclassService,
    private route: ActivatedRoute,
    private toastr: ToasterService,
    private titleService: Title,
    private confirmDialog: DialogService,
    private authservice: AuthService,
    private _location: Location) {

    this.titleService.setTitle("CARS - Account Attributes")
    //this.accountNo = this.route.snapshot.queryParams.id;
    this.accountNo = this.route.snapshot.paramMap.get('id');
    //this.isFromLand = this.route.snapshot.paramMap.get('isFromLand');
    this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
  }

  ngOnInit(): void {
    if (this.accountNo != "" && this.accountNo != null) {
      this.getAccountInformation();
    }
    
    forkJoin(
      {
        brand       : this.accountservice.getBrandList(),
        vehicle     : this.accountservice.getVehicleTypeList(),
        region      : this.accountservice.getRegionList(),
        salesoffice : this.accountservice.getsalesOfficeList(),
        invstObj    : this.accountservice.getinvestobjList(),     
       // investadv   : this.accountservice.getinvestadvList(),
        business    : this.accountservice.getbusinessList(),
        portman     : this.accountservice.getportmanList(),
        pctp        : this.accountservice.getpctpList(),
        xhldind     : this.accountservice.getxhldindList(),
        producttype : this.accountservice.getproducttypeList(),
        canadaman   : this.accountservice.getcanadamanList(),
        lpmg        : this.accountservice.getlpmgList()
      }
    ).subscribe((result: any)=>{
        //  brancd list ***************************
        this.brandlist = result.brand.data;
        this.brandfilterList = this.brandlist.filter((x: any) => x.aum_brand_status
        == "Active");

        // vehicle list ************************************
        this.vehicletypelist = result.vehicle.data;

        // region list ********************
        this.regionList = result.region.data;

        // sales office ******************
        this.salesOfficeList = result.salesoffice.data;

        // invest object list
        this.invstObjList = result.invstObj.data;

        //invest adv *****************
        //this.invstAdvList = result.investadv.data;

        //business list
        this.businessList = result.business.data;

        // portman list ****************888
        this.portmanList = result.portman.data;

        this.portmanFilteredList = this.portmanList.filter((x: any) => x.aum_port_man_status
        == "Active");

        //pctp list *******************8
        this.pctpList = result.pctp.data;

        // xhldind list *************************
        this.xhldindList = result.xhldind.data;
  
        this.xhldindFilterList = this.xhldindList.filter((x: any) => x.aum_xhld_indicator_status == "Active");

        // producttypeList ****************
        this.producttypeList = result.producttype.data;

        // canadaman list **************
        this.canadamanList = result.canadaman.data;

        // lpmg ********************
        this.lpmgList = result.lpmg.data;

    }, error=>{
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    })  

    // this.getBrandList();
    // this.getinvestobjList();
    // this.getVehicleTypeList();
    // this.getRegionsList();
    // this.getSalesofficeList();   
    // this.getpct1pList();
    // this.getportmanList();
    //  this.getxhldindList();
    // this.getcanadamanList();
    // this.getbusinessList();
    // this.getlpmgList();
    // this.getproducttypeList();    
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.pageDirty = false;
    if (!this.showRecords) {
      if (this.investmentObjectid != 0 ||
        this.brandid != 0 ||
        this.vehicleTypeid != 0 ||
        this.regionid != 0 ||
        this.groupManagerid != 0 ||
        this.businessUnitid != 0 ||
        this.leadPortfolioMgmtGrpid != 0 ||
        this.portfolioMandateid != 0 ||
        this.clientTypeParentid != 0 ||
        this.salesOfficeid != 0 ||
        this.crossHoldingIndicatorid != 0 ||
        this.productTypeid != 0 ||
        this.canadianMandateid != 0 ||
        this.xaumFlag != false) {
        this.pageDirty = true;
      }
    }
    if (this.showRecords) {
      if (this.investmentObjectid1 != this.investmentObjectid ||
        this.brandid1 != this.brandid ||
        this.vehicleTypeid1 != this.vehicleTypeid ||
        this.regionid1 != this.regionid ||
        this.groupManagerid1 != this.groupManagerid ||
        this.businessUnitid1 != this.businessUnitid ||
        this.leadPortfolioMgmtGrpid1 != this.leadPortfolioMgmtGrpid ||
        this.portfolioMandateid1 != this.portfolioMandateid ||
        this.clientTypeParentid1 != this.clientTypeParentid ||
        this.salesOfficeid1 != this.salesOfficeid ||
        this.crossHoldingIndicatorid1 != this.crossHoldingIndicatorid ||
        this.productTypeid1 != this.productTypeid ||
        this.canadianMandateid1 != this.canadianMandateid ||
        this.xaumFlag1 != this.xaumFlag) {
        this.pageDirty = true;

      }
    }
    if (this.pageDirty) {
      return this.confirmDialog.confirm("Do you want to discard the changes?");
    }
    return true;
  }

  displayAumAcctSDIM() {
    //this.display = true;
    //this.child.visible= true;
    this.router.navigate(['/aum-acct-sdm', { id: this.accountNo }])

  }
  displayAcctSDIM() {
    //this.display = true;
    //this.child.visible= true;
    this.router.navigate(['/acct-sdm', { id: this.accountNo }])
  }

  // getBrandList() {
  //   this.accountservice.getBrandList()
  //     .subscribe(result => {
  //       this.brandlist = result.data;
  //       this.brandfilterList = this.brandlist.filter((x: any) => x.aum_brand_status
  //       == "Active");
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getVehicleTypeList() {
  //   this.accountservice.getVehicleTypeList()
  //     .subscribe(result => {
  //       this.vehicletypelist = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getRegionsList() {
  //   this.accountservice.getRegionList()
  //     .subscribe(result => {
  //       this.regionList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getSalesofficeList() {
  //   this.accountservice.getsalesOfficeList()
  //     .subscribe(result => {
  //       this.salesOfficeList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getinvestobjList() {
  //   this.accountservice.getinvestobjList()
  //     .subscribe(result => {
  //       this.invstObjList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getinvestadvList() {
  //   this.accountservice.getinvestadvList()
  //     .subscribe(result => {
  //       this.invstAdvList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getbusinessList() {
  //   this.accountservice.getbusinessList()
  //     .subscribe(result => {
  //       this.businessList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getportmanList() {
  //   this.accountservice.getportmanList()
  //     .subscribe(result => {
  //       this.portmanList = result.data;

  //       this.portmanFilteredList = this.portmanList.filter((x: any) => x.aum_port_man_status
  //       == "Active");
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getpct1pList() {
  //   this.accountservice.getpctpList()
  //     .subscribe(result => {
  //       this.pctpList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getxhldindList() {
  //   this.accountservice.getxhldindList()
  //     .subscribe(result => {
  //       this.xhldindList = result.data;

  //       this.xhldindFilterList = this.xhldindList.filter((x: any) => x.aum_xhld_indicator_status
  //       == "Active");

  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getproducttypeList() {
  //   this.accountservice.getproducttypeList()
  //     .subscribe(result => {
  //       this.producttypeList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getcanadamanList() {
  //   this.accountservice.getcanadamanList()
  //     .subscribe(result => {
  //       this.canadamanList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getlpmgList() {
  //   this.accountservice.getlpmgList()
  //     .subscribe(result => {
  //       this.lpmgList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  // getgroupmgrList() {
  //   console.log("getgroupmgrlist called");
  //   this.groupmgrLoading = true;
  //   this.editGrpmgr = true;
  //   this.accountservice.getgroupmgrList()
  //     .subscribe(result => {
  //       this.groupmgrLoading = false;
  //       this.grpmgrList = result.data;
  //     },
  //     err => {
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }
  getAccountInformation() {
    if (this.accountNo == "" || this.accountNo == null) {
      this.isLoading = false;
      this.isShowButton = false;
      this.toastr.error("You must enter TIS Account Num");
      this.clearinfo()
    }
    else {
      this.isLoading = true;
      this._location.go("account-attributes;id=" + this.accountNo);
      this.accountservice.getAccountInfo(this.accountNo, this.direction)
        .subscribe((result: any) => {
          if (result.status == 200 && result.data != null) {
            this.accountdetails = result.data;

            if (this.accountdetails.p_acct_dim_id == null) {
              this.isShowButton = false;
              this.toastr.info("This account does not have an AUM record for this account. Please check your data and try again.");
              this.clearinfo()
            }
            else {
              this.isShowButton = true;
              this.brandfilterList = this.brandlist.filter((x: any) => x.aum_brand_status
              == "Active");
              let obj = this.brandfilterList.filter((x: any) => x.aum_brand_dim_id
               == result.data.p_brand_dim_id);
      
      if(obj.length == 0)
      {
                   this.brandfilterList.push({
                      "aum_brand_dim_id": result.data.p_brand_dim_id,
                      "aum_brand_name": result.data.p_brand_name              
                    });
                    
                  }

                  this.portmanFilteredList = this.portmanList.filter((x: any) => x.aum_port_man_status
        == "Active");
                   obj = this.portmanFilteredList.filter((x: any) => x.aum_port_man_dim_id
                   == result.data.p_aum_port_man_dim_id);
          
          if(obj.length == 0)
          {
                       this.portmanFilteredList.push({
                          "aum_port_man_dim_id": result.data.p_aum_port_man_dim_id,
                          "aum_port_man_name": result.data.p_aum_port_man_name              
                        });
                        
                      }

                      this.xhldindFilterList = this.xhldindList.filter((x: any) => x.aum_xhld_indicator_status
                      == "Active");
                       obj = this.xhldindFilterList.filter((x: any) => x.aum_xhld_indicator_dim_id
                       == result.data.p_xhld_indicator_dim_id);
              
              if(obj.length == 0)
              {
                           this.xhldindFilterList.push({
                              "aum_xhld_indicator_dim_id": result.data.p_xhld_indicator_dim_id,
                              "aum_xhld_indicator_name": result.data.p_xhld_indicator_name              
                            });
                            
                          }

              this.fillAccountInfo(result.data);
            }
            this.isLoading = false;

          }
        },
        err => {
          this.toastr.error("Issue while retrieving data. Please try again or contact support.")
        });
    }
  }
  onBlur(event: any) {
    if (event.target.value != "") {
      this.isShowButton = true;
    }
    else {
      this.isShowButton = false;
    }
  }

  // get next account no
  getNextAccountNo() {
    this.isLoading = true;
    this.accountservice.getNextAccountNo(this.accountNo)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.accountNo = result.data.next_account_number.trim();
          this.getAccountInformation();
          this.isLoading = false;
        }
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
  }

  fillAccountInfo(source: any) {
    this.default = 1;
    this.accountType = source.p_tis_acct_type;
    this.accountName = source.p_acct_name;
    this.subAdvisor = source.p_sub_advisor_code;
    this.subSubAdvisor = source.p_ss_adv_code;
    this.transferAgent = source.p_transfer_agent;
    this.businessManager = source.p_business_manager;
    this.taxbility = source.p_taxability;
    this.adminLocation = source.p_admin_location;
    this.iciInvestmentObjective = source.p_ici_invest_objective;
    this.lastUpdatedDate = formatDate(source.p_last_upd_date, 'MM/dd/yyyy hh:mm:ss a', 'en_US');
    this.lastUpdatedBy = source.p_last_upd_by;
    this.fiscalYearEnd = source.p_fiscal_year_end;
    this.xaumFlag = source.p_xaum_flag == "Yes" ? true : false;
    this.investmentObjectid = source.p_invst_obj_dim_id;
    // this.investmentAdvid = source.p_invst_adv_dim_id;
    this.investmentAdvname = source.p_invst_adv_name;
    this.brandid = source.p_brand_dim_id;
    this.vehicleTypeid = source.p_vehicle_dim_id;
    this.regionid = source.p_region_dim_id;
    this.groupManagerid = source.p_group_mgr_dim_id;
    this.groupManagername = source.p_group_mgr_name;
    this.businessUnitid = source.p_aum_busi_unit_dim_id;
    this.leadPortfolioMgmtGrpid = source.p_aum_lpmg_dim_id;
    this.portfolioMandateid = source.p_aum_port_man_dim_id;
    this.clientTypeParentid = source.p_aum_pct1p_dim_id;
    this.salesOfficeid = source.p_aum_sale_off_dim_id;
    this.crossHoldingIndicatorid = source.p_xhld_indicator_dim_id;
    this.productTypeid = source.p_prod_type_dim_id;
    this.canadianMandateid = source.p_canadian_mandate_dim_id;
    this.domicile = source.p_domicile_code;
    this.backupPortfoliomgmt = source.p_portfolio_manager;

    this.investmentAdvname1 = this.investmentAdvname;
    this.investmentObjectid1 = this.investmentObjectid;
    this.brandid1 = this.brandid;
    this.vehicleTypeid1 = this.vehicleTypeid;
    this.regionid1 = this.regionid;
    this.groupManagerid1 = this.groupManagerid;
    this.businessUnitid1 = this.businessUnitid;
    this.leadPortfolioMgmtGrpid1 = this.leadPortfolioMgmtGrpid;
    this.portfolioMandateid1 = this.portfolioMandateid;
    this.clientTypeParentid1 = this.clientTypeParentid;
    this.salesOfficeid1 = this.salesOfficeid;
    this.crossHoldingIndicatorid1 = this.crossHoldingIndicatorid;
    this.productTypeid1 = this.productTypeid;
    this.canadianMandateid1 = this.canadianMandateid;
    this.xaumFlag1 = this.xaumFlag;
    this.showRecords = true;

  }
  resetAccountInfo() {
    this.fillAccountInfo(this.accountdetails);
  }
  clearinfo() {
    this.brandfilterList = this.brandlist.filter((x: any) => x.aum_brand_status
    == "Active");
    this.portmanFilteredList = this.portmanList.filter((x: any) => x.aum_port_man_status
    == "Active");
    this.xhldindFilterList = this.xhldindList.filter((x: any) => x.aum_xhld_indicator_status
    == "Active");
    this.default = 0;
    this.accountNo = "";
    this.accountType = "";
    this.accountName = "";
    this.subAdvisor = "";
    this.subSubAdvisor = "";
    this.transferAgent = "";
    this.businessManager = "";
    this.taxbility = "";
    this.adminLocation = "";
    this.iciInvestmentObjective = "";
    this.lastUpdatedDate = "";
    this.lastUpdatedBy = "";
    this.fiscalYearEnd = "";
    this.xaumFlag = false;
    this.investmentObjectid = 0;
    this.groupManagername = "";
    this.brandid = 0;
    this.vehicleTypeid = 0;
    this.regionid = 0;
    this.groupManagerid = 0;
    this.businessUnitid = 0;
    this.leadPortfolioMgmtGrpid = 0;
    this.portfolioMandateid = 0;
    this.clientTypeParentid = 0;
    this.salesOfficeid = 0;
    this.crossHoldingIndicatorid = 0;
    this.productTypeid = 0;
    this.canadianMandateid = 0;
    this.domicile = "Share Class1";
    this.backupPortfoliomgmt = "Share Class1";
    this.investmentAdvname = "";
  }
  checkValue(event: any) {
    this.xaumFlag = event;
  }
  // changeValue(e) {
  //   console.log(e.target.value);
  // }
  saveAccountInfo() {
    var grpMgrName = "";
    if (this.grpmgrList.length > 0) {
      var obj = this.grpmgrList.filter((x: any) => x.mgr_dim_id == this.groupManagerid);
      if (obj.length > 0) {
        grpMgrName = obj[0].mgr_last_name + ', ' + obj[0].mgr_first_name;
      }
    }
    else {
      grpMgrName = this.accountdetails.p_group_mgr_name;
    }

    this.accountinfo = {
      acct_dim_id: this.accountdetails.p_acct_dim_id,
      invst_obj_dim_id: this.investmentObjectid,
      invst_obj_name: this.invstObjList.filter((x: any) => x.aum_invst_obj_dim_id ==
        this.investmentObjectid)[0].aum_invst_obj_name,
      /* invst_adv_dim_id: this.investmentAdvid,
       invst_adv_name: this.invstAdvList.filter((x: any) =>
         x.aum_invst_adv_dim_id == this.investmentAdvid)[0].aum_invst_adv_name,
         */
      invst_adv_dim_id: this.accountdetails.p_invst_adv_dim_id,
      invst_adv_name: this.investmentAdvname,
      brand_dim_id: this.brandid,
      brand_name: this.brandlist.filter((x: any) => x.aum_brand_dim_id == this.brandid)[0].aum_brand_name,
      vehicle_dim_id: this.vehicleTypeid,
      vehicle_type_name: this.vehicletypelist.filter((x: any) => x.aum_vehicle_type_dim_id
        == this.vehicleTypeid)[0].aum_vehicle_type_name,
      region_dim_id: this.regionid,
      region_name: this.regionList.filter((x: any) => x.aum_region_dim_id == this.regionid)[0].aum_region_name,
      groumgr_dim_id: this.groupManagerid,
      groumgr_id: 0,
      groumgr_name: grpMgrName,
      sub_advisor_code: this.subAdvisor,
      sub_advisor_desc: this.subAdvisor,
      prod_type_dim_id: this.productTypeid,
      prod_type_name: this.producttypeList.filter((x: any) => x.aum_product_type_dim_id
        == this.productTypeid)[0].aum_product_type_name,
      domicile: this.domicile,
      fiscal_year_end: this.fiscalYearEnd,
      business_manager: this.accountdetails.p_business_manager,
      acct_backumgr_id: this.accountdetails.p_acct_backup_mgr_id,
      ss_adv_code: this.subSubAdvisor,
      ss_adv_desc: this.subSubAdvisor,
      taxability: this.taxbility,
      transfer_agent: this.transferAgent,
      admin_location: this.adminLocation,
      portfolio_manager: this.accountdetails.p_portfolio_manager,
      ici_invest_objective: this.iciInvestmentObjective,
      frsteftdat: formatDate(new Date(), 'MM/dd/yyyy hh:mm:ssZZZZZ', 'en_US').slice(0, 10),
      aum_port_man_dim_id: this.portfolioMandateid,
      aum_port_man_name: this.portmanList.filter((x: any) => x.aum_port_man_dim_id
        == this.portfolioMandateid)[0].aum_port_man_name,
      aum_pct1dim_id: this.clientTypeParentid,
      aum_pct1name: this.pctpList.filter((x: any) => x.aum_pct1p_dim_id
        == this.clientTypeParentid)[0].aum_pct1p_name,
      aum_busi_unit_dim_id: this.businessUnitid,
      aum_busi_unit_name: this.businessList.filter((x: any) => x.aum_busi_unit_dim_id
        == this.businessUnitid)[0].aum_busi_unit_name,
      aum_sale_off_dim_id: this.salesOfficeid,
      aum_sale_off_name: this.salesOfficeList.filter((x: any) => x.aum_sale_off_dim_id
        == this.salesOfficeid)[0].aum_sale_off_name,
      aum_lpmg_dim_id: this.leadPortfolioMgmtGrpid,
      aum_lpmg_name: this.lpmgList.filter((x: any) => x.aum_lpmg_dim_id
        == this.leadPortfolioMgmtGrpid)[0].aum_lpmg_name,
      xhld_indicator_dim_id: this.crossHoldingIndicatorid,
      xhld_indicator_name: this.xhldindList.filter((x: any) => x.aum_xhld_indicator_dim_id
        == this.crossHoldingIndicatorid)[0].aum_xhld_indicator_name,
      canadian_mandate_dim_id: this.canadianMandateid,
      canadian_mandate_name: this.canadamanList.filter((x: any) => x.aum_canadian_mandate_dim_id
        == this.canadianMandateid)[0].aum_canadian_mandate_name,
      xaum_flag: this.xaumFlag == true ? "Yes" : "No",

    }

    // console.log(this.accountinfo);
    this.accountservice.saveAccountAttributes(this.accountinfo).subscribe(res => {
      this.toastr.success("Account details Updated Scuccessfully");
      this.getAccountInformation();
    },
    err => {
      this.toastr.error(err.error.message);

    })
  }

  // preven page refresh when form is dirty
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    this.pageDirty = false;
    console.log("ddddddddddddddddddddddddddddddddddd", this.showRecords)
    if (!this.showRecords) {
      if (this.investmentObjectid != 0 ||
        this.brandid != 0 ||
        this.vehicleTypeid != 0 ||
        this.regionid != 0 ||
        this.groupManagerid != 0 ||
        this.businessUnitid != 0 ||
        this.leadPortfolioMgmtGrpid != 0 ||
        this.portfolioMandateid != 0 ||
        this.clientTypeParentid != 0 ||
        this.salesOfficeid != 0 ||
        this.crossHoldingIndicatorid != 0 ||
        this.productTypeid != 0 ||
        this.canadianMandateid != 0 ||
        this.xaumFlag != false) {
        this.pageDirty = true;
      }
    }
    if (this.showRecords) {
      if (this.investmentObjectid1 != this.investmentObjectid ||
        this.brandid1 != this.brandid ||
        this.vehicleTypeid1 != this.vehicleTypeid ||
        this.regionid1 != this.regionid ||
        this.groupManagerid1 != this.groupManagerid ||
        this.businessUnitid1 != this.businessUnitid ||
        this.leadPortfolioMgmtGrpid1 != this.leadPortfolioMgmtGrpid ||
        this.portfolioMandateid1 != this.portfolioMandateid ||
        this.clientTypeParentid1 != this.clientTypeParentid ||
        this.salesOfficeid1 != this.salesOfficeid ||
        this.crossHoldingIndicatorid1 != this.crossHoldingIndicatorid ||
        this.productTypeid1 != this.productTypeid ||
        this.canadianMandateid1 != this.canadianMandateid ||
        this.xaumFlag1 != this.xaumFlag) {
        this.pageDirty = true;

      }
    }
    if(this.pageDirty) event.returnValue = false;
  }

  varChange = (event) =>{
    this.varInvestText = event.target.value;
  }

  varBrandChange= (event) =>{
    this.varBrandText = event.target.value;
  }
  varVechChange= (event) =>{
    this.varVechText = event.target.value;
  }

  varPvtClientChange= (event) =>{
    this.varpctpText = event.target.value;
  }

  varBussChange = (event) =>{
    this.varBussText = event.target.value;
  }

  varlpmgChange = (event) =>{
    this.varlpmgText = event.target.value;
  }

  varportmanChange = (event) =>{
    this.varportmanText = event.target.value;
  }

  varRegionChange = (event) =>{
    this.varRegionText = event.target.value;
  }

  varSalesChange  = (event) =>{
    this.varSalesText = event.target.value;
  }

  varIndChange = (event) =>{
    this.varIndText = event.target.value;
  } 

  varProdChange = (event) =>{
    this.varProdText = event.target.value;
  } 

  varCanadianChange = (event) =>{
    this.varCanadianText = event.target.value;
  } 

  vargrpmgrChange = (event) =>{
    this.vargrpmgrText = event.target.value;
  } 
  
  clearsearch = (event) =>{
    this.varInvestText = "";
    this.varBrandText = "";
    this.varVechText = "";
    this.varpctpText = "";
    this.varBussText = "";
    this.varlpmgText = "";
    this.varportmanText = "";
    this.varRegionText = "";
    this.varSalesText = "";
    this.varIndText = "";
    this.varProdText = "";
    this.varCanadianText = "";
    this.vargrpmgrText = "";
    event.target.value = "";
  }

}