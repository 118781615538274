import { Component, OnInit, Inject, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { Rules } from '../../../models/Rules';
import { HttpService } from '../../../services/http.service';
import {LayoutModule, Breakpoints} from '@angular/cdk/layout';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../services/toastr.service';
import { URLHelper } from '../../../services/url.helper';
import { DialogService } from '../../../services/dialog.service';
import {Observable} from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { EditRuleCriteriaComponent } from '../edit-criteria/edit-criteria.component';


@Component({
  selector: 'app-add-rule',
  templateUrl: './add-rule.component.html',
  styleUrls: ['./add-rule.component.css']
})
export class AddRuleComponent implements OnInit {

  form: FormGroup;
  onDirty = new EventEmitter();
  @ViewChild('valueSelect') selectElem: MatSelect;
  @ViewChild('inptCondition') inptCond: MatSelect;

  constructor(
    private http: HttpService, 
    private fb: FormBuilder,
    private toastr: ToasterService,
    private _dialog: MatDialog,
    private url: URLHelper,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { 

    this.form = this.fb.group({
      rule_name             : [null, Validators.required],
      rule_description      : [null, Validators.required], 
      rule_type_string      : ['', Validators.required],
      table_name            : [null, Validators.required],
      delimiter_rule_string : [null],
      rule_type_id          : [null, Validators.required],
      var_variable          : [null],
      var_condition         : [null],
      var_value             : [null]
    });
    //used for url redirection to sent when form is dirty
    this.form.valueChanges.subscribe((data)=>{
      if(this.form.dirty){
        this.onDirty.emit(true);
        this.http.setGoBack(true);
      }      
    });

  }


  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("form dirty ....", this.form.dirty);
    if (this.form.dirty) event.returnValue = false;
  }
  // @HostListener("nav::user_asked_history")
  // history(event){
  //   console.log("history ____________________", event)
  // }

  @HostListener('window:popstate')
  onPopState(event) {
    console.log("pop state.......")
    return false;
    
  }

  
  canDeactivate():Observable<boolean> | boolean{
    if(!this.form.dirty){
      return this.dialogService.confirm("Discard changes for Rule?");
    }
    return true;
  }
  
  public rule_types       = []
  public variables        = []
  public valid_operators  = [];
  public var_values       = [];

  public modVariable      = '';
  public modCondition     = '';
  public modValue         = '';
  public modString        = '';
  
  public rule_strings     = [];
  public table_names      = [];
  public var_sign_val;
  public rule_loading: boolean = false;
  public selectLoading:boolean = false;
  public varntab;
  public pagination:any       = {from: 0, to: 1000};
  public acct_num: any;
  valueLoading:boolean        = false;
  public varSearchTxt         = "";
  public valueSearchTxt       = "";
  public searchTextValue      = "";
  public show_text:boolean    = true;
  public show_select:boolean  = false;
  public error_condition      = "Select Value";
  public var_values_status: boolean = false;

  protected getData = () =>{
    
    this.http.getData(this.url.busrules_show_type).subscribe((data: any) => {
      this.rule_types = data.data;
    });

    this.http.getData(this.url.busrules_var_show).subscribe((data: any) => {
      this.variables = data.data;
    });

  }
  
  getValidOperators = (varntab='', from = 0, to = 1000) =>{
    this.varntab = varntab;
    this.selectLoading    = true;
    const table_name      = this.varntab.split(".")[0];
    const var_name        = this.varntab.split(".")[1];
    const param           = '?col_name=' + var_name + '&' + 'table_name=' + table_name + '&from=' + from + '&to=' + to;
    this.valid_operators  = [];
    this.var_values       = [];
    this.varSearchTxt     = "";

    this.http.getData(this.url.busrules_valid_operators + param).subscribe((data: any) => {
      this.valid_operators = data.data.value;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
    
    // if(var_name == 'ACCT_NUM'){
    //   this.var_values = this.acct_num;
    // }else
    {
      this.http.getData(this.url.busrules_variable_show + param).subscribe((data: any) => {
        this.selectLoading = false;
        if(from > 0){
          this.var_values = [...this.var_values, ...data.data];
        }else{
          this.var_values = data.data;
        }
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }  
  }

  saveRule = (frm) => {
    
    if(this.form.invalid){
      return;
    }

    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const data = JSON.parse(ruleData);


    data.delimiter_rule_string = "STR_START;" + this.rule_strings.join(";") + ";STR_END"
    
    this.http.postData(this.url.busrules_insert_rule_row, {}, data).subscribe((res: any) => {
      if(res.data.error_flag == '0'){
        this.toastr.success("Buss Rule Management added successfully !");
        this._dialog.closeAll();
      }else{
        this.toastr.error(res.message);
      }
      
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  addCondition = (frm) =>{
    
    this.error_condition = "Select Value";
    if(this.form.controls["var_condition"].value.trim() == 'IS NULL'){
      this.var_sign_val = false;
    }else if((!this.searchTextValue && !this.form.controls["var_value"].value) ||
        !this.form.controls["var_variable"].value ||
        !this.form.controls["var_condition"].value
      ){
      this.var_sign_val = true;
      return;
    }else{
      this.var_sign_val = false;
    }
    if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 1 ){
      this.error_condition = "The value like '%VALUE%";
      this.var_sign_val = true;
      return;
    }
    

    if (this.table_names.indexOf(this.form.controls["var_variable"].value.split(".")[0]) != 0){
      this.table_names.push(this.form.controls["var_variable"].value.split(".")[0])
    }
    
    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const dt = JSON.parse(ruleData);
    var strn = '';
    if(dt.var_condition.trim() == 'LIKE'){
      if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
        strn = dt.var_variable + dt.var_condition + "'" + this.searchTextValue.toString().toUpperCase() + "'";
      }else{
        if(dt.var_value != "" && dt.var_value != null ){
          strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
        }else{
          this.error_condition = "The value like '%VALUE%";
        this.var_sign_val = true;
        return;
        }
      }
    }else if(dt.var_condition.trim() == '='){
      if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
        this.var_sign_val = true;
        return;
      }
      if(!dt.var_value){
        strn = dt.var_variable + dt.var_condition + "'" + this.searchTextValue.toString().toUpperCase() + "'";
      }else{
        strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
      }
    }else if(dt.var_condition.trim() == 'IS NULL'){

      strn = dt.var_variable + dt.var_condition

    }else{
      strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
    }
    
    this.rule_strings.push(strn)
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + strn);
    this.form.controls["table_name"].setValue(this.table_names.join(","));
    
    this.form.controls["var_value"].setValue('')
    this.form.controls["var_variable"].setValue('')
    this.form.controls["var_condition"].setValue('')
    
    setTimeout(()=>{
      this.selectElem.trigger.nativeElement.click();
      this.valueSearchTxt ='';
      this.searchTextValue = '';
      this.var_values_status = false;
    }, 0);
  }

  addAnd = () =>{
    this.rule_strings.push(' AND ')
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' AND ');
  }

  addOr = () => {
    this.rule_strings.push(' OR ');
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' OR ');
  }

  addLeftPar = () =>{
    this.rule_strings.push(' ( ');
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' ( ');
  }

  addRgtPar = () =>{
    this.rule_strings.push(' ) ');
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' ) ');
  }

  formUndo = () =>{
    this.rule_strings.pop();
    this.form.controls["rule_type_string"].setValue(this.rule_strings.join(" "));
  }

  formClear = () =>{
    this.rule_strings = [];
    this.form.controls["rule_type_string"].setValue('');
  }

  checkValidCondition = (condition) =>{
    this.var_values_status = false;
    this.error_condition = "";
    this.searchTextValue = "";

    if (condition.trim() == 'IS NULL'){
      this.var_values_status = true;
    }
  }

  checkName = () =>{
    this.rule_loading = true;
    const rulName = this.form.controls["rule_name"].value;
    this.http.getData(this.url.busrules_chk_save + rulName).subscribe((res:any)=>{
      this.rule_loading = false;
      if(parseInt(res.data.flag_save) == 1){
        this.form.controls["rule_name"].setErrors({"duplicate": "Rule Name already exists"});
      }
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });

  }

  cancelDialog = (frm) =>{
    if(this.form.dirty){
      const ok = confirm("Discard changes for Rule?");
      
      if(ok){
      //   // this.saveRule(frm);
      // }else{
        this._dialog.closeAll();
      }
    }else{
      this._dialog.closeAll();
    }
  }
  
  
  onValueChange = (event) =>{
    this.valueLoading = false;
    this.valueSearchTxt = "";
    this.searchTextValue = "";
    if (event) {
      
      console.log(this.selectElem.panel.nativeElement.scrollHeight ,"-", this.selectElem.panel.nativeElement.offsetHeight,"rrrrrrrrrrrr")
      this.selectElem.panel.nativeElement.addEventListener('scroll', (event: any) => {
        
        // console.log(parseInt((this.selectElem.panel.nativeElement.scrollTop).toFixed(0)),' === ', this.selectElem.panel.nativeElement.scrollHeight ,"-", this.selectElem.panel.nativeElement.offsetHeight," = ",this.selectElem.panel.nativeElement.scrollHeight - this.selectElem.panel.nativeElement.offsetHeight, "sssssssssssssssss")
        if (parseInt((this.selectElem.panel.nativeElement.scrollTop).toFixed(0)) === this.selectElem.panel.nativeElement.scrollHeight - this.selectElem.panel.nativeElement.offsetHeight) {
          const from = this.pagination.to;
          const to = this.pagination.to + 1000;
          this.pagination.from = from;
          this.pagination.to = to;
          const table_name      = this.varntab.split(".")[0];
          const var_name        = this.varntab.split(".")[1];
          const param           = '?col_name=' + var_name + '&' + 'table_name=' + table_name + '&from=' + from + '&to=' + to;
          this.valueLoading = true;

          this.http.getData(this.url.busrules_variable_show + param).subscribe((data: any) => {
            this.valueLoading = false;
            if(from > 0){
              this.var_values = [...this.var_values, ...data.data];
            }else{
              this.var_values = data.data;
            }
          });
        }
      });
    }
  }

  registerPanelScrollEvent = () =>{
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener('scroll', event => this.loadAllOnScroll(event));
  }

  loadAllOnScroll = (event) =>{
    console.log("sssssssssssssssssssssss")
    // getValidOperators()
  }
  
  getAcctNum = () =>{
    const param  = '?col_name=ACCT_NUM&' + 'table_name=ACCT_SDIM&from=0&to=10000';
    
    this.http.getData(this.url.busrules_variable_show + param).subscribe((res:any)=>{
      this.acct_num = res.data.data;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    })
  }

  varChange = (event) =>{
    this.varSearchTxt = event.target.value;
    this.searchTextValue = "";
  }
  valueChange = (event) =>{
    this.valueSearchTxt = event.target.value;
    this.searchTextValue = event.target.value;
    setTimeout(()=>{
      this.valueSearchTxt = event.target.value;
      this.searchTextValue = event.target.value;
    },0)
    if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
      
      this.selectElem.panel.nativeElement.focus();
    }
  }
  clearsearch = (event) =>{
    this.varSearchTxt = "";
    this.valueSearchTxt = "";
    event.target.value = "";
  }
  editCriteria = () =>{
    const dialogConfig  = new MatDialogConfig();
    dialogConfig.data   = {'profile_string': this.rule_strings};
    dialogConfig.width  = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxHeight  = '100vw';
    dialogConfig.maxHeight  = '100vh';
    dialogConfig.panelClass = 'full-screen-modal';
    
  
    const dialogRef = this._dialog.open(EditRuleCriteriaComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      
      this.form.controls["rule_type_string"].setValue(this.rule_strings.join(" "));
    });
  }

  ngOnInit(): void {
    this.getData();    
    //this.getAcctNum();    
  }


}
