import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AccountclassService extends BaseService {

  constructor(private inject: HttpClient) {
    super(inject);
  }
  getAccountInfo(account_no:string,direction:string): Observable<any> {
   
    let resource = "/accounts/acct_attrib_info/" + account_no + '/' + direction;
   
    return this.getRequest<any>(resource);
  }
  //load brand list
  getBrandList(): Observable<any> {
    let resource = "/attributes/brands/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load vehicle list
  getVehicleTypeList(): Observable<any> {
    let resource = "/attributes/vehicletype/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load region list
  getRegionList(): Observable<any> {
    let resource = "/attributes/regions/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
   //load salesoffice list
   getsalesOfficeList(): Observable<any> {
    let resource = "/attributes/salesoffice/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load invstobj list
  getinvestobjList(): Observable<any> {
    let resource = "/attributes/investobj/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load invstadv list
  getinvestadvList(): Observable<any> {
    let resource = "/attributes/investadv/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
    //load invstadv list
    getbusinessList(): Observable<any> {
      let resource = "/attributes/busiunit/list";
      //console.log(resource)
      return this.getRequest<any>(resource);
    }
  //load portman list
  getportmanList(): Observable<any> {
    let resource = "/attributes/portman/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load pctp1 list
  getpctpList(): Observable<any> {
    let resource = "/attributes/pct1p/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
   //load xhldind list
   getxhldindList(): Observable<any> {
    let resource = "/attributes/xhldind/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load producttype list
  getproducttypeList(): Observable<any> {
    let resource = "/attributes/producttype/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load canadaman list
  getcanadamanList(): Observable<any> {
    let resource = "/attributes/canadaman/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load lpmg list
  getlpmgList(): Observable<any> {
    let resource = "/attributes/lmpg/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load grpmagr list
  getgroupmgrList(): Observable<any> {
    let resource = "/attributes/groupmgr/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  saveAccountAttributes(accountObject: any){
    let resource = "/accounts/acct_sdim_set_attrib";
    //console.log(resource);
    return this.postRequest(resource, accountObject);
  }
  getNextAccountNo(account_no:string): Observable<any> {
    let resource = "/accounts/next_account_number?acct_number="+account_no;
    return this.getRequest<any>(resource);
  }
}
