import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { TemplateModel1 } from 'src/app/models/dataloads';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { AcctAumSdmService } from 'src/app/services/acct-aum-sdm.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { AccountclassService } from 'src/app/services/accountclass.service';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe, formatDate } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-aum-acct-sdm',
  templateUrl: './aum-acct-sdm.component.html',
  styleUrls: ['./aum-acct-sdm.component.css'],
})
export class AumAcctSdmComponent implements OnInit {
  [x: string]: any;

  visible = true;
  accountNo: string = '';

  displayColumns = [
    'radio',
    'firstEffectiveDate',
    'lastEffectiveDate',
    'brandName',
    'brandID',
    'invstAdvisorName',
    'invstAdvisorID',
    'invstObjectName',
    'invstObjectID',
    'vehicleName',
    'vehicleID',
    'regionName',
    'regionID',
    'portfolioMandateName',
    'portfolioMandateID',
    'pvtClientTypeName',
    'pvtClientTypeID',
    'salesOfficeName',
    'salesOfficeID',
    'businessUnitName',
    'businessUnitID',
    'leadPortMgtGrpName',
    'leadPortMgtGrpID',
    'xHLDIndID',
    'xHLDIndName',
    'productTypeID',
    'productTypeName',
    'canadianMandateID',
    'canadianMandateName',
    'groupMgrName',
    'groupMgrCode',
    'groupMgrID',
  ];
  @ViewChild('tempsort', { static: true }) tempsort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('panel1') firstPanel: MatExpansionPanel;
  public templateList: TemplateModel1[] = [];
  public loadTemplateDatasource = new MatTableDataSource();
  panelOpenState: boolean;
  public tempRecord: any = [];
  public lastRecord: any = [];
  public firstRecord: any = [];
  accountacctinfo: any = {};
  public firstEffectiveDate: Date;
  public lastEffectiveDate: Date;
  pageDirty: boolean = false;
  accountType: string = '';
  accountName: string = '';
  lastUpdatedDate: string = '';
  lastUpdatedBy: string = '';
  investmentObjectid: number = 0;
  investmentAdvname: string = '';
  brandid: number = 0;
  vehicleTypeid: number = 0;
  regionid: number = 0;
  groupManagerid: number = 0;
  businessUnitid: number = 0;
  leadPortfolioMgmtGrpid: number = 0;
  portfolioMandateid: number = 0;
  clientTypeParentid: number = 0;
  salesOfficeid: number = 0;
  crossHoldingIndicatorid: number = 0;
  productTypeid: number = 0;
  canadianMandateid: number = 0;
  brandlist: any = [];
  vehicletypelist: any = [];
  regionList: any = [];
  salesOfficeList: any = [];
  invstObjList: any = [];
  invstAdvList: any = [];
  portmanList: any = [];
  pctpList: any = [];
  xhldindList: any = [];
  producttypeList: any = [];
  canadamanList: any = [];
  businessList: any = [];
  lpmgList: any = [];
  grpmgrList: any = [];
  validateinfo: string = '';
  isLoading: boolean = false;
  isEdit: boolean = false;
  showView: boolean = false;
  enable: boolean = false;
  brandfilterList: any = [];
  xhldindFilterList: any = [];
  portmanFilteredList: any = [];
  selectedrecord: string = '';

  // filter for dropdowns
  varInvestText;
  varInvestAdvText;
  varBrandText;
  varVehicleText;
  varRegionText;
  vargrpmgrText;
  varBusinessText;
  varLpmgtText;
  varPortmanText;
  varpct1pText;
  varSalesOfficeText;
  varIndicatorText;
  varProdTypeText;
  varCanadianText;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private accountacctservice: AcctAumSdmService,
    private toastr: ToasterService,
    private accountservice: AccountclassService,
    private confirmDialog: DialogService
  ) {
    this.accountNo = this.route.snapshot.paramMap.get('id');
  }

  clearsearch = (event) => {
    this.varBrandText = '';
    this.varInvestText = '';
    this.varVehicleText = '';
    this.varRegionText = '';
    this.vargrpmgrText = '';
    this.varBusinessText = '';
    this.varLpmgtText = '';
    this.varPortmanText = '';
    this.varpct1pText = '';
    this.varSalesOfficeText = '';
    this.varIndicatorText = '';
    this.varProdTypeText = '';
    this.varCanadianText = '';

    event.target.value = '';
  };
  searchInvestObj = (event) => {
    this.varInvestText = event.target.value;
  };
  searchBrand = (event) => {
    this.varBrandText = event.target.value;
  };
  searchVehicle = (event) => {
    this.varVehicleText = event.target.value;
  };
  searchRegion = (event) => {
    this.varRegionText = event.target.value;
  };
  searchgrpmgr = (event) => {
    this.vargrpmgrText = event.target.value;
  };
  searchBusiness = (event) => {
    this.varBusinessText = event.target.value;
  };
  searchLpmgt = (event) => {
    this.varLpmgtText = event.target.value;
  };
  searchPortman = (event) => {
    this.varPortmanText = event.target.value;
  };
  searchpct1p = (event) => {
    this.varpct1pText = event.target.value;
  };
  searchSalesOffice = (event) => {
    this.varSalesOfficeText = event.target.value;
  };
  searchIndicator = (event) => {
    this.varIndicatorText = event.target.value;
  };
  searchProductType = (event) => {
    this.varProdTypeText = event.target.value;
  };
  searchCanadian = (event) => {
    this.varCanadianText = event.target.value;
  };

  ngOnInit(): void {
    this.getAccountAcctSdmInformation();

    forkJoin({
      Brand: this.accountservice.getBrandList(),
      Vehicle: this.accountservice.getVehicleTypeList(),
      region: this.accountservice.getRegionList(),
      salesoffice: this.accountservice.getsalesOfficeList(),
      pctp: this.accountservice.getpctpList(),
      portman: this.accountservice.getportmanList(),
      xhldindicator: this.accountservice.getxhldindList(),
      canadian: this.accountservice.getcanadamanList(),
      business: this.accountservice.getbusinessList(),
      invest: this.accountservice.getinvestobjList(),
      lpmg: this.accountservice.getlpmgList(),
      producttype: this.accountservice.getproducttypeList(),
      groupmgr: this.accountservice.getgroupmgrList(),
    }).subscribe((result: any) => {
      this.brandlist = result.Brand.data;
      this.vehicletypelist = result.Vehicle.data;
      this.regionList = result.region.data;
      this.salesOfficeList = result.salesoffice.data;
      this.pctpList = result.pctp.data;
      this.portmanList = result.portman.data;
      this.xhldindList = result.xhldindicator.data;
      this.canadamanList = result.canadian.data;
      this.businessList = result.business.data;
      this.invstObjList = result.invest.data;
      this.lpmgList = result.lpmg.data;
      this.producttypeList = result.producttype.data;
      this.grpmgrList = result.groupmgr.data;
    });
  }
  getAccountAcctSdmInformation() {
    this.isLoading = true;
    this.accountacctservice
      .getAccountAcctSdmInfo(this.accountNo)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.loadTemplateDatasource = new MatTableDataSource(result.data);
          this.loadTemplateDatasource.sort = this.tempsort;
          this.loadTemplateDatasource.paginator = this.paginator;
          this.firstRecord = result.data[0];
          this.lastRecord = result.data[result.data.length - 1];
          this.validateAccountAcctSdmInformation();
          this.isLoading = false;
        }
      });
  }
  bindHtml(output: any) {
    let finalvalue = '';

    finalvalue = '<table><tr>';
    finalvalue += '<td class="col-16">' + output + '</td></tr></table>';

    return finalvalue;
  }
  validateAccountAcctSdmInformation() {
    var outputdata: any[];
    // let messages='';
    this.accountacctservice
      .validateAUMACCTHistory(this.accountNo)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.validateinfo = '<table><tr>';
          this.validateinfo +=
            '<td class="col-16"><font color="#FF0000">Data is inconsistent for the following effective dates:</font></td></tr></table>';

          if (result.data.meesage_1 != null) {
            outputdata = result.data.meesage_1.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">First Effective date is greater than Last Effective date for :</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
          if (result.data.meesage_2 != null) {
            outputdata = result.data.meesage_2.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">There is a time gap between the Last Effective date and the First Effective date for:</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
        }
      });
  }

  redirectToAcctSdm() {
    this.router.navigate(['/acct-sdm', { id: this.accountNo }]);
  }
  redirectToAccountPage() {
    this.router.navigate(['/account-attributes', { id: this.accountNo }]);
  }
  closePanel() {
    this.firstPanel.close();
    this.pageDirty = false;
  }

  loadDetailsPanel() {
    this.isEdit = false;
    this.pageDirty = true;
    if (this.tempRecord['aum_acct_sdim_key'] != undefined) {
      this.showView = true;
      this.selectedrecord = this.tempRecord['aum_acct_sdim_key'];
      this.firstPanel.open();
      this.brandfilterList = this.brandlist.filter(
        (x: any) => x.aum_brand_status == 'Active'
      );
      let obj = this.brandfilterList.filter(
        (x: any) =>
          x.aum_brand_dim_id == this.tempRecord['aum.aum_brand_dim_id']
      );

      if (obj.length == 0) {
        this.brandfilterList.push({
          aum_brand_dim_id: this.tempRecord['aum.aum_brand_dim_id'],
          aum_brand_name: this.tempRecord['aum.aum_brand_name'],
        });
      }

      this.portmanFilteredList = this.portmanList.filter(
        (x: any) => x.aum_port_man_status == 'Active'
      );
      obj = this.portmanFilteredList.filter(
        (x: any) =>
          x.aum_port_man_dim_id == this.tempRecord['aum.aum_port_man_dim_id']
      );

      if (obj.length == 0) {
        this.portmanFilteredList.push({
          aum_port_man_dim_id: this.tempRecord['aum.aum_port_man_dim_id'],
          aum_port_man_name: this.tempRecord['aum.aum_port_man_name'],
        });
      }

      this.xhldindFilterList = this.xhldindList.filter(
        (x: any) => x.aum_xhld_indicator_status == 'Active'
      );
      obj = this.xhldindFilterList.filter(
        (x: any) =>
          x.aum_xhld_indicator_dim_id ==
          this.tempRecord['aum.aum_xhld_indicator_dim_id']
      );

      if (obj.length == 0) {
        this.xhldindFilterList.push({
          aum_xhld_indicator_dim_id:
            this.tempRecord['aum.aum_xhld_indicator_dim_id'],
          aum_xhld_indicator_name:
            this.tempRecord['aum.aum_xhld_indicator_name'],
        });
      }
      this.fillAccountInfo(this.tempRecord);

      // if (
      //   this.lastRecord['aum_acct_sdim_key'] !=
      //   this.tempRecord['aum_acct_sdim_key']
      // ) {
      //   this.isEdit = true;
      // }
      if((this.tempRecord['aum.last_effective_date'] == null || this.tempRecord["aum.last_effective_date"] == "")){
        this.isEdit = false;
      }else{
        this.isEdit = true; 
      }
    }
  }
  viewAttribute(event: any) {
    this.tempRecord = event.value;
    console.log(event.value)
  }

  fillAccountInfo(source: any) {
    this.accountNo = source['acct.acct_num'];
    this.accountType = source['acct.tis_acct_type'];
    this.accountName = source['acct.acct_name'];
    this.lastUpdatedDate = formatDate(
      source['aum.system_last_updated_date'],
      'MM/dd/yyyy hh:mm:ss a',
      'en_US'
    );
    this.lastUpdatedBy = source['aum.system_last_updated_by_user_id'];
    this.groupManagerid = source['aum.aum_group_mgr_dim_id'];
    this.brandid = source['aum.aum_brand_dim_id'];
    this.investmentAdvname = source['aum.aum_invst_adv_name'];
    this.investmentObjectid = source['aum.aum_invst_obj_dim_id'];
    this.vehicleTypeid = source['aum.aum_vehicle_type_dim_id'];
    this.regionid = source['aum.aum_region_dim_id'];
    this.portfolioMandateid = source['aum.aum_port_man_dim_id'];
    this.clientTypeParentid = source['aum.aum_pct1p_dim_id'];
    this.salesOfficeid = source['aum.aum_sale_off_dim_id'];
    this.businessUnitid = source['aum.aum_busi_unit_dim_id'];
    this.leadPortfolioMgmtGrpid = source['aum.aum_lpmg_dim_id'];
    this.crossHoldingIndicatorid = source['aum.aum_xhld_indicator_dim_id'];
    this.productTypeid = source['aum.aum_product_type_dim_id'];
    this.canadianMandateid = source['aum.aum_canadian_mandate_dim_id'];
    this.firstEffectiveDate =
      source['aum.first_effective_date'] != null
        ? this.datepipe.transform(
            source['aum.first_effective_date'],
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source['aum.first_effective_date'];
    this.lastEffectiveDate =
      source['aum.last_effective_date'] != null
        ? this.datepipe.transform(
            source['aum.last_effective_date'],
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source['aum.last_effective_date'];
    this.enable = source['aum.last_effective_date'] != null ? false : true;
  }
  copyAsNewRecord() {
    const accountinfo = {
      p_aum_acct_sdim_key: this.tempRecord['aum_acct_sdim_key'],
    };
    this.accountacctservice
      .copyHistoryAUMAcctRecord(accountinfo)
      .subscribe((result: any) => {
        if (result.status == 200) {
          console.log(result.data);
          this.toastr.success('New Record created Scuccessfully');
          this.getAccountAcctSdmInformation();
        }
      });
  }
  saveAccountInfo() {
    this.accountacctinfo = {
      p_aum_acct_sdim_key: this.tempRecord['aum_acct_sdim_key'],
      p_aum_invst_obj_dim_id: this.investmentObjectid,
      p_aum_invst_adv_dim_id: this.tempRecord['aum.aum_invst_adv_dim_id'],
      p_aum_brand_dim_id: this.brandid,
      p_aum_vehicle_type_dim_id: this.vehicleTypeid,
      p_aum_region_dim_id: this.regionid,
      p_aum_group_mgr_dim_id: this.groupManagerid,
      p_aum_busi_unit_dim_id: this.businessUnitid,
      p_aum_lpmg_dim_id: this.leadPortfolioMgmtGrpid,
      p_aum_port_man_dim_id: this.portfolioMandateid,
      p_aum_pct1p_dim_id: this.clientTypeParentid,
      p_aum_sale_off_dim_id: this.salesOfficeid,
      p_aum_product_type_dim_id: this.productTypeid,
      p_first_effective_date:
        this.firstEffectiveDate != null
          ? this.datepipe.transform(
              this.firstEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.firstEffectiveDate,
      p_last_effective_date:
        this.lastEffectiveDate != null
          ? this.datepipe.transform(
              this.lastEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.lastEffectiveDate,
      p_aum_xhld_indicator_dim_id: this.crossHoldingIndicatorid,
      p_aum_canadian_mandate_dim_id: this.canadianMandateid,
    };
    console.log(this.accountacctinfo);
    this.accountacctservice
      .saveAccountACCTAttributes(this.accountacctinfo)
      .subscribe((res) => {
        this.toastr.success('Account details Updated Scuccessfully');
        this.getAccountAcctSdmInformation();
      }),
      (err) => {
        this.toastr.error(err.error.message);
      };
  }
  deleteRecord() {
    if (confirm('Are you sure want to delete this record ? ')) {
      if (this.tempRecord['aum.last_effective_date'] == null) {
        this.toastr.error('Delete is not allowed for First Record');
      } else if (
        this.lastRecord['aum_acct_sdim_key'] ==
        this.tempRecord['aum_acct_sdim_key']
      ) {
        this.toastr.error('Delete is not allowed for Last Record');
      } else {
        this.accountacctservice
          .deleteHistoryAUMAcctRecord(this.tempRecord['aum_acct_sdim_key'])
          .subscribe((result: any) => {
            if (result.status == 200) {
              if (result.data.error_message == null) {
                this.toastr.success('Record deleted successfully');
                this.getAccountAcctSdmInformation();
              } else {
                this.toastr.error(result.data.error_message);
              }
            }
          }),
          (err) => {
            this.toastr.error(err.error.message);
          };
      }
    }
  }
  canDeactivate(): Observable<boolean> | boolean {
    if (this.pageDirty) {
      return this.confirmDialog.confirm('Are you sure you want to leave?');
    }
    return true;
  }
}
