import { Component, Inject, OnInit, HostListener, Output, ElementRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributemanagementService } from 'src/app/services/attributemanagement.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { XholdAttributes } from 'src/app/models/attributes';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-crossholdingindicator-management-dialog',
  templateUrl: './crossholdingindicator-management-dialog.component.html',
  styleUrls: ['./crossholdingindicator-management-dialog.component.css']
})
export class CrossholdingindicatorManagementDialogComponent implements OnInit {
  mpar = "Yes";
  mparControl = new FormControl(this.mpar, Validators.required);
  message;
  title;
  addAttr: boolean;
  attr;
  in_aum_dim_id;
  in_aum_code;
  in_aum_dim_name;
  in_aum_dim_desc;
  in_aum_status;
  in_v_mpar_ext;
  savexholdAttr: any;
  editxholdAttr: any;
  resultXhold: any;
  resultCrossholdingIndicator: any;
  selectedCrossholdingIndicator: XholdAttributes[] = [];
  activatedSuccessfully: boolean;
  code; desc; name; status; action; id;
  isReadOnly: boolean;
  isDisabled: boolean;
  isActive: boolean = false;
  isDialogOpen: boolean = false;
  public pageDirty: boolean = false;
  private data: any;

  @Output() formResponse = new EventEmitter<any>();
  private elRef:ElementRef;

  constructor(
    // public dialogRef: MatDialogRef<CrossholdingindicatorManagementDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private attributeService: AttributemanagementService,
    private toastr: ToasterService,
    private confirmDialog: DialogService) {
  }

  ngOnInit(): void {
    // console.log(this.data);
    this.title = this.data.activeAttr;
    this.message = this.data.message;
    this.addAttr = this.data.addAttr;
    this.attr = this.data.attr;
    this.code = this.data.code;
    this.desc = this.data.desc;
    this.name = this.data.name;
    this.action = this.data.action;
    this.status = this.data.status;
    this.id = this.data.id;
    this.mpar = this.data.mpar
    console.log(this.data);
    if (this.action == "Edit") {
      this.isReadOnly = true;
    }
    else {
      this.isDisabled = true;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    console.log(this.code + " || " + this.desc + " ||" + this.name + " ||" + this.mpar);

    if (this.action == "Edit") {
      console.log("0");
      if (this.desc != this.data.desc || this.name != this.data.name) {
        this.pageDirty = true;
      }
    }
    else {

      if ((this.code != undefined || this.desc != undefined || this.name != undefined) &&
        (this.code != "" && this.desc != "" && this.name != "")) {
        console.log("1");
        this.pageDirty = true;
      }
    }

    if (this.pageDirty) {
      console.log("010");
      return this.confirmDialog.confirm("Do you want to discard the changes?");
    }
    return true;
  }

  fromParent = (data) =>{
    this.data = data;
    this.title = data.activeAttr;
    this.message = data.message;
    this.addAttr = data.addAttr;
    this.attr = data.attr;
    this.code = data.code;
    this.desc = data.desc;
    this.name = data.name;
    this.action = data.action;
    this.status = data.status;
    this.id = data.id;
    this.mpar = data.mpar
    this.isReadOnly = false
    if (this.action == "Edit") {
      this.isReadOnly = true;
    }
    console.log(this, data)
  }

  change(event){
    this.pageDirty = true;
    this.formResponse.emit({pagedirty: true, msg: 'form changed'})
  }

  ClearControls() {
    this.in_aum_code = "";
    this.in_aum_dim_name = "";
    this.in_aum_dim_desc = "";
  }

  SaveAttributes() {
    // this.getAttributes();   
    this.selectedCrossholdingIndicator = []
    if (this.action == "Edit") {
      this.editxholdAttr = {
        in_aum_dim_id: this.id,
        in_aum_code: this.code,
        in_aum_dim_name: this.name,
        in_aum_dim_desc: this.desc,
        in_v_mpar_ext: this.mpar == "Yes" ? true : false
      }
       console.log(this.editxholdAttr);
      this.attributeService.updatexholdAttributes(this.editxholdAttr).subscribe(res => {
        if (res) {
          this.activatedSuccessfully = true;
          if (this.activatedSuccessfully == true) {
            this.toastr.success("Attribute Updated");
            this.ClearControls();
          }
        }
        this.formResponse.emit({pagedirty: false, msg: 'save'})
        // this.dialogRef.close(this.selectedCrossholdingIndicator);
      },
        err => {
          this.activatedSuccessfully = false
          this.toastr.error(err.error.message);
        }
      );
    }
    else {
      // console.log("Add: " + this.action);
      this.savexholdAttr = {
        in_aum_code: this.code,
        in_aum_dim_name: this.name,
        in_aum_dim_desc: this.desc,
        in_parentid: null,
        in_aum_sort_order: 0,
        in_levelno: 0,
        in_status: "Inactive"
        // INCLUDE_FLAG_MPAR_EXT:this.mpar
      }

      // console.log(this.selectedCrossholdingIndicator);
      this.attributeService.savexholdAttributes(this.savexholdAttr).subscribe(res => {
        this.toastr.success("Attribute Added");
        this.attributeService.getCrossholdingIndicatorList().subscribe(result => {
          this.resultCrossholdingIndicator = result.data;
          // console.log(this.resultCrossholdingIndicator);
          for (let i = 0; i <= this.resultCrossholdingIndicator.length - 1; i++) {
            this.selectedCrossholdingIndicator.push({
              "value": this.resultCrossholdingIndicator[i].aum_xhld_indicator_code,
              "viewValue": this.resultCrossholdingIndicator[i].aum_xhld_indicator_name,
              "description": this.resultCrossholdingIndicator[i].aum_xhld_indicator_desc,
              "active": this.resultCrossholdingIndicator[i].aum_xhld_indicator_status,
              "id": this.resultCrossholdingIndicator[i].aum_xhld_indicator_dim_id,
              "sortOrder": this.resultCrossholdingIndicator[i].aum_sort_order,
              "mpar": this.resultCrossholdingIndicator[i].INCLUDE_FLAG_MPAR_EXT
            });
          }
          this.formResponse.emit({pagedirty: false, msg: 'save'})
          /*
          for (let i = 0; i <= this.selectedCrossholdingIndicator.length - 1; i++) {
            // console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value + "  --  " + this.saveBrandAttr["aum_code"]);
            if ((this.selectedCrossholdingIndicator[i].active == "Active")
              && (this.selectedCrossholdingIndicator[i].value.toLowerCase() != this.savexholdAttr["in_aum_code"].toLowerCase())) {
              //console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value.toLowerCase() + "  --  " + this.saveBrandAttr["aum_code"].toLowerCase());
              let xholdStatus =
              {
                dim_code: this.selectedCrossholdingIndicator[i].value,
                status: "Inactive"
              }             
            }
          } */
          // this.dialogRef.close(this.selectedCrossholdingIndicator);
        });
      }),
        err => {
          // this.activatedSuccessfully = false
          this.toastr.error(err.error.message);
        }
    }
  }

  onNoClick() {
    this.isDialogOpen = false;
    const ans = this.canDeactivate();
    this.pageDirty=false;
    
    if (ans) {
      this.formResponse.emit({pagedirty: false, msg: 'close'})
      // this.dialogRef.close(this.isDialogOpen);
    }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.pageDirty) event.returnValue = false;
  }
  
}
