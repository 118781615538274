<!-- <h2 mat-dialog-title>Add Profile</h2> -->
<form [formGroup]="form" #proFrm (ngSubmit)="saveProfile(form)" novalidate>
    <div class="modal-body">

    
        
    <mat-dialog-content class="mat-typography">
    
        <mat-form-field class="profile" appearance="legacy">
            <mat-label>Profile Name</mat-label>
            <input (blur)="checkName(form)" matInput placeholder="Rule Name" formControlName="profile_name">
            <div *ngIf="profile_loading" class="profile_loading">
                <mat-spinner diameter="20" color="warn"></mat-spinner>
            </div>
            <mat-hint>
            <div class="error" *ngIf="form.controls['profile_name'].invalid && (form.controls['profile_name'].dirty || form.controls['profile_name'].touched)" class="alert">
                <div  class="error" *ngIf="form.controls['profile_name'].errors.required">
                Please enter Profile Name
                </div>
            
            <div  class="error" *ngIf="form.controls['profile_name'].errors?.checkName">
                Duplicate Crossholding Profile names are not allowed.
            </div></div>
           </mat-hint>
        </mat-form-field>
                
        <mat-form-field appearance="legacy">
            <mat-label>Profile Description</mat-label>
            <textarea matInput placeholder="Description" rows="3" formControlName="profile_description"></textarea>
            
            <mat-hint>
                <div class="error" *ngIf="form.controls['profile_description'].invalid && (form.controls['profile_description'].dirty || form.controls['profile_description'].touched)" class="alert">
                    <div  class="error" *ngIf="form.controls['profile_description'].errors.required">
                    Please enter Profile Description
                    </div>
                </div>
               </mat-hint>
        </mat-form-field>
                
        <!-- <mat-form-field>
        <mat-select placeholder="Rule Type" formControlName="profile_type_id">
            <mat-option *ngFor="let type of profile_types" [value]="type.profile_type_id">
                {{ type.profile_type_name }}
            </mat-option>
        </mat-select>
        </mat-form-field> -->
            
            
        

        <mat-grid-list [cols]="breakpoint"  (window:resize)="onResize($event)" rowHeight="15:5">
            <mat-grid-tile>
                <mat-form-field [ngClass]="{'operate-loading': operate_loading}">
                    <!-- [(ngModel)]="modVariable"  -->
                    <mat-select formControlName="var_variable" placeholder="Variable" (selectionChange)="getValidOperators($event.value)" no-space>
                        
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let vls of variables | search : varText : 'aum_buss_var_ref_tab' : 'aum_buss_var_name' " [value]="vls.aum_buss_var_ref_tab + '.' + vls.aum_buss_var_name">
                           {{vls.aum_buss_var_ref_tab}}.{{ vls.aum_buss_var_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <!-- [(ngModel)]="modCondition"  -->
                    <mat-select formControlName="var_condition" placeholder="Condition" (selectionChange)="checkValue($event.value)">
                        <mat-option *ngFor="let opt of valid_operators" value={{opt}}>{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <!-- <mat-select formControlName="var_value" placeholder="Value">
                        <mat-option *ngFor="let vlu of var_values" value="{{vlu}}">{{vlu}}</mat-option>
                    </mat-select> -->
                    <mat-label>Value</mat-label>
                    <input matInput formControlName="var_value" placeholder="Value" #var_value>
                    <mat-hint>
                        <div class="text-danger">
                            {{valid_variable_entry}}
                        </div>
                    </mat-hint>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <!-- [disabled]="!form.controls['var_variable'].value || !form.controls['var_condition'].value || !form.controls['var_value'].value" -->
                <button type="button" #addBtn [disabled]="!form.controls['var_variable'].value || !form.controls['var_condition'].value" class="add" mat-raised-button color="primary" (click)="addCondition(form)">Add</button>
            </mat-grid-tile>    
        </mat-grid-list>

        <div class="row margin-top-bottom-10">
            <div class="col-2">
                <button type="button" class="add" mat-raised-button (click)="addAnd()">And</button>
            </div>
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addOr()">Or</button>
            </div>
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addLeftPar()">(</button>
            </div>
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addRgtPar()">)</button>
            </div>
        </div>
        
        <mat-form-field appearance="legacy">
            <mat-label>Condition</mat-label>
            <!-- [(ngModel)] = 'modString' -->
            <textarea [ngClass]="{'viewquery': viewquery}" class="input-text-icon"  readonly matInput placeholder="Condition" rows="5" formControlName="profile_type_string"></textarea>
            <mat-hint>
                <div  class="error" *ngIf="form.controls['profile_type_string'].errors?.invalidquery">
                    RuleString provided may be invalid.
                </div>
            </mat-hint>
            <!-- <mat-hint>Hint</mat-hint> -->
            <button type="button" (click)="viewQuery()" mat-mini-fab color="primary" matTooltip="View Query" aria-label="View Query" class="view-button">
                        
            </button>
            <button type="button" (click)="editCriteria()" mat-mini-fab color="primary" matTooltip="Edit Criteria" aria-label="Edit Criteria" class="edit-button">
                <mat-icon>edit</mat-icon>
            </button>
            <button type="button" (click)="formUndo()" mat-mini-fab color="primary" matTooltip="Undo Last Action" aria-label="Undo Last Action" class="undo-button">
                <mat-icon>undo</mat-icon>
            </button>
            <button type="button" (click)="formClear()" mat-mini-fab color="primary" matTooltip="Clear All" aria-label="Clear All" class="clear-button">
                <mat-icon>delete</mat-icon>
            </button>

        </mat-form-field>
        <mat-card class="example-card rules-text">
            <div class="where">
                <div *ngFor="let item of where_rule_strings; index as idx">
                    <span [ngClass]="{'selected': idx == whereIdx}" 
                    (click)="selectedWhere($event, idx, item)" >
                        {{ item }}
                    </span>
                    <button (click)="deleteCriteria(idx)" type="button" class="del-btn" matTooltip="Delete criteria" >clear</button>
                </div>
            </div>
            </mat-card>

        
    
    </mat-dialog-content>
    </div>
    <div class="modal-footer">
        <!-- <button mat-raised-button type="button" (click)="formUndo()">Undo</button>
        <button mat-raised-button type="button" (click)="formClear()">Clear All</button> -->
        <button mat-raised-button class="add" color="primary" type="submit" [disabled]="!form.dirty">Save</button>
        <button mat-button type="button" (click)="closeDialog()">Cancel</button>
        <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Add</button> -->
    </div>
</form>