import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of, throwError } from "rxjs";
import { catchError, retry, map, } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(public authService: AuthService, 
                public _router: Router
    ) {}

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        
        if (err.status === 401 || err.status === 403) {
            this._router.navigateByUrl(`/forbidden`);
            return of(err.message);
        }else if(err.status === 500 || err.status === 502 || err.status === 404){
            this._router.navigateByUrl(this._router.url, {skipLocationChange: true}).then(() => {
                this._router.navigate([this._router.url]);
            });
        }
        return throwError(err);
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const ipAddress = request.url.match(/(\d+\.){3}\d+/);
        
        if (ipAddress?.length > 0 && ipAddress[0] != "127.0.0.1"){
            
            this._router.navigateByUrl(`/forbidden`);
        }

        // var ip =  request.url.match(/\.[0-9.]*/g);
        // console.log(ipAddress)
        // if(ip.length == 1 && ip[0].length > 9){
        //     this._router.navigateByUrl(`/forbidden`);
        // }

        let ignore =
            typeof request.body === 'undefined' ||
            request.body === null ||
            request.body.toString() === '[object FormData]' || // <-- This solves your problem
            request.headers.has('Content-Type')
        
        if (ignore) {


            // request = request.clone({
            //     setHeaders: {
            //         accept: 'application/json',
            //         ...(this.authService.getToken()
            //             ? { authorization: this.authService.getToken() }
            //             : { authorization: '' }),
            //     },
            // })
            // request = request.clone({
            //     setHeaders: { Authorization: `Bearer A3456@deedf4efe` } //{ Authorization: `Bearer ${account.token}` }
            // });
            return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
        }
        // request = request.clone({
        //     setHeaders: { Authorization: `Bearer A3456@deedf4efe` } //{ Authorization: `Bearer ${account.token}` }
        // });
        // request.clone({
        //     setHeaders: {
        //         'content-type': 'application/json',
        //         accept: 'application/json',
        //         ...(this.authService.getToken()
        //             ? { authorization: this.authService.getToken() }
        //             : { authorization: '' }),
        //     },
        // })

        // return next.handle(request)
        return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
    }
}