import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CategoryModel } from 'src/app/models/dataloads';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class DataloadService extends BaseService {
  public dataList: CategoryModel[];
  
  constructor(private inject: HttpClient) {
    super(inject);
  }

  getCategories(): Observable<any> {
    let resource = "/templates/list/AUM_Loads_Cat";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  getTemplates(code: string) {
    let resource;
    if(code!="")
    {
       resource = "/templates/fetch?category_type="+code;
    }
    else
    {
        resource = "/templates/fetch";
    }
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  uploadFile(file: File)
  {
    //let resource = "/api/thumbnail-upload";
    //console.log(resource);
    return this.fileuploadRequest(file)
  }
}
    