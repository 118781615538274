

        <mat-tab-group [(selectedIndex)]="selectedIndex" (click)="tabClick($event)" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4 rules-tab">
            <mat-tab label="Crossholding Profile" color="primary">
                <ng-template matTabContent>
                   <app-crossholding-profile 
                        [profileid]="profileId" 
                        (profileSelected)="onProfileSelected($event)"
                        (pageDirty)="onPageDirty($event)"></app-crossholding-profile> 
                </ng-template>
            </mat-tab>
            <mat-tab label="AUM Crossholding Rules"> 
                <ng-template matTabContent>
                    <app-cross-holding-rules (pageDirty)="onPageDirty($event)" [profile]="profile" [profileid]="profileId"></app-cross-holding-rules>
                </ng-template>
            </mat-tab>
            <mat-tab label="Capital Activity Crossholding Rules">
                <ng-template matTabContent>
                    <capital-activity (pageDirty)="onPageDirty($event)" [profile]="profile" [profileid]="profileId"></capital-activity>
                </ng-template>
            </mat-tab>
            <mat-tab label="Cross Trading Rules">
                <ng-template matTabContent>
                    <cross-trading (pageDirty)="onPageDirty($event)" [profile]="profile" [profileid]="profileId"></cross-trading>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

