import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Rules } from '../../../models/Rules';
import { HttpService } from '../../../services/http.service';
import {LayoutModule, Breakpoints} from '@angular/cdk/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../services/toastr.service';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig
} from "@angular/material/dialog";
import { URLHelper } from '../../../services/url.helper';
import { EditCriteriaComponent } from '../edit-criteria/edit-criteria.component';
import { ModalService } from '../../../services/modal.service';
import { PageEvent } from '@angular/material/paginator';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  
  form: FormGroup;
  onDirty = new EventEmitter();
  @ViewChild('var_value',{}) valRef: ElementRef;
  @ViewChild('addBtn',{}) addBtnRef: ElementRef;
  @ViewChild('pagination',{}) pagination: ElementRef;
  @Input() profileId: any;
  @Output() onFormResponse = new EventEmitter<any>();
  @ViewChild('sbmt',{}) sbmtRef: ElementRef;

  constructor(
    private http: HttpService, 
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private toastr: ToasterService,
    private url: URLHelper,
    private elRef:ElementRef,
    public modalService: ModalService,
    private renderer: Renderer2
    // @Inject(MAT_DIALOG_DATA) public data:any
  ) { 
    
    this.form = this.fb.group({
      profile_id                : this.profileId,
      profile_name              : [null, Validators.required],
      profile_description       : [null, Validators.required], 
      profile_type_string       : [null, Validators.required],
      delimiter_profile_string  : [null, Validators.required],
      table_name                : [null],
      // profile_type_id           : [null, Validators.required],
      var_variable              : [null],
      var_condition             : [null],
      var_value                 : [null]
    });

    //used for url redirection to sent when form is dirty
    this.form.valueChanges.subscribe((data)=>{
      if(this.form.dirty){
        this.onDirty.emit(true);
      }      
    });
  }
  
  public par_profile_id;
  public profile_types    = [];
  public variables        = [];
  public rules_row:any    = [];
  public valid_operators  = [];
  public var_values       = [];
  public selected_profile_type:any;
  public profile_strings  = [];
  public table_names      = [];
  public accounts:any            = [];
  public acctsColumns = ["acct_dim_id", "aum_dim_id", "aum_acct_num", "aum_share_class_code", "acct_name"]//, "report_eligifility_flag"]
  public loading:any;
  public profile_loading: boolean = false;
  public profile_name;
  public old_profile      = '';
  public refreshLoading   = false;
  public operate_loading:boolean = false;
  public valid_variable_entry:string;
  public pageLoading:boolean = false;
  public page:any       = {from: 0, to: 1000};
  varText;
  where_rule_strings: any = [];
  where_rule_strings_action: any = [];
  whereIdx;
  whereItem;
  viewquery:boolean = false;
  breakpoint;

  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number;
  pageSize:number = 100;
  length:number;
  lastLoadPaging: boolean = true;

  addCondition = (frm) =>{
    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const dt = JSON.parse(ruleData);
    let strn:string = '';

    if(dt.var_variable.indexOf("DATE")>=0){

      var re = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
      if(dt.var_condition.trim() != 'IS NULL' && !re.test(dt.var_value)) {
        this.toastr.warning("The date format should be : mm/dd/yyyy");
        return;
      }
    }

    if(dt.var_condition.trim() == "IS NULL"){
      strn = dt.var_variable + dt.var_condition
    }else{
      if(dt.var_value == '' || dt.var_value == null || dt.var_value == ' ') return;
      strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
    }

    if (this.table_names.indexOf(this.form.controls["var_variable"].value.split(".")[0]) != 0){
      this.table_names.push(this.form.controls["var_variable"].value.split(".")[0])
    }
    
   
    // const strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
    
    this.profile_strings.push(strn)
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + strn);
    this.form.controls["table_name"].setValue(this.table_names.join(","));
    this.valid_variable_entry = '';
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: strn, action: 'add' });
    this.where_rule_strings.push(strn);
  }
  protected getData = () =>{
    
    this.http.getData(this.url.busrules_show_type).subscribe((data: any) => {
      this.profile_types = data.data;
    });

    this.http.getData(this.url.xhold_profile_attr).subscribe((data: any) => {
      this.variables = data.data;
    });

    
  }
  protected getProfileData = (profile_id: any) =>{
    this.loading = true;

    forkJoin(
      {
        rule  : this.http.getData(this.url.xhold_profile_disp + profile_id),
        assoc : this.http.getData(this.url.xhold_profile_accts + '/' + profile_id)
      }
    ).subscribe((result: any)=>{
        //  edit rule data ***************************
        let data = result.rule;
        
        this.old_profile = data.data.profile_name;
        this.selected_profile_type = this.rules_row.ruletype_id;
        this.form.controls["profile_type_string"].setValue(data.data.profile_string);
        this.form.controls["profile_name"].setValue(data.data.profile_name);
        this.form.controls["profile_description"].setValue(data.data.profile_desc);
        this.form.controls["delimiter_profile_string"].setValue(data.data.dlmtr_profile_str);

        // this.table_names  = this.form.controls["table_name"].value.split(",");
        this.profile_name = data.data.profile_name;
        this.profile_strings = this.form.controls["delimiter_profile_string"].value.split(";");
        this.profile_strings.pop();
        this.profile_strings.shift();
        this.loading = false;

        let tmpWhere = data.data.profile_string.replace(/ OR /g, "| OR | ").replace(/ AND /g, "| AND |").replace(/ and /g, "| OR |");
        this.where_rule_strings =  tmpWhere.split("|");

        // Associate accounts ******************************************
        this.accounts = result.assoc.data.data;
        this.length = result.assoc.data.total;
        this.loading = false;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
    // **********ivar convert to fork join*****************; 
    // this.http.getData(this.url.xhold_profile_disp + profile_id).subscribe((data: any) => {
    //   // this.rules_row = data.data;
    //   this.old_profile = data.data.profile_name;
    //   this.selected_profile_type = this.rules_row.ruletype_id;
    //   this.form.controls["profile_type_string"].setValue(data.data.profile_string);
    //   this.form.controls["profile_name"].setValue(data.data.profile_name);
    //   this.form.controls["profile_description"].setValue(data.data.profile_desc);
    //   this.form.controls["delimiter_profile_string"].setValue(data.data.dlmtr_profile_str);

    //   // this.table_names  = this.form.controls["table_name"].value.split(",");
    //   this.profile_name = data.data.profile_name;
    //   this.profile_strings = this.form.controls["delimiter_profile_string"].value.split(";");
    //   this.profile_strings.pop();
    //   this.profile_strings.shift();
    //   this.loading = false;

    //   let tmpWhere = data.data.profile_string.replace(/ OR /g, "| OR | ").replace(/ AND /g, "| AND |").replace(/ and /g, "| OR |");
    //   this.where_rule_strings =  tmpWhere.split("|");
    // },
    // err => {
    //   this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    // });

    // this.http.getData(this.url.xhold_profile_accts + '/' + profile_id).subscribe((res: any) => {
    //   this.accounts = res.data.data;
    //   this.length = res.data.total;
    //   this.loading = false;
    // },
    // err => {
    //   this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    // });

  }

  getValidOperators = (varntab) =>{
    const table_name      = varntab.split(".")[0];
    const var_name        = varntab.split(".")[1];

    this.valid_variable_entry = "";
    if (var_name.indexOf("DATE")>=0){
      this.valid_variable_entry = "MM/DD/YYYY";
    }

    this.valid_operators  = [];
    this.var_values       = [];
    const param = '?col_name=' + var_name + '&' + 'table_name=' + table_name;
    this.http.getData(this.url.busrules_valid_operators + param).subscribe((data: any) => {
      this.valid_operators = data.data.value;
    });

    this.http.getData(this.url.busrules_variable_show + param).subscribe((res: any) => {
      this.var_values = res.data;
    });
  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 950) ? 2 : 4;
  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 950) ? 2 : 4;
    this.getData();
    if(this.profileId){
      this.par_profile_id = this.profileId;
      this.getProfileData(this.profileId);
    }
    
  }
  callProfile = (id) =>{
    this.profileId = id;
    this.par_profile_id = id;
    this.form = this.fb.group({
      profile_id                : id,
      profile_name              : [null, Validators.required],
      profile_description       : [null, Validators.required], 
      profile_type_string       : [null, Validators.required],
      delimiter_profile_string  : [null, Validators.required],
      table_name                : [null],
      // profile_type_id           : [null, Validators.required],
      var_variable              : [null],
      var_condition             : [null],
      var_value                 : [null]
    });
    this.getProfileData(id);
  }
  checkValue = (val) =>{
    if(val.trim() == 'IS NULL') {
      this.valRef.nativeElement.disabled = true;
    }else{
      this.valRef.nativeElement.disabled = false;
    }
  }

  addAnd = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' AND ', action: 'add' });
    this.where_rule_strings.push(' AND ');

    // this.profile_strings.push(' AND ')
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' AND ');
  }

  addOr = () => {
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' OR ', action: 'add' });
    this.where_rule_strings.push(' OR ');

    // this.profile_strings.push(' OR ');
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' OR ');
  }

  addLeftPar = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' ( ', action: 'add' });

    this.where_rule_strings.push(' ( ');

    // this.profile_strings.push(' ( ');
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' ( ');
  }

  addRgtPar = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' ) ', action: 'add' });

    this.where_rule_strings.push(' ) ');
    // this.profile_strings.push(' ) ');
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' ) ');
  }

  formUndo = () =>{
    this.profile_strings.pop();
    
    if(this.where_rule_strings_action.length > 0){
      let unidx = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].idx;
      let unval = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].value;
      let unact = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].action;

      this.where_rule_strings_action.pop();
      if(unact == 'del'){
          this.where_rule_strings.splice(unidx, 0, unval);        
          
      }else{
          this.where_rule_strings.pop();
      }
      this.form.controls["profile_type_string"].setValue(this.where_rule_strings.join(" "));
  }else{
    this.toastr.warning("No Undo Actions");
  }
  }

  formClear = () =>{
    this.profile_strings = [];
    this.form.controls["profile_type_string"].setValue('');
  }
  submitProfile = () =>{
    this.sbmtRef.nativeElement.trigger.click();
  }
  saveRule = (frm) => {
    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const data = JSON.parse(ruleData);
    
    if(this.form.invalid) return;
    data.delimiter_profile_string = "STR_START;" + this.profile_strings.join(";") + ";STR_END"
    
    this.http.putData(this.url.xhold_profile_edit, {}, data).subscribe((res: any) => {
      if(parseInt(res.data.error_flag) == 0){
        this.toastr.success("Buss Rule Management edited successfully !");
        this.form.reset();
        this.onFormResponse.emit({pagedirty: false, msg:"close"});
        this.modalService.close();
      }else{
        this.form.controls["profile_type_string"].setErrors({invalidquery:"RuleString provided may be invalid."});
        this.toastr.error("RuleString provided may be invalid.");
      }
      
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  editCriteria = () =>{
    if (!this.whereItem) {
      this.toastr.warning("Select criteria to Edit.");
      return;
    }
    const dialogConfig  = new MatDialogConfig();
    dialogConfig.data   = {'profile_string': this.profile_strings, "profile_name": this.profile_name, "criteria": this.whereItem};
    dialogConfig.width  = '60%';
    dialogConfig.height = '50%';
    // dialogConfig.maxHeight  = '100vw';
    // dialogConfig.maxHeight  = '100vh';
    dialogConfig.panelClass = 'full-screen-modal';
    

   
    const dialogRef = this._dialog.open(EditCriteriaComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      // this.profile_strings = result.data;
      // this.form.controls["profile_type_string"].setValue(result.data.join(" "));

      this.where_rule_strings[this.whereIdx] = this.where_rule_strings[this.whereIdx].replace(result.data.edit_criteria.trim(), result.data.updated_criteria);
      this.form.controls["profile_type_string"].setValue(this.where_rule_strings.join(" "));
      this.form.controls["profile_type_string"].markAsDirty();

    });
  }

  checkName = (event) =>{
    if(this.old_profile == this.form.controls["profile_name"].value){
      return;
    }
    this.profile_loading = true;
    this.http.getData(this.url.xhold_profile_chk_save + this.form.controls["profile_name"].value).subscribe((res:any)=>{
      this.profile_loading = false;
      if(parseInt(res.data.error_flag) == 1){
        // this.toastr.warning("This profile name found");
        this.form.controls["profile_name"].setErrors({checkName:"Profile Name already exists"});
      }
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  cancelEditProfile = (frm) =>{
    if(this.form.dirty){
      const ok = confirm("Discard save changes the Profile?");
      if( ok){
        this.accounts = [];
        this.form.reset();
        this.where_rule_strings = [];
        this.where_rule_strings_action = [];
        this.whereIdx = null;
        this.whereItem = '';
        this.modalService.close();
        this.onFormResponse.emit({pagedirty: false, msg:"close"});
      }
    }else{
      this.accounts = [];
      this.form.reset();
      this.where_rule_strings = [];
      this.where_rule_strings_action = [];
      this.whereIdx = null;
      this.whereItem = '';
      this.onFormResponse.emit({pagedirty: false, msg:"close"});
      this.modalService.close();
    }
    
  }

  refreshAccounts = () =>{
    this.refreshLoading = true;
    this.http.getData(this.url.xhold_profile_refresh_Accounts + this.profileId)
      .subscribe((res: any) => {
        
        // this.accounts = JSON.parse(JSON.stringify(this.accounts))
        this.accounts = JSON.parse(JSON.stringify(res.data))
        this.refreshLoading = false;
        this.page.from = 0;
        this.page.to = 1000;
        this.pageSize = 1000;
        this.pageIndex = 0;
        this.length = this.accounts.length;
        
      }, (error:any)=>{
        console.log(error)
        this.refreshLoading = false;
        this.toastr.error(error.statusText)
      });      
  }

  ngAfterViewInit(){
    
    this.elRef.nativeElement.querySelector('mat-dialog-content#pagination').addEventListener('scroll', (event)=>{
      return;
      let sitem = this.elRef.nativeElement.querySelector('mat-dialog-content#pagination')
      const st = parseInt(sitem.scrollTop.toFixed(0)) + 1
      
      if(st == sitem.scrollHeight.toFixed(0) - sitem.offsetHeight.toFixed(0)){
          
          const frm = this.page.to;
          const to = this.page.to + 1000;
          this.page.from = frm;
          this.page.to = to;
          const param           = this.url.xhold_profile_accts + '/' + this.par_profile_id + '/' + frm + '/' + to;
          this.refreshLoading = true;

          this.http.getData(param).subscribe((data: any) => {
            this.refreshLoading = false;
            this.accounts = [...this.accounts, ...data.data];
          });
      }
      
    });
  }
  
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.form.dirty) event.returnValue = false;
  }

  varChange = (event) =>{
    this.varText = event.target.value;
  }
  clearsearch = (event) =>{
    this.varText = "";
    event.target.value = "";
  }


  selectedWhere = (event, idx, item) =>{
    this.whereIdx = idx;
    this.whereItem = item;
    this.renderer.addClass(event.target,"selected");
    
  }

  deleteCriteria = (idx: any) =>{
    try{    
        let proc_rule = this.where_rule_strings[idx];
        
        this.where_rule_strings_action.push({idx: idx, value: proc_rule, action: 'del'});
        
        if(proc_rule.indexOf("(") >=0 ){

          let lft_par_count = (proc_rule.match(/\(/g) || []).length

          if(proc_rule.indexOf(")") >=0 ){
            if(proc_rule.indexOf("TRUNC") >=0 || proc_rule.indexOf("TRUNC(") >=0 || proc_rule.indexOf(" IN ") >=0 || proc_rule.indexOf("IN(") >=0 ){

            }else{
                let rgt_par_count = (proc_rule.match(/\)/g) || []).length;
                
                if(rgt_par_count > lft_par_count){
                  
                    this.where_rule_strings[idx] = ")".repeat(lft_par_count);

                }else{
                    this.where_rule_strings.splice(idx, 1);
                }
                
            }
          }else{
            this.where_rule_strings[idx] = proc_rule.replace(/[^(]/g, "")
          }
        }else if(proc_rule.indexOf(")") >= 0){
            this.where_rule_strings[idx] = proc_rule.replace(/[^)]/g, "")
        }else if(proc_rule.trim().toLowerCase() == 'and' || 
            proc_rule.trim().toLowerCase() == 'or'){
            this.where_rule_strings.splice(idx, 1);
            this.toastr.warning('Chek the conditon Carefully.')
        }else{
          
          this.where_rule_strings.splice(idx, 1);
        }
        this.form.controls["profile_type_string"].setValue(this.where_rule_strings.join(" "));
        this.form.markAsDirty();

        this.whereIdx = null;
        this.whereItem = '';
    }
    catch(e){
      console.log(e)
    }
  }
  
  viewQuery = () =>{
    this.viewquery = !this.viewquery;
  }

  getPageData = (event?:PageEvent) => {
      let pgSize = this.pageSize;
      let pgIdx = this.pageIndex;
      if(event?.pageSize != this.pageSize){
        pgSize = event?.pageSize;
        pgIdx = event?.pageIndex;
      }
      pgIdx = event?.pageIndex == 0?1: ((event?.pageIndex) + 1);
      const param           = this.url.xhold_profile_accts + '/' + this.profileId + '/' + (pgSize * (pgIdx - 1)) + '/' + (pgSize * pgIdx);
      this.pageLoading = true;
      this.refreshLoading = true;
      
      this.http.getData(param).subscribe((data: any) => {
          this.pageLoading = false;
          this.refreshLoading = false;
          if(data.data.length <=1000 ) this.lastLoadPaging = false;
          // this.accts.data = [...this.accts.data, ...data.data];
          this.accounts = data.data.data; //as Accts[];
          
      },
        err => {
          this.refreshLoading = false;
          this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });

    return event;
  }

}
