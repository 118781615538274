import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AumSdmShareClassService extends BaseService {

  constructor(private inject: HttpClient) {
    super(inject);
  }

  getSharehistInfo(accountObject:any): Observable<any> {
   
    let resource = "/accounts/get_shareclass_hist";
   
    return this.postRequest(resource,accountObject);
  }
  copyHistoryshareClass(accountinfo: any){
    let resource = "/accounts/shareclass/copy_history_share_class";
    //console.log(resource);
    return this.postRequest(resource, accountinfo);
  }
  deleteHistoryshareClass(aum_sdim_key  : string){
   let resource = "/accounts/shareclass/delete_history_share_class/" + aum_sdim_key ;
  
   return this.deleteRequest(resource);
  }

 
  saveHistoryshareClass(accountObject: any){
    let resource = "/accounts/shareclass/update_history_share_class";
    //console.log(resource);
    return this.putRequest(resource, accountObject);
  }

  getBillingInd(): Observable<any> {
    let resource = "/attributes/billingind/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load canadaman list
  getApprovalgrp(): Observable<any> {
    let resource = "/attributes/approvalgrp/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load lpmg list
  getfreqCode(): Observable<any> {
    let resource = "/attributes/updatefreq/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load grpmagr list
  getAccountstatus(): Observable<any> {
    let resource = "/attributes/acctstatus/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  validateShareclassHistory(account_no:string,class_code:string): Observable<any> {
   
    let resource = "/accounts/shareclass/validate_history_share_class?acct_number="+ account_no + "&share_class_code=" + class_code;
   
    return this.getRequest<any>(resource);
  }

}
