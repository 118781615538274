
<mat-dialog-content>
<mat-card>
    <mat-card-subtitle>Crossholding Profile Name --> {{profile_name}} </mat-card-subtitle>
    <!-- <mat-card-actions>
            
            <div class="rule-list mat-card-height"  > 
                <h3>Profile Query</h3>
                <div class="rule-box active" *ngFor="let rpt of criteria; index as idx"
                    (click)="selectCriteria(idx, rpt)"
                    [ngClass]="{selected: selectedIdx == idx}"
                >
                   {{rpt}}
                </div>
            </div>
    </mat-card-actions> -->
</mat-card>
<mat-card >
    <mat-card-subtitle>Account Criteria</mat-card-subtitle>
    <mat-card-actions *ngIf="showLogic">
        <div class="row margin-top-bottom-10">
            <div class="col-2">
                <button [ngClass]="{'selected': showLogical=='AND'}" class="margin-top-bottom-10" type="button" mat-raised-button (click)="addAnd()">And</button>
            </div>
            <div class="col-2">
                <button [ngClass]="{'selected': showLogical=='OR'}"  class="margin-top-bottom-10" type="button" mat-raised-button (click)="addOr()">Or</button>
            </div>
        </div>
    </mat-card-actions>
    <mat-card-actions *ngIf="!showLogic">
        <div class="row">
            <div class="col-4">
                <mat-form-field [ngClass]="{'operate-loading': operate_loading}">
                    <!-- [(ngModel)]="modVariable"  -->
                    <mat-select [(ngModel)]="mod_variable" placeholder="Variable" (selectionChange)="getValidOperators($event.value)">
                        <mat-option *ngFor="let vls of variables" [value]="vls.aum_buss_var_ref_tab + '.' + vls.aum_buss_var_name">
                            {{vls.aum_buss_var_ref_tab}}.{{ vls.aum_buss_var_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <!-- [(ngModel)]="modCondition"  -->
                    <mat-select [(ngModel)]="mod_operator" placeholder="Condition">
                        <mat-option *ngFor="let opt of valid_operators" value={{opt}}>{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput type="text" [(ngModel)]="mod_value" placeholder="Value">
                    <!-- <mat-select [(ngModel)]="mod_value" placeholder="Value">
                        <mat-option *ngFor="let vlu of var_values" value="{{vlu}}">{{vlu}}</mat-option>
                    </mat-select> -->
                </mat-form-field>
            </div>
            <!-- <div class="col-2">
                <button [disabled]="selectedReletive" type="button" mat-raised-button color="primary" (click)="updateCriteria($event)">Update Criteria</button>
            </div> -->
        </div>
    </mat-card-actions>
</mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
    
    <button (click)="updateCriteria($event)" mat-raised-button color="primary" type="button">Save</button>
    <button mat-raised-button type="button" (click)="cancelCriteria()">Cancel</button>
</mat-dialog-actions>