<div *ngIf="isLoading" class="load-overlay">
    <mat-spinner class="loader"></mat-spinner>
</div>
<mat-card class="example-card ">    
    <mat-card class="example-card">
        <mat-card-title class="text-center">AUM Share Class Level Attributes</mat-card-title>
        <div class="row centered">
            <div class="col-3 ">
                <mat-form-field>
                    <mat-placeholder>Enter TIS Account Num</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="accountNo">
                </mat-form-field>
            </div>

            <div class=" col-3 ">
                <mat-form-field appearance="fill">
                    <mat-label>Select share class</mat-label>
                    <mat-select [(value)]="classcode">
                        <div class="drop-down-add">
                            <input class="search"
                            matInput type="text" style="padding-bottom: 8px;"
                            placeholder="Search" [(ngModel)]="searchTerm" 
                            (ngModelChange)="filterOptions()"/>
                        </div>
                        <mat-option *ngFor="let shareclass of fileterdOptions" [value]="shareclass">{{ shareclass }}
                        </mat-option>
                    </mat-select>
                  
                </mat-form-field>
            </div>
            <div class=" col-3 ">
                <mat-form-field>
                    <mat-placeholder>TIS Account Type</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="accountType" [disabled]="true">
                </mat-form-field>
            </div>
            <div class=" col-3 ">
                <mat-form-field>
                    <mat-placeholder>Account Name</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="accountName" [disabled]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="button-row text-center centered">
            <button class="button" (click)="getShareclassInformation()"> Show Record</button>
            &nbsp;
            <button class="button" *ngIf="isShowButton" (click)="displayXHLDProfileAssocSDIM()"> Show XHLD PROFILE ASSOC SDIM</button>
            &nbsp;
            <button class="button" *ngIf="isShowButton" (click)="displayAcctSDIM()">Show AUM SDIM</button>
            &nbsp;
            <button class="button" (click)="clearShareclassinfo()">Select New Record</button>
        </div>


    </mat-card>
    
    <mat-card class="example-card text-center ">
        <div class="row centered">
            <div class="col-3 ">
                <mat-form-field appearance="fill">
                    <mat-label>Inception Date</mat-label>
                    <input matInput [matDatepicker]="Inceptionpicker" [(ngModel)]="inceptDate" #inceptdate="ngModel"  [disabled]= "isReadOnly">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="Inceptionpicker"></mat-datepicker-toggle>
                    <mat-datepicker #Inceptionpicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3 ">
                <mat-form-field appearance="fill">
                    <mat-label>Termination Date</mat-label>
                    <input matInput [matDatepicker]="Terminationpicker" [(ngModel)]="terminateDate" #terminatedate="ngModel" [disabled]= "isReadOnly">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="Terminationpicker"></mat-datepicker-toggle>
                    <mat-datepicker #Terminationpicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3 ">
                <mat-form-field appearance="fill">
                    <mat-label>Billing Indicator</mat-label>
                    <mat-select [(value)]="billingindiId" [(ngModel)]="billingindiId" #billingindicator="ngModel" [disabled]= "isReadOnly" no-space>

                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varBillChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let billingInd of BillingFilteredIndList | search : varBillText : 'aum_billing_indicator_name' "
                            [value]="billingInd.aum_billing_indicator_dim_id" >{{
                            billingInd.aum_billing_indicator_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-3 ">
                <mat-form-field appearance="fill">
                    <mat-label>Account Status</mat-label>
                    <mat-select [(value)]="accountStatus" [(ngModel)]="accountStatus" #accountstatus="ngModel" [disabled]= "isReadOnly" no-space>

                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varAcctChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let acctstaus of AccountstatusList | search : varAcctText : 'p_Acct_Status_List_Code' "
                            [value]="acctstaus.p_Acct_Status_List_Code" >{{
                            acctstaus.p_Acct_Status_List_Code}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <br>
        <div class="row centered">
           
            <div class="col-4">
                <mat-form-field appearance="fill">
                    <mat-label>Update Freq</mat-label>
                    <mat-select [(value)]="freqCode" [(ngModel)]="freqCode" #freqcode="ngModel" [disabled]= "isReadOnly" no-space>

                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varFreqCodeChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let freq of freqCodeList | search : varFreqCodeText : 'p_update_freq_code' " [value]="freq.p_update_freq_code"  >{{
                            freq.p_update_freq_code}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Approval Group</mat-label>
                    <mat-select [(value)]="approvalgrpid"  [disabled]= "isReadOnly">
                        <mat-option *ngFor="let applgrp of ApprovalgrpList" [value]="applgrp.aum_apprvl_group_dim_id" >{{
                            applgrp.aum_apprvl_group_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <div class="col-4">
                <mat-form-field>
                    <mat-placeholder>Profile Name</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="profileName" #profilename="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-placeholder>Maximum Annual Fee Rate</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="maximumAnnualRate"  #maximumrate="ngModel"  [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class="col-6 text-left">
                <div class="col-3">
                    <section class="example-section">
                        <mat-checkbox class="example-margin" color="primary" [checked]="AumFlag" [(ngModel)]="isChecked"  [disabled]= "isReadOnly"
                            #xamFlag="ngModel" (change)="checkValue(isChecked)"> AUM Report Flag </mat-checkbox>
                    </section>
                </div>
                <div class="col-9 example-margin">
                    Note: Changes to the flag field will affect only the specified fund class
                    within the specified amount above.
                </div>
            </div>
            <div class="col-3 text-center">
                <mat-form-field>
                    <mat-placeholder>Last Updated By</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="lastUpdatedBy" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="col-3 text-center">
                <mat-form-field>
                    <mat-placeholder>Last Updated Date</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="lastUpdatedDate" [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class="col-9"></div>
            <div class="col-3 row centered">
                <div class="col-6 text-left" style="padding:0;">
                    <button class="button2 btn btn-secondary" type="submit"
                    [disabled]="(!inceptdate.touched && !terminatedate.touched && !billingindicator.touched
                    && !accountstatus.touched && !freqcode.touched && !profilename.touched 
                    && !maximumrate.touched && !xamFlag.touched) || isReadOnly"
                     (click)="saveAccountInfo()">Save</button>
                </div>
                <div class="col-6 text-right" style="padding:0;">
                    <button class="button2" (click)="resetAccountInfo()">Reset</button>
                </div>
            </div>
        </div>
    </mat-card>
</mat-card>

