import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent , HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map, } from 'rxjs/operators';
import { environment, API_BASE_URL, UPLOAD_FILE_BASE_URL } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  private baseUrl ;
  private fileuploadurl;

  protected headers: HttpHeaders = new HttpHeaders(
    {
      'Content-Type': 'application/json'
    });

  protected httpOptions = { headers: this.headers };

  constructor(private http: HttpClient) {
    // if (environment.development){
    //   this.baseUrl = API_BASE_URL.development;
    //   this.fileuploadurl = UPLOAD_FILE_BASE_URL.development
    // }else if(environment.local){
    //   this.baseUrl= API_BASE_URL.local;
    //   this.fileuploadurl = UPLOAD_FILE_BASE_URL.local
    // }
    this.baseUrl = API_BASE_URL
    this.fileuploadurl = API_BASE_URL + UPLOAD_FILE_BASE_URL
      
    return this.baseUrl;
  }

  protected getRequest<T>(apiUrl: string): Observable<any> {
    this.httpOptions.headers = this.headers;
    return this.http.get(this.baseUrl + apiUrl, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected postRequest<T, L>(apiUrl: string, payload: T): Observable<any> {
    return this.http.post(this.baseUrl + apiUrl, payload, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected putRequest<T, L>(apiUrl: string, payload: T): Observable<any> {    
    return this.http.put(this.baseUrl + apiUrl, payload , this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }
  


  protected deleteRequest<T, L>(apiUrl: string): Observable<any> {

    return this.http.delete(this.baseUrl + apiUrl)
      .pipe(catchError(this.errorHandler));
  }
  protected fileDownloadRequest(apiUrl: string): Observable<any>{
    return this.http.get(this.baseUrl + apiUrl , { responseType: 'blob' })
      .pipe(catchError(this.errorHandler));
  }

   // Returns an observable
   protected fileuploadRequest(file: File): Observable<any> {
    // Create form data
    //const formData = new FormData();

    // Store form name as "file" with file data
    //formData.append('file', file, file.name);

    // Make http post request over api
    // with formData as req
    return this.http.post(this.fileuploadurl, file, {reportProgress: true, observe: 'events'})
    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "api server error.");
  }

}















