<h2 mat-dialog-title>Add Rule Management</h2>
<form [formGroup]="form" (ngSubmit)="saveRule(form)">
<mat-dialog-content class="mat-typography">
    
        <mat-form-field appearance="legacy">
            <mat-label>Rule Name</mat-label>
            <input matInput placeholder="Rule Name" formControlName="rule_name" (blur)="checkName()">
            <div *ngIf="rule_loading" class="rule_loading">
                <mat-spinner diameter="20" color="warn"></mat-spinner>
            </div>
            <mat-hint>
                <div class="error" *ngIf="form.controls['rule_name'].invalid && (form.controls['rule_name'].dirty || form.controls['rule_name'].touched)" class="alert">
                    <div  class="error" *ngIf="form.controls['rule_name'].errors.required">
                    Please enter Profile Name
                    </div>
                
                    <div  class="error" *ngIf="form.controls['rule_name'].errors?.duplicate">
                        Rule Name already exists
                    </div>
                </div>
            </mat-hint>
        </mat-form-field>
                
        <mat-form-field appearance="legacy">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" rows="3" formControlName="rule_description"></textarea>
            
            <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
                
        <mat-form-field>
        <mat-select placeholder="Rule Type" formControlName="rule_type_id">
            <mat-option *ngFor="let type of rule_types" [value]="type.rule_type_id">
                {{ type.rule_type_name }}
            </mat-option>
        </mat-select>
        </mat-form-field>
            
            
        

        <mat-grid-list cols="4" rowHeight="15:5">
            <mat-grid-tile>

                <!-- <mat-form-field [ngClass]="{hidden: show_text}">
                    
                    <input matInput type="text"  
                            (keyup)="valueChange($event)" 
                            [(ngModel)]="searchTextValue" 
                            value="{{searchTextValue}}">
                    <mat-placeholder >Value</mat-placeholder>
                </mat-form-field> -->

                <mat-form-field [ngClass]="{hidden: show_select}">
                    <!-- [(ngModel)]="modVariable"  -->
                    <mat-select #inptCondition [ngClass]="{select:selectLoading}" formControlName="var_variable" no-space
                        placeholder="Variable" (selectionChange)="getValidOperators($event.value)">
                        
                        <input type="text" placeholder="search" class="search" 
                            (keyup)="varChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>
                        
                        <mat-option *ngFor="let vls of variables | search : varSearchTxt : 'var_ref_tab' : 'var_name'" [value]="vls.var_ref_tab + '.' + vls.var_name">
                            {{vls.var_ref_tab}}.{{ vls.var_name }}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <div class="error" *ngIf="var_sign_val">
                            Select variable
                        </div>
                    </mat-hint>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <!-- [(ngModel)]="modCondition"  -->
                    <mat-select formControlName="var_condition" placeholder="Condition"
                         (selectionChange)="checkValidCondition($event.value)">
                        <mat-option *ngFor="let opt of valid_operators" value={{opt}}>{{opt}}</mat-option>
                    </mat-select>
                    <mat-hint>
                        <div class="error" *ngIf="var_sign_val">
                            Select sign
                        </div>
                    </mat-hint>
                </mat-form-field>

            </mat-grid-tile>
            
            <mat-grid-tile>
                <!-- <mat-form-field [ngClass]="{hidden: show_text}">
                    
                    <input matInput type="text" [(ngModel)]="searchTextValue" value="{{searchTextValue}}">
                    <mat-placeholder >Value</mat-placeholder>
                </mat-form-field> -->
                <mat-form-field [ngClass]="{hidden: show_select}" >
                    <mat-select #valueSelect formControlName="var_value" 
                            placeholder="Value" 
                            (openedChange)="onValueChange($event)"
                            [disabled]="var_values_status" no-space>
                            <div class="drop-down-add">
                                <input type="text" placeholder="search" class="search" 
                                    [value]="valueSearchTxt" 
                                    (keyup)="valueChange($event)"  (keydown.escape)="clearsearch($event)">
                                
                                <mat-icon class="search-icon">search</mat-icon>
                                <mat-icon class="add-icon" (click)="addCondition(form)">add</mat-icon>
                            </div>
                        <mat-option *ngFor="let vlu of var_values | valueSearch : valueSearchTxt" value="{{vlu}}">{{vlu}}</mat-option>
                    </mat-select>
                    <mat-hint>
                        <div class="error" *ngIf="var_sign_val">
                            {{error_condition}}
                        </div>
                    </mat-hint>

                </mat-form-field>

            </mat-grid-tile>
            <mat-grid-tile>
                <button type="button" mat-raised-button color="primary" class="add" (click)="addCondition(form)">Add</button>
            </mat-grid-tile>    
        </mat-grid-list>

        <div class="row">
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addAnd()">And</button>
            </div>
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addOr()">Or</button>
            </div>
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addLeftPar()">(</button>
            </div>
            <div class="col-2">
                <button type="button" mat-raised-button (click)="addRgtPar()">)</button>
            </div>
        </div>

        <mat-form-field appearance="legacy" class=" rules-text margin-top-bottom-10">
            <mat-label>Condition</mat-label>
            <!-- [(ngModel)] = 'modString' -->
            <textarea readonly matInput placeholder="Condition" rows="7" formControlName="rule_type_string"></textarea>
            
            <button type="button" (click)="editCriteria()" mat-mini-fab color="primary" matTooltip="Edit Rules" aria-label="Undo Last Action" class="edit-button">
                <mat-icon>edit</mat-icon>
            </button>

            
            <button type="button" (click)="formUndo()" mat-mini-fab color="primary" matTooltip="Undo Last Action" aria-label="Undo Last Action" class="undo-button">
                <mat-icon>undo</mat-icon>
            </button>
            <button type="button" (click)="formClear()" mat-mini-fab color="primary" matTooltip="Clear All" aria-label="Clear All" class="clear-button">
                <mat-icon>delete</mat-icon>
            </button>
            <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>

        
    
</mat-dialog-content>

<mat-dialog-actions align="end">
    <!-- <button mat-raised-button type="button" (click)="feormUndo()">Undo</button>
    <button mat-raised-button type="button" (click)="formClar()">Clear All</button> -->
    <button mat-raised-button color="primary" class="add" type="submit">Save</button>
    <button mat-button type="button" (click)="cancelDialog(form)">Cancel</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Add</button> -->
</mat-dialog-actions>
</form>