import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Attributes, AttributesGroup, SelectedAttributes, BillingAttributes, XholdAttributes } from 'src/app/models/attributes';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { BrandManagementDialogComponent } from '../dialogs/brand-management-dialog/brand-management-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AttributemanagementService } from 'src/app/services/attributemanagement.service';
import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { PortmandateManagementDialogComponent } from '../dialogs/portmandate-management-dialog/portmandate-management-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { CrossholdingindicatorManagementDialogComponent } from '../dialogs/crossholdingindicator-management-dialog/crossholdingindicator-management-dialog.component';
import { BillingindicatorManagementDialogComponent } from '../dialogs/billingindicator-management-dialog/billingindicator-management-dialog.component';
import { Title } from '@angular/platform-browser';
import { DialogService } from 'src/app/services/dialog.service';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-attributes-management',
  templateUrl: './attributes-management.component.html',
  styleUrls: ['./attributes-management.component.css']
})
export class AttributesManagementComponent implements OnInit {
  @ViewChild('panel1') firstPanel: MatExpansionPanel;
  @ViewChild(BrandManagementDialogComponent) childBrand:BrandManagementDialogComponent;
  @ViewChild(CrossholdingindicatorManagementDialogComponent) childXhold: CrossholdingindicatorManagementDialogComponent;
  @ViewChild(PortmandateManagementDialogComponent) childPort:PortmandateManagementDialogComponent;
  @ViewChild(BillingindicatorManagementDialogComponent) childBill:BillingindicatorManagementDialogComponent;
  selectedValue: string = 'Brand';
  selectedRValue: string;
  ;
  output = "";
  display: boolean = false;
  sortList = [];
  message;
  addAttr: boolean;
  title: any = "";
  selectedAttr: SelectedAttributes[] = [];
  selectedPortfolioAttr: SelectedAttributes[] = [];
  selectedBillingIndicator: BillingAttributes[] = [];
  selectedCrossholdingIndicator: XholdAttributes[] = [];
  resultBrand; resultBillingIndicator; resultCrossholdingIndicator; resultPortfolioMandate;
  str;
  activatedSuccessfully: boolean;
  aum_brand_code: string;
  aum_brand_description: string;;
  aum_brand_name: string;
  aum_status: string;
  aum_port_man_code: string;
  aum_port_man_desc: string;;
  aum_port_man_name: string;
  aum_port_man_status: string;
  action: string;
  isDialogOpen: boolean = false;
  id;
  aum_code;
  aum_dim_name;
  aum_dim_desc;
  fee;
  mpar;
  brandColumns: string[] = ['Activate', 'aum_brand_name', 'aum_brand_code', 'aum_brand_description','Action'];
  branddataSource;
  brandStatus;
  portfolioColumns: string[] = ['Activate', 'aum_port_man_name','aum_port_man_code', 'aum_port_man_desc', 'system_last_updated_by_user_id', 'system_last_updated_date', 'Action'];
  portfoliodataSource;
  billingColumns: string[] = ['Activate', 'aum_billing_indicator_name', 'aum_billing_indicator_code', 'aum_billing_indicator_desc', 'system_last_updated_by_user_id', 'system_last_updated_date', 'Action'];
  billingdataSource;
  xholdColumns: string[] = ['Activate', 'aum_billing_indicator_name', 'aum_billing_indicator_code', 'aum_billing_indicator_desc', 'system_last_updated_by_user_id', 'system_last_updated_date', 'Action'];
  xholddataSource;
  isbrandLoading: boolean = false;
  isportfolioLoading: boolean = false;
  isxholdLoading: boolean = false;
  isbillingLoading: boolean = false;
  public pageDirty: boolean = false;
  isReadOnly: boolean =false;
  constructor(
    public dialog: MatDialog,
    private attributeService: AttributemanagementService,
    private http: HttpClient,
    private toastr: ToasterService,
    private router: Router,
    private titleService: Title,
    private confirmDialog: DialogService,
    private authservice: AuthService,
    private modalService: ModalService) {
      this.titleService.setTitle("CARS - Attributes Management")
      this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
  }

  ngOnInit(): void {
    this.trigger();

  }

  canDeactivate(): Observable<boolean> | boolean {
    console.log('can', this.pageDirty)
    if (this.pageDirty) {
      return this.confirmDialog.confirm("Do you want to discard the changes?");
    }
    return true;
  }


  trigger() {
    // this.selectedAttr= [];
    this.isDialogOpen = false;
    let element = document.getElementById('attributesmgmt') as HTMLInputElement;
    element.click();
    // this.loadAttributesPanel();
  }
  attributes: Attributes[] = [
    { value: 'brand', viewValue: 'Brand' },
    { value: 'crossholdingindicator', viewValue: 'Crossholding Indicator' },
    { value: 'portfoliomandate', viewValue: 'Portfolio Mandate' },
    { value: 'billingindicator', viewValue: 'Billing Indicator' },
  ];

  loadAttributesPanel() {
    
    this.isDialogOpen = false;

    if (this.selectedValue == "Brand") {
      this.getBrandAttributes();
    }
    else if (this.selectedValue == "Crossholding Indicator") {
      this.getCrossholdingIndicatorAttributes();
    }
    else if (this.selectedValue == "Portfolio Mandate") {
      this.getPortfolioAttributes();
    }
    else if (this.selectedValue == "Billing Indicator") {
      this.getBillingIndicatorAttributes();
    }
  }

  //Brand
  getBrandAttributes() {
    console.log(this.isbrandLoading);
    this.isbrandLoading = true;
    this.selectedAttr = [];
    this.attributeService.getBrandList()
      .subscribe(result => {
        this.resultBrand = result.data;
        this.branddataSource = new MatTableDataSource(this.resultBrand).data;
        this.isbrandLoading = false;
        for (let i = 0; i <= this.resultBrand.length - 1; i++) {
          this.selectedAttr.push({
            "value": this.resultBrand[i].aum_brand_code,
            "viewValue": this.resultBrand[i].aum_brand_name,
            "description": this.resultBrand[i].aum_brand_description,
            "active": this.resultBrand[i].aum_brand_status,
            "id": this.resultBrand[i].aum_brand_dim_id,
            "sortOrder": this.resultBrand[i].aum_sort_order
          });
        }

      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    // console.log(this.selectedAttr);
    for (let i = 0; i <= this.selectedAttr.length - 1; i++) {
      if (this.selectedAttr[i]["active"] == "Active") {
        //console.log(this.sel =ectedAttr[i]);
        this.aum_brand_code = this.selectedAttr[i]["value"];
        this.aum_brand_description = this.selectedAttr[i]["description"];
        this.aum_brand_name = this.selectedAttr[i]["viewvalue"];
        this.aum_status = this.selectedAttr[i]["active"];
      }

    }
    //console.log(this.isbrandLoading);
  }

  activateBrandAttributes(e: any, action: string): void {
    if ((e["aum_brand_status"] == "Inactive")) {
      this.brandStatus =
      {
        dim_code: e["aum_brand_code"],
        status: "Active"
      }
      // e["aum_brand_status"] = "Active";
      this.attributeService.changeBrandStatus(this.brandStatus).subscribe(res => {
        this.activatedSuccessfully = true;
        this.toastr.success("Attribute Activated");
        //this.getBrandAttributes();
        this.trigger();

      },
        err => {
          this.toastr.error(err.error.message);
          this.activatedSuccessfully = false;
        });
    }
    else {
      this.brandStatus =
      {
        dim_code: e["aum_brand_code"],
        status: "Inactive"
      }
      // e["aum_brand_status"] = "Inactive";
      this.attributeService.changeBrandStatus(this.brandStatus).subscribe(res => {
        this.activatedSuccessfully = true;
        this.toastr.success("Attribute Deactivated");
        this.trigger();
      },
        err => {
          this.toastr.error(err.error.message);
          this.activatedSuccessfully = false;
        });
    }
  }

  addBrandAttributes(action: string): void {
    let activeAttr: any;
    let attr: any;
    this.isDialogOpen = true;
    for (let i = 0; i <= this.selectedAttr.length - 1; i++) {
      if (this.selectedAttr[i].active == "Active") {
        this.output = this.selectedAttr[i].viewValue;
        this.title = this.selectedAttr[i].active;
      }
    }
    let data = {
          message: "Add Attributes",
          addAttr: true,
          activeAttr: this.selectedValue,
          attr: this.output,
          action: "Add"
    }
    this.childBrand.fromParent(data);
    this.modalService.open('add_brand_modal', '')
    // return;
    // const dialogRef = this.dialog.open(BrandManagementDialogComponent, {
    //   hasBackdrop: false,
    //   height: '71%',
    //   width: '30%',
    //   data: {
    //     message: "Add Attributes",
    //     addAttr: true,
    //     activeAttr: this.selectedValue,
    //     attr: this.output,
    //     action: "Add"
    //   },
    // });
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
    // dialogRef.afterClosed().subscribe(result => {      
    //   this.pageDirty=false;
    //   this.selectedAttr = [];
    //   this.isDialogOpen = false;
    //   if (result) {
    //     console.log(this.selectedAttr);
    //     this.isDialogOpen = false;
    //     this.trigger();
    //   }
    // });   
  }

  editBrandAttributes(e: any, action: string): void {
    this.isDialogOpen = true;

    let data = {
      message: "Edit Attributes",
      addAttr: false,
      activeAttr: this.selectedValue,
      attr: e["aum_brand_name"],
      code: e["aum_brand_code"],
      desc: e["aum_brand_description"],
      name: e["aum_brand_name"],
      action: "Edit",
      status: e["aum_brand_status"]
    }

    // this.output = e["aum_brand_name"];
    // this.title = e["aum_brand_status"];

    // this.aum_brand_code = e["aum_brand_code"];
    // this.aum_brand_description = e["aum_brand_description"];
    // this.aum_brand_name = e["aum_brand_name"];
    // this.aum_status = e["aum_brand_status"];
    this.childBrand.fromParent(data);
    this.modalService.open('add_brand_modal', '', 'Edit Attributes')
    return;

    // let code, desc, name;
    // const dialogRef = this.dialog.open(BrandManagementDialogComponent, {
    //   hasBackdrop: false,
    //   height: '71%',
    //   width: '30%',
    //   data: {
    //     message: "Edit Attributes",
    //     addAttr: false,
    //     activeAttr: this.selectedValue,
    //     attr: this.output,
    //     code: this.aum_brand_code,
    //     desc: this.aum_brand_description,
    //     name: this.aum_brand_name,
    //     action: "Edit",
    //     status: this.aum_status,
    //   },
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.pageDirty =false;
    //   this.selectedAttr = [];
    //   this.isDialogOpen = false;
    //   if (result) {
    //     this.isDialogOpen = false;
    //     this.trigger();
    //   }
    // });
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
  }

  dropBrand(event: CdkDragDrop<string[]>) {
    const data = {
      aum_dim_code: this.selectedAttr[event.previousIndex]["value"],
      // sort_no: this.selectedPortfolioAttr[event.currentIndex]["sortOrder"],
      //sort_no1: event.previousIndex,
      aum_sort_number: event.currentIndex
    }
    console.log(data)

    if (confirm("Are you sure change sort order ? ")) {
      moveItemInArray(this.selectedAttr, event.previousIndex, event.currentIndex);
      this.attributeService.updateBrandAttrSortOrder(data).subscribe((res: any) => {
        if (res) {
          this.getBrandAttributes();
          this.toastr.success(res.message);
        }
        err => {
          this.toastr.error(err.error.message);
        }
      });
    } else {
      this.toastr.warning("Sort order not changed");
    }

  }

  //Portfolio
  getPortfolioAttributes() {
    this.isportfolioLoading = true;
    this.selectedPortfolioAttr = [];
    this.attributeService.getPortfolioMandateList()
      .subscribe(result => {
        this.resultPortfolioMandate = result.data;
        this.portfoliodataSource = new MatTableDataSource(this.resultPortfolioMandate).data;
        this.isportfolioLoading = false;
        for (let i = 0; i <= this.resultPortfolioMandate.length - 1; i++) {
          this.selectedPortfolioAttr.push({
            "value": this.resultPortfolioMandate[i].aum_port_man_code,
            "viewValue": this.resultPortfolioMandate[i].aum_port_man_name,
            "description": this.resultPortfolioMandate[i].aum_port_man_desc,
            "active": this.resultPortfolioMandate[i].aum_port_man_status,
            "id": this.resultPortfolioMandate[i].aum_port_man_dim_id,
            "sortOrder": this.resultPortfolioMandate[i].aum_sort_order
          });
        }

      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    //console.log(this.selectedAttr.length);
    for (let i = 0; i <= this.selectedPortfolioAttr.length - 1; i++) {
      if (this.selectedPortfolioAttr[i]["active"] == "Active") {
        //console.log(this.sel =ectedAttr[i]);
        this.aum_brand_code = this.selectedPortfolioAttr[i]["value"];
        this.aum_brand_description = this.selectedPortfolioAttr[i]["description"];
        this.aum_port_man_name = this.selectedPortfolioAttr[i]["viewvalue"];
        this.aum_port_man_status = this.selectedPortfolioAttr[i]["active"];
      }
    }
    /* console.log(this.selectedPortfolioAttr.length);
    console.log(this.selectedPortfolioAttr); */
  }

  addPortfolioAttributes(action: string) {
    // console.log("Add Portfolio");
    let activeAttr: any;
    let attr: any;
    this.isDialogOpen = true;
    for (let i = 0; i <= this.selectedPortfolioAttr.length - 1; i++) {
      if (this.selectedPortfolioAttr[i].active == "Active") {
        this.output = this.selectedPortfolioAttr[i].viewValue;
        this.title = this.selectedPortfolioAttr[i].active;
      }
    }
    let data= {
      message: "Add Attributes",
      addAttr: true,
      activeAttr: this.selectedValue,
      attr: this.output,
      action: "Add",
    }
    this.childPort.fromParent(data);
    this.modalService.open('portfolio_modal', '')
    return;
    const dialogRef = this.dialog.open(PortmandateManagementDialogComponent, {
      hasBackdrop: false,
      height: '71%',
      width: '30%',
      data: {
        message: "Add Attributes",
        addAttr: true,
        activeAttr: this.selectedValue,
        attr: this.output,
        action: "Add",
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.pageDirty=false;
      this.selectedPortfolioAttr = [];
      this.isDialogOpen = false;
      if (result) {
        this.isDialogOpen = false;
        this.trigger();
      }
    });
    dialogRef.afterOpened().subscribe(res=>{
      this.pageDirty = true;
    })
  }

  editPortfolioAttributes(e: any, action: string) {
    this.isDialogOpen = true;
    console.log(e);
    this.output = e["aum_port_man_name"];
    this.title = e["aum_port_man_status"];
    this.aum_code = e["aum_port_man_code"];
    this.aum_dim_desc = e["aum_port_man_desc"];
    this.aum_dim_name = e["aum_port_man_name"];

    let data= {
      message: "Edit Attributes",
      addAttr: false,
      activeAttr: this.selectedValue,
      attr: this.output,
      code: this.aum_code,
      desc: this.aum_dim_desc,
      name: this.aum_dim_name,
      action: "Edit",
      status: this.aum_status,
      id: e["aum_port_man_dim_id"]
    }
    this.childPort.fromParent(data);
    this.modalService.open('portfolio_modal', '')
    // return;
    // let code, desc, name;
    // const dialogRef = this.dialog.open(PortmandateManagementDialogComponent, {
    //   hasBackdrop: false,
    //   height: '71%',
    //   width: '30%',
    //   data: {
    //     message: "Edit Attributes",
    //     addAttr: false,
    //     activeAttr: this.selectedValue,
    //     attr: this.output,
    //     code: this.aum_code,
    //     desc: this.aum_dim_desc,
    //     name: this.aum_dim_name,
    //     action: "Edit",
    //     status: this.aum_status,
    //   },
    // });
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.pageDirty=false;
    //   this.selectedPortfolioAttr = [];
    //   this.isDialogOpen = false;
    //   if (result) {
    //     this.isDialogOpen = false;
    //     this.trigger();
    //   }
    // });
  }

  activatePortfolioAttributes(e: any, action: string) {
    let portStatus: any;
    
      if ((e["aum_port_man_status"] == "Inactive")) {
        portStatus =
        {
          dim_code: e["aum_port_man_code"],
          status: "Active"
        }        
        this.attributeService.activatePortAttStatus(portStatus).subscribe(res => {
          this.activatedSuccessfully = true;
          this.toastr.success("Attribute Activated");
          this.getPortfolioAttributes();
        },
          err => {
            this.toastr.error(err.error.message);
            this.activatedSuccessfully = false;
          });
      }
      else {
        portStatus =
        {
          dim_code: e["aum_port_man_code"],
          status: "Inactive"
        }        
        this.attributeService.activatePortAttStatus(portStatus).subscribe(res => {          
          this.toastr.success("Attribute Deactivated");
          this.getPortfolioAttributes();
        },
          err => {
            this.toastr.error(err.error.message);
            this.activatedSuccessfully = false;
          });
      }
  }

  dropPortfolio(event: CdkDragDrop<string[]>) {

    const data = {
      dim_code: this.selectedPortfolioAttr[event.previousIndex]["value"],
      // sort_no: this.selectedPortfolioAttr[event.currentIndex]["sortOrder"],
      //sort_no1: event.previousIndex,
      sort_no: event.currentIndex
    }
    console.log(data)

    if (confirm("Are you sure change sort order ? ")) {
      moveItemInArray(this.selectedPortfolioAttr, event.previousIndex, event.currentIndex);
      this.attributeService.updatePortAttrSortOrder(data).subscribe((res: any) => {
        if (res) {
          this.getPortfolioAttributes();
          this.toastr.success(res.message);
        }
        err => {
          this.toastr.error(err.error.message);
        }
      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    } else {
      this.toastr.warning("Sort order not changed");
    }

  }


  //CrossHoldingIndicator
  getCrossholdingIndicatorAttributes() {
    this.isxholdLoading = true;
    this.selectedCrossholdingIndicator = [];
    this.attributeService.getCrossholdingIndicatorList()
      .subscribe(result => {
        this.resultCrossholdingIndicator = result.data;
        this.xholddataSource = new MatTableDataSource(this.resultCrossholdingIndicator).data;
        this.isxholdLoading = false;
        //console.log(this.resultCrossholdingIndicator);
        for (let i = 0; i <= this.resultCrossholdingIndicator.length - 1; i++) {
          this.selectedCrossholdingIndicator.push({
            "value": this.resultCrossholdingIndicator[i].aum_xhld_indicator_code,
            "viewValue": this.resultCrossholdingIndicator[i].aum_xhld_indicator_name,
            "description": this.resultCrossholdingIndicator[i].aum_xhld_indicator_desc,
            "active": this.resultCrossholdingIndicator[i].aum_xhld_indicator_status,
            "id": this.resultCrossholdingIndicator[i].aum_xhld_indicator_dim_id,
            "sortOrder": this.resultCrossholdingIndicator[i].aum_sort_order,
            "mpar": this.resultCrossholdingIndicator[i].INCLUDE_FLAG_MPAR_EXT
          });
        }

      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    // console.log(this.selectedCrossholdingIndicator);
    for (let i = 0; i <= this.selectedCrossholdingIndicator.length - 1; i++) {
      if (this.selectedCrossholdingIndicator[i]["active"] == "Active") {
        //console.log(this.sel =ectedAttr[i]);
        this.aum_brand_code = this.selectedCrossholdingIndicator[i]["value"];
        this.aum_brand_description = this.selectedCrossholdingIndicator[i]["description"];
        this.aum_brand_name = this.selectedCrossholdingIndicator[i]["viewvalue"];
        this.aum_status = this.selectedCrossholdingIndicator[i]["active"];
        this.mpar = this.selectedCrossholdingIndicator[i]["mpar"]
      }
    }
  }

  addxholdAttributes(action: string) {
    // console.log("Add Portfolio");
    let activeAttr: any;
    let attr: any;
    this.isDialogOpen = true;
    for (let i = 0; i <= this.selectedCrossholdingIndicator.length - 1; i++) {
      if (this.selectedCrossholdingIndicator[i].active == "Active") {
        this.output = this.selectedCrossholdingIndicator[i].viewValue;
        this.title = this.selectedCrossholdingIndicator[i].active;
      }
    }
    let data = {
      message: "Add Attributes",
      addAttr: true,
      activeAttr: this.selectedValue,
      attr: this.output,
      action: "Add",
    }
    this.childXhold.fromParent(data);
    this.modalService.open('xhold_modal', '', 'Add Attributes');
    // return;

    // const dialogRef = this.dialog.open(CrossholdingindicatorManagementDialogComponent, {
    //   hasBackdrop: false,
    //   height: '71%',
    //   width: '30%',
    //   data: {
    //     message: "Add Attributes",
    //     addAttr: true,
    //     activeAttr: this.selectedValue,
    //     attr: this.output,
    //     action: "Add",
    //   },
    // });
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.pageDirty=false;
    //   this.selectedCrossholdingIndicator = [];
    //   this.isDialogOpen = false;
    //   if (result) {
    //     console.log(result);
    //     this.isDialogOpen = false;
    //     this.trigger();
    //   }
    // });
  }

  editxholdAttributes(e: any, action: string) {
    this.isDialogOpen = true;
    console.log(this.id, e)
    let data =  {
      message: "Edit Attributes",
      addAttr: false,
      activeAttr: this.selectedValue,
      attr: e["aum_xhld_indicator_name"],
      code: e["aum_xhld_indicator_code"],
      desc: e["aum_xhld_indicator_desc"],
      name: e["aum_xhld_indicator_name"],
      action: "Edit",
      status: this.aum_status,
      id: e["aum_xhld_indicator_dim_id"], //this.id,
      mpar: e["INCLUDE_FLAG_MPAR_EXT"] //this.mpar,
    }

    // this.output = e["aum_xhld_indicator_name"];
    // this.title = e["aum_xhld_indicator_status"];
    // this.aum_code = e["aum_xhld_indicator_code"];
    // this.aum_dim_desc = e["aum_xhld_indicator_desc"];
    // this.aum_dim_name = e["aum_xhld_indicator_name"];
    // this.id = e["aum_xhld_indicator_dim_id"];
    // this.mpar = e["INCLUDE_FLAG_MPAR_EXT"];
    this.childXhold.fromParent(data);
    this.modalService.open('xhold_modal', '', 'Edit Attributes');
    return;

    let code, desc, name;
    const dialogRef = this.dialog.open(CrossholdingindicatorManagementDialogComponent, {
      hasBackdrop: false,
      height: '71%',
      width: '30%',
      data: {
        message: "Edit Attributes",
        addAttr: false,
        activeAttr: this.selectedValue,
        attr: this.output,
        code: this.aum_code,
        desc: this.aum_dim_desc,
        name: this.aum_dim_name,
        action: "Edit",
        status: this.aum_status,
        id: this.id,
        mpar: this.mpar,
      },
    });
    dialogRef.afterOpened().subscribe(res=>{
      this.pageDirty = true;
    });
    dialogRef.afterClosed().subscribe(result => {
      this.pageDirty=false;
      this.selectedCrossholdingIndicator = [];
      this.isDialogOpen = false;
      if (result) {
        this.isDialogOpen = false;
        this.trigger();
      }
    });
  }

  dropXhold(event: CdkDragDrop<string[]>) {
    const data = {
      dim_code: this.selectedCrossholdingIndicator[event.previousIndex]["value"],
      sortno: event.currentIndex
    }
    console.log(data)

    if (confirm("Are you sure change sort order ? ")) {
      moveItemInArray(this.selectedCrossholdingIndicator, event.previousIndex, event.currentIndex);
      this.attributeService.updateXholdAttrSortOrder(data).subscribe((res: any) => {
        if (res) {
          this.getCrossholdingIndicatorAttributes();
          this.toastr.success(res.message);
        }
        err => {
          this.toastr.error(err.error.message);
        }
      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    } else {
      this.toastr.warning("Sort order not changed");
    }

  }

  activatexholdAttributes(e: any, action: string): void {
    console.log(e);
    let xholdStatus: any;
    if (e["aum_xhld_indicator_status"] == "Active") {
      xholdStatus =
      {
        in_aum_dim_code: e["aum_xhld_indicator_code"],
        in_aum_status: "Inactive"
      }
      e["aum_xhld_indicator_status"] = "Inactive"
      this.attributeService.activateXholdAttStatus(xholdStatus).subscribe(res => {
        //this.activatedSuccessfully = true;
        this.toastr.success("Attribute Deactivated");
        this.getCrossholdingIndicatorAttributes();
      },
        err => {
          this.toastr.error(err.error.message);
          //this.activatedSuccessfully = false;
        });
    }
    else {
      xholdStatus =
      {
        in_aum_dim_code: e["aum_xhld_indicator_code"],
        in_aum_status: "Active"
      }
      e["aum_xhld_indicator_status"] = "Active"
      this.attributeService.activateXholdAttStatus(xholdStatus).subscribe(res => {
        this.toastr.success("Attribute Activated");
        this.getCrossholdingIndicatorAttributes();
      },
        err => {
          this.toastr.error(err.error.message);
          //this.activatedSuccessfully = false;
        });
    }
  }

  //BillingIndicator
  getBillingIndicatorAttributes() {
    this.isbillingLoading = true;
    this.selectedBillingIndicator = [];
    this.attributeService.getBillingIndicatorList().subscribe(result => {
      this.resultBillingIndicator = result.data;
      this.billingdataSource = new MatTableDataSource(this.resultBillingIndicator).data;
      this.isbillingLoading = false;
      // console.log(this.resultBillingIndicator);
      for (let i = 0; i <= this.resultBillingIndicator.length - 1; i++) {
        this.selectedBillingIndicator.push({
          "value": this.resultBillingIndicator[i].aum_billing_indicator_code,
          "viewValue": this.resultBillingIndicator[i].aum_billing_indicator_name,
          "description": this.resultBillingIndicator[i].aum_billing_indicator_desc,
          "active": this.resultBillingIndicator[i].aum_billing_indicator_status,
          "id": this.resultBillingIndicator[i].aum_billing_indicator_dim_id,
          "sortOrder": this.resultBillingIndicator[i].aum_sor_torder,
          "fee": this.resultBillingIndicator[i].max_annual_fee_rt
        });
      }

    });
    for (let i = 0; i <= this.selectedBillingIndicator.length - 1; i++) {
      if (this.selectedBillingIndicator[i]["active"] == "Active") {
        //console.log(this.sel =ectedAttr[i]);
        this.aum_brand_code = this.selectedBillingIndicator[i]["value"];
        this.aum_brand_description = this.selectedBillingIndicator[i]["description"];
        this.aum_brand_name = this.selectedBillingIndicator[i]["viewvalue"];
        this.aum_status = this.selectedBillingIndicator[i]["active"];
      }
    }
    // console.log(this.selectedBillingIndicator); 
  }

  addBillingAttributes(action: string) {
    // console.log("Add Portfolio");
    let activeAttr: any;
    let attr: any;
    this.isDialogOpen = true;
    for (let i = 0; i <= this.selectedBillingIndicator.length - 1; i++) {
      if (this.selectedBillingIndicator[i].active == "Active") {
        this.output = this.selectedBillingIndicator[i].viewValue;
        this.title = this.selectedBillingIndicator[i].active;
      }
    }
    let data = {
      message: "Add Attributes",
      addAttr: true,
      activeAttr: this.selectedValue,
      attr: this.output,
      action: "Add",
    }
    this.childBill.fromParent(data);
    this.modalService.open('billing_modal', '')

    // const dialogRef = this.dialog.open(BillingindicatorManagementDialogComponent, {
    //   hasBackdrop: false,
    //   height: '80%',
    //   width: '30%',
    //   data: {
    //     message: "Add Attributes",
    //     addAttr: true,
    //     activeAttr: this.selectedValue,
    //     attr: this.output,
    //     action: "Add",
    //   },
    // });
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.pageDirty=false;
    //   this.selectedBillingIndicator = [];
    //   this.isDialogOpen = false;
    //   if (result) {
    //     console.log(result);
    //     this.isDialogOpen = false;
    //     this.trigger();
    //   }
    // });
  }

  editBillingAttributes(e: any, action: string) {
    this.isDialogOpen = true;
    this.output = e["aum_billing_indicator_name"];
    this.title = e["aum_billing_indicator_status"];

    this.aum_code = e["aum_billing_indicator_code"];
    this.aum_dim_desc = e["aum_billing_indicator_desc"];
    this.aum_dim_name = e["aum_billing_indicator_name"];
    this.id = e["aum_billing_indicator_dim_id"];
    this.fee = e["max_annual_fee_rt"];

    let data = {
      message: "Edit Attributes",
      addAttr: false,
      activeAttr: this.selectedValue,
      attr: this.output,
      code: this.aum_code,
      desc: this.aum_dim_desc,
      name: this.aum_dim_name,
      action: "Edit",
      status: this.aum_status,
      id: this.id,
      fee: this.fee
    }
    this.childBill.fromParent(data);
    this.modalService.open('billing_modal', '')

    // let code, desc, name;
    // const dialogRef = this.dialog.open(BillingindicatorManagementDialogComponent, {
    //   hasBackdrop: false,
    //   height: '80%',
    //   width: '30%',
    //   data: {
    //     message: "Edit Attributes",
    //     addAttr: false,
    //     activeAttr: this.selectedValue,
    //     attr: this.output,
    //     code: this.aum_code,
    //     desc: this.aum_dim_desc,
    //     name: this.aum_dim_name,
    //     action: "Edit",
    //     status: this.aum_status,
    //     id: this.id,
    //     fee: this.fee
    //   },
    // });
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.pageDirty=false;
    //   this.selectedBillingIndicator = [];
    //   this.isDialogOpen = false;
    //   if (result) {
    //     this.isDialogOpen = false;
    //     this.trigger();
    //   }
    // });
  }


  dropBilling(event: CdkDragDrop<string[]>) {
    const data = {
      dim_code: this.selectedBillingIndicator[event.previousIndex]["value"],
      sortno: event.currentIndex
    }
    console.log(data)

    if (confirm("Are you sure change sort order ? ")) {
      moveItemInArray(this.selectedBillingIndicator, event.previousIndex, event.currentIndex);
      this.attributeService.updateBillingAttrSortOrder(data).subscribe((res: any) => {
        if (res) {
          this.getBillingIndicatorAttributes();
          this.toastr.success(res.message);
        }
        err => {
          this.toastr.error(err.error.message);
        }
      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    } else {
      this.toastr.warning("Sort order not changed");
    }

  }

  activateBillingAttributes(e: any, action: string): void {
    let billingStatus: any;
    if (e["aum_billing_indicator_status"] == "Active") {
      billingStatus =
      {
        dim_code: e["aum_billing_indicator_code"],
        status: "Inactive"
      }
      this.attributeService.activateBillingAttStatus(billingStatus).subscribe(res => {
        // this.activatedSuccessfully = true;
        this.toastr.success("Attribute Deativated");
        this.getBillingIndicatorAttributes();
      },
        err => {
          this.toastr.error(err.error.message);
          // this.activatedSuccessfully = false;
        });
    }
    else {
      billingStatus =
      {
        dim_code: e["aum_billing_indicator_code"],
        status: "Active"
      }
      this.attributeService.activateBillingAttStatus(billingStatus).subscribe(res => {
        this.toastr.success("Attribute Activated");
        this.getBillingIndicatorAttributes();
      },
        err => {
          this.toastr.error(err.error.message);
          // this.activatedSuccessfully = false;
        });
    }
  }

  // openDialog(row: any, action: string): void { }

  returnResponse = (eventData: {pagedirty: boolean, msg: string})=>{
    if (eventData.pagedirty == true){
      this.pageDirty = eventData.pagedirty;
      this.isDialogOpen = true;
    }else{

      // 
        this.pageDirty=false;
        this.selectedAttr = [];
        this.isDialogOpen = false;
      if(eventData.msg =='save'){
        this.trigger();
      }

      this.isDialogOpen = false;
      this.modalService.close();
    }
    console.log(eventData.pagedirty ,eventData.msg, "add form response")
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log(this.pageDirty, "reload")
    // if (this.pageDirty){
    //   console.log("inside")
    //   event.returnValue = false;
    // } 
  }
  

  searchBrand = (event) =>{
    
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    
    try{
      let fltr = this.resultBrand.filter((item:any)=> {
          return item.aum_brand_description != null && item.aum_brand_description.toLowerCase().indexOf(filterValue)>=0
      });
      
      this.branddataSource = new MatTableDataSource(fltr);
    }
    catch(err){
      console.log(err);
    }
    
  }

  searchXhold = (event) =>{
    
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    
    try{
      let fltr = this.resultCrossholdingIndicator.filter((item:any)=> {
          return item.aum_xhld_indicator_desc != null && item.aum_xhld_indicator_desc.toLowerCase().indexOf(filterValue)>=0
      });
      
      this.xholddataSource = new MatTableDataSource(fltr);
    }
    catch(err){
      console.log(err);
    }
    
  }

  searchBill = (event) =>{
    
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    
    try{
      let fltr = this.resultBillingIndicator.filter((item:any)=> {
          return item.aum_billing_indicator_desc != null && item.aum_billing_indicator_desc.toLowerCase().indexOf(filterValue)>=0
      });
      
      this.billingdataSource = new MatTableDataSource(fltr);
    }
    catch(err){
      console.log(err);
    }
    
  }
  searchPort = (event) =>{
    
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    
    try{
      let fltr = this.resultPortfolioMandate.filter((item:any)=> {
          return item.aum_port_man_desc != null && item.aum_port_man_desc.toLowerCase().indexOf(filterValue)>=0
      });
      
      this.portfoliodataSource = new MatTableDataSource(fltr);
    }
    catch(err){
      console.log(err);
    }
    
  }
  clearsearch = (event) => {
    this.branddataSource    = new MatTableDataSource(this.resultBrand);
    this.xholddataSource    = new MatTableDataSource(this.resultCrossholdingIndicator);
    this.billingdataSource  = new MatTableDataSource(this.resultBillingIndicator);
    this.portfoliodataSource= new MatTableDataSource(this.resultPortfolioMandate);
  }
}
