import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountAttributesComponent } from './components/account-attributes/account-attributes.component';
import { FinalAssetAmountAdjustmentComponent } from './components/final-asset-amount-adjustment/final-asset-amount-adjustment.component';
import { MonthEndSnapshotComponent } from './components/month-end-snapshot/month-end-snapshot.component';
import { ShareClassAttributesComponent } from './components/share-class-attributes/share-class-attributes.component';
import { DataLoadsComponent } from './components/data-loads/data-loads.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AumAcctSdmComponent } from './components/aum-acct-sdm/aum-acct-sdm.component';
import { AcctSdmComponent } from './components/acct-sdm/acct-sdm.component';
import { LoadAccountDetailsComponent } from './components/load-acct-details/load-acct-details.component';
import { AumSDIMShareClassComponent } from './components/aum-sdm-share-class/aum-sdm-share-class.component';
import { XHLDProfileAssocSDIMComponent } from './components/xhld-profile-assoc-sdim/xhld-profile-assoc-sdim.component';
import { AttributesManagementComponent } from './components/attributes-management/attributes-management.component';
import { CrossholdingRulesManagementComponent } from './components/crossholding-rules-management/crossholding-rules-management.component';
//import { OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ReportingRulesManagementComponent } from './components/reporting-rules-management/reporting-rules-management.component';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LocksComponent } from './components/admin/locks/locks.component';
import { SnsComponent } from './components/admin/sns/sns.component';
import { ValueListComponent } from './components/value-list/value-list.component';
import { LogsComponent } from './components/admin/logs/logs.component';

const routes: Routes = [  
  { 
    path: '', 
    //loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule),
    component: LandingPageComponent, 
    canLoad: [OktaAuthGuard]
  },
  { path: 'final-asset', component: FinalAssetAmountAdjustmentComponent },
  { path: 'account-attributes', component: AccountAttributesComponent , canDeactivate: [CanDeactivateGuardService]},
  { path: 'share-class-attributes', component: ShareClassAttributesComponent , canDeactivate: [CanDeactivateGuardService]},
  { path: 'snapshot', component: MonthEndSnapshotComponent },
  { path: 'data-loads', component: DataLoadsComponent, canDeactivate: [CanDeactivateGuardService] },
  { path: 'aum-acct-sdm', component: AumAcctSdmComponent , canDeactivate: [CanDeactivateGuardService]},
  { path: 'acct-sdm', component: AcctSdmComponent , canDeactivate: [CanDeactivateGuardService]},
  { path: 'load-acct-details', component: LoadAccountDetailsComponent },
  { path: 'aum-sdm-share-class', component: AumSDIMShareClassComponent },
  { path: 'xhld-profile-assoc-sdim', component: XHLDProfileAssocSDIMComponent },
  { path: 'attributes-management', component: AttributesManagementComponent , canDeactivate: [CanDeactivateGuardService]},
  { path: 'crossholding-rules-management', component: CrossholdingRulesManagementComponent, canDeactivate: [CanDeactivateGuardService] },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'report-rules-mgmt', component: ReportingRulesManagementComponent, canDeactivate: [CanDeactivateGuardService] },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'admin/locks', component: LocksComponent },
  { path: 'admin/sns', component: SnsComponent },
  { path: 'valuelists', component: ValueListComponent },
  { path: 'admin/logs', component: LogsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

