<p>SNS Notification!</p>

<div class="row">

    <div class="col-12">
        <mat-form-field>
            <mat-placeholder>Subject</mat-placeholder>
            <input matInput type="text" name="subject" [(ngModel)]="subject">
        </mat-form-field>
    </div>
    <div class="col-12">
        <mat-form-field>
            <mat-placeholder>Message</mat-placeholder>
            <textarea matInput type="text" [(ngModel)]="message"></textarea>
        </mat-form-field>
    </div>
    <div class="button-row centered">
        <button mat-raised-button color="primary" class="button" (click)="sendSNS()"> Send </button>
    </div>

</div>
