import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}


@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuardService implements CanDeactivate<CanComponentDeactivate>{

  constructor(
    private dialog:MatDialog, 
    private authService:AuthService, 
    private _router: Router,
    private http: HttpService) { }
  canDeactivate(component: CanComponentDeactivate, 
      route: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot) {
    
    if (this.authService.checkExpired()){
        this.authService.reSet();
        this._router.navigate(["/forbidden"]);
        return false;
      }

    let url: string = state.url;
    
    
    // if (this.http.isGoBack() != undefined && this.http.isGoBack()) {
    //   if(confirm("Unsaved data ?")){
    //     this.dialog.closeAll();
    //     return true;
    //   }
    //   return false;
    // }
    
    const res = component.canDeactivate() ? true : false;
    
    if(res){
      this.dialog.closeAll();
    }
   
    return res;//component.canDeactivate ? component.canDeactivate() : true;
  }
}
