import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessages } from '../../../services/error_message';
import { HttpService } from '../../../services/http.service';
import { ToasterService } from '../../../services/toastr.service';
import { URLHelper } from '../../../services/url.helper';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cross-holding-rules',
  templateUrl: './cross-holding-rules.component.html',
  styleUrls: ['./cross-holding-rules.component.css']
})
export class CrossHoldingRulesComponent implements OnInit {
  isReadOnly: boolean =false;
  constructor(
    private http: HttpService, 
    private _dialog: MatDialog,
    private toastr: ToasterService,
    private url: URLHelper,
    private err: ErrorMessages,
    private authservice: AuthService) {
      this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
     }

    @Input() profileid:number;
    @Input() profile:any;
    @Output() pageDirty = new EventEmitter<{pagedirty: boolean, msg: string}>();

  reportStyles:any = [];
  selectedStyleId:Number;
  selectedAssocProfileId: Number;
  selectedRowProfile: any;
  selectedRowProfileId:Number;
  rules:any = []
  displayedColumns = ["aum_xhld_profiles_name", "aum_xhld_holding_flag", "aum_xhld_undly_flag", "actions"]
  profiles:any = []
  rule_loading = false;
  editing = false;
  caption_edit = 'Edit';
  caption_del  = 'Delete';
  dirty = false;
  varText;

  getReportStyles=()=>{

    //report styles
    this.http.getData(this.url.xhold_rules_get_report_styles).subscribe((res:any)=>{
      this.reportStyles = res.data;
      if(this.selectedStyleId){
        this.selecteStyle({aum_rpt_style_sq_id: this.selectedStyleId});
      }else if(this.reportStyles.length > 0){
        this.selectedStyleId = this.reportStyles[0].aum_rpt_style_sq_id;
        this.selecteStyle({aum_rpt_style_sq_id: this.selectedStyleId});
      }
    });

    // this.http.getData(this.url.xhold_rules_disp + '1024'+'/1').subscribe((res:any)=>{
    //   this.rules = res.data;
    // });

    this.http.getData(this.url.xhold_profile_get).subscribe((res:any)=>{
      this.profiles = res.data;
    });

    if(this.profileid && this.selectedStyleId){
      this.http.getData(this.url.xhold_rules_disp + this.profileid + '/' + this.selectedStyleId).subscribe((res:any)=>{
        this.rules = res.data;
        this.rule_loading = false;
      });
    }
  }

  selecteStyle = (style) =>{
    this.editing = true;
    this.selectedStyleId = style.aum_rpt_style_sq_id;
    this.selectedRowProfileId = null;
    this.selectedRowProfile = [];
    this.http.getData(this.url.xhold_rules_disp + this.profileid + '/' + style.aum_rpt_style_sq_id).subscribe((res:any)=>{
      this.rules = res.data;
      this.editing = false;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  addRule = () =>{
    this.editing = true;
    if (this.selectedAssocProfileId != undefined && this.selectedStyleId != undefined){

      const fltrRule = this.rules.filter(item=>item.aum_xhld_assoc_profile_id == this.selectedAssocProfileId)
      if(fltrRule.length > 0){
        this.editing = false;
        this.toastr.error(this.err.DUPLICATE_ASSOCIATE_PROFILE_NAME)
        return;
      }
      const body = {
        "profile_id"        : this.profileid,
        "rep_style_id"      : this.selectedStyleId,
        "assoc_profile_id"  : this.selectedAssocProfileId,
        "hold_flag"         : "N",
        "undly_flag"        : "Y"
      }

      this.http.postData(this.url.xhold_rules_insert,{}, body).subscribe((res:any)=>{
        this.editing = false;
        this.selectedAssocProfileId = null;
        if (res.data.error_flag === 0){
          this.toastr.success("AUM Rules Inserted Successfully");
        }else{
          this.toastr.error("AUM Rules Inserted error");
        }
        this.getReportStyles();
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }else{
      this.editing = false;
      if(this.selectedStyleId == undefined) this.toastr.warning("Please select report style");
      if(this.selectedAssocProfileId == undefined) this.toastr.warning("Please select Profile");
    }
    this.dirty = false;
    this.pageDirty.emit({pagedirty: false, msg: ""});
  }

  selectProfile = (rec:any) =>{
    this.selectedRowProfileId = rec.aum_xhld_assoc_profile_id;
    this.selectedRowProfile = rec;
    this.caption_del = 'Delete';
    this.caption_edit = 'Edit';
    if(rec.action == 'edit'){
      this.caption_edit = 'Unedit';
    }
    if(rec.action == 'delete'){
      this.caption_del = 'Undelete';
    }
  }
  editRule = (dedId) =>{
    // console.log(dedId)
    // if (this.selectedRowProfile.length == 0){
    //   this.toastr.warning("Please select Decuction");
    //   return;
    // }
    this.selectedRowProfileId = dedId;
    this.dirty = true;
    this.pageDirty.emit({pagedirty: true, msg: "Discard Changes for Rules?"});
    this.rules.map(item=>{
      if(item.aum_xhld_assoc_profile_id == this.selectedRowProfileId){
        item.aum_xhld_holding_flag = item.aum_xhld_holding_flag=='Y'?'N':'Y';
        item.aum_xhld_undly_flag = item.aum_xhld_undly_flag=='Y'?'N':'Y';
        if(item.action == 'edit'){
          item.action = '';
          this.caption_edit = 'Edit';
        }else{
          item.action = 'edit'
          this.caption_edit = 'Unedit';
          this.caption_del = 'Delete'
        }
        
        
      }
    });


    // this.editing = true;
    // const body = {
    //   "profile_id"        : this.profileid,
    //   "rep_style_id"      : this.selectedRowProfile.aum_rpt_style_sq_id,
    //   "assoc_profile_id"  : this.selectedRowProfile.aum_xhld_assoc_profile_id,
    //   "hold_flag"         : this.selectedRowProfile.aum_xhld_holding_flag == 'Y'?'N':'Y',
    //   "undly_flag"        : this.selectedRowProfile.aum_xhld_undly_flag == 'Y'?'N':'Y'
    // }
    
    // this.http.postData(this.url.xhold_rules_insert,{}, body).subscribe((res:any)=>{
    //   this.editing = false;
    //   if (res.data.error_flag === 0){
    //     this.toastr.success("AUM Rules Updated Successfully");
    //   }else{
    //     this.toastr.error("AUM Rules Updated error");
    //   }
    //   this.getReportStyles();
    // });
  }

  deleteRule = (dedId) =>{
    // if (this.selectedRowProfile.length == 0){
    //   this.toastr.warning("Please select Decuction");
    //   return;
    // }
    this.selectedRowProfileId = dedId;
    this.dirty = true;
    this.pageDirty.emit({pagedirty: true, msg: ""});
    this.rules.map(item=>{
      if(item.aum_xhld_assoc_profile_id == this.selectedRowProfileId){
        if(item.action == 'delete'){
          item.action = '';
          this.caption_del = 'Delete';
        }else{
          item.action = 'delete'
          this.caption_del = 'Undelete';
          this.caption_edit = 'Edit';
        }
      }
    });
  }

  cancelActions = () =>{
    this.rules.map(item=>{
      item.action = '';
    });
    this.dirty = false;
    this.pageDirty.emit({pagedirty: false, msg: ""});
  }

  saveRule = ()=>{
    this.editing = true;
    let len = this.rules.length;
    let msg = "AUM Rules Updated Successfully";
    const fltr = this.rules.filter(item=>{return item.action == 'delete'});
    this.rules.forEach((item:any, i:number)=>{
      if(item.action && item.action == 'delete'){
        //skip record to save or single record/all to delete
        if(this.rules.length == 1 || this.rules.length == fltr.length ){
          let body = {
            "profile_id"        : this.profileid,
            "rep_style_id"      : item.aum_rpt_style_sq_id,
            "assoc_profile_id"  : 0,
            "hold_flag"         : '',
            "undly_flag"        : ''
          }
          msg = "AUM Rules Deleted Successfully";
          this.http.postData(this.url.xhold_rules_insert,{}, body).subscribe((res:any)=>{
            
          },
          err => {
            this.toastr.error("Issue while retrieving data. Please try again or contact support.")
          });
        }
      }else{
        setTimeout(() => {
          let body = {
              "profile_id"        : this.profileid,
              "rep_style_id"      : item.aum_rpt_style_sq_id,
              "assoc_profile_id"  : item.aum_xhld_assoc_profile_id,
              "hold_flag"         : item.aum_xhld_holding_flag,
              "undly_flag"        : item.aum_xhld_undly_flag
            }
          
          this.http.postData(this.url.xhold_rules_insert,{}, body).subscribe((res:any)=>{
            //saved
          },
          err => {
            this.toastr.error("Issue while retrieving data. Please try again or contact support.")
          });
        }, i * 500);
      }
    });
    setTimeout(() => {

      this.editing = false;
      this.selecteStyle({aum_rpt_style_sq_id: this.selectedStyleId});
      this.toastr.success(msg);
    }, len * 500);
  }

  searchProfile = (event) =>{
    this.varText = event.target.value;
  }
  clearsearch = (event) =>{
    this.varText = "";
    event.target.value = "";
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.dirty) event.returnValue = false;
  }

  ngOnInit(): void {
    this.getReportStyles();

  }


}
