import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { TemplateModel1 } from 'src/app/models/dataloads';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { XhldProfileAssocSdimService } from 'src/app/services/xhld-profile-assoc-sdim.service';
import { MatPaginator } from '@angular/material/paginator';
import { ToasterService } from 'src/app/services/toastr.service';
import { DatePipe, formatDate } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Observable } from 'rxjs/internal/Observable';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'xhld-profile-assoc-sdim',
  templateUrl: './xhld-profile-assoc-sdim.component.html',
  styleUrls: ['./xhld-profile-assoc-sdim.component.css'],
})
export class XHLDProfileAssocSDIMComponent implements OnInit {
  [x: string]: any;

  visible = true;
  accountNo: string = '';
  accountName: string = '';
  classcode: string = '';
  lastUpdatedDate: string = '';
  lastUpdatedBy: string = '';
  firstEffectiveDate: Date;
  lastEffectiveDate: Date;
  profileId: string = '';
  pageDirty: boolean = false;
  public tempRecord: any = [];
  public lastRecord: any = [];
  public firstRecord: any = [];
  public selectedrecord: string = '';
  displayColumns = [
    'radio',
    'firstEffectiveDate',
    'lastEffectiveDate',
    'accountNo',
    'shareClassCode',
    'profileName',
  ];
  @ViewChild('tempsort', { static: true }) tempsort = new MatSort();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('panel1') firstPanel: MatExpansionPanel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public templateList: TemplateModel1[] = [];
  public loadTemplateDatasource = new MatTableDataSource();
  panelOpenState: boolean;
  isLoading: boolean = false;
  isEdit: boolean = false;
  profileList: any = [];
  validateinfo: string = '';
  enable: boolean = false;
  showView: boolean = false;
  valueSearchTxt;

  constructor(
    private router: Router,
    private xhldservice: XhldProfileAssocSdimService,
    private route: ActivatedRoute,
    private toastr: ToasterService,
    private datepipe: DatePipe,
    private confirmDialog: DialogService
  ) {
    this.classcode = this.route.snapshot.paramMap.get('classcode');
    this.accountNo = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getProfileassocInfo();
    this.getProfileList();
  }
  getProfileassocInfo() {
    this.isLoading = true;
    this.xhldservice
      .getProfileassocInfo(this.accountNo, this.classcode)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.loadTemplateDatasource = new MatTableDataSource(
            result.data.attributes
          );
          this.loadTemplateDatasource.sort = this.tempsort;
          this.loadTemplateDatasource.paginator = this.paginator;
          this.lastRecord =
            result.data.attributes[result.data.attributes.length - 1];
          this.firstRecord = result.data.attributes[0];
          this.validateProfileHistoryInformation();
          this.isLoading = false;
        }
      });
  }
  bindHtml(output: any) {
    let finalvalue = '';

    finalvalue = '<table><tr>';
    finalvalue += '<td class="col-16">' + output + '</td></tr></table>';

    return finalvalue;
  }
  validateProfileHistoryInformation() {
    var outputdata: any[];
    // let messages='';
    this.xhldservice
      .validateprofileassoc(this.accountNo, this.classcode)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.validateinfo = '<table><tr>';
          this.validateinfo +=
            '<td class="col-16"><font color="#FF0000">Data is inconsistent for the following effective dates:</font></td></tr></table>';

          if (result.data.meesage_1 != null) {
            outputdata = result.data.meesage_1.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">First Effective date is greater than Last Effective date for :</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
          if (result.data.meesage_2 != null) {
            outputdata = result.data.meesage_2.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">There is a time gap between the Last Effective date and the First Effective date for:</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
        }
      });
  }
  getProfileList() {
    this.xhldservice.getProfileNames().subscribe((result) => {
      this.profileList = result.data;
    });
  }
  viewAttribute(event: any) {
    this.tempRecord = event.value;
  }
  redirectToAUMSDIM() {
    this.router.navigate([
      '/aum-sdm-share-class',
      { id: this.accountNo, classcode: this.classcode },
    ]);
  }
  redirectToSharePage() {
    this.router.navigate([
      '/share-class-attributes',
      { id: this.accountNo, classcode: this.classcode },
    ]);
  }

  closePanel() {
    this.firstPanel.close();
    this.pageDirty = false;
  }

  loadDetailsPanel() {
    this.isEdit = false;
    this.pageDirty = true;
    //this.router.navigate(['/load-acct-details'], { queryParams: { a:value, b: editable, c:index } })
    if (this.tempRecord['profile_assoc_sdim_key'] != undefined) {
      this.showView = true;
      this.selectedrecord = this.tempRecord['profile_assoc_sdim_key'];
      this.firstPanel.open();
      this.fillAccountInfo(this.tempRecord);

      if (
        this.firstRecord['profile_assoc_sdim_key'] !=
        this.tempRecord['profile_assoc_sdim_key']
      ) {
        this.isEdit = true;
      }
    }
  }
  fillAccountInfo(source: any) {
    this.accountNo = source['aum_acct_num'];
    this.classcode = source['aum_share_class_code'];
    this.accountName = source['acct_name'];
    this.lastUpdatedDate = formatDate(
      source['system_last_updated_date'],
      'MM/dd/yyyy hh:mm:ss a',
      'en_US'
    );
    this.lastUpdatedBy = source['system_last_updated_by_user_id'];
    this.firstEffectiveDate =
      source['first_effective_date'] != null
        ? this.datepipe.transform(
            source['first_effective_date'],
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source['first_effective_date'];
    this.lastEffectiveDate =
      source['last_effective_date'] != null
        ? this.datepipe.transform(
            source['last_effective_date'],
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source['last_effective_date'];
    this.profileId = source['aum_xhld_profiles_sq_id'];
    this.enable = source['last_effective_date'] != null ? false : true;
  }
  copyAsNewRecord() {
    const accountinfo = {
      prof_assoc_sdim_key: this.tempRecord['profile_assoc_sdim_key'],
    };
    this.xhldservice
      .copyHistoryprofileRecord(accountinfo)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.toastr.success('New Record created Scuccessfully');
          this.getProfileassocInfo();
        }
      });
  }

  deleteRecord() {
    if (confirm('Are you sure want to delete this record ? ')) {
      if (this.tempRecord['last_effective_date'] == null) {
        this.toastr.error('Delete is not allowed for First Record');
      } else if (
        this.lastRecord['profile_assoc_sdim_key'] ==
        this.tempRecord['profile_assoc_sdim_key']
      ) {
        this.toastr.error('Delete is not allowed for Last Record');
      } else {
        this.xhldservice
          .deleteHistoryprofileRecord(this.tempRecord['profile_assoc_sdim_key'])
          .subscribe((result: any) => {
            if (result.status == 200) {
              if (result.data.error_message == null) {
                this.toastr.success('Record deleted successfully');
                this.getProfileassocInfo();
              } else {
                this.toastr.error(result.data.error_message);
              }
            }
          }),
          (err) => {
            this.toastr.error(err.error.message);
          };
      }
    }
  }
  saveAccountInfo() {
    const accountacctinfo = {
      prof_assoc_sdim_key: this.tempRecord['profile_assoc_sdim_key'],
      acct_number: this.accountNo,
      share_class_code: this.classcode,
      aum_xhld_profiles_sq_id: this.profileId,
      first_effective_date:
        this.firstEffectiveDate != null
          ? this.datepipe.transform(
              this.firstEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.firstEffectiveDate,
      last_effective_date:
        this.lastEffectiveDate != null
          ? this.datepipe.transform(
              this.lastEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.lastEffectiveDate,
    };

    this.xhldservice.savexhldProfileAssoc(accountacctinfo).subscribe((res) => {
      this.toastr.success('Account details Updated Scuccessfully');
      this.getProfileassocInfo();
    }),
      (err) => {
        this.toastr.error(err.error.message);
      };
  }

  profileSearch(event) {
    this.valueSearchTxt = event.target.value;
  }

  clearsearch = (event) => {
    this.valueSearchTxt = '';
    event.target.value = '';
  };

  canDeactivate(): Observable<boolean> | boolean {
    console.log(this.pageDirty);
    if (this.pageDirty) {
      return this.confirmDialog.confirm('Are you sure you want to leave?');
    }
    return true;
  }
}
