<h2 mat-dialog-title>Add Value List</h2>
<form [formGroup]="valueForm" (ngSubmit)="saveValue(valueForm)">
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="row col-12">
            <mat-form-field appearance="legacy">
                <mat-label>Code</mat-label>
                <input matInput placeholder="Code" formControlName="list_code">
                
                <mat-hint>
                    <div class="error" *ngIf="valueForm.controls['list_code'].invalid && (valueForm.controls['list_code'].dirty || valueForm.controls['list_code'].touched)" class="alert">
                        <div  class="error" *ngIf="valueForm.controls['list_code'].errors.required">
                        Please enter Code
                        </div>
                    </div>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="row col-12">
            <mat-form-field appearance="legacy">
                <mat-label>type</mat-label>
                <input matInput placeholder="type" formControlName="list_type">
                
                <mat-hint>
                    <div class="error" *ngIf="valueForm.controls['list_type'].invalid && (valueForm.controls['list_type'].dirty || valueForm.controls['list_type'].touched)" class="alert">
                        <div  class="error" *ngIf="valueForm.controls['list_type'].errors.required">
                        Please enter type
                        </div>
                    </div>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="row col-12">
            <mat-form-field appearance="legacy">
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Description" rows="5" formControlName="list_desc"></textarea>
                
                <mat-hint>
                    <div class="error" *ngIf="valueForm.controls['list_desc'].invalid && (valueForm.controls['list_desc'].dirty || valueForm.controls['list_desc'].touched)" class="alert">
                        <div  class="error" *ngIf="valueForm.controls['list_desc'].errors.required">
                        Please enter type
                        </div>
                    </div>
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <!-- <button mat-raised-button type="button" (click)="feormUndo()">Undo</button>
    <button mat-raised-button type="button" (click)="formClar()">Clear All</button> -->
    <button mat-raised-button color="primary" class="add" type="submit">Save</button>
    <button mat-button type="button" (click)="cancelDialog()">Cancel</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Add</button> -->
</mat-dialog-actions>
</form>