import { Component, OnInit, Output, EventEmitter, Input, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrossholdingProfileAttributes, UnAssignedAccounts } from 'src/app/models/attributes';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


import { Profiles, Accts } from '../../../models/crossholding';


import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';
import { AddProfileComponent } from '../add-profile/add-profile.component';
import { ToasterService } from '../../../services/toastr.service';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from '../../../services/modal.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-crossholding-profile',
  templateUrl: './crossholding-profile.component.html',
  styleUrls: ['./crossholding-profile.component.css']
})
export class CrossholdingProfileComponent implements OnInit {
  @Input() profileid:number;
  @Output() profileSelected = new EventEmitter<{ profileid: number, profilename: string }>();
  @Output() pageDirty = new EventEmitter<{pagedirty: boolean, msg: string}>();
  @ViewChild(EditProfileComponent) child:EditProfileComponent
  @ViewChild('mdlbdy',{}) bdyRef: ElementRef;

  profileAttr;
  profileId;
  show: boolean = false;
  resAction: string;
  //UnAssignedAcctsList : UnAssignedAccounts[] =[];
  loadUnAssignedAcctColumns = ['AccountDim', 'AumDim', 'AccountNumber', 'ShareClass', 'AccountName', 'ReportEligibility'];
  loadUnAssignedAcctDatasource: MatTableDataSource<any>;
  profileList;
  loading:boolean = false;
  activeloading:boolean = false;
  activeProfile;
  labelAccounts = 'Associated Accounts';
  isLoading:boolean = false;
  public pagination:any       = {from: 0, to: 1000};
  pageLoading:boolean = false;  
  lastLoadPaging: boolean = true;
  isReadOnly: boolean =false;
  accts = new MatTableDataSource<Accts>();
  acctsColumns = ['acct_dim_id', 'aum_dim_id', 'aum_acct_num', 'aum_share_class_code', 'acct_name', 'report_eligibility_flag'];
  
  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number;
  pageSize:number = 100;
  length:number;
  
  constructor(
    public dialog: MatDialog,
    public toastr: ToasterService,
    private http: HttpService,
    private url: URLHelper,
    private elRef:ElementRef,
    private authservice: AuthService,
    public modalService: ModalService
  ) {
    this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
   }


  get_profile_names(){
    this.isLoading = true;
    this.http.getData(this.url.xhold_profile_get).subscribe((res:any)=>{
      this.isLoading = false;
      this.profileAttr = res.data as Profiles[];
      this.profileList = res.data as Profiles[];
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  
  profileFilter(value: string) {
    if (value) {
      value = value.toLowerCase();
      this.profileAttr = this.profileList.filter(x => x.aum_xhld_profiles_name && x.aum_xhld_profiles_name.toLowerCase().includes(value) || x.aum_xhld_profiles_name.toLowerCase().includes(value))
    } else {
      this.profileAttr = this.profileList;
    }
  }
  
  formResponse = (eventData: {pagedirty: boolean, msg: string})=>{
    if (eventData.pagedirty == true){
      this.pageDirty.emit({pagedirty: eventData.pagedirty, msg: 'Discard changes for Profile?'});
    }else{
      this.closeModal();
    }
  }
  closeModal(){
    this.modalService.close();
    this.pageDirty.emit({pagedirty: false, msg: ''});
    this.get_profile_names();
  }
  // add 
  openAddDialog() {
    this.modalService.open('add_modal');
  }
  openAddDialog1(row: any, action: string): void {
    this.show = false;
    this.resAction= action;
    this.profileId = "";
    /*  const dialogRef = this.dialog.open(ReportsetupDialogComponent, {});
     dialogRef.afterClosed().subscribe(result => { this.ReloadReportPackages() }); */
     
  }

  // editrule
  openEditDialog(row: any, action: string): void {
    
    this.profileId = row.aum_xhld_profiles_sq_id;
    setTimeout(()=>{
      this.bdyRef.nativeElement.scrollTop = top;
    }, 0);
    this.child.callProfile(this.profileId);
    this.modalService.open('edit_modal');
    
  }

  editSubmit(){
    console.log('submit')
    this.child.submitProfile();
  }

  onProfileChange(action, id, value) {
    console.log("sizeeeeeeeeeeeeeeeee", this.pageSize)
    this.resAction= action;
    this.profileId = id;    
    this.pageLoading = true;

    this.profileSelected.emit({profileid: id, profilename: value});//pass profile id to parent for using globally

    if (this.resAction == "clicked") {      
      this.show = true;
      if(id == 624 && value == 'Unassigned'){
        this.labelAccounts = "Unassigned Accounts";
        this.http.getData(this.url.xhold_profile_unassigned).subscribe((res: any)=>{
          if (res && res.status == 200){
            if (res.data.length == 1) {
              this.accts.data = [];
            }else{
              this.accts.data = res.data as Accts[];
            }
          }else{
            this.accts.data = [];
          }
          this.pageLoading = false;
        },(err:any)=>{
          this.accts.data = [];
          this.pageLoading = false;
          this.toastr.error("Issue while retrieving data. Please try again or contact support.")
        });
      }else{
        
        this.labelAccounts = "Associated Accounts";

        this.pagination.from = 0;
        this.pagination.to = 1000;
        this.lastLoadPaging = true;
        this.pageIndex = 0;

        this.http.getData(this.url.xhold_profile_accts + '/' + this.profileId).subscribe((res: any)=>{
          if (res && res.status == 200){
            this.accts.data = res.data.data as Accts[];
            this.length = res.data.total // / this.pageSize;
          }else{
            this.accts.data = [];
          }
          this.pageLoading = false;
        },(err:any)=>{
          this.accts.data = [];
          this.pageLoading = false;
          this.toastr.error("Issue while retrieving data. Please try again or contact support.")
        });
      }
      
    }
    
  }
  droppedProfile = (prof)=>{
    console.log(prof.previousIndex, prof.currentIndex, this.profileAttr)
    const data = {
      profile_id: this.profileAttr[prof.previousIndex]["aum_xhld_profiles_sq_id"],
      sort_order: prof.currentIndex//this.profileAttr[prof.currentIndex]["sort_order"]
    }

    if(confirm("Are you sure change sort order ? ")) {
      this.http.putData(this.url.xhold_profile_sort, {}, data).subscribe((res:any)=>{
        if(res.data.error_flag===0){
          this.toastr.success(res.message);
        }else{
          this.toastr.warning("Sort order not changed");
        }
        this.get_profile_names();
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }else{
      this.toastr.warning("Sort order not changed");
    }
  }
  saveChanges(list) {
    this.activeloading = true;
    this.activeProfile = list["aum_xhld_profiles_sq_id"];
    const body = {
      profile_id: list["aum_xhld_profiles_sq_id"],
      status_flag: list["aum_xhld_profiles_status"] == 'A'?'I':'A'
    }
    
    this.http.putData(this.url.xhold_profile_status,{}, body).subscribe((res:any)=>{
      this.activeloading = false;
      this.activeProfile = null;
      if (res.data.error_flag == 0){
        this.toastr.success("Status Changed");
      }else{
        this.toastr.error("Status not Changed");
      }
      this.get_profile_names();
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  currentProfile(e){
    console.log("dddddddddddddddddddddd", e)
  }

  ngOnInit(): void {
    this.profileId = this.profileid;
    this.get_profile_names();
  }

  scrolling (ele){
    console.log(ele)
  }

  getPageData = (event?:PageEvent) => {
      let pgSize = this.pageSize;
      let pgIdx = this.pageIndex;
      if(event?.pageSize != this.pageSize){
        pgSize = event?.pageSize;
        pgIdx = event?.pageIndex;
      }
      pgIdx = event?.pageIndex == 0?1: ((event?.pageIndex) + 1);
      const param           = this.url.xhold_profile_accts + '/' + this.profileId + '/' + (pgSize * (pgIdx - 1)) + '/' + (pgSize * pgIdx);
      this.pageLoading = true;

      this.http.getData(param).subscribe((data: any) => {
        this.pageLoading = false;
        
        if(data.data.length <=1000 ) this.lastLoadPaging = false;
        // this.accts.data = [...this.accts.data, ...data.data];
        this.accts.data = data.data.data as Accts[];
        
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });

    return event;
  }
  ngAfterViewInit(){
    
    this.elRef.nativeElement.querySelector('div#pagination').addEventListener('scroll', (event)=>{
      let sitem = this.elRef.nativeElement.querySelector('div#pagination')
      if (this.pageLoading) return;
      if(sitem.scrollTop.toFixed(0) == (sitem.scrollHeight - sitem.offsetHeight).toFixed(0)){
          return;
          if (!this.lastLoadPaging) return;
          const frm = this.pagination.to;
          const to = this.pagination.to + 1000;
          this.pagination.from = frm;
          this.pagination.to = to;
          
          const param           = this.url.xhold_profile_accts + '/' + this.profileId + '/' + frm + '/' + to;
          this.pageLoading = true;

          this.http.getData(param).subscribe((data: any) => {
            this.pageLoading = false;
            
            if(data.data.length <=1000 ) this.lastLoadPaging = false;
            this.accts.data = [...this.accts.data, ...data.data];
            // this.accts.data = data.data as Accts[];
          },
          err => {
            this.toastr.error("Issue while retrieving data. Please try again or contact support.")
          });
      }
      
    });
  }
}
