<div class="float-container">
  <div>
    <mat-card class="example-card">
      <mat-card-title class="text-left" style="font-size:smaller;">Account Attributes Management</mat-card-title>
      <mat-button-toggle-group id="attributesmgmt" aria-label="Font Style" #attributesmgmt="matButtonToggleGroup"
        [(ngModel)]="selectedValue" (click)="loadAttributesPanel()">
        <mat-button-toggle *ngFor="let attr of attributes;" [value]="attr.viewValue" style="color:blue;">
          {{attr.viewValue}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card>

    <div *ngIf="selectedValue"><div *ngIf="isbrandLoading">
          <mat-spinner class="loader" mode="determinate" value=100></mat-spinner>
        </div>
      <mat-accordion class="example-headers-align" *ngIf="selectedValue=='Brand'">
        
        <mat-expansion-panel #panel1 id="Panel1" class="example-card" [expanded]="true">
          
          <span class="global-search">
            <input type="text" placeholder="search" (keyup)="searchBrand($event)" (keydown.escape)="clearsearch($event)" >
          </span>
          
          <mat-expansion-panel-header>
            <mat-panel-title style="font-size:20px;">
              {{selectedValue | uppercase}} - Attributes Management
              

            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <p *ngIf="selectedValue"> Selected Attribute: {{output}} </p> -->

          <div *ngIf="isbrandLoading === false">
            <table mat-table [dataSource]="branddataSource" class="mat-elevation-z8" cdkDropList class="example-list"
              [cdkDropListData]="branddataSource" (cdkDropListDropped)="dropBrand($event)" style="border: right 1px;;">
              <ng-container matColumnDef="Activate">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Activate</th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm" >
                  <mat-icon [ngStyle]="{'color': isReadOnly ? 'grey' : (attr.aum_brand_status=='Active'? 'green' : 'red')}"
                    (click)="isReadOnly ? null : activateBrandAttributes(attr, 'acivate')" style="cursor: pointer;"
                    matTooltip="Activate Attributes">
                    radio_button_checked
                  </mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_brand_name">
                <th mat-header-cell *matHeaderCellDef class="spacing">Attribute Name</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_brand_name}}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="aum_brand_dim_id">
                <th mat-header-cell *matHeaderCellDef class="spacing">Dim Id</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_brand_dim_id}}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="aum_brand_code">
                <th mat-header-cell *matHeaderCellDef class="spacing">Code</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_brand_code}}
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_brand_description">
                <th mat-header-cell *matHeaderCellDef class="spacing">Description</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_brand_description}}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Add Attributes <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button mat-icon-button class="material-icons app-toolbar-menu add-button" matTooltip="Add Attributes" 
                    color="white" (click)="addBrandAttributes('add')" [disabled]="isDialogOpen || isReadOnly">
                    <mat-icon>library_add</mat-icon>
                  </button>

                </th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                    matTooltip="Edit Attributes" color="primary" (click)="editBrandAttributes(attr,'edit')"
                    [disabled]="isDialogOpen  || isReadOnly">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="brandColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let attr; columns: brandColumns;" cdkDrag [cdkDragData]="attr"></tr>
            </table>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="example-headers-align" *ngIf="selectedValue=='Crossholding Indicator'">
        <div *ngIf="isxholdLoading">
          <mat-spinner class="loader" mode="determinate" value=100></mat-spinner>
        </div>
        <mat-expansion-panel #panel4 id="Panel4" class="example-card" [expanded]="true">

          <span class="global-search">
            <input type="text" placeholder="search" (keyup)="searchXhold($event)" (keydown.escape)="clearsearch($event)" >
          </span>

          <mat-expansion-panel-header>
            <mat-panel-title style="font-size:20px;">
              {{selectedValue | uppercase}} - Attributes Management
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <p *ngIf="selectedValue"> Selected Attribute: {{output}} </p> -->
          <div *ngIf="isxholdLoading === false">
            <table mat-table [dataSource]="xholddataSource" class="mat-elevation-z8" cdkDropList class="example-list"
              [cdkDropListData]="xholddataSource" (cdkDropListDropped)="dropXhold($event)" style="border: right 1px;;">
              <ng-container matColumnDef="Activate">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Activate</th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <mat-icon [ngStyle]="{'color': isReadOnly ? 'grey' : (attr.aum_xhld_indicator_status=='Active'? 'green' : 'red')}"
                    (click)="isReadOnly ? null :activatexholdAttributes(attr, 'acivate')" style="cursor: pointer;"
                    matTooltip="Activate Attributes">
                    radio_button_checked
                  </mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_billing_indicator_name">
                <th mat-header-cell *matHeaderCellDef class="spacing">Attribute Name</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_xhld_indicator_name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_billing_indicator_code">
                <th mat-header-cell *matHeaderCellDef class="spacing">Code</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_xhld_indicator_code}}
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_billing_indicator_desc">
                <th mat-header-cell *matHeaderCellDef class="spacing">Description</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_xhld_indicator_desc}}
                </td>
              </ng-container>
              <ng-container matColumnDef="system_last_updated_by_user_id">
                <th mat-header-cell *matHeaderCellDef class="spacing">Updated User</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.system_last_updated_by_user_id}}
                </td>
              </ng-container>
              <ng-container matColumnDef="system_last_updated_date">
                <th mat-header-cell *matHeaderCellDef class="spacing">Updated Date</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.system_last_updated_date | date: 'MM-dd-yyyy h:mm a'}}
                </td>
              </ng-container>
              
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Add Attributes <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button mat-icon-button class="material-icons app-toolbar-menu add-button" matTooltip="Add Attributes"
                    color="white" (click)="addxholdAttributes('add')" [disabled]="isDialogOpen  || isReadOnly">
                    <mat-icon>library_add</mat-icon>
                  </button>

                </th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                    matTooltip="Edit Attributes" color="primary" (click)="editxholdAttributes(attr,'edit')"
                    [disabled]="isDialogOpen || isReadOnly">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="xholdColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let attr; columns: xholdColumns;" cdkDrag [cdkDragData]="attr"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="example-headers-align" *ngIf="selectedValue=='Billing Indicator'">
        <div *ngIf="isbillingLoading">
          <mat-spinner class="loader" mode="determinate" value=100></mat-spinner>
        </div>
        <mat-expansion-panel #panel3 id="Panel3" class="example-card" [expanded]="true">
          
          <span class="global-search">
            <input type="text" placeholder="search" (keyup)="searchBill($event)" (keydown.escape)="clearsearch($event)" >
          </span>

          <mat-expansion-panel-header>
            <mat-panel-title style="font-size:20px;">
              {{selectedValue | uppercase}} - Attributes Management
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <p *ngIf="selectedValue"> Selected Attribute: {{output}} </p> -->
          <div *ngIf="isbillingLoading === false">
            <table mat-table [dataSource]="billingdataSource" class="mat-elevation-z8" cdkDropList class="example-list"
              [cdkDropListData]="billingdataSource" (cdkDropListDropped)="dropBilling($event)"
              style="border: right 1px;;">
              <ng-container matColumnDef="Activate">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Activate</th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <mat-icon [ngStyle]="{'color': isReadOnly ? 'grey' : ( attr.aum_billing_indicator_status=='Active'? 'green' : 'red')}"
                    (click)="isReadOnly ? null :activateBillingAttributes(attr, 'acivate')" style="cursor: pointer;"
                    matTooltip="Activate Attributes">
                    radio_button_checked
                  </mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_billing_indicator_name">
                <th mat-header-cell *matHeaderCellDef class="spacing">Attribute Name</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_billing_indicator_name}}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="aum_billing_indicator_dim_id">
                <th mat-header-cell *matHeaderCellDef class="spacing">Dim Id</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_billing_indicator_dim_id}}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="aum_billing_indicator_code">
                <th mat-header-cell *matHeaderCellDef class="spacing">Code</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_billing_indicator_code}}
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_billing_indicator_desc">
                <th mat-header-cell *matHeaderCellDef class="spacing">Description</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_billing_indicator_desc}}
                </td>
              </ng-container>
              <ng-container matColumnDef="system_last_updated_by_user_id">
                <th mat-header-cell *matHeaderCellDef class="spacing">Updated User</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.system_last_updated_by_user_id}}
                </td>
              </ng-container>
              <ng-container matColumnDef="system_last_updated_date">
                <th mat-header-cell *matHeaderCellDef class="spacing">Updated Date</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.system_last_updated_date | date: 'MM-dd-yyyy h:mm a'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Add Attributes <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button mat-icon-button class="material-icons app-toolbar-menu add-button" matTooltip="Add Attributes"
                    color="white" (click)="addBillingAttributes('add')" [disabled]="isDialogOpen  || isReadOnly">
                    <mat-icon>library_add</mat-icon>
                  </button>

                </th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                    matTooltip="Edit Attributes" color="primary" (click)="editBillingAttributes(attr,'edit')"
                    [disabled]="isDialogOpen  || isReadOnly">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="billingColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let attr; columns: billingColumns;" cdkDrag [cdkDragData]="attr"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="example-headers-align" *ngIf="selectedValue=='Portfolio Mandate'">
        <div *ngIf="isportfolioLoading">
          <mat-spinner class="loader" mode="determinate" value=100></mat-spinner>
        </div>
        <mat-expansion-panel #panel2 id="Panel2" class="example-card" [expanded]="true">     
          
          <span class="global-search">
            <input type="text" placeholder="search" (keyup)="searchPort($event)" (keydown.escape)="clearsearch($event)" >
          </span>

          <mat-expansion-panel-header>
            <mat-panel-title style="font-size:20px;">
              {{selectedValue | uppercase}} - Attributes Management
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <p *ngIf="selectedValue"> Selected Attribute: {{output}} </p> -->
          <div *ngIf="isportfolioLoading === false">
            <table mat-table [dataSource]="portfoliodataSource" class="mat-elevation-z8" cdkDropList
              class="example-list" [cdkDropListData]="portfoliodataSource" (cdkDropListDropped)="dropPortfolio($event)"
              style="border: right 1px;;">
              <ng-container matColumnDef="Activate">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Activate</th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <mat-icon [ngStyle]="{'color': isReadOnly ? 'grey' : ( attr.aum_port_man_status=='Active'? 'green' : 'red')}"
                    (click)="isReadOnly ? null :activatePortfolioAttributes(attr, 'acivate')" style="cursor: pointer;"
                    matTooltip="Activate Attributes">
                    radio_button_checked
                  </mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_port_man_name">
                <th mat-header-cell *matHeaderCellDef class="spacing">Attribute Name</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_port_man_name}}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="aum_port_man_dim_id">
                <th mat-header-cell *matHeaderCellDef class="spacing">Dim Id</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_port_man_dim_id}}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="aum_port_man_code">
                <th mat-header-cell *matHeaderCellDef class="spacing">Code</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_port_man_code}}
                </td>
              </ng-container>
              <ng-container matColumnDef="aum_port_man_desc">
                <th mat-header-cell *matHeaderCellDef class="spacing">Description</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.aum_port_man_desc}}
                </td>
              </ng-container>
              <ng-container matColumnDef="system_last_updated_by_user_id">
                <th mat-header-cell *matHeaderCellDef class="spacing">Updated User</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.system_last_updated_by_user_id}}
                </td>
              </ng-container>
              <ng-container matColumnDef="system_last_updated_date">
                <th mat-header-cell *matHeaderCellDef class="spacing">Updated Date</th>
                <td mat-cell *matCellDef="let attr" class="spacing">
                  {{attr.system_last_updated_date | date: 'MM-dd-yyyy h:mm a'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="spacing-sm">Add Attributes <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button mat-icon-button class="material-icons app-toolbar-menu add-button" matTooltip="Add Attributes"
                    color="white" (click)="addPortfolioAttributes('add')" [disabled]="isDialogOpen || isReadOnly">
                    <mat-icon>library_add</mat-icon>
                  </button>

                </th>
                <td mat-cell *matCellDef="let attr" class="spacing-sm">
                  <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                    matTooltip="Edit Attributes" color="primary" (click)="editPortfolioAttributes(attr,'edit')"
                    [disabled]="isDialogOpen || isReadOnly">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="portfolioColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let attr; columns: portfolioColumns;" cdkDrag [cdkDragData]="attr"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</div>


<!-- **************************************************************************** -->
<!-- ************************** Modal Brand ************************************* -->
<!-- **************************************************************************** -->



<modal id="add_brand_modal">
  <!-- <form [formGroup]="form" #proFrm (ngSubmit)="saveProfile(form)" novalidate> -->
      <div class="modal-header">
          <h2 mat-dialog-title>Attributes Management</h2>
          <!-- <button type="button" class="btn-close" (click)="closeModal()"></button>  (onFormResponse)="formResponse($event)"-->
      </div>
      <div class="modal-body">
          <app-brand-management-dialog (formResponse)="returnResponse($event)"></app-brand-management-dialog>
      </div>
  <!-- </form> -->
</modal>



<!-- **************************************************************************** -->
<!-- ************************** Modal Indicator ********************************* -->
<!-- **************************************************************************** -->


<modal id="xhold_modal">
  <!-- <form [formGroup]="form" #proFrm (ngSubmit)="saveProfile(form)" novalidate> -->
      <div class="modal-header">
          <h2 mat-dialog-title>Attributes Management</h2>
          <!-- <button type="button" class="btn-close" (click)="closeModal()"></button>  (onFormResponse)="formResponse($event)"-->
      </div>
      <div class="modal-body">
          <app-crossholdingindicator-management-dialog (formResponse)="returnResponse($event)"></app-crossholdingindicator-management-dialog>
      </div>
  <!-- </form> -->
</modal>


<!-- **************************************************************************** -->
<!-- ************************** Modal Portfolio ********************************* -->
<!-- **************************************************************************** -->


<modal id="portfolio_modal">
  <!-- <form [formGroup]="form" #proFrm (ngSubmit)="saveProfile(form)" novalidate> -->
      <div class="modal-header">
          <h2 mat-dialog-title>Attributes Management</h2>
          <!-- <button type="button" class="btn-close" (click)="closeModal()"></button>  (onFormResponse)="formResponse($event)"-->
      </div>
      <div class="modal-body">
          <app-portmandate-management-dialog (formResponse)="returnResponse($event)"></app-portmandate-management-dialog>
      </div>
  <!-- </form> -->
</modal>

<!-- **************************************************************************** -->
<!-- ************************** Modal Billing *********************************** -->
<!-- **************************************************************************** -->


<modal id="billing_modal">
  <!-- <form [formGroup]="form" #proFrm (ngSubmit)="saveProfile(form)" novalidate> -->
      <div class="modal-header">
          <h2 mat-dialog-title>Attributes Management</h2>
          <!-- <button type="button" class="btn-close" (click)="closeModal()"></button>  (onFormResponse)="formResponse($event)"-->
      </div>
      <div class="modal-body">
          <app-billingindicator-management-dialog (formResponse)="returnResponse($event)"></app-billingindicator-management-dialog>
      </div>
  <!-- </form> -->
</modal>