<div *ngIf="isLoading" class="spinner">
    <mat-spinner class="loader"></mat-spinner>
</div>

<mat-card class="text-left">
    <mat-card class="example-card text-center">
        <h3 class="title"><b>Crossholding Profile</b></h3>
    </mat-card>
    <br>
        <div class="row">
            <div class="list-grid col-4 ">
                <mat-form-field style="width:100%;">
                    <div class="row">
                        <div class="list-grid col-8 ">
                            <mat-label>Profile</mat-label>
                            <input class="search-profile" matInput (keyup)="profileFilter($event.target.value)" placeholder="Profile">
                            <mat-icon class="search-icon" matSuffix>search</mat-icon>
                            
                        </div>
                        <div class="list-grid col-4 text-right">
                            <button type="button" (click)="openAddDialog()" 
                                class="add-button" [disabled]= "isReadOnly"
                                mat-mini-fab color="primary" 
                                matTooltip="New Profile" aria-label="Create Profile">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-form-field>
                <!-- <div class="mat-elevation-z8"> -->
                <div cdkDropList class="profile-list mat-elevation-z8"  (cdkDropListDropped)="droppedProfile($event)" > 
                    <mat-selection-list class="mat-select-elevation" #profiles [multiple]="false">
                        <mat-list-option aria-selected="true" 
                            cdkDrag
                            *ngFor="let list of profileAttr" 
                            [value]="list.aum_xhld_profiles_name"
                            [selected]="this.profileId == list.aum_xhld_profiles_sq_id" 
                            [ngStyle]="{'color':isReadOnly ? 'grey' :( list.active? 'green' : 'red')}">
                            <div class="row">
                                <!-- <div class="col-2 vert-center">
                                    {{list.aum_xhld_profiles_sq_id}}. 
                                </div> -->
                                <div class="col-9 vert-center" (click)="onProfileChange('clicked', list.aum_xhld_profiles_sq_id, list.aum_xhld_profiles_name)">
                                    <span mat-line>
                                        <span class="inline-block"  >
                                            {{list.aum_xhld_profiles_name}}
                                        </span>
                                    </span>
                                </div>
                                
                                <div class="col-1">
                                    <button mat-list-button (click)="openEditDialog(list, 'edit')" style="float:right;"
                                        class="btn-report" type="button" [disabled]= "isReadOnly">
                                        <mat-icon mat-list-icon matSuffix matTooltip="Edit profile">edit
                                        </mat-icon>
                                    </button>
                                </div>
                                
                                <div class="col-1 text-left">
                                    <button mat-list-button (click)="saveChanges(list)" [disabled]= "isReadOnly"
                                        class="btn-report" type="button"
                                        [ngClass]="{loading:activeloading && activeProfile==list.aum_xhld_profiles_sq_id}"
                                        [ngStyle]="{'color': isReadOnly ? 'grey' :(list.aum_xhld_profiles_status == 'A'? 'green' : 'red')}">
                                        <mat-icon mat-list-icon matSuffix matTooltip="Activate/Deactivate profile">radio_button_checked
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                                <!-- <button mat-list-button (click)="openAddDialog(list, 'add')" style="float:right;"
                                    class="btn-report" type="button">
                                    <mat-icon mat-list-icon matSuffix matTooltip="Add new profile">add_box
                                    </mat-icon>
                                </button> -->
                           
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </div>
            
            <div class="col-8 text-right">
                <div class=" xhold-table" id = "pagination">
                    <mat-card class="example-card" *ngIf="show==true"  [ngClass]="{xloading: pageLoading}">
                        <h4 align="left">{{labelAccounts}}</h4>

                        <table mat-table 
                            [ngClass]="{loading: loading}"
                            [dataSource]="accts" class="mat-elevation-z8">
                            <ng-container matColumnDef="acct_dim_id">
                                <th mat-header-cell *matHeaderCellDef>Acct Dim</th>
                                <td mat-cell *matCellDef="let element">{{ element.acct_dim_id }} </td>
                            </ng-container>
                            <ng-container matColumnDef="aum_dim_id">
                                <th mat-header-cell *matHeaderCellDef>AUM Dim</th>
                                <td mat-cell *matCellDef="let element">{{ element.aum_dim_id }} </td>
                            </ng-container>
                            <ng-container matColumnDef="aum_acct_num">
                                <th mat-header-cell *matHeaderCellDef>Account Number</th>
                                <td mat-cell *matCellDef="let element">{{ element.aum_acct_num }} </td>
                            </ng-container>
                            <ng-container matColumnDef="aum_share_class_code">
                                <th mat-header-cell *matHeaderCellDef>Share Class</th>
                                <td mat-cell *matCellDef="let element">{{ element.aum_share_class_code }} </td>
                            </ng-container>
                            <ng-container matColumnDef="acct_name">
                                <th mat-header-cell *matHeaderCellDef>Account Name</th>
                                <td mat-cell *matCellDef="let element">{{ element.acct_name }} </td>
                            </ng-container>
                            <ng-container matColumnDef="report_eligibility_flag">
                                <th mat-header-cell *matHeaderCellDef>Report Eligifility</th>
                                <td mat-cell *matCellDef="let element">{{ element.report_eligibility_flag }} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="acctsColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: acctsColumns;"
                                ></tr>
                        
                        </table>
                        
                        
                        <div *ngIf="accts.data.length === 0"
                            class="no-records alert alert-info text-center">
                            No records found
                        </div>
                        
                    </mat-card>
                </div>
                <mat-paginator #paginator  *ngIf="accts.data.length > 0"
                            [length]="length"
                            [pageIndex]="pageIndex"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="[100, 500, 1000]"
                            (page)="pageEvent = getPageData($event)" 
                            showFirstLastButtons>
                </mat-paginator>
               
            </div>
        </div>
</mat-card>





<!-- **************************************************************************** -->
<!-- ************************** Add Profile ************************************* -->
<!-- **************************************************************************** -->



<modal id="add_modal">
    <!-- <form [formGroup]="form" #proFrm (ngSubmit)="saveProfile(form)" novalidate> -->
        <div class="modal-header">
            <h2 mat-dialog-title>Add Profile</h2>
            <!-- <button type="button" class="btn-close" (click)="closeModal()"></button> -->
        </div>
        
            <app-add-profile (onFormResponse)="formResponse($event)"></app-add-profile>
            
    <!-- </form> -->
</modal>


<!-- **************************************************************************** -->
<!-- ************************** Edit Profile ************************************ -->
<!-- **************************************************************************** -->

<modal id="edit_modal">
        <div class="modal-header">
            <h2 mat-dialog-title>Edit Profile</h2>
        </div>
        
        <app-edit-profile (onFormResponse)="formResponse($event)" [profileId]="profileId" ></app-edit-profile>
        
</modal>