<!-- <h2 mat-dialog-title>Edit Profile :</h2> -->
<form [formGroup]="form" (ngSubmit)="saveRule(form)" [ngClass]="{'edit-loading': loading}">
    <div class="modal-body" #mdlbdy>
        <mat-dialog-content class="mat-typography" id = "pagination" >
            <mat-form-field appearance="legacy">
                <mat-label>Profile Name</mat-label>
                <input  (keyup)="checkName($event)" formControlName="profile_name" matInput placeholder="Profile Name" value="{{rules_row.profile_name}}">
                <div *ngIf="profile_loading" class="profile_loading">
                    <mat-spinner diameter="20" color="warn"></mat-spinner>
                </div>
                <mat-hint>
                    <div class="error" *ngIf="form.controls['profile_name'].invalid && (form.controls['profile_name'].dirty || form.controls['profile_name'].touched)" class="alert">
                        <div  class="error" *ngIf="form.controls['profile_name'].errors.required">
                        Please enter Profile Name
                        </div>
                    
                    <div  class="error" *ngIf="form.controls['profile_name'].errors?.checkName">
                        Duplicate Crossholding Profile names are not allowed.
                    </div></div>
                </mat-hint>
            </mat-form-field>
                    
            <mat-form-field appearance="legacy">
                <mat-label>Description</mat-label>
                <textarea formControlName="profile_description" matInput placeholder="Description">{{ rules_row.profile_description }}</textarea>
                
                <mat-hint>Enter Profile Description</mat-hint>
            </mat-form-field>
                    
            <!-- <mat-form-field>
            <mat-select formControlName="profile_type_id" placeholder="Rule Type" [(value)] = 'selected_profile_type'>
                <mat-option *ngFor="let type of profile_types" [value]="type.profile_type_id" >
                    {{ type.profile_type_name }}
                </mat-option>
            </mat-select>
            </mat-form-field> -->
                
                
            <mat-grid-list  [cols]="breakpoint"  (window:resize)="onResize($event)" rowHeight="15:5">
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-select formControlName="var_variable" placeholder="Variable" (selectionChange)="getValidOperators($event.value)" no-space>
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="varChange($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>
                            <mat-option *ngFor="let vls of variables | search : varText : 'aum_buss_var_name' " [value]="vls.aum_buss_var_ref_tab + '.' + vls.aum_buss_var_name">
                                {{vls.aum_buss_var_ref_tab}}.{{ vls.aum_buss_var_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <span class="loader"></span> -->
                </mat-grid-tile>
                <mat-grid-tile>
                    
                    <mat-form-field>
                        <mat-select formControlName="var_condition" placeholder="Condition">
                            <mat-option *ngFor="let opt of valid_operators" value="{{opt}}">{{opt}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field>
                        <!-- <mat-select formControlName="var_value" placeholder="Value">
                            <mat-option *ngFor="let vlu of var_values" value="{{vlu}}">{{vlu}}</mat-option>
                        </mat-select> -->
                        <mat-label>Value</mat-label>
                        <input matInput formControlName="var_value" placeholder="Value" #var_value>
                        <mat-hint>
                            <div class="text-danger">
                                {{valid_variable_entry}}
                            </div>
                        </mat-hint>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <button #addBtn [disabled]="!form.controls['var_variable'].value || !form.controls['var_condition'].value" type="button" class="add" mat-raised-button color="primary" (click)="addCondition(form)">Add</button>
                </mat-grid-tile>    
            </mat-grid-list>

            <div class="row margin-top-bottom-10">
                <div class="col-2">
                    <button class="margin-top-bottom-10" type="button" mat-raised-button (click)="addAnd()">And</button>
                </div>
                <div class="col-2">
                    <button class="margin-top-bottom-10" type="button" mat-raised-button (click)="addOr()">Or</button>
                </div>
                <div class="col-2">
                    <button class="margin-top-bottom-10" type="button" mat-raised-button (click)="addLeftPar()">(</button>
                </div>
                <div class="col-2">
                    <button class="margin-top-bottom-10" type="button" mat-raised-button (click)="addRgtPar()">)</button>
                </div>
            </div>

            
                <mat-form-field appearance="legacy">
                    <mat-label>Rules</mat-label>
                    <textarea [ngClass]="{'viewquery': viewquery}" class="input-text-icon"  readonly formControlName="profile_type_string" matInput placeholder="Condition" rows="5">{{ rules_row.profile_string }}</textarea>
                    <mat-hint>
                        <div  class="error" *ngIf="form.controls['profile_type_string'].errors?.invalidquery">
                            RuleString provided may be invalid.
                        </div>
                    </mat-hint>
                    <button type="button" (click)="viewQuery()" mat-mini-fab color="primary" matTooltip="View Query" aria-label="View Query" class="view-button">
                            
                    </button>
                    
                    <button type="button" (click)="editCriteria()" mat-mini-fab color="primary" matTooltip="Edit Criteria" aria-label="Edit Criteria" class="edit-button">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" (click)="formUndo()" mat-mini-fab color="primary" matTooltip="Undo Last Action" aria-label="Undo Last Action" class="undo-button">
                        <mat-icon>undo</mat-icon>
                    </button>
                    <button type="button" (click)="formClear()" mat-mini-fab color="primary" matTooltip="Clear All" aria-label="Clear All" class="clear-button">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-form-field>
            <mat-card class="example-card rules-text">
            <div class="where">
                <div *ngFor="let item of where_rule_strings; index as idx">
                    <span [ngClass]="{'selected': idx == whereIdx}" 
                    (click)="selectedWhere($event, idx, item)" >
                        {{ item }}
                    </span>
                    <button (click)="deleteCriteria(idx)" type="button" class="del-btn" matTooltip="Delete criteria" >clear</button>
                </div>
            </div>
            </mat-card>
            <mat-card class="profile-card">
                <mat-card-actions>Associated Accounts</mat-card-actions>
                
                <div class="associate">
                    <table mat-table class="table-accounts loading mat-elevation-z8"
                        [ngClass]="{loading: refreshLoading}"
                        [dataSource]="accounts">
                        <ng-container matColumnDef="acct_dim_id">
                            <th mat-header-cell *matHeaderCellDef>Acct Dim</th>
                            <td mat-cell *matCellDef="let element">{{ element.acct_dim_id }} </td>
                        </ng-container>
                        <ng-container matColumnDef="aum_dim_id">
                            <th mat-header-cell *matHeaderCellDef>AUM Dim</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_dim_id }} </td>
                        </ng-container>
                        <ng-container matColumnDef="aum_acct_num">
                            <th mat-header-cell *matHeaderCellDef>Account Number</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_acct_num }} </td>
                        </ng-container>
                        <ng-container matColumnDef="aum_share_class_code">
                            <th mat-header-cell *matHeaderCellDef>Share Class</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_share_class_code }} </td>
                        </ng-container>
                        <ng-container matColumnDef="acct_name">
                            <th mat-header-cell *matHeaderCellDef>Account Name
                                <button type="button" (click)="refreshAccounts()" mat-mini-fab color="primary" matTooltip="Refresh Accounts" aria-label="Edit Criteria" class="refresh-button">
                                    <mat-icon>refresh</mat-icon>
                                </button>

                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.acct_name }} </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="report_eligifility_flag">
                            <th mat-header-cell *matHeaderCellDef>Report Eligifility</th>
                            <td mat-cell *matCellDef="let element">{{ element.report_eligifility_flag }} </td>
                        </ng-container> -->

                        <tr mat-header-row *matHeaderRowDef="acctsColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: acctsColumns;"
                            ></tr>
                    
                    </table>
                    
                    
                    <div *ngIf="accounts.length === 0"
                        class="no-records alert alert-info text-center loading">
                        No records found
                    </div>
                </div>
                <mat-paginator #paginator *ngIf="accounts.length > 0"
                                [length]="length"
                                [pageIndex]="pageIndex"
                                [pageSize]="pageSize"
                                [pageSizeOptions]="[100, 500, 1000]"
                                (page)="pageEvent = getPageData($event)" 
                                showFirstLastButtons>
                    </mat-paginator>
            </mat-card>
            
        </mat-dialog-content>
    </div>
    <div class="modal-footer">
        <!-- <button mat-raised-button type="button" (click)="formUndo()">Undo</button>
        <button mat-raised-button type="button" (click)="formClear()">Clear All</button> -->
        <button mat-raised-button #sbmt class="add" [disabled]="!form.dirty" color="primary" type="submit">Save</button>
        <button mat-button type="button" (click)="cancelEditProfile(form)">Cancel</button>
    </div>

</form>