import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig
} from "@angular/material/dialog";
import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'edit-criteria',
  templateUrl: './edit-criteria.component.html',
  styleUrls: ['./edit-criteria.component.css']
})
export class EditCriteriaComponent implements OnInit {

  constructor(
    private http: HttpService,
    private url: URLHelper,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialogRef: MatDialogRef<EditCriteriaComponent>
  ) { }
  criteria: any;
  selectedIdx;
  selectedCriteria: any;
  variables: any;
  valid_operators: any;
  var_values: any;
  mod_variable;
  mod_operator;
  mod_value;
  operate_loading:boolean = false;
  profile_name;
  select_variable: MatSelect;
  select_condition: MatSelect;
  selectedReletive: boolean = false;
  temp_criteria: any;
  rule_val_status: boolean = false;
  keyWords = ['SYSDATE', 'TRUNC' ];
  updatedCriteria: any;
  specialCond='';
  specialText = '';
  special_var = '';
  special_opr = '';
  special_val = '';
  profile_string = '';
  showLogic:boolean = false;
  showLogical = '';

  // selectCriteria = (idx, rec) =>{
  //   this.selectedReletive = false;

  //   if(rec == 'AND' || rec == 'OR' || rec == '(' || rec == ')')  {
      
  //     this.selectedIdx = -1;
  //     this.mod_operator = "";
  //     this.mod_variable = "";
  //     this.mod_value = "";
  //     this.selectedReletive = true;
  //     return;
  //   }  
  //   this.selectedIdx = idx;
  //   this.selectedCriteria = rec;

  //   const extractQuoteValue = rec
  //     .match(/(?:'[^']*'|^[^']*$)/)[0]
  //     .replace(/'/g, '')


  //   const tmpCriteria = rec.split(" ");
  //   this.mod_variable = tmpCriteria[0];
  //   this.mod_value = extractQuoteValue //tmpCriteria[2];
  //   this.getValidOperators(this.mod_variable, tmpCriteria[1]);
  // }
  selectCriteria = (idx, rec) =>{
    this.selectedReletive = false;
    if(rec.indexOf(">")==-1 && rec.indexOf('<')==-1){
      rec = rec.replace("=", " = ");
    }

    rec = rec.trim().replace(/[\n]/g, '').replace(/\s+/g, ' ');  //remove escape sequence, replace doube space to single space
    if(rec == 'AND' || rec == 'OR' || rec == '(' || rec == ')')  {
      
      this.selectedIdx = -1;
      this.mod_operator = "";
      this.mod_variable = "";
      this.mod_value = "";
      this.selectedReletive = true;
      return;
    }  
    this.selectedIdx = idx;
    this.selectedCriteria = rec;

    //extract value from quote
    const extractQuoteValue = rec
      .match(/(?:'[^']*'|^[^']*$)/)[0]
      .replace(/'/g, '')

    
    var tmpCriteria;
    
    if(rec.indexOf(" IN ") >= 0 || rec.indexOf(" IN(") >= 0){
      rec = rec.replace(" IN", " IN ").replace("IN(", " IN (");
      tmpCriteria = rec.split(" IN ");
      tmpCriteria.splice(1, 0, "IN");
    }
    else if(rec.indexOf('TRUNC')>=0){
      this.specialCond = 'TRUNC';
      this.specialText = rec;
      tmpCriteria = [];
      try{
        rec = rec.replace(/\s+/g, '');
        this.special_var = rec.substring(rec.lastIndexOf("(") +1, rec.indexOf(")"));
        tmpCriteria.push(this.special_var);
        let  mdn = (rec.indexOf(">=")>=0?">=":(rec.indexOf("<=")>=0?"<=": (rec.indexOf(">")>=0?">": (rec.indexOf("<")>0?"<":"=" ) ) ))
        tmpCriteria.push(mdn);
        this.special_opr = mdn;
    
        

        rec = rec.replace(">=", "=").replace("<=", "=").replace("<", "=").replace(">", "=");
        let ary = rec.split("=")
        this.special_val = ary[1].replace(/\)/g, '');
        tmpCriteria.push(ary[1]);
        tmpCriteria[2] = tmpCriteria[2].replace(")", '')
      }
      catch(e){
        console.log(e)
      }
    }
    else{
      tmpCriteria = rec.split(" ");
    }

    let cond = ""
    this.rule_val_status = false;
    if(tmpCriteria[1] == 'IS' && tmpCriteria[2] == 'NULL'){
      this.mod_variable = tmpCriteria[0];
      cond = tmpCriteria[1] + " " + tmpCriteria[2];
      this.rule_val_status = true;
    }else{
      this.mod_variable = tmpCriteria[0];
      tmpCriteria[2] = tmpCriteria[2].indexOf("(")==-1?tmpCriteria[2].split(")")[0]: tmpCriteria[2];
      this.mod_value = rec.indexOf(' IN ') >=0 ? tmpCriteria[2]: ( this.keyWords.find((kw)=>kw==tmpCriteria[2])?tmpCriteria[2]: extractQuoteValue) //tmpCriteria[2];
      cond = tmpCriteria[1]
    }
    
    this.getValidOperators(this.mod_variable, cond);//tmpCriteria[1]);
    
  }
  protected getData = () =>{
    
    this.http.getData(this.url.xhold_profile_attr).subscribe((data: any) => {
      this.variables = data.data;
    });

  }

  getValidOperators = (varntab, opr = '') =>{
    this.operate_loading  = true;
    const table_name      = varntab.split(".")[0];
    const var_name        = varntab.split(".")[1];
    const param           = '/' + table_name + '/' + var_name;
    //'?col_name=' + var_name + '&' + 'table_name=' + table_name;
    // this.valid_operators  = [];
    this.var_values       = [];
    this.http.getData(this.url.xhold_profile_valid_operators + param).subscribe((data: any) => {
      this.valid_operators = data.data;
      this.mod_operator = " " + opr + " ";
    });

    this.http.getData(this.url.xhold_profile_attr_values + param).subscribe((data: any) => {
      this.var_values = data.data;
      this.operate_loading = false;
    });
  }

  // updateCriteria1111111111111111111111111111 = (frm) =>{
  //   this.criteria[this.selectedIdx] = this.mod_variable + " " + this.mod_operator.trim() + " '" + this.mod_value + "'";
  // }
  updateCriteria = (frm) =>{
try{
    if(this.specialCond == 'TRUNC'){
      let updt_text =  this.specialText.replace(this.special_var, this.mod_variable);
      updt_text = updt_text.replace(this.special_opr, this.mod_operator);
      updt_text = updt_text.replace(this.special_val, this.mod_value);
      this.updatedCriteria = updt_text;
    }else if(this.specialCond == 'ANDOR'){
      this.updatedCriteria = this.showLogical;
    }else if(this.mod_operator.trim() == 'IS NULL'){
      this.updatedCriteria = this.mod_variable + " " + this.mod_operator.trim();
    }else if(this.mod_operator.trim() == 'IN'){
      this.updatedCriteria = this.mod_variable + " " + this.mod_operator.trim() + this.mod_value;
    }else{
      
      this.updatedCriteria = this.mod_variable + " " + this.mod_operator.trim() + ((this.keyWords.indexOf(this.mod_value) >= 0)? this.mod_value: " '" + this.mod_value + "'");
      
    }
  
    console.log(this.criteria, this.updatedCriteria)
    
      // this.criteria = this.criteria.replace(this.criteria.trim(), this.updatedCriteria);
    }catch(e){
      console.log(e)
    }
    this.dialogRef.close({data:{rule_strings: this.profile_string, edit_criteria: this.criteria, updated_criteria: this.updatedCriteria }});
  }

  // saveCriteria = () =>{
  //   this.dialogRef.close({data:this.criteria});
  // }
  cancelCriteria = () =>{
    
    this.criteria = this.temp_criteria;
    this.dialogRef.close({data: this.temp_criteria});
  }
  
  addAnd = () =>{
    this.showLogical = 'AND';
    console.log(this.criteria)
    this.updatedCriteria = this.criteria.replace('AND', this.showLogical);
    console.log(this.criteria)
  }

  addOr = () =>{
    this.showLogical = 'OR';
    console.log(this.criteria)
    this.updatedCriteria = this.criteria.replace('AND', this.showLogical);
    console.log(this.criteria)
  }
  ngOnInit(): void {
    
    this.temp_criteria = JSON.parse(JSON.stringify(this.data.profile_string));
    this.criteria = this.data.criteria;  
    this.profile_string = this.data.profile_string;
    this.profile_name = this.data.profile_name;
    if (this.criteria.trim().toUpperCase() == 'AND' || this.criteria.trim().toUpperCase() == 'OR'){
      this.specialCond = 'ANDOR';
      this.showLogic = true;
      this.showLogical = this.criteria.trim().toUpperCase();
    }
    this.getData();
    this.selectCriteria(0, this.data.criteria)
  }

}
