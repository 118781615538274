import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './commonComponents/sidenav/sidenav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Angular Material Imports, we can clean this up later. I imported everything to make it easier for everyone */
import {  MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
// import { ScrollingModule } from '@angular/cdk/scrolling';
// import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ExhibitComponent } from './components/exhibits/exhibit.component';
import { MatAccordion } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';


import { SideNavModel } from './models/sidenav';
import { FinalAssetAmountAdjustmentComponent } from './components/final-asset-amount-adjustment/final-asset-amount-adjustment.component';
import { AccountAttributesComponent } from './components/account-attributes/account-attributes.component';
import { ShareClassAttributesComponent } from './components/share-class-attributes/share-class-attributes.component';
import { MonthEndSnapshotComponent } from './components/month-end-snapshot/month-end-snapshot.component';
import { DataLoadsComponent } from './components/data-loads/data-loads.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AumAcctSdmComponent } from './components/aum-acct-sdm/aum-acct-sdm.component';
import { AcctSdmComponent } from './components/acct-sdm/acct-sdm.component';
import { LoadAccountDetailsComponent } from './components/load-acct-details/load-acct-details.component';
import { AumSDIMShareClassComponent } from './components/aum-sdm-share-class/aum-sdm-share-class.component';
import { XHLDProfileAssocSDIMComponent } from './components/xhld-profile-assoc-sdim/xhld-profile-assoc-sdim.component';
import { AttributesManagementComponent } from './components/attributes-management/attributes-management.component';
import { BrandManagementDialogComponent } from './components/dialogs/brand-management-dialog/brand-management-dialog.component';
import { CrossholdingRulesManagementComponent } from './components/crossholding-rules-management/crossholding-rules-management.component';
import { PortmandateManagementDialogComponent } from './components/dialogs/portmandate-management-dialog/portmandate-management-dialog.component';
import { CrossholdingindicatorManagementDialogComponent } from './components/dialogs/crossholdingindicator-management-dialog/crossholdingindicator-management-dialog.component';
import { BillingindicatorManagementDialogComponent } from './components/dialogs/billingindicator-management-dialog/billingindicator-management-dialog.component';



//Services
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { ToasterService } from './services/toastr.service';

// Okta
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ProfileComponent } from './profile/profile.component';
import { ReportingRulesManagementComponent } from './components/reporting-rules-management/reporting-rules-management.component';
import { AddRuleComponent } from './components/reporting-rules-management/add-rule/add-rule.component';
import { EditRuleComponent } from './components/reporting-rules-management/edit-rule/edit-rule.component';
import { EditRuleCriteriaComponent } from './components/reporting-rules-management/edit-criteria/edit-criteria.component';

import { URLHelper } from './services/url.helper';
import { AddProfileComponent } from './components/crossholding-rules-management/add-profile/add-profile.component';
import { EditProfileComponent } from './components/crossholding-rules-management/edit-profile/edit-profile.component';
import { CrossholdingProfileComponent } from './components/crossholding-rules-management/crossholding-profile/crossholding-profile.component';
import { CrossHoldingRulesComponent } from './components/crossholding-rules-management/cross-holding-rules/cross-holding-rules.component';
import { CapitalActivityComponent } from './components/crossholding-rules-management/capital-activity/capital-activity.component';
import { CrossTradingComponent } from './components/crossholding-rules-management/cross-trading/cross-trading.component';
import { EditCriteriaComponent } from './components/crossholding-rules-management/edit-criteria/edit-criteria.component';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { DialogService } from './services/dialog.service';
import { DatePipe } from '@angular/common';
import { ErrorMessages } from './services/error_message';
import { RequestInterceptor } from './services/request.interceptor';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LocksComponent } from './components/admin/locks/locks.component';
import { SnsComponent } from './components/admin/sns/sns.component';
import { ValueListComponent } from './components/value-list/value-list.component';
import { SearchPipe } from './search/search.pipe';
import { ValueSearchPipe } from './search/value-search.pipe';
import { AddListComponent } from './components/value-list/add-list/add-list.component';
import { EditListComponent } from './components/value-list/edit-list/edit-list.component';
import { TypePipe } from './search/type.pipe';
import { LogsComponent } from './components/admin/logs/logs.component';
// import { SearchDirective } from './directive/search.directive';
import { NoSpaceDirective } from './directive/no-space.directive';
import { ModalComponent } from './components/modal/modal.component';
import { UserIdleModule } from 'angular-user-idle';

const oktaAuth = new OktaAuth({
  issuer: 'https://franklintempleton.oktapreview.com/oauth2/default',
  clientId: '0oa6lav7imle0AJMm1d7', //'${yourClientID}',
  redirectUri: window.location.origin + '/login/callback'
  //redirectUri: 'http://localhost:4200/login/callback'
});


@NgModule({
 

  declarations: [
    AppComponent,
    SidenavComponent,
    FinalAssetAmountAdjustmentComponent,
    AccountAttributesComponent,
    ShareClassAttributesComponent,
    MonthEndSnapshotComponent,
    DataLoadsComponent,
    LandingPageComponent,
    AumAcctSdmComponent,
    AcctSdmComponent,
    LoadAccountDetailsComponent,
    AumSDIMShareClassComponent,
    XHLDProfileAssocSDIMComponent,
    AttributesManagementComponent,
    BrandManagementDialogComponent,
    CrossholdingRulesManagementComponent,
    ProfileComponent,
    PortmandateManagementDialogComponent,
    CrossholdingindicatorManagementDialogComponent,
    BillingindicatorManagementDialogComponent,
    ReportingRulesManagementComponent,
    AddRuleComponent,
    EditRuleComponent,
    EditRuleCriteriaComponent,
    AddProfileComponent,
    EditProfileComponent,
    CrossholdingProfileComponent,
    CrossHoldingRulesComponent,
    CapitalActivityComponent,
    CrossTradingComponent,
    EditCriteriaComponent,
    ForbiddenComponent,
    LocksComponent,
    SnsComponent,
    ValueListComponent,
    SearchPipe,
    ValueSearchPipe,
    AddListComponent,
    EditListComponent,
    TypePipe,
    LogsComponent,
    // SearchDirective,
    NoSpaceDirective,
    ModalComponent
    
  ],

  

  exports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule
 
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule, 
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule, 
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule, 
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ToastrModule.forRoot(),
    OktaAuthModule
  ],
  providers: [
   /*  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}, */
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    SideNavModel,
    ToasterService,
    URLHelper,
    CanDeactivateGuardService,
    DialogService,
    DatePipe,
    ErrorMessages,
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
 
}

