
<h1>Report Groups - Unlock in Use</h1>

<div class="row data-loads">
    <mat-card class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <table class="example-container" mat-table id="loadTemplelates1" #tempsort="matSort"
        [dataSource]="locks" matSort>
        <ng-container matColumnDef="report_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Name </th>
          <td mat-cell *matCellDef="let element"> {{element.report_name}} </td>
        </ng-container>
  
        <!-- Template Download Column -->
        <ng-container matColumnDef="in_use_by_userid">
          <th mat-header-cell *matHeaderCellDef> Used By </th>
          <td mat-cell *matCellDef="let element" align="center">
            <ng-container>
              {{element.in_use_by_userid}}
            </ng-container>
          </td>
        </ng-container>
  
        <!--Template Upload Column hiddenfileinput.click() -->
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell *matCellDef="let element" align="center"> 
            <ng-container *ngIf="element.in_use_by_userid != null">
                <mat-icon matTooltip="Unlock in Use" class="red" [ngClass]="{'loading': element.report_list_id == report_id}" (click)="unlockInUse(element)" style="cursor: pointer;">lock</mat-icon>
              
            </ng-container>
            <ng-container *ngIf="element.in_use_by_userid == null" align="center">
              <mat-icon matTooltip="Get Set in Use" class="green" [ngClass]="{'loading': element.report_list_id == report_id}" (click)="getSetInUse(element)" style="cursor: pointer;">lock_open</mat-icon>
              
            </ng-container>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="loadTemplelatesColumns; sticky: false"></tr>
        <tr mat-row *matRowDef="let row; columns: loadTemplelatesColumns;"></tr>
  
      </table>
  
      <div *ngIf="locks.data.length === 0" class="no-records alert alert-info text-center">
        No records found
      </div>
    </mat-card>
</div>