<mat-card>
    <mat-card-title style="text-align:center;">{{message}}</mat-card-title>
    <br>
    <div class="container">      
        <div class="row">
            <div>
                <mat-form-field>
                    <mat-placeholder>Billing Indicator Code--{{isReadOnly}}</mat-placeholder>
                    <input matInput type="text"  id="aum_code" [(ngModel)]="code" #aumcode="ngModel" [readonly]="isReadOnly" (input)="change($event)">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-placeholder>Billing Indicator Name</mat-placeholder>
                    <input matInput type="text" [value]="name" id="aum_dim_name" [(ngModel)]="name"  #aumname="ngModel" (input)="change($event)">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-placeholder>Billing Indicator Description</mat-placeholder>
                    <input matInput type="text" [value]="desc" id="aum_dim_desc" [(ngModel)]="desc" #aumdesc="ngModel" (input)="change($event)">
                </mat-form-field>
            </div>          
            <div>
                <mat-form-field>
                    <mat-placeholder>Maximum Annual Fee Rate</mat-placeholder>
                    <input matInput type="text" [value]="fee" id="anul_fee_rate" [(ngModel)]="fee" #aumfee="ngModel" (input)="change($event)">
                </mat-form-field>
            </div>       
        </div>
        <div class="row">
            <div class="col-12 ">
                <button mat-button class="mat-raised-button mat-primary float-right btn-dialog" cdkFocusInitial
                [disabled]="(!aumcode.touched && !aumname.touched && !aumdesc.touched && !aumfee.touched)"  (click)="SaveAttributes()">Save</button>
                <button mat-button class="mat-stroked-button float-right btn-dialog " color="primary"
                    (click)="onNoClick()">Cancel</button>
            </div>
        </div>
    </div>
</mat-card>