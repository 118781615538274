<mat-card>
    <mat-card class="example-card text-center">
        <h3>Historical ACCT_SDIM Attributes</h3>
    </mat-card>
    <mat-card class="example-card text-center">
        <div class="row">          
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToAccountPage()">Show Current Acct Attributes</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="loadDetailsPanel()">View</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToAUMAcctSdm()">Show AUM ACCT SDIM</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="copyAsNewRecord()" >Copy As New Record</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="deleteRecord()">Delete Record</button>
            </div>
        </div>
        <br>
        <div class="row">
            <div *ngIf="isLoading" >         
                <mat-spinner class="loader"></mat-spinner>
                  </div>
            <div class="component data component-card "> 
                <table border="0" width="100%" cellpadding="0" cellspacing="0"
                 mat-table [dataSource]="loadTemplateDatasource"
                     matSort>
                     <ng-container matColumnDef="radio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-width"></th>
                        <td mat-cell *matCellDef="let element" class="custom-width">
                             <mat-radio-button color="primary" [checked]="element.acct_dim_key == selectedrecord"
                             [value]="element" (change)="viewAttribute($event)">
                            </mat-radio-button> 
                        </td> 
                    </ng-container>
                    <!-- Template Name Column -->
                    <ng-container matColumnDef="firstEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> First Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element.first_effective_date | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lastEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Last Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element.last_effective_date | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

                    <!-- Template Download Column -->
                    <ng-container matColumnDef="staus">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> Staus </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_status}} </td>
                    </ng-container>

                    <!--Template Upload Column -->
                    <ng-container matColumnDef="inceptDate">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm"> Incept Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_incept_date | date: 'MM/dd/yyyy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="inceptAmt">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm"> Incept Amt </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_incept_amt}}</td>
                    </ng-container>


                    <ng-container matColumnDef="termdate">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm"> Term Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_termination_date |date: 'MM/dd/yyyy' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="xaumFlag">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> XAUM Flag </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.xaum_flag}}</td>
                    </ng-container>
                    <ng-container matColumnDef="aumRpt">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> AUM RPT </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aumrpt}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tisActType">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> TIS Acct Type </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.tis_acct_type}}</td>
                    </ng-container>
                    <ng-container matColumnDef="primaryMgr">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> Primary Mgr</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_primary_mgr_id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="primaryMgrName">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm"> Primary Mgr Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.primary_mgr_last_name }} {{element.primary_mgr_first_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="portfolioMgr">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Portfolio Mgr </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.portfolio_manager}}</td>
                    </ng-container>
                    <ng-container matColumnDef="portfolioMgrName">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">Portfolio Mgr Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.portfolio_mgr_last_name}} {{element.portfolio_mgr_first_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="assitMgrID">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm"> Assistant Mgr ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_assistant_mgr_id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="assitMgrName">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">Assistant Mgr Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_assistant_mgr_id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="ficalYE">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> Fiscal YE
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.fiscal_year_end}}</td>
                    </ng-container>
                    <ng-container matColumnDef="gStatus">
                        <th mat-header-cell *matHeaderCellDef class="spacing"> GStatus </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.gstatus}}</td>
                    </ng-container>
                    <ng-container matColumnDef="domicile">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Domicile </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.domicile}}</td>
                    </ng-container>
                    <ng-container matColumnDef="subAdvisor">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Sub Advisor </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.sub_advisor}}</td>
                    </ng-container>
                    <ng-container matColumnDef="ssAdvisor">
                        <th mat-header-cell *matHeaderCellDef class="spacing">SS Advisor </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.ssadvisor}}</td>
                    </ng-container>
                    <ng-container matColumnDef="mutFundType">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Mut Fund Type </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_mut_fund_type}}</td>
                    </ng-container>
                    <ng-container matColumnDef="transferAgent">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Transfer Agent </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.transfer_agent}}</td>
                    </ng-container>
                    <ng-container matColumnDef="transferAgentDesc">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">Transfer Agt Desc </th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element.transfer_agent_desc}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tax">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Taxability </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.taxability}}</td>
                    </ng-container>
                    <ng-container matColumnDef="taxDesc">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">Taxability Desc
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.taxability_desc}}</td>
                    </ng-container>
                    <ng-container matColumnDef="invstObj">
                        <th mat-header-cell *matHeaderCellDef class="spacing">ICI Invst Obj </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.ici_invest_objective}}</td>
                    </ng-container>
                    <ng-container matColumnDef="invstObjDesc">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm2">ICI Invst Obj Desc
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element.ici_invest_objective_desc}}</td>
                    </ng-container>
                    <ng-container matColumnDef="busMgrDesc">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">Bus Mgr Desc </th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element.business_manager_desc}}</td>
                    </ng-container>
                    <ng-container matColumnDef="busMgrName">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Bus Mgr Name</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.business_manager}}</td>
                    </ng-container>
                    <ng-container matColumnDef="adminLoc">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Admin Location </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.admin_location}}</td>
                    </ng-container>
                    <ng-container matColumnDef="acctName">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Acct Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element.acct_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="bckMgrID">
                        <th mat-header-cell *matHeaderCellDef class="spacing">Backup Mgr ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_backup_mgr_id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="bckMgrName">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">Backup Mgr Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.back_mgr_last_name}} {{element.back_mgr_first_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="cwg">
                        <th mat-header-cell *matHeaderCellDef class="spacing">CWG </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_chinese_wall_group_code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="imcCode">
                        <th mat-header-cell *matHeaderCellDef class="spacing">IMC Code </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.invest_mgmt_company_code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="13fginvstDis">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">13FG Invst Discretion </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_invst_discretion}}</td>
                    </ng-container>
                    <ng-container matColumnDef="13fgVoting">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">13FG Voting Authority </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_voting_authority}}</td>
                    </ng-container>
                    <ng-container matColumnDef="13fgAdvCode">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">13FG Invst Adv Code </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_invst_adv_code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="13fgAdvName">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm2">13FG Invst Adv Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element.acct_invst_adv_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="13fgInclude">
                        <th mat-header-cell *matHeaderCellDef class="spacing-sm">13FGD Include </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.acct_include_13fgd}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: false"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

                </table>

                <mat-paginator [pageSize]="10"
                showFirstLastButtons>
 </mat-paginator>
                <div *ngIf="loadTemplateDatasource.data.length === 0" class="no-records alert alert-info text-center">
                    No records found
                </div>

               
              
            </div>
        </div>
    </mat-card>
    <mat-card class="example-card text-center">
        <h3>Historical ACCT_SDIM Attributes Account Validation Results</h3>
        <div class="row">                     
            <div [innerHTML] = "validateinfo">          
             </div>
         </div>
    </mat-card>
</mat-card>
<mat-accordion class="example-headers-align prevent-click">
    <mat-expansion-panel #panel1 [hideToggle]="true">
        <mat-expansion-panel-header *ngIf="showView">
            <mat-panel-title style="font-size:20px;">
                Historical Account SDIM Attributes Details
            </mat-panel-title>
           
        </mat-expansion-panel-header>

        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-4">
                    <mat-form-field>
                        <mat-placeholder>TIS Account Num</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "accountNo" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>TIS Account Type</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "accountType" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>Account Name</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "accountName" [disabled]="true">
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Account Chinese Wall Group Code</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "CWG" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Mut Fund Type</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "mutFundType" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Incept Date</mat-label>
                        <input matInput [matDatepicker]="Inceptionpicker" [(ngModel)] = "inceptionDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="Inceptionpicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #Inceptionpicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Termination Date</mat-label>
                        <input matInput [matDatepicker]="Terminationpicker" [(ngModel)] = "terminationDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="Terminationpicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #Terminationpicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Incept Amount</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "inceptAmt" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3 ">
                    <mat-form-field>
                        <mat-placeholder>Invest. Mgmt Company Code</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "imcCode" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Primary Manager</mat-label>
                       
                        <mat-select [(value)]= "primaryMgrID" [disabled]="!isEdit">   
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchGrpmgr($event)" (keydown.escape)="clearsearch($event)">              
                            <mat-option *ngFor="let mgr of grpmgrList | search : varGrpmgrText : 'mgr_last_name' : 'mgr_first_name' " 
                            [value] ="mgr.mgr_dim_id">{{ mgr.mgr_last_name + ' ' + mgr.mgr_first_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Backup Manager</mat-label>
                       
                        <mat-select [(value)]= "bckMgrID" [disabled]="!isEdit">     
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchGrpmgr($event)" (keydown.escape)="clearsearch($event)">            
                            <mat-option *ngFor="let mgr of grpmgrList | search : varGrpmgrText : 'mgr_last_name' : 'mgr_first_name'" 
                            [value] ="mgr.mgr_dim_id">{{ mgr.mgr_last_name + ' ' + mgr.mgr_first_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Sub Advisor</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "subAdvisor" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Sub Advisor Desc</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "subAdvisorDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Sub-Sub Advisor</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "ssAdvisor" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Sub-Sub Advisor Desc</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "ssAdvisorDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Fiscal Year End [MM/YY]</mat-placeholder>
                        <input matInput type="text"  [(ngModel)] = "ficalYE" [disabled]="!isEdit">
                    </mat-form-field>

                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Backup Portfolio Manager</mat-label>
                        <mat-select [(value)]= "bckportManagerID" [disabled]="!isEdit">          
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchPort($event)" (keydown.escape)="clearsearch($event)">       
                            <mat-option *ngFor="let mgr of grpmgrList | search : varPortText : 'mgr_last_name' : 'mgr_first_name'" 
                            [value] ="mgr.mgr_id">{{ mgr.mgr_last_name + ' ' + mgr.mgr_first_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Business Manager</mat-placeholder>
                        <input matInput type="text"  [(ngModel)] = "businessMgr" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Business Manager Desc</mat-placeholder>
                        <input matInput type="text"  [(ngModel)] = "businessMgrDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Taxability</mat-placeholder>
                        <input matInput type="text"  [(ngModel)] = "taxability" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder> Taxability Desc</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "taxabilityDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>ICI Investment Objective</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "invstObj" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>ICI Invest Objective Desc</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "invstObjDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Transfer Agent</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "transferAgent" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder> Transfer Agent Desc</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "transferAgentDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Admin Location</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "adminLoc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Admin Location Desc</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "adminLocDesc" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Domicile</mat-label>
                        <mat-select [(value)]="default" [disabled]="true">
                            <mat-option [value]="1"> {{domicile}} </mat-option>

                        </mat-select>
                    </mat-form-field>


                    
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Assistant Manager</mat-label>
                        <mat-select [(value)]= "assitMgrID" [disabled]="!isEdit">
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchGrpmgr($event)" (keydown.escape)="clearsearch($event)">            
                            
                            <mat-option *ngFor="let mgr of grpmgrList | search : varGrpmgrText : 'mgr_last_name' : 'mgr_first_name'" 
                            [value] ="mgr.mgr_dim_id">{{ mgr.mgr_last_name + ' ' + mgr.mgr_first_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>13FG Invest Discretion</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "invstDis" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>13FG Voting Authority</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "Voting" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>13FG Investment Advisor</mat-label>
                        <mat-select [(value)]= "invstAdvID" [disabled]="!isEdit"> 
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchInvst($event)" (keydown.escape)="clearsearch($event)">

                            <mat-option *ngFor="let adv of invstAdvList | search : varInvstText : 'aum_invst_adv_name'" 
                            [value] ="adv.aum_invst_adv_code">{{ adv.aum_invst_adv_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder> 13FGD Include</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "invInclude" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder> AUM Rpt</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "aumRpt" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Acct Status</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "acctStatus" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
               
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>First Effective Date</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "firstEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>                    
                    </mat-form-field>
                </div>
                <div class="col-3" *ngIf="!enable">
                    <mat-form-field>
                        <mat-label>Last Effective Date</mat-label>
                        <input matInput type="text" [(ngModel)] = "lastEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>                       
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>GStatus</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "gStatus" [disabled]="!isEdit">
                    </mat-form-field>
                </div>
                <div class="col-3">                 
                    <section class="example-section">
                        <mat-checkbox class="example-margin" 
                        color="primary" [checked]="xaumFlag" [disabled]="!isEdit"
                          [(ngModel)]="isChecked" (change)="checkValue(isChecked?'Yes':'No')"> XAUM Flag </mat-checkbox>
                    </section>
                </div>

                
            </div>
            <div class="row">
               
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Last Updated By</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "lastUpdatedBy" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Last Updated Date</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "lastUpdatedDate" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <button class="button btn btn-secondary" type="submit" [disabled]="!isEdit" (click)="saveAccountSDIMInfo()">Save</button>
                    <button mat-raised-button class="button1" style="color:white;" (click)="closePanel()">Cancel</button>
                </div>
            </div>
        </mat-card>

    </mat-expansion-panel>
</mat-accordion>