import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Logs } from '../../../models/logs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})

export class LogsComponent implements OnInit {

  constructor(
    private http: HttpService,
    private url: URLHelper,
    private dialog: MatDialog
  ) { }
  pageDirty: boolean;

  listColumns:any[] = ['date', 'process', 'text', 'action'];
  public lists = new MatTableDataSource<Logs>();
  public total = 0;
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  @ViewChild('template', {static: false}) callAPIDialog: TemplateRef<any>;
  loading: boolean = false;
  typeSearchTxt;
  selectedItem;

  getTemplates(frm = 0, to = 100){
    this.loading = true;
    const body = {
      from: frm,
      to: to
    };
    this.http.postData(this.url.pma_logs, {}, body).subscribe((res:any)=>{
      this.loading = false;
      this.lists.data = res.data.data as Logs[];
      this.total = res.data.total;
      console.log(this.lists.data)
    })
  }

  typeChange = (event) =>{
    this.typeSearchTxt = event.target.value;
  }

  selectItem = (item) =>{
    this.selectedItem = item;
    const dialogConfig          = new MatDialogConfig();
    dialogConfig.data           = {
            "title": "Confirmation message",
            "message": "Do you accept these terms and conditions?"
          };
    dialogConfig.width          = '70%';
    dialogConfig.height         = '100%';
    dialogConfig.maxHeight      = '50vw';
    dialogConfig.maxHeight      = '70vh';
    dialogConfig.panelClass     = 'full-screen-modal';
    dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    dialogConfig.hasBackdrop    = false;

    this.dialog.open(this.callAPIDialog, dialogConfig);
  }

  closeDialog = () =>{
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.getTemplates();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(
        (event) => {
          const frm = event.pageIndex * event.pageSize;
          const to = event.pageSize;
          console.log(event)
          this.getTemplates(frm, to);

        }
    )
    }

}