<mat-card class="text-center">
    <mat-card-title>Historical Account SDIM Attributes Details</mat-card-title>
    <mat-card class="example-card text-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-placeholder>TIS Account Num</mat-placeholder>
                    <input matInput type="text"   [(ngModel)]="templatename">
                </mat-form-field>
            </div>
            <div class=" col-4">
                <mat-form-field>
                    <mat-placeholder>TIS Account Type</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-4">
                <mat-form-field>
                    <mat-placeholder>Account Name</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
    </mat-card>
    <mat-card class="example-card text-center">
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Account Chinese Wall Group Code</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Mutual Fund Type</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Inception Date</mat-label>
                    <input matInput [matDatepicker]="Inceptionpicker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="Inceptionpicker"></mat-datepicker-toggle>
                    <mat-datepicker #Inceptionpicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Termination Date</mat-label>
                    <input matInput [matDatepicker]="Terminationpicker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="Terminationpicker"></mat-datepicker-toggle>
                    <mat-datepicker #Terminationpicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Incept Amount</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3 ">
                <mat-form-field>
                    <mat-placeholder>Invest. Mgmt Company Code</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Primary Manager</mat-label>
                    <mat-select>
                        <mat-option value="Share Class1"> Share Class1 </mat-option>
                        <mat-option value="Share Class2"> Share Class2 </mat-option>
                        <mat-option value="Share Class3"> Share Class3 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Backup Manager</mat-label>
                    <mat-select>
                        <mat-option value="Share Class1"> Share Class1 </mat-option>
                        <mat-option value="Share Class2"> Share Class2 </mat-option>
                        <mat-option value="Share Class3"> Share Class3 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Sub Advisor</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Sub Advisor Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Sub-Sub Advisor</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Sub-Sub Advisor Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Fiscal Year End [MM/YY]</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>

            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Backup Portfolio Manager</mat-label>
                    <mat-select>
                        <mat-option value="Share Class1"> Share Class1 </mat-option>
                        <mat-option value="Share Class2"> Share Class2 </mat-option>
                        <mat-option value="Share Class3"> Share Class3 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Business Manager</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Business Manager Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Taxability</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder> Taxability Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>ICI Investment Objective</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>ICI Investment Objective Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Transfer Agent</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder> Transfer Agent Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Admin Location</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Admin Location Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Domicile Manager</mat-label>
                    <mat-select>
                        <mat-option value="Share Class1"> Share Class1 </mat-option>
                        <mat-option value="Share Class2"> Share Class2 </mat-option>
                        <mat-option value="Share Class3"> Share Class3 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Asst. Manager</mat-label>
                    <mat-select>
                        <mat-option value="Share Class1"> Share Class1 </mat-option>
                        <mat-option value="Share Class2"> Share Class2 </mat-option>
                        <mat-option value="Share Class3"> Share Class3 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>13FG Invest Discretion</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>13FG Voting Authority</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>13FG Investment Advisor</mat-label>
                    <mat-select>
                        <mat-option value="Share Class1"> Share Class1 </mat-option>
                        <mat-option value="Share Class2"> Share Class2 </mat-option>
                        <mat-option value="Share Class3"> Share Class3 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder> 13FGD Investment Include</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>ICI Investment Objective</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>ICI Investment Objective Desc</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-placeholder> AUM Report</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder> Account Status</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>First Effective Date</mat-label>
                    <input matInput [matDatepicker]="FirstEffectiveDatepicker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="FirstEffectiveDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #FirstEffectiveDatepicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>GStatus</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <section class="example-section">
                    <mat-checkbox class="example-margin"> XAUM Flag </mat-checkbox>
                </section>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Last Updated By</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Last Updated Date</mat-placeholder>
                    <input matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-3">
                <button mat-raised-button class="button1" style="color:white;">Cancel</button>
            </div>
        </div>
    </mat-card>
</mat-card>