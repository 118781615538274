<h2>Final Asset Amount Adjustment </h2>
<!-- <mat-selection-list class="mat-select-elevation" #entities [multiple]="false">
    <mat-list-option aria-selected="true" *ngFor="let entity of attributes" [value]="entity">
        <span>{{entity.first_effective_date}} || {{entity.status}}</span>
    </mat-list-option>
</mat-selection-list> -->
<div class="example-container mat-elevation-z8">
<mat-table #table mat-table [dataSource]="attributes">
    <ng-container matColumnDef="first_effective_date">
        <div *matHeaderCellDef>First Effective Date</div>
        <div *matCellDef="let lesson">{{lesson.first_effective_date}}</div>
    </ng-container>
    <ng-container matColumnDef="last_effective_date">
        <div *matHeaderCellDef>Last Effective Date</div>
        <div *matCellDef="let lesson">{{lesson.last_effective_date}}</div>
    </ng-container>
    <ng-container matColumnDef="inspection_date">
        <div *matHeaderCellDef>Inspection Date</div>
        <div *matCellDef="let lesson">{{lesson.inspection_date}}</div>
    </ng-container>
    <ng-container matColumnDef="status">
        <div *matHeaderCellDef>Status</div>
        <div *matCellDef="let lesson">{{lesson.status}}</div>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
</div>
