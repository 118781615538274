import { Injectable } from "@angular/core";

@Injectable()
export class SideNavModel {

    ReportPackages: string="Edit";
    FinalAssetAmountAdjustment: string = "Final Asset Amount Adjustment";
    AccountAttributes: string="Account Attributes";
    ShareClassAttributes: string= "Share Class Attributes";
    MonthEndSnapshot:string="Month End Snapshot";
    DataLoads:string="Data Loads";
    AttributesManagement: string ="Attributes Management";
    CrossholdingRulesManagement: string="Crossholding Rules Management";
    ReportRulesManagement: string = "Reporting Rules Management";
    adminLocks: string = "Unlocked Reports";
    valueLists: string = "Value Lists";
    logs: string = "PMA Logs";
  }