import { Component, OnInit, EventEmitter, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';
import { ToasterService } from '../../../services/toastr.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MAT_DIALOG_DATA, MatDialog
} from "@angular/material/dialog";

@Component({
  selector: 'app-add-list',
  templateUrl: './add-list.component.html',
  styleUrls: ['./add-list.component.css']
})
export class AddListComponent implements OnInit {

  
  valueForm: FormGroup;
  // onDirty: new EventEmitter();
  value_type =  '';
  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private url: URLHelper,
    private _dialog: MatDialog,
    private toastr: ToasterService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {

      this.value_type = this.data.type;
      this.valueForm = this.fb.group({
        list_code: [null, Validators.required],
        list_type: [this.data.type, Validators.required],
        list_desc: [null, Validators.required]
      });

  //     // this.valueForm.valueChanges.subscribe((data)=>{
  //     //   if(this.valueForm.dirty){
  //     //     this.onDirty.emit(true);
  //     //   }      
  //     // });
  }

  saveValue = (frm) =>{
    
    if(this.valueForm.invalid){
      return;
    }

    const valueData:any = JSON.stringify(this.valueForm.value, null, 4);
    const data = JSON.parse(valueData);
    
    this.http.postData(this.url.value_list_add, {}, data).subscribe((res)=>{
      this.toastr.success("Successfully added!")
        this._dialog.closeAll();
    }, (error: HttpErrorResponse)=>{
      this.toastr.error('Value List add error');
    });
  }

  cancelDialog = () =>{
    this._dialog.closeAll();
  }

  ngOnInit(): void {

  }



}
