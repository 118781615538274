import { Component, OnInit, HostListener } from '@angular/core';
import { ShareclassService } from 'src/app/services/shareclass.service';
import { CategoryModel, TemplateModel } from '../../models/dataloads';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { formatDate, DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { DialogService } from 'src/app/services/dialog.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-share-class-attributes',
  templateUrl: './share-class-attributes.component.html',
  styleUrls: ['./share-class-attributes.component.css']
})
export class ShareClassAttributesComponent implements OnInit {
  public dataList: CategoryModel[];
  BillingIndList: any = [];
  BillingFilteredIndList:any=[];
  freqCodeList: any = [];
  AccountstatusList: any = [];
  ShareClassList: any = [];
  ApprovalgrpList: any = [];
  accountNo: string = "";
  classcode: string = "";
  accountType: string = "";
  accountName: string = "";
  inceptDate: Date;
  terminateDate: Date;
  billingindiId: number = 0;
  approvalgrpid: number = 0;
  accountStatus: string = "";
  freqCode: string = "";
  lastUpdatedDate: string = "";
  lastUpdatedBy: string = "";
  profileName: string = "";
  maximumAnnualRate: string = "";
  AumFlag: boolean = false;
  isChecked: boolean = false;
  shareClassInfo: any = {};
  shareclassdetails: any = [];
  isLoading: boolean = false;
  isShowButton: boolean = false;
  public pageDirty: boolean = false;
  inceptDate1: Date;
  terminateDate1: Date;
  billingindiId1: number = 0;
  approvalgrpid1: number = 0;
  accountStatus1: string = "";
  freqCode1: string = "";
  AumFlag1: boolean = false;
  showRecords: boolean = false;
  searchTerm:string;
  fileterdOptions:any[];  

  varFreqCodeText;
  varAcctText;
  varBillText;

  isReadOnly: boolean =false;

  constructor(
    private shareclassService: ShareclassService,
    private router: Router,
    private route: ActivatedRoute, private datepipe: DatePipe,
    private toastr: ToasterService,
    private titleService: Title,
    private confirmDialog: DialogService,
    private authservice: AuthService) {

    this.titleService.setTitle("CARS - Share Class Level Attributes");
    this.classcode = this.route.snapshot.paramMap.get('classcode');
    this.accountNo = this.route.snapshot.paramMap.get('id');
    this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
  }

  ngOnInit(): void {
    this.getShareClassList();
    if (this.accountNo != null && this.accountNo != "") {
      this.getShareclassInformation();
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.pageDirty=false;
    
    let dt1: any;
    let dt2: any;
    dt1 = this.inceptDate;
    dt2 = this.terminateDate;
    if (!this.showRecords) {
      if (this.isChecked || dt1 != undefined || dt2 != undefined || this.billingindiId != 0 || this.accountStatus != ""
        || this.freqCode != "" || this.approvalgrpid != 0) {
        this.pageDirty = true;
        console.log("PageDirty1: " + this.pageDirty);
      }
    }
    if (this.showRecords) {
      if (this.terminateDate1 != this.terminateDate
        || this.inceptDate1 != this.inceptDate
        || this.billingindiId1 != this.billingindiId
        || this.approvalgrpid1 != this.approvalgrpid
        || this.accountStatus1 != this.accountStatus
        || this.freqCode1 != this.freqCode
        || this.AumFlag1 != this.AumFlag) {
        this.pageDirty = true;
        console.log("PageDirty2: " + this.pageDirty);
      }
    }
    if (this.pageDirty) {
      return this.confirmDialog.confirm("Do you want to discard the changes?");
    }
    return true;
  }

  // public getCategories() {
  //   console.log("getcategories called");
  //   this.shareclassService.getCategories()
  //     .subscribe(result => {
  //       this.dataList = result.data;
  //       //console.log(this.dataList);    
  //     }, err=>{
  //       this.toastr.error("Issue while retrieving data. Please try again or contact support.")
  //     });
  // }

  getShareclassInformation() {
    console.log("class called");
    if (this.accountNo == "" || this.accountNo == null) {
      this.isLoading = false;
      this.isShowButton = false;
      this.toastr.error("You must enter TIS Account Num");
      this.clearShareclassinfo()
    }
    else {
      this.isLoading = true;
      this.getBillingIndList();
      this.getfreqCodeList();
      this.getApprovalgrpList();
      this.getAccountstatusList();

      this.shareclassService.getShareClassInfo(this.accountNo, this.classcode)
        .subscribe((result: any) => {

          if (result.status == 200 && result.data != null) {

            this.shareclassdetails = result.data;
            if (this.shareclassdetails.p_share_cls_dim_id == null) {
              this.toastr.info("This account does not have an AUM record for this account. Please check your data and try again.");
              this.clearShareclassinfo();
              this.isShowButton = false;
            }
            else {
              this.isShowButton = true;

              this.BillingFilteredIndList = this.BillingIndList.filter((x: any) => x.aum_billing_indicator_status
              == "Active");
 let obj = this.BillingFilteredIndList.filter((x: any) => x.aum_billing_indicator_dim_id
        == result.data.p_aum_billing_indicator_dim_id);

if(obj.length == 0)
{
             this.BillingFilteredIndList.push({
                "aum_billing_indicator_dim_id": result.data.p_aum_billing_indicator_dim_id,
                "aum_billing_indicator_name": result.data.p_aum_billing_indicator_name              
              });
              
            }
            this.fillAccountInfo(result.data);
          }
          this.fileterdOptions = this.ShareClassList;
          this.searchTerm="";
            this.isLoading = false;

          }
        }, err=>{
          this.isLoading = false;
          this.toastr.error("Issue while retrieving data. Please try again or contact support.")
        });
    }
  }
  fillAccountInfo(source: any) {

    this.accountType = source.p_tis_acct_type;
    this.accountName = source.p_acct_name;
    this.lastUpdatedDate = formatDate(source.p_last_upd_date, 'MM/dd/yyyy hh:mm:ss a', 'en_US');
    this.lastUpdatedBy = source.p_last_upd_by;
    this.billingindiId = source.p_aum_billing_indicator_dim_id;
    this.approvalgrpid = source.p_apprvl_group_dim_id;
    this.freqCode = source.p_update_freq_code;
    this.accountStatus = source.p_acct_status_list_code;
    this.terminateDate = source.p_termination_date != null ? this.datepipe.transform(source.p_termination_date, 'yyyy-MM-dd') : source.p_termination_date;
    this.inceptDate = source.p_incept_date != null ? this.datepipe.transform(source.p_incept_date, 'yyyy-MM-dd') : source.p_incept_date;
    this.maximumAnnualRate = source.p_max_annual_fee_rt;
    this.profileName = source.p_aum_xhld_profiles_name;
    this.getAcctFlag(source);

    this.terminateDate1 = this.terminateDate;
    this.inceptDate1 = this.inceptDate;
    this.billingindiId1 = this.billingindiId;
    this.approvalgrpid1 = this.approvalgrpid;
    this.accountStatus1 = this.accountStatus;
    this.freqCode1 = this.freqCode;
    
    this.showRecords = true;
  }
  resetAccountInfo() {
    this.fillAccountInfo(this.shareclassdetails);
  }
  clearShareclassinfo() {
    this.BillingFilteredIndList = this.BillingIndList.filter((x: any) => x.aum_billing_indicator_status
    == "Active");
    this.accountNo = "";
    this.classcode = this.ShareClassList[0];
    this.accountType = "";
    this.accountName = "";
    this.lastUpdatedDate = "";
    this.lastUpdatedBy = "";
    this.billingindiId = 0;
    this.approvalgrpid = 0;
    this.freqCode = "";
    this.accountStatus = "";
    this.terminateDate = null;
    this.inceptDate = null;
    this.maximumAnnualRate = "";
    this.profileName = "";
    this.AumFlag = false;
  }
  saveAccountInfo() {

    this.shareClassInfo = {
      aum_dim_id: this.shareclassdetails.p_aum_dim_id,
      incept_date: this.inceptDate != null ? formatDate(this.inceptDate, 'MM/dd/yyyy hh:mm:ssZZZZZ', 'en_US').slice(0, 10) : this.inceptDate,
      acct_status: this.accountStatus,
      termination_date: this.terminateDate != null ? formatDate(this.terminateDate, 'MM/dd/yyyy hh:mm:ssZZZZZ', 'en_US').slice(0, 10) : this.terminateDate,
      update_freq_code: this.freqCode,
      apprvl_group_dim_id: this.approvalgrpid,
      apprvl_group_name: this.ApprovalgrpList.filter((x: any) =>
        x.aum_apprvl_group_dim_id == this.approvalgrpid)[0].aum_apprvl_group_name,
      paumrptflag: this.AumFlag == true ? "Active" : "InActive",
      acct_dim_id: this.shareclassdetails.p_acct_dim_id,
      share_cls_dim_id: this.shareclassdetails.p_share_cls_dim_id,
      aum_billing_indicator_dim_id: this.billingindiId,
      aum_billing_indicator_name: this.BillingIndList.filter((x: any) =>
        x.aum_billing_indicator_dim_id == this.billingindiId)[0].aum_billing_indicator_name,
    }
    
    this.shareclassService.saveShareClassAttributes(this.shareClassInfo).subscribe(res => {
      console.log(res);
      this.toastr.success("Share Class details Updated Scuccessfully");
      this.getShareclassInformation();
    },
      err => {
        this.toastr.error(err.error.message);
      })
  }
  checkValue(event: any) {
    this.AumFlag = event;
  }
  getBillingIndList() {
    this.shareclassService.getBillingInd()
      .subscribe(result => {
       this.BillingIndList = result.data;
       this.BillingFilteredIndList = this.BillingIndList.filter((x: any) => x.aum_billing_indicator_status
              == "Active");

      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
  }
  getfreqCodeList() {
    this.shareclassService.getfreqCode()
      .subscribe(result => {
        this.freqCodeList = result.data;
      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
  }
  getAccountstatusList() {
    this.shareclassService.getAccountstatus()
      .subscribe(result => {
        this.AccountstatusList = result.data;
      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
  }
  getShareClassList() {  
    this.isLoading = true;
    this.shareclassService.getShareClass()
      .subscribe(result => {
        this.ShareClassList = result.data;
        if (this.classcode == "" || this.classcode == null) {
          this.classcode = result.data[0];
        }
      this.fileterdOptions = this.ShareClassList;
        this.isLoading = false;
      }, err=>{
        this.isLoading = false;
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
  }
  filterOptions()
  {
    if(this.searchTerm)
    {
      this.fileterdOptions=this.ShareClassList.filter(x=> x.toLowerCase().
      includes(this.searchTerm.toLowerCase()));
    }
    else{
      this.fileterdOptions=this.ShareClassList;
    }
  }
  getApprovalgrpList() {
    this.shareclassService.getApprovalgrp()
      .subscribe(result => {
        this.ApprovalgrpList = result.data;
      });
  }
  public displayXHLDProfileAssocSDIM() {
    this.router.navigate(['/xhld-profile-assoc-sdim', { id: this.accountNo, classcode: this.classcode }])
  }

  public displayAcctSDIM() {
    this.router.navigate(['/aum-sdm-share-class', { id: this.accountNo, classcode: this.classcode }])
  }
  getAcctFlag(source: any) {
    this.shareclassService.acctShareFlag(source.p_acct_dim_id, source.p_share_cls_dim_id)
      .subscribe(result => {
        if (result.status == 200 && result.data != null) {
          this.AumFlag = result.data.flag_status == "Active" ? true : false;     
          this.AumFlag1 = this.AumFlag;     
        }

      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
  }

  // prevent refresh page when the form is dirty.
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    this.pageDirty=false;
    
    let dt1: any;
    let dt2: any;
    dt1 = this.inceptDate;
    dt2 = this.terminateDate;
    if (!this.showRecords) {
      if (this.isChecked || dt1 != undefined || dt2 != undefined || this.billingindiId != 0 || this.accountStatus != ""
        || this.freqCode != "" || this.approvalgrpid != 0) {
        this.pageDirty = true;
        console.log("PageDirty1: " + this.pageDirty);
      }
    }
    if (this.showRecords) {
      if (this.terminateDate1 != this.terminateDate
        || this.inceptDate1 != this.inceptDate
        || this.billingindiId1 != this.billingindiId
        || this.approvalgrpid1 != this.approvalgrpid
        || this.accountStatus1 != this.accountStatus
        || this.freqCode1 != this.freqCode
        || this.AumFlag1 != this.AumFlag) {
        this.pageDirty = true;
        console.log("PageDirty2: " + this.pageDirty);
      }
    }
    if (this.pageDirty) {
      event.returnValue = false;
    }
    
  }

  varFreqCodeChange = (event) =>{
    this.varFreqCodeText = event.target.value;
  }

  varAcctChange = (event) =>{
    this.varAcctText = event.target.value;
  }
  varBillChange = (event) =>{
    this.varBillText = event.target.value;
  }
  clearsearch = (event) =>{
    this.varBillText = "";
    this.varAcctText = "";
    this.varFreqCodeText = "";
    event.target.value = "";
  }

}

