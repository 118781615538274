import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareclassService } from 'src/app/services/shareclass.service';
import { CategoryModel,TemplateModel } from '../../models/dataloads';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalConstants } from '../../../appconstants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  public attributesList: CategoryModel[];
  showAcct=false;
  showShr=false;
  accountNo:string="";
  classcode:string="";
  groupInfo;
  group;
  constructor(
      private shareclassService: ShareclassService,
      private router: Router,
      private authservice: AuthService,
      private toastr: ToastrService
  ) {

    let grps = authservice.getUserGroups();
    this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
    this.isReadOnly = true;
    

    grps && GlobalConstants.CARS_USER_GROUPS.map((grp) => {
      if (grp.toUpperCase().indexOf('AUM')>=0 && grps[grp] == true){
        this.isReadOnly = false;
      }
      if (grp.toLowerCase().indexOf('readonly')>=0 && grps[grp] == true){
        this.isReadOnly = false;
      }
    });
    

  }

  ShareClassList:any=[];
  isReadOnly: boolean = false;

  ngOnInit(): void {
      this.showShr = true;
      this.attributesList = [     
        { list_desc: " Share Class Attributes", list_code: "SHR"},
        { list_desc: "Account Attributes", list_code: "AUM"}     
      ] 
      if(this.showShr)
      {
        this.getShareClassList();
      }
  }
  getShareClassList()
  {
    this.shareclassService.getShareClass()
    .subscribe(result => {
      this.ShareClassList = result.data;        
    });
  }
  loadClassComponents()
  {
    if(!this.accountNo){
      this.toastr.warning('Enter TIS Account Num !');
      return;
    }
    if (this.showAcct == true)
    {
      this.router.navigate(
        ['/account-attributes',
        {id:this.accountNo}]);
      
    }
    else
    {
      this.router.navigate(['/share-class-attributes' ,{id:this.accountNo,classcode:this.classcode}]);
    }
  }
  onHideShow(code: string) {
    if (code == "AUM")
    {
    this.showAcct = true;
    this.showShr=false;
    }
      else
    {
      this.showAcct = false;
      this.showShr=true;
      
    }
  }
  varSearchTxt = "";
  valueSearchTxt = "";
  searchTextValue = "";
  varChange = (event) =>{
    this.varSearchTxt = event.target.value;
    this.searchTextValue = "";
  }

  clearsearch = (event) =>{
    this.varSearchTxt = "";
    this.valueSearchTxt = "";
    event.target.value = "";
  }

  reSet = () =>{
    this.accountNo = "";
    this.classcode = "";
    this.varSearchTxt = "";
    this.searchTextValue = "";
  }

}
