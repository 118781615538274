import { Component, Inject, OnInit } from '@angular/core';
import { animateText, onSideNavChange } from 'src/app/animations/animations';
import { AuthService } from '../../services/auth.service'
import { SideNavModel } from '../../models/sidenav';
import { Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [onSideNavChange, animateText]
})
export class SidenavComponent implements OnInit {


  constructor(
    public sideNav: SideNavModel,
    private _router: Router,
    private authService: AuthService,
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
  public isAuthenticated$!: Observable<boolean>;
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  folders: any = [{ "name": "list1", "id": "1" }, { "name": "list1", "id": "2" }]
  ngOnInit(): void {
    console.log("userrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
    this.getCurrentUserDetails();
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
  }


  onSideNavChange = () => {

  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)

  }

  getCurrentUserDetails() {
    this.authService.getCurrentUserDetails();
  }

  getLocalUserDetails() {
    this.authService.getLocalUser();
  }

  //okta



  public async signIn(): Promise<void> {
    await this._oktaAuth.signInWithRedirect().then(
      _ => this._router.navigate(['/profile'])
    );
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  // end okta
}
