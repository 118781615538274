import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchTxt: string, field?: string, field1: string = ""): any[] {
    if(!items || !items.length) return items;
    if(!searchTxt || !searchTxt.length) return items;
    return items.filter(item => {
      var srchStr = "";
      if(field1.length > 0)
      {
        srchStr = item[field].toString().toLowerCase() + " - " + item[field1].toString().toLowerCase()
      }else{
        srchStr = item[field].toString().toLowerCase()
      }
      return srchStr.indexOf(searchTxt.toLowerCase()) > -1
    });
  }

}
