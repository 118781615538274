import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ToasterService } from '../../../services/toastr.service';
import { URLHelper } from '../../../services/url.helper';
import {delay} from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'cross-trading',
  templateUrl: './cross-trading.component.html',
  styleUrls: ['./cross-trading.component.css']
})
export class CrossTradingComponent implements OnInit {
  isReadOnly: boolean =false;
  constructor(
    private http: HttpService, 
    private toastr: ToasterService,
    private url: URLHelper,
    private authservice: AuthService
    ) {
      this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
     }

  @Input() profileid    : number;
  @Input() profile      : any;
  @Output() pageDirty   = new EventEmitter<{pagedirty: boolean, msg: string}>();

  reportStyles:any      = [];
  rule_loading:boolean  = false;
  selectedStyleId:Number;
  rules:any             = [];
  selectedAssocProfileId: Number;
  selectedRowProfile    : any;
  selectedRowProfileId  :Number;
  displayedColumns      = ["aum_xhld_profiles_name", "aum_xhld_holding_flag", "aum_xhld_undly_flag", "actions"]
  profiles:any          = [];
  editing:boolean       = false;
  caption_edit          = 'Edit';
  caption_del           = 'Delete';
  formDirty             = false;
  searchText;

  getReportStyles=()=>{
    this.editing = true;
    //report styles
    this.http.getData(this.url.xhold_rules_get_report_styles).subscribe((res:any)=>{
      this.reportStyles = res.data;
      if(this.selectedStyleId){
        this.selecteStyle(this.selectedStyleId);
      }else if(this.reportStyles.length > 0){
        this.selectedStyleId = this.reportStyles[0].aum_rpt_style_sq_id;
        this.selecteStyle(this.selectedStyleId);
      }
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
    this.http.getData(this.url.xhold_profile_get).subscribe((res:any)=>{
      this.profiles = res.data;
      this.editing = false;
    });
  }

  selecteStyle = (style_id) =>{
    this.editing = true;
    this.selectedStyleId = style_id;
    this.selectedRowProfile = [];
    this.selectedRowProfileId = null;
    this.http.getData(this.url.xhold_xtrd_get_xtrd_rules + this.profileid + '/' + style_id).subscribe((res:any)=>{
      this.rules = res.data;
      this.editing = false;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  selectProfile = (rec:any) =>{
    
    this.selectedRowProfileId = rec.aum_xtrade_assoc_profile_id;
    this.selectedRowProfile = rec;
    this.caption_del = 'Delete';
    this.caption_edit = 'Edit';
    if(rec.action == 'edit'){
      this.caption_edit = 'Unedit';
    }
    if(rec.action == 'delete'){
      this.caption_del = 'Undelete';
    }
    
  }

  addRule = () =>{
    if (this.selectedAssocProfileId != undefined && this.selectedStyleId != undefined){
      const body = {
        "profile_id"        : this.profileid,
        "rep_style_id"      : this.selectedStyleId,
        "assoc_profile_id"  : this.selectedAssocProfileId,
        "hold_flag"         : "N",
        "undly_flag"        : "Y"
      }
      this.http.postData(this.url.xhold_xtrd_rule_insert,{}, body).subscribe((res:any)=>{
        if (res.data.error_flag === 0){
          this.toastr.success("AUM Rules Inserted Successfully");
        }else{
          this.toastr.error("AUM Rules Inserted error");
        }
        this.selectedAssocProfileId = null;
        this.getReportStyles();
        this.selecteStyle(this.selectedStyleId);
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }else{
      if(this.selectedAssocProfileId == undefined) this.toastr.warning("Please select Profile");
      if(this.selectedStyleId == undefined) this.toastr.warning("Please select report style");
    }
  }
  editRule = (ded) =>{
    // if (this.selectedRowProfile.length == 0){
    //   this.toastr.warning("Please select Decuction");
    //   return;
    // }
    this.selectedRowProfile = ded;
    this.rules.map((item:any)=>{
      if (item.aum_xtrade_assoc_profile_id == this.selectedRowProfile.aum_xtrade_assoc_profile_id){
        item.aum_xtrade_top_flag = item.aum_xtrade_top_flag == 'Y'?'N':'Y';
        item.aum_xtrade_undly_flag = item.aum_xtrade_undly_flag == 'Y'?'N':'Y';
        if(item.action == 'edit'){
          item.action = '';
          this.caption_edit = 'Edit';
        }else{
          item.action = 'edit'
          this.caption_edit = 'Unedit';
          this.caption_del = 'Delete'
        }
      }
    });

    this.formDirty = true;
    this.pageDirty.emit({pagedirty: true, msg: "Discard the changes of Cross Trading?"});
    // const body = {
    //   "profile_id"        : this.profileid,
    //   "rep_style_id"      : this.selectedRowProfile.aum_rpt_style_sq_id,
    //   "assoc_profile_id"  : this.selectedRowProfile.aum_xtrade_assoc_profile_id,
    //   "hold_flag"         : this.selectedRowProfile.aum_xtrade_top_flag,
    //   "undly_flag"        : this.selectedRowProfile.aum_xtrade_undly_flag
    // }
    // this.http.postData(this.url.xhold_xtrd_rule_insert,{}, body).subscribe((res:any)=>{
    //   this.editing = false;
      
    //   if (res.data.error_flag === 0){
    //     this.toastr.success("AUM Rules Inserted Successfully");
    //   }else{
    //     this.toastr.error("AUM Rules Inserted error");
    //   }
    //   this.getReportStyles();
    //   this.selecteStyle(this.selectedStyleId);
    // });
  }

  deleteRule = (ded) =>{
    // if (this.selectedRowProfile.length == 0){
    //   this.toastr.warning("Please select Decuction");
    //   return;
    // }
    this.selectedRowProfile = ded;
    this.rules.map((item:any)=>{
      if (item.aum_xtrade_assoc_profile_id == this.selectedRowProfile.aum_xtrade_assoc_profile_id){
        if(item.action == 'delete'){
          item.action = '';
          this.caption_del = 'Delete';
        }else{
          item.action = 'delete'
          this.caption_del = 'Undelete';
          this.caption_edit = 'Edit';
        }
      }
    });
    this.formDirty = true;
    this.pageDirty.emit({pagedirty: true, msg: "Discard the changes of Cross Trading?"});
  }

  cancelActions = () =>{
    this.rules.map(item=>{
      item.action = '';
    });
  }
  
  saveRule = () =>{
    this.editing = true;
    let action:boolean = true
    let len = this.rules.length;
    let counter = 0;
    let msg = "Updated Successfully !";
    const fltr = this.rules.filter((item:any)=>{return item.action == 'delete'});
    this.rules.forEach((item:any, i:number) => {
      if(item.action && item.action == 'delete'){
        //skip record to save or single record/all to delete
        if(this.rules.length == 1 || this.rules.length == fltr.length ){
          let body = {
            "profile_id"        : this.profileid,
            "rep_style_id"      : item.aum_rpt_style_sq_id,
            "assoc_profile_id"  : 0,
            "hold_flag"         : '',
            "undly_flag"        : ''
          }
          
          this.http.postData(this.url.xhold_xtrd_rule_insert,{}, body).subscribe((res:any)=>{
            msg = "Deleted successfully !";
          },
          err => {
            this.toastr.error("Issue while retrieving data. Please try again or contact support.")
          });     
        }
      }else{
        setTimeout(() => {
          let body = {
            "profile_id"        : this.profileid,
            "rep_style_id"      : item.aum_rpt_style_sq_id,
            "assoc_profile_id"  : item.aum_xtrade_assoc_profile_id,
            "hold_flag"         : item.aum_xtrade_top_flag,
            "undly_flag"        : item.aum_xtrade_undly_flag
          }
          
          this.http.postData(this.url.xhold_xtrd_rule_insert,{}, body).subscribe((res:any)=>{
            // saved         
          },
          err => {
            this.toastr.error("Issue while retrieving data. Please try again or contact support.")
          });     
        }, i * 500);
      }
    });

    setTimeout(() => {
      this.toastr.success(msg);
      this.editing = false;
      this.selecteStyle(this.selectedStyleId);
      this.formDirty = false;
      this.pageDirty.emit({pagedirty: false, msg: ""});
    }, len * 500);
    // while (len > 0){
      
    //     let item = this.rules[counter];

    //     action = false;
    //     let body = {
    //       "profile_id"        : this.profileid,
    //       "rep_style_id"      : item.aum_rpt_style_sq_id,
    //       "assoc_profile_id"  : item.aum_xtrade_assoc_profile_id,
    //       "hold_flag"         : item.aum_xtrade_top_flag,
    //       "undly_flag"        : item.aum_xtrade_undly_flag
    //     }
    //     console.log(action, len, counter, body);
    //     this.http.postData(this.url.xhold_xtrd_rule_insert,{}, body).subscribe((res:any)=>{
          
          
    //       console.log("inner", action, len, counter);
          
    //     });     
    //     action = true;
    //     len = len - 1;
          
    //     counter++;
    //     setTimeout(function() {
          
          
    //     }, 1000);
        
         
    //   }
    }
    @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
      if (this.formDirty) event.returnValue = false;
    }

    searchProfile = (event) =>{
      this.searchText = event.target.value;
    }
    clearsearch = (event) =>{
      this.searchText = "";
      event.target.value = "";
    }
  
  ngOnInit(): void {
    this.getReportStyles();
  }

}

