<mat-card class="example-card">
    <mat-card class="example-card text-center">
        <h3>Historical XHLD Profile Assoc SDIM Attributes</h3>
    </mat-card>
    <mat-card class="example-card">
      
        <div class="row">          
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToSharePage()">Show Current Share Class</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="loadDetailsPanel()">View</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToAUMSDIM()">Show AUM SDIM</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="copyAsNewRecord()">Copy As New Record</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="deleteRecord()">Delete Record</button>
            </div>
        </div>
        <br>
        <div class="row">
            <div *ngIf="isLoading" >         
                <mat-spinner class="loader"></mat-spinner>
                  </div>
         
                <table border="0" width="100%" cellpadding="0" cellspacing="0" 
                mat-table [dataSource]="loadTemplateDatasource"
                matSort>
                
                    <!-- Template Name Column -->
                    <ng-container matColumnDef="radio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-width"></th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">
                            <!-- <mat-radio-button color="primary" [checked]="element == defaultSelected"
                             [value]="element" (change)="selection = $event.value"> -->
                             <mat-radio-button color="primary" [checked]="element.profile_assoc_sdim_key == selectedrecord"
                             [value]="element" (change)="viewAttribute($event)">
                            </mat-radio-button> 
                        </td> 
                    </ng-container>
                    <ng-container matColumnDef="firstEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element.first_effective_date | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lastEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element.last_effective_date | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

                    <!-- Template Download Column -->
                    <ng-container matColumnDef="accountNo">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Account Number </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_acct_num}} </td>
                    </ng-container>

                    <!--Template Upload Column -->
                    <ng-container matColumnDef="shareClassCode">
                        <th mat-header-cell *matHeaderCellDef> Share Class Code </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_share_class_code}}</td>
                    </ng-container>

                    <ng-container matColumnDef="profileName">
                        <th mat-header-cell *matHeaderCellDef> Profile Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_xhld_profiles_name}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

                </table>
                
                <mat-paginator [pageSize]="10"
                showFirstLastButtons>
 </mat-paginator>
                <div *ngIf="loadTemplateDatasource.data.length === 0" class="no-records alert alert-info text-center">
                    No records found
                </div>
        </div>
    </mat-card>
    <mat-card class="example-card text-center">
        <h3>Historical XHLD_Profile_Assoc_SDIM Attributes Account Validation Results</h3>
        <div class="row">                     
            <div [innerHTML] = "validateinfo">          
             </div>
         </div>
    </mat-card>
</mat-card>
<mat-accordion class="example-headers-align prevent-click">
    <mat-expansion-panel #panel1 [hideToggle]="true">
        <mat-expansion-panel-header *ngIf="showView">
            <mat-panel-title style="font-size:20px;">
                Historical XHLD_Profile_Assoc_SDIM Attributes Details
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-4">
                    <mat-form-field>
                        <mat-placeholder>TIS Account Num</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="accountNo" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>Share Class</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="classcode" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>Account Name</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="accountName" [disabled]="true">
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-4">
                    <mat-form-field>
                        <mat-placeholder>First Effective Date</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "firstEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                        <!-- <mat-label>First Effective Date</mat-label>
                        <input matInput [matDatepicker]="FirstEffectivepicker" [disabled]="!isEdit" [(ngModel)]="firstEffectiveDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="FirstEffectivepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #FirstEffectivepicker></mat-datepicker> -->
                    </mat-form-field>
                </div>
                <div class="col-4" *ngIf="!enable">
                    <mat-form-field>
                        <mat-label>Last Effective Date</mat-label>
                        <input matInput type="text" [(ngModel)] = "lastEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                        <!-- <mat-label>Last Effective Date</mat-label>
                        <input matInput [matDatepicker]="LastEffectivepicker" [disabled]="!isEdit" [(ngModel)]="lastEffectiveDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="LastEffectivepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #LastEffectivepicker></mat-datepicker> -->
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Profile Name</mat-label>
                        <mat-select [(value)]= "profileId" [disabled]="!isEdit"> 
                            <input type="text" placeholder="search" class="search" 
                                    (keyup)="profileSearch($event)"  (keydown.escape)="clearsearch($event)">
                                
                            <mat-icon class="search-icon">search</mat-icon>

                            <mat-option *ngFor="let profile of profileList | search : valueSearchTxt : 'aum_xhld_profiles_name'" 
                            [value] ="profile.aum_xhld_profiles_sq_id">{{ profile.aum_xhld_profiles_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Last Updated By</mat-placeholder>
                        <input matInput type="text" [disabled]="true" [(ngModel)]="lastUpdatedBy">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Last Updated Date</mat-placeholder>
                        <input matInput type="text" [disabled]="true" [(ngModel)]="lastUpdatedDate">
                    </mat-form-field>
                </div>
        
                    <div class="col-3">
                        <button class="button btn btn-secondary"  type="submit" [disabled]="!isEdit"
                            (click)="saveAccountInfo()">Save</button>
                        <button class="button"
                            (click)="closePanel()">Cancel</button>
                    </div>
                </div>
            </div>
        </mat-card>

    </mat-expansion-panel>
</mat-accordion>