import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../../../services/toastr.service';

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.css']
})
export class EditListComponent implements OnInit {

  valueForm: FormGroup;
  constructor(
    private http: HttpService,
    private url: URLHelper,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private toastr: ToasterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.valueForm = this.fb.group({
      list_id: [null, Validators.required],
      list_code: [null, Validators.required],
      list_type: [null, Validators.required],
      list_desc: [null, Validators.required]
    });

  }

  getRowList = () =>{
    console.log(this.data.list_id, "inner")
    this.http.getData(this.url.value_list_edit + "/" + this.data.list_id).subscribe((res: any)=>{

      this.valueForm.controls["list_id"].setValue(res.data.list_id);
      this.valueForm.controls["list_code"].setValue(res.data.list_code);
      this.valueForm.controls["list_type"].setValue(res.data.list_type);
      this.valueForm.controls["list_desc"].setValue(res.data.list_desc);

    });
  }

  saveValue = (frm) =>{
    
    if(this.valueForm.invalid){
      return;
    }

    const valueData:any = JSON.stringify(this.valueForm.value, null, 4);
    const data = JSON.parse(valueData);
    
    this.http.putData(this.url.value_list_update, {}, data).subscribe((res)=>{
        this.toastr.success("Updated Successfully!")
        this._dialog.closeAll();
    }, (error: HttpErrorResponse)=>{
      this.toastr.error('Value List update error');
      this._dialog.closeAll();
    })

  }

  cancelDialog = () =>{
    this._dialog.closeAll();
  }


  ngOnInit(): void {
    this.getRowList();
  }

}
