import { Component, OnInit, Inject, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Rules } from '../../../models/Rules';
import { HttpService } from '../../../services/http.service';
import {LayoutModule, Breakpoints} from '@angular/cdk/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../services/toastr.service';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig
} from "@angular/material/dialog";
import { URLHelper } from '../../../services/url.helper';
import { MatSelect } from '@angular/material/select';
import { EditRuleCriteriaComponent } from '../edit-criteria/edit-criteria.component';


@Component({
  selector: 'app-edit-rule',
  templateUrl: './edit-rule.component.html',
  styleUrls: ['./edit-rule.component.css']
})
export class EditRuleComponent implements OnInit {
  
  form: FormGroup;
  onDirty = new EventEmitter();
  @ViewChild('valueSelect') selectElem: MatSelect;
  @ViewChild('inptCondition') inptCond: MatSelect;
 
  constructor(
    private http: HttpService, 
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private toastr: ToasterService,
    private url: URLHelper,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { 
    this.form = this.fb.group({
      rule_id               : this.data.rule_id,
      rule_name             : [null, Validators.required],
      rule_description      : [null, Validators.required], 
      rule_type_string      : [null, Validators.required],
      table_name            : [null, Validators.required],
      delimiter_rule_string : [null],
      rule_type_id          : [null, Validators.required],
      var_variable          : [null],
      var_condition         : [null],
      var_value             : [null]
    });

    //used for url redirection to sent when form is dirty
    this.form.valueChanges.subscribe((data)=>{
      if(this.form.dirty){
        this.onDirty.emit(true);
      }      
    });
  }
  
  public rule_types           = [];
  public variables            = [];
  public rules_row:any        = [];
  public valid_operators      = [];
  public var_values           = [];
  public selected_rule_type:any;
  public rule_strings         = [];
  public table_names          = [];
  public var_sign_val;
  public old_rule_name;
  public varntab;
  public form_changes:boolean = false;
  public rule_loading:boolean = false;
  public selectLoading:boolean= false;t
  valueLoading:boolean        = false;
  public pagination:any       = {from: 0, to: 1000};
  public varSearchTxt         = "";
  public valueSearchTxt       = "";
  public searchTextValue      = "";
  public ruleCondition        = "";
  public var_values_status: boolean = false;
  public error_condition      = "";
  // public show_text:boolean    = true;
  // public show_select:boolean  = false;
  

  addCondition = (frm) =>{
    this.error_condition = "";
    if(this.form.controls["var_condition"].value.trim() == 'IS NULL'){
      this.var_sign_val = false;
    }else if((!this.searchTextValue && !this.form.controls["var_value"].value) ||
        !this.form.controls["var_variable"].value ||
        !this.form.controls["var_condition"].value
      ){
      this.var_sign_val = true;
      return;
    }else{
      this.var_sign_val = false;
    }
    if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 1 ){
      this.error_condition = "The value like '%VALUE%";
      this.var_sign_val = true;
      return;
    }
    
    if (this.table_names.indexOf(this.form.controls["var_variable"].value.split(".")[0]) != 0){
      this.table_names.push(this.form.controls["var_variable"].value.split(".")[0])
    }
    
    var strn = '';
    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const dt = JSON.parse(ruleData);
    // const strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
    // console.log("condition = ","-"+dt.var_condition.trim()+"-", this.searchTextValue)
    if(dt.var_condition.trim() == 'LIKE'){
      console.log(this.searchTextValue.match(new RegExp("%", "g")), (this.searchTextValue.match(new RegExp("%", "g")) || []).length)
      if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
        strn = dt.var_variable + dt.var_condition + "'" + this.searchTextValue.toString().toUpperCase() + "'";
      }else{
        if(dt.var_value != "" && dt.var_value != null ){
          strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
        }else{
          this.error_condition = "The value like '%VALUE%";
          this.var_sign_val = true;
          return;
        }
      }
    }else if(dt.var_condition.trim() == '='){
      if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
        this.var_sign_val = true;
        return;
      }
      if(!dt.var_value){
        strn = dt.var_variable + dt.var_condition + "'" + this.searchTextValue.toString().toUpperCase() + "'";
      }else{
        strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
      }
    }else if(dt.var_condition.trim() == 'IS NULL'){

      strn = dt.var_variable + dt.var_condition

    }else{
      strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
    }
    
    this.rule_strings.push(strn)
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + strn);
    this.form.controls["table_name"].setValue(this.table_names.join(","));

    // this.show_text        = true;
    // this.show_select      = false;
    this.form.controls["var_value"].setValue('')
    this.form.controls["var_variable"].setValue('')
    this.form.controls["var_condition"].setValue('')
    
    setTimeout(()=>{
      // this.selectElem.trigger.nativeElement.click();
      this.valueSearchTxt ='';
      this.searchTextValue = '';
      this.var_values_status = false;
      this.selectElem.close();
    }, 0);
  }

  protected getData = () =>{
    
    this.http.getData(this.url.busrules_show_type).subscribe((data: any) => {
      this.rule_types = data.data;
    });

    this.http.getData(this.url.busrules_var_show).subscribe((data: any) => {
      this.variables = data.data;
    });

  }
  protected getRuleData = (rule_id: any) =>{
    this.http.getData(this.url.busrules_rule_row + rule_id).subscribe((data: any) => {
      // this.rules_row = data.data;

      this.selected_rule_type = this.rules_row.ruletype_id;
      this.form.controls["rule_type_string"].setValue(data.data.rule_string.replace(/[\n]/g, ' '));
      this.form.controls["rule_name"].setValue(data.data.rule_name);
      this.old_rule_name = data.data.rule_name;
      this.form.controls["rule_description"].setValue(data.data.rule_description);
      this.form.controls["table_name"].setValue(data.data.table_name);
      this.form.controls["delimiter_rule_string"].setValue(data.data.delimiter_rule_string);
      this.form.controls["rule_type_id"].setValue(data.data.ruletype_id);

      this.table_names  = this.form.controls["table_name"].value.split(",");
      this.rule_strings = this.form.controls["delimiter_rule_string"].value.split(";");
      this.rule_strings.pop();
      this.rule_strings.shift();
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  getValidOperators = (varntab='', from = 0, to = 1000) =>{
    this.varntab          = varntab;
    this.selectLoading    = true;
    const table_name      = varntab.split(".")[0];
    const var_name        = varntab.split(".")[1];
    this.valid_operators  = [];
    this.var_values       = [];
    this.varSearchTxt     = "";
    const param           = '?col_name=' + var_name + '&' + 'table_name=' + table_name + '&from=' + from + '&to=' + to;

    this.http.getData(this.url.busrules_valid_operators + param).subscribe((data: any) => {
      this.valid_operators = data.data.value;
    });

    this.http.getData(this.url.busrules_variable_show + param).subscribe((data: any) => {
      this.selectLoading = false;
      if(from > 0){
        this.var_values = [...this.var_values, ...data.data];
      }else{
        this.var_values = data.data;
      }
    });
  }

  checkValidCondition = (condition) =>{
    this.ruleCondition = condition;
    this.error_condition = "";
    this.var_values_status = false;
    this.searchTextValue = "";
    if (condition.trim() == 'IS NULL'){
      this.var_values_status = true;
    }
  }

  ngOnInit(): void {
    this.getData();
    
    if(this.data && this.data.rule_id){
      this.getRuleData(this.data.rule_id);
    }
    
  }
  addAnd = () =>{
    this.rule_strings.push(' AND ')
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' AND ');
  }

  addOr = () => {
    this.rule_strings.push(' OR ');
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' OR ');
  }

  addLeftPar = () =>{
    this.rule_strings.push(' ( ');
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' ( ');
  }

  addRgtPar = () =>{
    this.rule_strings.push(' ) ');
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' ) ');
  }

  formUndo = () =>{
    this.rule_strings.pop();
    this.form.controls["rule_type_string"].setValue(this.rule_strings.join(" "));
  }

  formClear = () =>{
    this.rule_strings = [];
    this.form.controls["rule_type_string"].setValue('');
  }
  saveRule = (frm) => {
    
    if(this.form.invalid){
      return;
    }

    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const data = JSON.parse(ruleData);


    data.delimiter_rule_string = "STR_START;" + this.rule_strings.join(";") + ";STR_END"
    
    this.http.putData(this.url.busrules_edit_rule_row, {}, data).subscribe((res: any) => {
      if(res.data.edit_flag == '0'){
        this.toastr.success("Buss Rule Management edited successfully !");
        this._dialog.closeAll();
      }else{
        this.toastr.error(res.message);
      }
      
    });
  }
  
  checkName = (event) =>{
    
    if(this.old_rule_name == this.form.controls["rule_name"].value){
      return;
    }
    const rulName = this.form.controls["rule_name"].value;
    this.rule_loading = true;
    this.http.getData(this.url.busrules_chk_save + rulName).subscribe((res:any)=>{
      this.rule_loading = false;
      if(parseInt(res.data.flag_save) == 1){
        this.form.controls["rule_name"].setErrors({"duplicate": "Rule Name already exists"});
      }
    });
  }

  cancelDialog = (frm) =>{
    
    if(this.form.dirty){
      const ok = confirm("Discard changes for Rule?");
      
      if(ok){
      //   // this.saveRule(frm);
      // }else{
        this._dialog.closeAll();
      }
    }else{
      this._dialog.closeAll();
    }
  }

  onValueChange = (event) =>{
    this.valueLoading = false;
    this.valueSearchTxt = "";
    this.searchTextValue = '';
    if (event) {
      
      this.selectElem.panel.nativeElement.addEventListener('scroll', (event: any) => {
        
        if (parseInt((this.selectElem.panel.nativeElement.scrollTop).toFixed(0)) === this.selectElem.panel.nativeElement.scrollHeight - this.selectElem.panel.nativeElement.offsetHeight) {
          const from = this.pagination.to;
          const to = this.pagination.to + 1000;
          this.pagination.from  = from;
          this.pagination.to    = to;
          const table_name      = this.varntab.split(".")[0];
          const var_name        = this.varntab.split(".")[1];
          const param           = '?col_name=' + var_name + '&' + 'table_name=' + table_name + '&from=' + from + '&to=' + to;
          this.valueLoading     = true;
          // this.getValidOperators(this.varntab, from , to);
          this.http.getData(this.url.busrules_variable_show + param).subscribe((data: any) => {
            this.valueLoading = false;
            // this.selectLoading = false;
            if(from > 0){
              this.var_values = [...this.var_values, ...data.data];
            }else{
              this.var_values = data.data;
            }
          });
        }
      });
    }
  }

  registerPanelScrollEvent = () =>{
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener('scroll', event => this.loadAllOnScroll(event));
  }

  loadAllOnScroll = (event) =>{
    console.log("sssssssssssssssssssssss")
    // getValidOperators()
  }

  varChange = (event) =>{
    this.varSearchTxt = event.target.value;
    this.searchTextValue = "";
  }
  valueChange = (event) =>{
    this.valueSearchTxt = event.target.value;
    this.searchTextValue = event.target.value;
    setTimeout(()=>{
      this.valueSearchTxt = event.target.value;
      this.searchTextValue = event.target.value;
    },0)
    
  }

  clearsearch = (event) =>{
    this.varSearchTxt = "";
    this.valueSearchTxt = "";
    event.target.value = "";
  }

  editCriteria = () =>{
    const dialogConfig  = new MatDialogConfig();
    dialogConfig.data   = {'profile_string': this.rule_strings};
    dialogConfig.width  = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxHeight  = '100vw';
    dialogConfig.maxHeight  = '100vh';
    dialogConfig.panelClass = 'full-screen-modal';
    

   
    const dialogRef = this._dialog.open(EditRuleCriteriaComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      
      this.form.controls["rule_type_string"].setValue(this.rule_strings.join(" "));
    });
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.form.dirty) event.returnValue = false;
  }

}
