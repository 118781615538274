import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CategoryModel } from 'src/app/models/dataloads';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AttributemanagementService extends BaseService {
  brandStatus: any;
  constructor(private inject: HttpClient) {
    super(inject);
  }


  getBrandList(): Observable<any> {
    let resource = "/attributes/brands/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  changeBrandStatus(brandStatus: any) {
    let resource = "/attributes/brands/change_status";
    // console.log(resource);
    console.log(brandStatus);
    return this.putRequest(resource, brandStatus);
  }

  saveBrandAttributes(saveAttributes: any) {
    let resource = "/attributes/brands/add";
    //console.log(resource);
    return this.postRequest(resource, saveAttributes);
  }

  updateBrandAttributes(editAttributes: any) {
    let resource = "/attributes/brands/update";
    /*    console.log(resource); */
    return this.putRequest(resource, editAttributes);
  }

  updateBrandAttrSortOrder(sortOrder) {
    let resource = "/attributes/brands/sortupdate";
    return this.putRequest(resource, sortOrder);
  }

  //Protfolio Mandate
  getPortfolioMandateList(): Observable<any> {
    let resource = "/portmandate/";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  savePortfolioAttributes(saveAttributes: any) {
    let resource = "/portmandate/add";
    //console.log(resource);
    //console.log(saveAttributes);
    return this.postRequest(resource, saveAttributes);
  }

  updatePortfolioAttributes(editAttributes: any) {
    let resource = "/portmandate/indicator";
    /*  console.log(resource);
     console.log(editAttributes); */
    return this.putRequest(resource, editAttributes);
  }

  activatePortAttStatus(portStatus) {
    let resource = "/portmandate/statusupdate";
    return this.putRequest(resource, portStatus);
  }

  updatePortAttrSortOrder(sortOrder) {
    let resource = "/portmandate/sortupdate/";
    return this.putRequest(resource, sortOrder);
  }

  getBillingIndicatorList() {
    let resource = "/billingind/ind_tree";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  saveBillingAttributes(saveAttributes: any) {
    let resource = "/billingind/ins_ind_hier";
    /* console.log(resource);
    console.log(saveAttributes); */
    return this.postRequest(resource, saveAttributes);
  }

  updateBillingAttributes(editAttributes: any) {
    let resource = "/billingind/upd_ind_hier";
    /*  console.log(resource);
     console.log(editAttributes); */
    return this.putRequest(resource, editAttributes);
  }

  updateBillingAttrSortOrder(sortOrder) {
    let resource = "/billingind/upd_ind_sort";
    return this.putRequest(resource, sortOrder);
  }

  activateBillingAttStatus(billingStatus) {
    console.log(billingStatus);
    let resource = "/billingind/upd_ind_status";
    return this.putRequest(resource, billingStatus);
  }

  getCrossholdingIndicatorList() {
    let resource = "/xhold/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  savexholdAttributes(saveAttributes: any) {
    let resource = "/xhold/add";
    /*  console.log(resource);
     console.log(saveAttributes); */
    return this.postRequest(resource, saveAttributes);
  }

  updatexholdAttributes(editAttributes: any) {
    let resource = "/xhold/update";
    /*  console.log(resource);
     console.log(editAttributes); */
    return this.putRequest(resource, editAttributes);
  }

  updateXholdAttrSortOrder(sortOrder) {
    let resource = "/xhold/save/";
    return this.putRequest(resource, sortOrder);
  }

  activateXholdAttStatus(xholdStatus) {
    console.log(xholdStatus);
    let resource = "/xhold/update_status";
    return this.putRequest(resource, xholdStatus);
  }
}
