import { Component, HostListener, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { HttpService } from '../../services/http.service';
// import { environment, API_BASE_URL, UPLOAD_FILE_BASE_URL } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import {Rules} from '../../models/Rules'
import { AddRuleComponent } from './add-rule/add-rule.component';
import { EditRuleComponent } from './edit-rule/edit-rule.component';
import { ToasterService } from 'src/app/services/toastr.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Title } from '@angular/platform-browser';
import { URLHelper } from '../../services/url.helper';
import { DialogService } from '../../services/dialog.service';
import {Observable} from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from '../../services/modal.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { EditRuleCriteriaComponent } from './edit-criteria/edit-criteria.component';



@Component({
  selector: 'app-reporting-rules-management',
  templateUrl: './reporting-rules-management.component.html',
  styleUrls: ['./reporting-rules-management.component.css']
})

export class ReportingRulesManagementComponent implements OnInit{
  isReadOnly: boolean =false;
  form: FormGroup;
  @ViewChild('valueSelect') selectElem: MatSelect;
  @ViewChild('inptCondition') inptCond: MatSelect;
  @ViewChild('textRuleString') txtRule: ElementRef;
  @ViewChild('whereRuleString') whereRule: ElementRef;
  public rules = new MatTableDataSource<Rules>();


  constructor(private http: HttpService, 
              private router: Router,
              public dialog: MatDialog,
              public _dialog: MatDialog,
              private toastr: ToasterService,
              private titleService: Title,
              private url: URLHelper,
              private confirmDialog: DialogService,
              private authservice: AuthService,
              public modalService: ModalService,
              private fb: FormBuilder,
              private renderer: Renderer2
  ) { 
    this.titleService.setTitle("CARS - Reporting Rules Management");
    this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;

    this.form = this.fb.group({
      rule_id               : null,
      rule_name             : [null, Validators.required],
      rule_description      : [null, Validators.required], 
      rule_type_string      : ['', Validators.required],
      table_name            : [null, Validators.required],
      delimiter_rule_string : [null],
      rule_type_id          : [null, Validators.required],
      var_variable          : [null],
      var_condition         : [null],
      var_value             : [null]
    });
    //used for url redirection to sent when form is dirty
    this.form.valueChanges.subscribe((data)=>{
      if(this.form.dirty){
        // this.onDirty.emit(true);
        // this.http.setGoBack(true);
      }      
    });

  }

  canDeactivate():Observable<boolean> | boolean{
    console.log("logggggggggggggg", this.pageDirty, this.http.isGoBack(), this.form.dirty)
    
    if(this.form.dirty) return this.confirmDialog.confirm("Do you want to discard the changes?");
    
    return true;
  }
  public pageDirty:boolean = false;
  public highlighdRowIndex = ''
  public selectedRowIndex;
  public selectedOption = ''
  public highlightOption = ''
  public selectedItem:any = {};
  public statusFlagLabel = 'De-Activate';
  public isLoading:boolean = false;

  public displayedColumns: any[] = ["rule_name"];
  public profile_string: any;
  public where_rule_strings: any = [];
  public where_rule_strings_action: any = [];
  whereIdx;
  whereItem;
  viewquery:boolean = false;

  // public dataSource: MatTableDataSource<any>;
  // public displayedColumns = ["first_effective_date", "last_effective_date", "inspection_date", "status"];


  protected getData = () =>{
    this.isLoading = true;
    this.http.getData(this.url.busrules_show).subscribe((res: any) => {
      this.isLoading = false;
      this.rules = res.data ;//as Rules[];
      this.selectedItem = [];
      this.selectedRowIndex = null;
    },
    err => {
      this.isLoading = false;
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  

  highlight = (row) =>{
    this.highlighdRowIndex = row.rule_id;
    this.highlightOption = 'hover';
  }
  selected = (row) =>{
    this.selectedRowIndex = row.rule_id;
    this.selectedOption   = 'select';
    this.selectedItem     = row;
    if(this.selectedItem.status_flag == 'T'){
      this.statusFlagLabel = 'De-Activate';
    }else{
      this.statusFlagLabel = "Activate";
    }
  }

  openAddDialog() {
    this.modalService.open('add_modal');
    this.getRuleType();
    // const dialogConfig          = new MatDialogConfig();
    // dialogConfig.width          = '100%';
    // dialogConfig.height         = '100%';
    // dialogConfig.maxHeight      = '100vw';
    // dialogConfig.maxHeight      = '100vh';
    // dialogConfig.panelClass     = 'full-screen-modal';
    // dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    // dialogConfig.hasBackdrop    = false;
    
    // const dialogRef             = this.dialog.open(AddRuleComponent, dialogConfig);
    // dialogRef.afterOpened().subscribe(res=>{
    //   this.pageDirty = true;
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.pageDirty = false;
    //   this.getData();
    //   // console.log(`Dialog result: ${result}`);
    // });
    // dialogRef.beforeClosed().subscribe(result=>{
    //   console.log(this.pageDirty, "check dirty before closed")
      
      
    // })
    // //get value from dialog component when the dialog form is dirty
    // dialogRef.componentInstance.onDirty.subscribe((res)=>{
    //   if(res) {
    //     this.pageDirty = true;
    //     this.http.setGoBack(true);
    //   }
    //   console.log("dirtyyyyyyyyyyyyyyyyyyy", this.pageDirty)
    // });

  }
  openEditDialog() { 
    if(this.selectedRowIndex == null || this.selectedRowIndex == ''){
      this.toastr.error("Need to select any one Rule from the list.");
    }else{
      
      this.modalService.open("edit_modal");
      this.getRuleType();
      
      this.form = this.fb.group({
        rule_id               : this.selectedRowIndex,
        rule_name             : [null, Validators.required],
        rule_description      : [null, Validators.required], 
        rule_type_string      : [null, Validators.required],
        table_name            : [null, Validators.required],
        delimiter_rule_string : [null],
        rule_type_id          : [null, Validators.required],
        var_variable          : [null],
        var_condition         : [null],
        var_value             : [null]
      });
      this.getRuleData(this.selectedRowIndex);
    }

    // const dialogConfig          = new MatDialogConfig();
    // dialogConfig.data           = {'rule_id': this.selectedRowIndex};
    // dialogConfig.width          = '100%';
    // dialogConfig.height         = '100%';
    // dialogConfig.maxHeight      = '100vw';
    // dialogConfig.maxHeight      = '100vh';
    // dialogConfig.panelClass     = 'full-screen-modal';
    // dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    // dialogConfig.hasBackdrop    = false;

    // if(this.selectedRowIndex == null || this.selectedRowIndex == ''){
    //   this.toastr.error("Need to select any one Rule from the list.");
    // }else{
    //   const dialogRef = this.dialog.open(EditRuleComponent, dialogConfig);
    
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.getData();
    //     this.pageDirty = false;
    //   });
    //     //get value from dialog component when the dialog form is dirty
    //   dialogRef.componentInstance.onDirty.subscribe((res)=>{
    //     console.log("edit ----", res)
    //     if(res) {
    //       this.pageDirty = true;
    //       // this.http.setGoBack(true);
    //     }
    //   })
    // }
    
  }
  
  addRule = () =>{
    this.router.navigate(['\account-attributes'])
  }
  
  deActivate = () =>{
    if (this.selectedRowIndex != ''){
      const uptData = {
        rule_id: this.selectedRowIndex,
        status_flag: this.selectedItem["status_flag"] == 'T'? 'F':'T'
      }
      let msg = "Specific rule Activated successfully!";
      if(this.selectedItem["status_flag"] == 'T'){
        msg = "Specific rule Deactivated successfully!";
      }
      
      this.http.putData(this.url.busrules_update, {}, uptData).subscribe((res: any) => {
        if(res.data.status_flag == '0'){
          this.toastr.success(msg);
          this.getData();
        }
        else{
          this.toastr.warning("Deactivate not done.")
        }
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
      
    }
  }

  delete = () =>{
    if (this.selectedRowIndex != ''){
      const data = {
        rule_id: this.selectedRowIndex
      }
      this.http.deleteData(this.url.busrules_delete, {}, data).subscribe((res: any) => {
        // console.log(res.data, "test");
        if(res.data.status_flag == "0"){
          this.toastr.success("The rule removed Successfully!");
          this.getData();
        }else{
          this.toastr.warning("Rule remove error.")
        }
        // this.rules.data = data.data as Rules[];
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }
  }

  sortRuleDialog() {
    // const dialogConfig  = new MatDialogConfig();
    // dialogConfig.width  = '100%';
    // dialogConfig.height = '100%';
    // dialogConfig.maxHeight  = '100vw';
    // dialogConfig.maxHeight  = '100vh';
    // dialogConfig.panelClass = 'full-screen-modal';
    // const dialogRef = this.dialog.open(SortRuleComponent, dialogConfig);

    // dialogRef.afterClosed().subscribe(result => {
    //   this.getData();
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  dropRue(event: CdkDragDrop<string[]>){
    try{
    const tmp = this.rules[event.currentIndex]
    
    const data = {
      rule_id: this.rules[event.previousIndex]["rule_id"],
      sort_order: this.rules[event.currentIndex]["sort_order"]
    }
    // console.log(data)
    if(confirm("Are you sure change sort order ? ")) {
      this.http.putData(this.url.busrules_sort_order, {}, data).subscribe((res:any)=>{
        if(res.data.status_flag=="0"){
          this.getData();
          this.toastr.success(res.message);
        }
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }else{
      this.toastr.warning("Sort order not changed");
    }
  }catch(e){
    console.log(e)
  }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    // console.log("unload....", this.http.isGoBack())
    
    if (this.form.dirty) event.returnValue = false;

  }

  
  @HostListener('window:popstate')
  onPopState() {
    
    
    console.log("pop pop pop pop", "state")
    // this.pageDirty = false;
    // window.location.reload();
    // const s = this.confirmDialog.confirm("Discard changes for Rule??????????????????????????????????");
    history.pushState({}, 'unused argument', '#baseState');

    
    return false

    // if (this.pageDirty){
    //   return false
    // }
  }
  





  // **********************************************************
  // ************** add rule **********************************
  // **********************************************************






  public rule_types             = []
  public variables              = []
  public valid_operators        = [];
  public var_values             = [];

  public modVariable            = '';
  public modCondition           = '';
  public modValue               = '';
  public modString              = '';
  
  public rule_strings           = [];
  public display_rule_strings;
  public table_names            = [];
  public var_sign_val;
  public rule_loading: boolean  = false;
  public selectLoading:boolean  = false;
  public varntab;
  public pagination:any         = {from: 0, to: 1000};
  public acct_num: any;
  valueLoading:boolean          = false;
  editRowLoading: boolean       = false;
  public varSearchTxt           = "";
  public valueSearchTxt         = "";
  public searchTextValue        = "";
  public show_text:boolean      = true;
  public show_select:boolean    = false;
  public error_condition        = "Select Value";
  public var_values_status: boolean = false;
  public saveLoading:boolean    = true;
  public ruleCondition;

  protected getRuleType = () =>{
    
    this.http.getData(this.url.busrules_show_type).subscribe((data: any) => {
      this.rule_types = data.data;
    });

    this.http.getData(this.url.busrules_var_show).subscribe((data: any) => {
      this.variables = data.data;
    });

  }
  
  getValidOperators = (varntab='', from = 0, to = 1000) =>{
    this.varntab = varntab;
    this.selectLoading    = true;
    const table_name      = this.varntab.split(".")[0];
    const var_name        = this.varntab.split(".")[1];
    const param           = '?col_name=' + var_name + '&' + 'table_name=' + table_name + '&from=' + from + '&to=' + to;
    this.valid_operators  = [];
    this.var_values       = [];
    this.varSearchTxt     = "";

    this.http.getData(this.url.busrules_valid_operators + param).subscribe((data: any) => {
      this.valid_operators = data.data.value;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
    
    // if(var_name == 'ACCT_NUM'){
    //   this.var_values = this.acct_num;
    // }else
    {
      this.http.getData(this.url.busrules_variable_show + param).subscribe((data: any) => {
        this.selectLoading = false;
        if(from > 0){
          this.var_values = [...this.var_values, ...data.data];
        }else{
          this.var_values = data.data;
        }
      },
      err => {
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
    }  
  }

  saveRule = (frm) => {
    
    if(this.form.invalid){
      this.toastr.warning("Fill mandatory fields!")
      return;
    }
    this.isLoading = true;
    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const data = JSON.parse(ruleData);


    data.delimiter_rule_string = "STR_START;" + this.rule_strings.join(";") + ";STR_END"
    
    this.http.postData(this.url.busrules_insert_rule_row, {}, data).subscribe((res: any) => {
      this.saveLoading = false;
      if(res.data.error_flag == '0'){
        this.toastr.success("Buss Rule Management added successfully !");
        this._dialog.closeAll();
        this.modalService.close();
        this.getData();
        this.form.reset();
        this.whereIdx = null;
        this.whereItem = '';
        this.where_rule_strings = [];
      }else{
        this.toastr.error(res.message);
      }
      
    },
    err => {
      this.saveLoading = false;
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  addCondition = (frm) =>{
    console.log(this.rule_strings, frm)
    this.error_condition = "Select Value";
    if(this.form.controls["var_condition"].value.trim() == 'IS NULL'){
      this.var_sign_val = false;
    }else if((!this.searchTextValue && !this.form.controls["var_value"].value) ||
        !this.form.controls["var_variable"].value ||
        !this.form.controls["var_condition"].value
      ){
      this.var_sign_val = true;
      return;
    }else{
      this.var_sign_val = false;
    }
    if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 1 ){
      this.error_condition = "The value like '%VALUE%";
      this.var_sign_val = true;
      return;
    }
    

    if (this.table_names.indexOf(this.form.controls["var_variable"].value.split(".")[0]) != 0){
      this.table_names.push(this.form.controls["var_variable"].value.split(".")[0])
    }
    try{
      const ruleData:any = JSON.stringify(frm.value, null, 4);
      const dt = JSON.parse(ruleData);
      var strn = '';
      if(dt.var_condition.trim() == 'LIKE'){
        if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
          strn = dt.var_variable + dt.var_condition + "'" + this.searchTextValue.toString().toUpperCase() + "'";
        }else{
          if(dt.var_value != "" && dt.var_value != null ){
            strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
          }else{
            this.error_condition = "The value like '%VALUE%";
          this.var_sign_val = true;
          return;
          }
        }
      }else if(dt.var_condition.trim() == '='){
        if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
          this.var_sign_val = true;
          return;
        }
        if(!dt.var_value){
          strn = dt.var_variable + dt.var_condition + "'" + this.searchTextValue.toString().toUpperCase() + "'";
        }else{
          strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
        }
      }else if(dt.var_condition.trim() == 'IS NULL'){

        strn = dt.var_variable + dt.var_condition

      }else{
        strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
      }
      
      //ivar this.rule_strings.push(strn)
      this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + strn);
      this.form.controls["table_name"].setValue(this.table_names.join(","));
      
      this.form.controls["var_value"].setValue('')
      this.form.controls["var_variable"].setValue('')
      this.form.controls["var_condition"].setValue('')

      this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: strn, action: 'add' });

      this.where_rule_strings.push(strn);
      console.log(this.where_rule_strings)
      
      setTimeout(()=>{
        // this.selectElem.trigger.nativeElement.click();
        this.valueSearchTxt ='';
        this.searchTextValue = '';
        this.var_values_status = false;
      }, 0);
    }catch(e){
      console.log(e)
    }
  }

  addAnd = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' AND ', action: 'add' });
    
    this.form.controls["rule_type_string"].setValue(this.form.controls["rule_type_string"].value + ' AND ');
    this.where_rule_strings.push(' AND ')
    console.log(this.rule_strings, this.where_rule_strings, this.where_rule_strings_action)
  }

  addOr = () => {
    this.where_rule_strings.push(' OR ');
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' OR ', action: 'add' });
    this.form.controls["rule_type_string"].setValue(this.where_rule_strings.join(" "));
  }

  addLeftPar = () =>{
    this.where_rule_strings.push(" ( ")
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' ( ', action: 'add' });
    this.form.controls["rule_type_string"].setValue(this.where_rule_strings.join(" "));
  }

  addRgtPar = () =>{
    this.where_rule_strings.push(" ) ")
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' ) ', action: 'add' });
    this.form.controls["rule_type_string"].setValue(this.where_rule_strings.join(" "));
  }

  formUndo = () =>{

    if(this.where_rule_strings_action.length > 0){
        let unidx = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].idx;
        let unval = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].value;
        let unact = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].action;

        this.where_rule_strings_action.pop();
        if(unact == 'del'){
            this.where_rule_strings.splice(unidx, 0, unval);        
            
        }else{
            this.where_rule_strings.pop();
        }
        this.form.controls["rule_type_string"].setValue(this.where_rule_strings.join(" "));
    }else{
      this.toastr.warning("No Undo Actions");
    }
  }

  formClear = () =>{
    this.rule_strings = [];
    this.form.controls["rule_type_string"].setValue(null);
    this.whereIdx = null;
    this.whereItem = '';
    this.where_rule_strings = [];    
  }

  checkValidCondition = (condition) =>{
    this.ruleCondition = condition;
    this.var_values_status = false;
    this.error_condition = "";
    this.searchTextValue = "";

    if (condition.trim() == 'IS NULL'){
      this.var_values_status = true;
    }
  }

  checkName = () =>{
    this.rule_loading = true;
    const rulName = this.form.controls["rule_name"].value;
    this.http.getData(this.url.busrules_chk_save + rulName).subscribe((res:any)=>{
      this.rule_loading = false;
      if(parseInt(res.data.flag_save) == 1){
        this.form.controls["rule_name"].setErrors({"duplicate": "Rule Name already exists"});
      }
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });

  }

  cancelDialog = (frm) =>{
    if(this.form.dirty){
      const ok = confirm("Discard changes for Rule?");
      
      if(ok){
        this.form.reset();
        this.whereIdx = null;
        this.whereItem = '';
        this.where_rule_strings = [];
        this.modalService.close();
      }
    }else{
        this.form.reset();
        this.whereIdx = null;
        this.whereItem = '';
        this.where_rule_strings = [];
        this.modalService.close();
    }
  }
  
  
  onValueChange = (event) =>{
    this.valueLoading = false;
    this.valueSearchTxt = "";
    this.searchTextValue = "";
    if (event) {
      
      
      this.selectElem.panel.nativeElement.addEventListener('scroll', (event: any) => {
        
        // console.log(parseInt((this.selectElem.panel.nativeElement.scrollTop).toFixed(0)),' === ', this.selectElem.panel.nativeElement.scrollHeight ,"-", this.selectElem.panel.nativeElement.offsetHeight," = ",this.selectElem.panel.nativeElement.scrollHeight - this.selectElem.panel.nativeElement.offsetHeight, "sssssssssssssssss")
        if (parseInt((this.selectElem.panel.nativeElement.scrollTop).toFixed(0)) === this.selectElem.panel.nativeElement.scrollHeight - this.selectElem.panel.nativeElement.offsetHeight) {
          const from = this.pagination.to;
          const to = this.pagination.to + 1000;
          this.pagination.from = from;
          this.pagination.to = to;
          const table_name      = this.varntab.split(".")[0];
          const var_name        = this.varntab.split(".")[1];
          const param           = '?col_name=' + var_name + '&' + 'table_name=' + table_name + '&from=' + from + '&to=' + to;
          this.valueLoading = true;

          this.http.getData(this.url.busrules_variable_show + param).subscribe((data: any) => {
            this.valueLoading = false;
            if(from > 0){
              this.var_values = [...this.var_values, ...data.data];
            }else{
              this.var_values = data.data;
            }
          });
        }
      });
    }
  }

  registerPanelScrollEvent = () =>{
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener('scroll', event => this.loadAllOnScroll(event));
  }

  loadAllOnScroll = (event) =>{
    console.log("sssssssssssssssssssssss")
    // getValidOperators()
  }
  
  getAcctNum = () =>{
    const param  = '?col_name=ACCT_NUM&' + 'table_name=ACCT_SDIM&from=0&to=10000';
    
    this.http.getData(this.url.busrules_variable_show + param).subscribe((res:any)=>{
      this.acct_num = res.data.data;
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    })
  }

  varChange = (event) =>{
    this.varSearchTxt = event.target.value;
    this.searchTextValue = "";
  }
  valueChange = (event) =>{
    this.valueSearchTxt = event.target.value;
    this.searchTextValue = event.target.value;
    setTimeout(()=>{
      this.valueSearchTxt = event.target.value;
      this.searchTextValue = event.target.value;
    },0)
    if((this.searchTextValue.match(new RegExp("%", "g")) || []).length == 2){
      
      this.selectElem.panel.nativeElement.focus();
    }
  }
  clearsearch = (event) =>{
    this.varSearchTxt = "";
    this.valueSearchTxt = "";
    event.target.value = "";
  }

  deleteCriteria = (idx: any) =>{
    try{    
        let proc_rule = this.where_rule_strings[idx];
        
        this.where_rule_strings_action.push({idx: idx, value: proc_rule, action: 'del'});
        
        if(proc_rule.indexOf("(") >=0 ){

          let lft_par_count = (proc_rule.match(/\(/g) || []).length

          if(proc_rule.indexOf(")") >=0 ){
            if(proc_rule.indexOf("TRUNC") >=0 || proc_rule.indexOf("TRUNC(") >=0 || proc_rule.indexOf(" IN ") >=0 || proc_rule.indexOf("IN(") >=0 ){

            }else{
                let rgt_par_count = (proc_rule.match(/\)/g) || []).length;
                
                if(rgt_par_count > lft_par_count){
                  
                    this.where_rule_strings[idx] = ")".repeat(lft_par_count);

                }else{
                    this.where_rule_strings.splice(idx, 1);
                }
                
            }
          }else{
            this.where_rule_strings[idx] = proc_rule.replace(/[^(]/g, "")
          }
        }else if(proc_rule.indexOf(")") >= 0){
            this.where_rule_strings[idx] = proc_rule.replace(/[^)]/g, "")
        }else if(proc_rule.trim().toLowerCase() == 'and' || 
            proc_rule.trim().toLowerCase() == 'or'){
            this.where_rule_strings.splice(idx, 1);
            this.toastr.warning('Chek the conditon Carefully.')
        }else{
          
          this.where_rule_strings.splice(idx, 1);
        }
        this.form.controls["rule_type_string"].setValue(this.where_rule_strings.join(" "));
        this.form.markAsDirty();
    }
    catch(e){
      console.log(e)
    }
  }
  
  viewQuery = () =>{
    this.viewquery = !this.viewquery;
  }

  editCriteria = () =>{
    
    if( this.whereIdx == undefined || this.whereIdx == null) this.toastr.warning("Select criteria to edit!")
    let chkrule = '';
    if (this.whereItem.indexOf(" IN ") == -1 && 
      this.whereItem.indexOf(" IN(") == -1 &&
      this.whereItem.indexOf(" TRUNC ") == -1 && 
      this.whereItem.indexOf(" TRUNC (") == -1 &&
      this.whereItem.indexOf("TRUNC (") == -1 &&
      this.whereItem.indexOf("TRUNC(") == -1 &&
      this.whereItem.indexOf(" TRUNC(") == -1
      ){
        chkrule = this.whereItem.replace(/\(/g, '').replace(/\)/g, ''); //Replace paranthesis to null
    }else if (this.whereItem.indexOf(" IN ") >= 0 ||  this.whereItem.indexOf(" IN(") >= 0 ){
        chkrule = this.whereItem.replace(/\)\)/g, ')'); 
    }else{
        chkrule = this.whereItem
    }

    const dialogConfig  = new MatDialogConfig();
    dialogConfig.data   = {'profile_name': "",'profile_string': chkrule, 'rule_strings': this.rule_strings};
    dialogConfig.width  = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxHeight  = '50vw';
    dialogConfig.maxHeight  = '50vh';
    dialogConfig.panelClass = 'full-screen-modal';
    
  
    const dialogRef = this._dialog.open(EditRuleCriteriaComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => { 
      this.where_rule_strings[this.whereIdx] = this.where_rule_strings[this.whereIdx].replace(result.data.edit_criteria.trim(), result.data.updated_criteria);
      this.form.controls["rule_type_string"].setValue(this.where_rule_strings.join(" "));
      this.form.controls["rule_type_string"].markAsDirty();

    });
  }
  editCriteria111111111111 = () =>{
    
    const sel = window.getSelection();
    let strt_pos = this.txtRule.nativeElement.selectionStart;
    let end_pos = this.txtRule.nativeElement.selectionEnd;
    const r_string = sel?.toString();

    
    var start = sel.anchorOffset;
      var end = sel.focusOffset;

      
      console.log(r_string?.indexOf("<br>"));

    console.log(strt_pos, end_pos, start, end)


    let rule_string = r_string?.trim().replace('<br>', ' ')  //.replace(/[\n]/g, '').replace(/\s+/g, ' ');
    console.log(rule_string);
    rule_string = rule_string?.replace(/ OR /g, "|").replace(/ AND /g, "|").replace(/ and /g, "|"); //replace all " OR " to "|" for using split 
    let rule_string_ary = rule_string?.split("|")
    
    console.log("==============================")
    console.log(rule_string)
    console.log("ddddddddddddddddddddddddddddddd", this.rule_strings)
    const dialogConfig  = new MatDialogConfig();
    dialogConfig.data   = {'profile_string': rule_string_ary, 'rule_strings': this.rule_strings};
    dialogConfig.width  = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxHeight  = '100vw';
    dialogConfig.maxHeight  = '100vh';
    dialogConfig.panelClass = 'full-screen-modal';
    
  
    const dialogRef = this._dialog.open(EditRuleCriteriaComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => { 
      console.log(result)
      console.log(this.rule_strings)
//ivar //this.rule_strings = result.data.rule_strings;
      this.display_rule_strings = "<span>" + result.data.rule_strings.replace(/ OR /g, "</span> OR <span> ").replace(/ AND /g, " AND <br> ").replace(/ and /g, " AND <br> ");
      
      this.form.controls["rule_type_string"].setValue(result.data.rule_strings);
      this.form.controls["rule_type_string"].markAsDirty();
    });
  }






  // *********************************************************************
  // *********************** Edit Rule Management ************************
  // *********************************************************************

  public selected_rule_type;
  public old_rule_name;
  public rules_row:any        = [];
  

  protected getRuleData = (rule_id: any) =>{
    this.editRowLoading = true;
    this.http.getData(this.url.busrules_rule_row + rule_id).subscribe((data: any) => {
      try{
      // this.rules_row = data.data;
      this.editRowLoading = false;
      this.selected_rule_type = this.rules_row.ruletype_id;
      // this.form.controls["rule_id"].setValue(data.data.rule_id);
      this.form.controls["rule_type_string"].setValue(data.data.rule_string.replace(/[\n]/g, ' '));
      this.form.controls["rule_name"].setValue(data.data.rule_name);
      this.old_rule_name = data.data.rule_name;
      this.form.controls["rule_description"].setValue(data.data.rule_description);
      this.form.controls["table_name"].setValue(data.data.table_name);
      this.form.controls["delimiter_rule_string"].setValue(data.data.delimiter_rule_string);
      this.form.controls["rule_type_id"].setValue(data.data.ruletype_id);

      this.table_names  = this.form.controls["table_name"].value.split(",");
//       console.log(this.rule_strings)
// //ivar //this.rule_strings = this.form.controls["delimiter_rule_string"].value.split(";");
//       this.rule_strings.pop();
//       this.rule_strings.shift();
      


      //ivar
      this.rule_strings = data.data.rule_string;//.replace(/ OR /g, " OR <br>");
      // this.display_rule_strings = "<span style='background-color:#faffbd'>" + data.data.rule_string.replace(/ OR /g, "</span> OR <span style='color:red'> ").replace(/ AND /g, "</span> AND <span> ").replace(/ and /g, "</span> OR <span> ");
      //this.display_rule_strings =  data.data.rule_string.replace(/ OR /g, "</span> OR <br> <span style='color:red'> ").replace(/ AND /g, "</span> AND <span> ").replace(/ and /g, "</span> OR <span> ");

      
      let tmpWhere = data.data.rule_string.replace(/ OR /g, "| OR | ").replace(/ AND /g, "| AND |").replace(/ and /g, "| OR |");
      this.where_rule_strings =  tmpWhere.split("|");
      }catch(e){
        console.log(e)
      }
      //console.log(this.display_rule_strings)
      //this.profile_string = data.data.rule_strings;
      // this.rule_strings_updt = data.data.rule_strings_updt;
      
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  editSaveRule = (frm) => {
    
    if(this.form.invalid){
      return;
    }
    this.editRowLoading = true;
    const ruleData:any = JSON.stringify(frm.value, null, 4);
    const data = JSON.parse(ruleData);
    
    // data.delimiter_rule_string = "STR_START;" + this.rule_strings.join(";") + ";STR_END";
    
    
    this.http.putData(this.url.busrules_edit_rule_row, {}, data).subscribe((res: any) => {
      if(res.data.edit_flag == '0'){
        this.toastr.success("Buss Rule Management edited successfully !");
        this.form.reset();
        this._dialog.closeAll();
        this.getData();
        this.modalService.close();
        this.whereIdx = null;
        this.whereItem = '';
        this.where_rule_strings = [];
        this.editRowLoading = false
      }else{
        this.toastr.error(res.message);
        this.editRowLoading = false;
      }
    });
  }

  editCheckName = (event) =>{
    
    if(this.old_rule_name == this.form.controls["rule_name"].value){
      return;
    }
    const rulName = this.form.controls["rule_name"].value;
    this.rule_loading = true;
    this.http.getData(this.url.busrules_chk_save + rulName).subscribe((res:any)=>{
      this.rule_loading = false;
      if(parseInt(res.data.flag_save) == 1){
        this.form.controls["rule_name"].setErrors({"duplicate": "Rule Name already exists"});
      }
    });
  }
  

  selectedWhere = (event, idx, item) =>{
    // this.renderer.removeClass("span", "selected");
    
    this.whereIdx = idx;
    this.whereItem = item;
    this.renderer.addClass(event.target,"selected");
    
  }

  @HostListener("window:onselect", ["$event"])  selectText(e: Event)
  {
    
      let start = this.txtRule.nativeElement.selectionStart;
      let end =  this.txtRule.nativeElement.selectionEnd;
      // start = e.target.selectionStart;
      // end = e.target.selectionEnd;
      alert(e.target)//.value.substring(start, end));
  }

  spanClick(event){
    console.log(event)
  }

  ngOnInit(): void {
    this.getData();

  }
  

}

