import { Component, Inject, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributemanagementService } from 'src/app/services/attributemanagement.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { BillingAttributes } from 'src/app/models/attributes';
import { DialogService } from 'src/app/services/dialog.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-billingindicator-management-dialog',
  templateUrl: './billingindicator-management-dialog.component.html',
  styleUrls: ['./billingindicator-management-dialog.component.css']
})
export class BillingindicatorManagementDialogComponent implements OnInit {
  message;
  title;
  addAttr: boolean;
  attr;
  aum_code;
  aum_dim_name;
  aum_dim_desc;
  aum_status;
  id;
  fee;
  saveBillingAttr: any;
  editBillingAttr: any;
  billingAttr: any;
  resultBilling: any;
  selectedBillingIndicator: BillingAttributes[] = [];
  activatedSuccessfully: boolean;
  code; desc; name; status; action;
  isReadOnly: boolean = false;
  isActive: boolean = false;
  isDialogOpen: boolean = false;
  public pageDirty: boolean = false;
  public data: any;
  @Output() formResponse = new EventEmitter<any>();

  constructor(
    // public dialogRef: MatDialogRef<BillingindicatorManagementDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private attributeService: AttributemanagementService, 
    private toastr: ToasterService, 
    private confirmDialog: DialogService) {
  }

  ngOnInit(): void {
    this.title = this.data.activeAttr;
    this.message = this.data.message;
    this.addAttr = this.data.addAttr;
    this.attr = this.data.attr;
    this.code = this.data.code;
    this.desc = this.data.desc;
    this.name = this.data.name;
    this.action = this.data.action;
    this.status = this.data.status;
    this.id = this.data.id;
    this.fee = this.data.fee;
    console.log(this.data);
    if (this.action == "Edit") {
      this.isReadOnly = true;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
        if (this.action == "Edit") {     
      if (this.desc != this.data.desc || this.name != this.data.name || this.fee != this.data.fee) {
        this.pageDirty = true;
      }
    }
    else {
      if ((this.code != undefined || this.desc != undefined || this.name != undefined || this.fee != undefined)
      && (this.code != "" && this.desc != "" && this.name != "" && this.fee !="")) {
        this.pageDirty = true;
      }
    }    
    if (this.pageDirty) {
      return this.confirmDialog.confirm("Do you want to discard the changes?");
      
    }
    return true;  
}
fromParent = (data) =>{
  this.data = data;
  this.title = data.activeAttr;
  this.message = data.message;
  this.addAttr = data.addAttr;
  this.attr = data.attr;
  this.code = data.code;
  this.desc = data.desc;
  this.name = data.name;
  this.action = data.action;
  this.status = data.status;
  this.fee = this.data.fee;
  this.isReadOnly = false
  if (this.action == "Edit") {
    this.id = data.id;
    this.isReadOnly = true;
  }
  console.log(this, data)
}
ClearControls() {
  this.aum_code = "";
  this.aum_dim_name = "";
  this.aum_dim_desc = "";
}

SaveAttributes() {
  // this.getAttributes();   
  this.selectedBillingIndicator = []
  if (this.action == "Edit") {
    this.editBillingAttr = {
      aum_id: this.id,
      aum_code: this.code,
      aum_dim_name: this.name,
      aum_dim_desc: this.desc,
      max_annual_fee_rt: this.fee
    }
    console.log("Edit: " + this.action);
    this.attributeService.updateBillingAttributes(this.editBillingAttr).subscribe(res => {
      if (res) {
        this.activatedSuccessfully = true;
        if (this.activatedSuccessfully == true) {
          this.toastr.success("Attribute Updated");
          this.ClearControls();
        }
      }
      this.formResponse.emit({pagedirty: false, msg: 'save'})
      // this.dialogRef.close(this.selectedBillingIndicator);
    },
      err => {
        this.activatedSuccessfully = false
        this.toastr.error(err.error.message);
      }
    );
  }
  else {
    // console.log("Add: " + this.action);
    this.saveBillingAttr = {
      aum_code: this.code,
      aum_dim_name: this.name,
      aum_dim_desc: this.desc,
      parentid: null,
      aum_sort_order: 0,
      levelno: 0,
      in_levelno: 0,
      max_annual_fee_rt: this.fee,
      status: "Inactive"

    }

    // console.log(this.selectedCrossholdingIndicator);
    this.attributeService.saveBillingAttributes(this.saveBillingAttr).subscribe(res => {
      this.toastr.success("Attribute Added");
      this.attributeService.getCrossholdingIndicatorList().subscribe(result => {
        this.resultBilling = result.data;
        //  console.log(this.resultCrossholdingIndicator);
        for (let i = 0; i <= this.resultBilling.length - 1; i++) {
          this.selectedBillingIndicator.push({
            "value": this.resultBilling[i].aum_xhld_indicator_code,
            "viewValue": this.resultBilling[i].aum_xhld_indicator_name,
            "description": this.resultBilling[i].aum_xhld_indicator_desc,
            "active": this.resultBilling[i].aum_xhld_indicator_status,
            "id": this.resultBilling[i].aum_xhld_indicator_dim_id,
            "sortOrder": this.resultBilling[i].aum_sort_order,
            "fee": "0"
          });
        }
        this.formResponse.emit({pagedirty: false, msg: 'save'})
        /*
        for (let i = 0; i <= this.selectedBillingIndicator.length - 1; i++) {
          // console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value + "  --  " + this.saveBrandAttr["aum_code"]);
          if ((this.selectedBillingIndicator[i].active == "Active")
            && (this.selectedBillingIndicator[i].value.toLowerCase() != this.saveBillingAttr["aum_code"].toLowerCase())) {
            //console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value.toLowerCase() + "  --  " + this.saveBrandAttr["aum_code"].toLowerCase());
            let xholdStatus =
            {
              dim_code: this.selectedBillingIndicator[i].value,
              status: "Inactive"
            }            
          }
        } */
        // this.dialogRef.close(this.selectedBillingIndicator);
      });
    }),
      err => {
        // this.activatedSuccessfully = false
        this.toastr.error(err.error.message);
      }
  }
}

onNoClick() {
  const ans = this.canDeactivate();
  this.pageDirty=false;
  if(ans){
    this.formResponse.emit({pagedirty: false, msg: 'close'})
    // this.dialogRef.close(this.isDialogOpen);
  }
  this.isDialogOpen = false;
  
}

change(event){
  this.pageDirty = true;
  this.formResponse.emit({pagedirty: true, msg: 'form changed'})
}

@HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
  if (this.pageDirty) 
  event.returnValue = false;
}

}
