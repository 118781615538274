import { Injectable } from '@angular/core';
// import { environment, API_BASE_URL, UPLOAD_FILE_BASE_URL } from '../../../environments/environment';
@Injectable()
export class URLHelper {
    constructor() {

    }

    // private env: string = this.httpService.getCurrentEnvironment();
    // private Server: string = this.getServerUrl(this.env);

    

    // Collection of Bussiness rule management APIs
    public User                     = 'api/User';
    public busrules_show            = 'busrules/show';
    public busrules_update          = 'busrules/update';
    public busrules_delete          = 'busrules/delete';
    public busrules_sort_order      = 'busrules/sort_order';
    public busrules_show_type       = 'busrules/show_type';
    public busrules_var_show        = 'busrules/var_show';
    public busrules_valid_operators = 'busrules/valid_operators';
    public busrules_variable_show   = 'busrules/variable_show';
    public busrules_insert_rule_row = 'busrules/insert_rule_row';
    public busrules_edit_rule_row   = 'busrules/edit_rule_row';
    public busrules_rule_row        = 'busrules/rule_row?rule_id=';
    public busrules_chk_save        = 'busrules/rule_check_save?rule_name='


    //Collection of CrossHolding profile management APIs
  
    public xhold_profile_get                = 'xhld_profiles/get_profile_names';
    public xhold_profile_attr               = 'xhld_profiles/display_attributes';
    public xhold_profile_attr_values        = 'xhld_profiles/display_attribute_values';
    public xhold_profile_valid_operators    = 'xhld_profiles/valid_operators';
    
    public xhold_profile_accts              = 'xhld_profiles/get_profile_accounts';
    public xhold_profile_save               = 'xhld_profiles/rule_save';
    public xhold_profile_sort               = 'xhld_profiles/rule_sort';
    public xhold_profile_disp               = 'xhld_profiles/display_profile_edit/';
    public xhold_profile_refresh_Accounts   = 'xhld_profiles/refresh_accounts/';
    public xhold_profile_edit               = 'xhld_profiles/rule_edit';
    public xhold_profile_sort_name          = 'xhld_profiles/sort_profile_names';
    public xhold_profile_active             = 'xhld_profiles/get_active_profile_names';
    public xhold_profile_status             = 'xhld_profiles/change_profile_status';
    public xhold_profile_unassigned         = 'xhld_profiles/get_unassigned_accounts';
    public xhold_profile_chk_save           = 'xhld_profiles/profile_chk_save/';
    public xhold_profile_chk_validate       = 'xhld_profiles/validate_profile_edit/';

    //xhold rules management
    public xhold_rules_get_report_styles    = 'xhld_profiles/get_report_styles'
    public xhold_rules_disp                 = 'xhld_profiles/get_aum_rules/'   
    public xhold_rules_insert               = 'xhld_profiles/insert_aum_rules'

    //Capital activity crossholding rules
    public xhold_capital_rules_disp         = 'xhld_profiles/rules_disp/'   
    public xhold_capital_rule_edit          = 'xhld_profiles/cap_act_rule_edit/'
    public xhold_cap_acct_rules             = 'xhld_profiles/disp_cap_acct_rules'
    public xhold_cap_acct_vars              = 'xhld_profiles/disp_cap_acct_vars'
    public xhold_cap_act_rule_update        = 'xhld_profiles/cap_act_rule_update'
    public xhold_cap_act_rule_select        = 'xhld_profiles/sel_cap_acct_rule'


    //xtrade rules
    public xhold_xtrd_get_xtrd_rules        = 'xhld_profiles/get_xtrd_rules/'
    public xhold_xtrd_rule_insert           = 'xhld_profiles/xtrade_ins_rules'

    //Data Loads
    public data_loads_download              = 'dataloads/download'
    public data_loads_load_only             = 'dataloads/load_only'
    public data_loads_check_in_use          = 'dataloads/check_in_use/'
    public data_loads_locked_reports        = 'dataloads/locked_reports'
    public data_loads_unlock_in_use         = 'dataloads/unlock_in_use'
    public data_loads_get_set_in_use        = 'dataloads/get_set_in_use'
    
    public data_loads_monthend_snapshot     = 'monthend/take_me_snapshot'
    public data_loads_check_snapshot        = 'monthend/check_me_snapshot/'

    //SNS
    public admin_send_sns                   = 'templates/sns'

    //templates (value_list)
    public value_list                       = 'valuelists/list'
    public value_list_add                   = 'valuelists/add'
    public value_list_edit                  = 'valuelists/edit'
    public value_list_update                = 'valuelists/update'
    public value_list_types                 = 'valuelists/types'

    //PMA Logs

    public pma_logs                         = 'valuelists/logs'

    //timeout
    public timeouturl                   = 'health_check'
}
