// This file contains all constants that are used across the application

export class GlobalConstants {

  public static CARS_USER_GROUPS = [
    'CARS-AUM-Users',
    'CARS-Perf-Users',
    'CARS-Sec13-Users',
    'CARS-ReadOnly-Users',
    'CARS-Maint-Users'
  ];

  public static readOnlyUser = 'CARS-ReadOnly-Users';
}