import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class XhldProfileAssocSdimService extends BaseService {

  constructor(private inject: HttpClient) {
    super(inject);
  }

  getProfileassocInfo(account_no:string,class_code:string): Observable<any> {
   
    let resource = "/accounts/shareclass/get_profile_assoc_history?acct_number="+ account_no + "&share_class_code=" + class_code;
   
    return this.getRequest<any>(resource);
  }
  copyHistoryprofileRecord(accountinfo: any){
    let resource = "/accounts/shareclass/copy_history_profile_assoc";
    //console.log(resource);
    return this.postRequest(resource, accountinfo);
  }
  deleteHistoryprofileRecord(prof_assoc_sdim_key : string){
   let resource = "/accounts/shareclass/delete_history_profile_assoc/" + prof_assoc_sdim_key ;
  
   return this.deleteRequest(resource);
  }

  getProfileNames(): Observable<any> {
   
    let resource = "/xhld_profiles/get_profile_names";
   
    return this.getRequest<any>(resource);
  }
  savexhldProfileAssoc(accountObject: any){
    let resource = "/accounts/shareclass/update_history_profile_assoc";
    //console.log(resource);
    return this.putRequest(resource, accountObject);
  }
  validateprofileassoc(account_no:string,class_code:string): Observable<any> {
   
    let resource = "/accounts/shareclass/validate_history_profile_assoc?acct_number="+ account_no + "&share_class_code=" + class_code;
   
    return this.getRequest<any>(resource);
  }
}
