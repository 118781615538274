import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sns',
  templateUrl: './sns.component.html',
  styleUrls: ['./sns.component.css']
})
export class SnsComponent implements OnInit {

  constructor(private http: HttpService, private url: URLHelper, private toastr: ToastrService) { }
  public message;
  public subject;


  sendSNS(){

    const body = {
      subject: this.subject,
      message: this.message
    }
    this.http.postData(this.url.admin_send_sns, {}, body).subscribe(res=>{
      console.log(res)
      this.toastr.success("Notification send successfully !")
    })
  }
  ngOnInit(): void {
  }

}
