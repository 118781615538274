import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-load-acct-details',
  templateUrl: './load-acct-details.component.html',
  styleUrls: ['./load-acct-details.component.css']
})
export class LoadAccountDetailsComponent implements OnInit {
  templatename;
  download;
  index;
  constructor( private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.templatename = params['a'];
      this.download = params['b'];
      this.index = params['c'];
    });

   // console.log(this.templatename);
  }

}

