<div class="row">
  <div class="col-lg-6">
      <h1>Value List</h1>
  </div>
  <div class="col-lg-6 text-right">
      <mat-label>Please select a filter : </mat-label>
      <mat-form-field appearance="fill">
          <mat-label>Filter by List Types</mat-label>
          <mat-select [(value)]="list_type" [(ngModel)]="list_type" #invstObj="ngModel" no-space (selectionChange)="getListByType($event.value)">
            
            <input type="text" placeholder="search" class="search" (keyup)="typeChange($event)"  (keydown.escape)="clearsearch($event)">

            <mat-icon class="search-icon">search</mat-icon>
            
            <mat-option *ngFor="let type of types | type : typeSearchTxt" [value]="type.type">
                {{ type.type }}</mat-option>
          </mat-select>
      </mat-form-field>
      <mat-icon class="clear" matTooltip="Clear Filter" (click)="getListByType('')">cancel</mat-icon>
  </div>
</div>



<div class="row" [ngClass] = "{'loading': loading}">
    <mat-card class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <table class="example-container" mat-table id="listData" #tempsort="matSort"
        [dataSource]="lists" matSort>
        
        <ng-container matColumnDef="list_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
  
        <ng-container matColumnDef="list_code">
          <th mat-header-cell *matHeaderCellDef> Code </th>
          <td mat-cell *matCellDef="let element"> {{element.code}} </td>
        </ng-container>

        <ng-container matColumnDef="list_type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="list_desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef align="center"> 
            <mat-icon (click)="addList()" matTooltip="Add Value List" class="add-icon">add</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element" align="center"> 

            <ng-container align="center">
              <mat-icon class="edit-icon" matTooltip = "Edit Value List" (click)="editList(element)">edit</mat-icon>
              
            </ng-container>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="listColumns; sticky: false"></tr>
        <tr mat-row *matRowDef="let row; columns: listColumns;"></tr>
  
      </table>
  
      <div *ngIf="lists.data.length === 0" class="no-records alert alert-info text-center">
        No records found
      </div>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]" [length]="total" showFirstLastButtons></mat-paginator>
    </mat-card>

</div>