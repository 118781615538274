import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { GlobalConstants } from '../../appconstants'

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  protected username;
  constructor(private inject: HttpClient, private _router: Router) {
    super(inject);
    this.username = sessionStorage.getItem('username');
  }

  protected headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  protected httpOptions = { headers: this.headers };
  userInfo: any;
  groupInfo: any;
  userGroups = {};

  getLocalUser() {
    while (!this.username) {
      this.username = prompt('Please enter your username');
      if (this.username) {
        sessionStorage.setItem('username', this.username);
      }
    }
  }

  getCurrentUserDetails() {
    ///users/currentuser
    const dt = new Date();
    let dtExp = new Date(dt.getTime() + 30 * 60000);
    console.log(dtExp);
    sessionStorage.clear();
    sessionStorage.setItem('key', JSON.stringify({ exp: dtExp, user: 'res' }));
    console.log(sessionStorage.getItem('key'));
 
    forkJoin({
      user: this.getRequest<any>('/dataloads/userinfo'),

      group: this.getGroup(),
    }).subscribe((res: any) => {
      
      if (res.user.data && res.user.data.name) {
        this.userInfo = res.data;

        sessionStorage.setItem(
          'key',
          JSON.stringify({ exp: dtExp, user: res.user.data })
        );
      } else {
        this._router.navigate(['/forbidden']);
      }

      //*******************group response ******************/

      this.groupInfo = res.group.data;
      
      GlobalConstants.CARS_USER_GROUPS.map((grp) => {
        this.userGroups[grp] = false;
      this.groupInfo.map((rec: any) => {   
          if (rec.groupname.indexOf(grp) >= 0) {
            this.userGroups[grp] = true;
          }
        });
      });

      localStorage.setItem('userGroups', JSON.stringify(this.userGroups));
    });
  }

  getUserGroups() {
    return JSON.parse(localStorage.getItem('userGroups'));
  }

  checkExpired(): boolean {
    if (sessionStorage.getItem('key')) {
      var exp: any = JSON.parse(sessionStorage.getItem('key'));
      if (new Date() > new Date(exp['exp'])) return true;
    }

    return false;
  }

  reSet() {
    sessionStorage.clear();
  }

  getGroup(): Observable<boolean> {
    return this.getRequest<any>('/templates/okta_usergroups');
  }

  getToken() {
    var tkn: any = JSON.parse(sessionStorage.getItem('key'));
    const stkn = tkn['token'];
    return `Bearer ${stkn}`;
  }
}
