import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ShareclassService extends BaseService {

  constructor(private inject: HttpClient) {
    super(inject);
  }

  getCategories(): Observable<any> {
    let resource = "/templates/list/AUM_Loads_Cat";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  getBillingInd(): Observable<any> {
    let resource = "/attributes/billingind/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load canadaman list
  getApprovalgrp(): Observable<any> {
    let resource = "/attributes/approvalgrp/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load lpmg list
  getfreqCode(): Observable<any> {
    let resource = "/attributes/updatefreq/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  //load grpmagr list
  getAccountstatus(): Observable<any> {
    let resource = "/attributes/acctstatus/list";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  getShareClass(): Observable<any> {
    let resource = "/accounts/share_classes";
    //console.log(resource)
    return this.getRequest<any>(resource);
  }
  getShareClassInfo(account_no:string,class_code:string): Observable<any> {
   
    let resource = "/accounts/share_classes_info?acct_number=" + account_no + "&class_code=" + class_code;
   
    return this.getRequest<any>(resource);
  }
  acctShareFlag(acctDimId:number,shareDimId:number): Observable<any> {
    let resource = "/accounts/acct_share_flag?acct_dim_id="+ acctDimId + "&share_class_dim_id=" + shareDimId;
    //console.log(resource)
    return this.getRequest<any>(resource);
  }

  saveShareClassAttributes(classObject: any){
    let resource = "/accounts/shareclass/set_attrib";
    //console.log(resource);
    return this.putRequest(resource, classObject);
  }
}
