import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-final-asset-amount-adjustment',
  templateUrl: './final-asset-amount-adjustment.component.html',
  styleUrls: ['./final-asset-amount-adjustment.component.css']
})
export class FinalAssetAmountAdjustmentComponent implements OnInit {

  constructor(private http: HttpService) { }

  public attributes: any[];
  public dataSource: MatTableDataSource<any>;
  public displayedColumns = ["first_effective_date", "last_effective_date", "inspection_date", "status"];


  protected getFinalAsset = () =>{
    this.http.getData('http://localhost:5000/pmaaum/api/v1.0/attributes/').subscribe((data: any) => {
      console.log(data);
      this.attributes = data.data;
    });
  }
  ngOnInit(): void {
    this.getFinalAsset();
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.attributes)
  }

}


/*
import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../../interface/table-column';

import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-final-asset-amount-adjustment',
  templateUrl: './final-asset-amount-adjustment.component.html',
  styleUrls: ['./final-asset-amount-adjustment.component.css']
})
export class FinalAssetAmountAdjustmentComponent implements OnInit {

  constructor(private http: HttpService) { }

  public attributes: any[];
  public _dataSource = new MatTableDataSource([]);
  public displayedColumns: string[];// = ["first_effective_date", "last_effective_date", "inspection_date", "status"];

  @Input() columns: TableColumn[];
  
  @Input() setDataSource(data: any[]){
    this.setDatasource(data);
  }

  protected getFinalAsset = () =>{
    this.http.getData('http://localhost:5000/pmaaum/api/v1.0/attributes/').subscribe((data: any) => {
      console.log(data);
      this.attributes = data.data;
    });
  }
  ngOnInit(): void {
    this.getFinalAsset();
  }

  ngAfterViewInit() {
    // this.dataSource = new MatTableDataSource(this.attributes)
  }
  //*************set Data source ************
  setDataSource(data: any){
    this._dataSource = new MatTableDataSource<any>(data);
  }
}

*/