<mat-card>
    <mat-card class="example-card text-center">
        <h3>AUM_ACCT_SDIM Historical Account Attributes</h3>
    </mat-card>
    <mat-card class="example-card text-center">
        <div class="row">          
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToAccountPage()">Show Current Acct Attributes</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="loadDetailsPanel()">View</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToAcctSdm()">Show ACCT SDIM</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="copyAsNewRecord()" >Copy As New Record</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="deleteRecord()">Delete Record</button>
            </div>
        </div>
        <br>
        <div class="row">
            <div *ngIf="isLoading" >         
                <mat-spinner class="loader"></mat-spinner>
                  </div>
            <div class="component data component-card ">              
                    <table border="0" width="100%" cellpadding="0" cellspacing="0" mat-table [dataSource]="loadTemplateDatasource"
                     matSort>
               <ng-container matColumnDef="radio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-width"></th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">
                    
                             <mat-radio-button color="primary" [checked]="element.aum_acct_sdim_key == selectedrecord"
                             [value]="element" (change)="viewAttribute($event)">
                            </mat-radio-button> 
                        </td> 
                    </ng-container>
                    <ng-container matColumnDef="firstEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> First Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">
                            {{element["aum.first_effective_date"] | date: 'MM/dd/yyyy hh:mm:ss a'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="lastEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Last Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element["aum.last_effective_date"] | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

                    <!-- Template Download Column -->
                    <ng-container matColumnDef="brandName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Brand Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_brand_name"]}} </td>
                    </ng-container>
                    

                    <!--Template Upload Column -->
                    <ng-container matColumnDef="brandID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Brand ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_brand_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="invstAdvisorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Invst Advisor Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element["aum.aum_invst_adv_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="invstAdvisorID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Invst Advisor ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_invst_adv_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="invstObjectName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Invst Obj Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width">{{element["aum.aum_invst_obj_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="invstObjectID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Invst Obj ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_invst_obj_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="vehicleName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Vehicle Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_vehicle_type_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="vehicleID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Vehicle ID</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_vehicle_type_dim_id"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="regionName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Region Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_region_name"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="regionID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> Region ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_region_dim_id"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="portfolioMandateName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2"> Portfolio Mandate Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_port_man_name"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="portfolioMandateID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2"> Portfolio Mandate ID</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_port_man_dim_id"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pvtClientTypeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2"> Pvt Client Type Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_pct1p_name"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pvtClientTypeID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Pvt Client Type ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_pct1p_dim_id"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="salesOfficeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Sales Office Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_sale_off_name"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="salesOfficeID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Sales Office ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_sale_off_dim_id"]}}</td>
                    </ng-container>
                    <ng-container matColumnDef="businessUnitName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Business Unit Name</th>
                        <td mat-cell *matCellDef="let element" class="custom-width1">{{element["aum.aum_busi_unit_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="businessUnitID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Business Unit ID</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_busi_unit_dim_id"]}}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="leadPortMgtGrpName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2"> Lead Port Mgt Grp Name</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_lpmg_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="leadPortMgtGrpID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2">Lead Port Mgt Grp ID</th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_lpmg_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="xHLDIndID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing"> XHLD Ind ID
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_xhld_indicator_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="xHLDIndName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> XHLD Ind Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_xhld_indicator_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="productTypeID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Product Type ID
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_product_type_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="productTypeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Product Type Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_product_type_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="canadianMandateID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2"> Canadian Mandate ID
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_canadian_mandate_dim_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="canadianMandateName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm2"> Canadian Mandate Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_canadian_mandate_name"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="groupMgrName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Group Mgr Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_group_mgr_name"]}}</td>
                    </ng-container>


                    <ng-container matColumnDef="groupMgrCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Group Mgr Code
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_group_mgr_id"]}}</td>
                    </ng-container>

                    <ng-container matColumnDef="groupMgrID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="spacing-sm"> Group MGR ID
                        </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element["aum.aum_group_mgr_dim_id"]}}</td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                    
                    <div *ngIf="loadTemplateDatasource.data.length === 0" class="no-records alert alert-info text-center">
                        No records found
                    </div>
                </table>
            
                <mat-paginator [pageSize]="10"
                showFirstLastButtons>
 </mat-paginator>
              
            </div>
        </div>
    </mat-card>
    <mat-card class="example-card text-center">
        <h3>Historical AUM_ACCT_SDIM Attributes Account Validation Results</h3>
        <div class="row">                     
           <div [innerHTML] = "validateinfo">          
            </div>
        </div>
    </mat-card>

</mat-card>
<mat-accordion class="example-headers-align prevent-click">
    <mat-expansion-panel #panel1 [hideToggle]="true">
        <mat-expansion-panel-header *ngIf="showView">
            <mat-panel-title style="font-size:20px;">
                AUM_ACCT_SDIM Historical Account Attributes Details
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-4">
                    <mat-form-field>
                        <mat-placeholder>TIS Account Num</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "accountNo" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>TIS Account Type</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "accountType" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>Account Name</mat-placeholder>
                        <input matInput type="text" [(ngModel)] = "accountName" [disabled]="true">
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        <mat-card class="example-card text-center authorize-click">
            
            <div class="row text-left">
                <div class="col-6 text-left">
                    <mat-form-field appearance="fill" style="text-align:left;width:450px;">
                        <mat-label>Investment Objective</mat-label>
                        <mat-select [(value)]= "investmentObjectid" [disabled]="!isEdit"  no-space>  
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchInvestObj($event)"  (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>

                            <mat-option *ngFor="let invstobj of invstObjList | search : varInvestText : 'aum_invst_obj_name' " 
                            [value] ="invstobj.aum_invst_obj_dim_id">{{ invstobj.aum_invst_obj_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6 text-left">
                    <mat-form-field>

                        <mat-placeholder>Investment ADv</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="investmentAdvname" [disabled]="true">
                       
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Brand</mat-label>
                        <mat-select [(value)]= "brandid" [disabled]="!isEdit"  no-space>   
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchBrand($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>
                                          
                            <mat-option *ngFor="let brand of brandfilterList | search : varBrandText : 'aum_brand_name' " 
                            [value] ="brand.aum_brand_dim_id">{{ brand.aum_brand_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Vehicle Type</mat-label>
                        <mat-select [(value)]= "vehicleTypeid" [disabled]="!isEdit"  no-space>  
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchVehicle($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>             
                             <mat-option *ngFor="let vehicle of vehicletypelist | search : varVehicleText : 'aum_vehicle_type_name' " 
                            [value] ="vehicle.aum_vehicle_type_dim_id">{{ vehicle.aum_vehicle_type_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Region</mat-label>
                        <mat-select [(value)]= "regionid" [disabled]="!isEdit"  no-space>
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchRegion($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>  
                            <mat-option *ngFor="let region of regionList | search : varRegionText : 'aum_region_name' " 
                            [value] ="region.aum_region_dim_id">{{ region.aum_region_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Group Manager</mat-label>
                        <mat-select [(value)]= "groupManagerid" [disabled]="!isEdit"  no-space>     
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchgrpmgr($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>              
                            <mat-option *ngFor="let grpmgr of grpmgrList | search : vargrpmgrText : 'mgr_first_name'" 
                            [value] ="grpmgr.mgr_dim_id">{{ grpmgr.mgr_first_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Business Unit</mat-label>
                        <mat-select [(value)]= "businessUnitid" [disabled]="!isEdit"  no-space>   
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchBusiness($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>                
                            <mat-option *ngFor="let business of businessList | search : varBusinessText : 'aum_busi_unit_name'" 
                            [value] ="business.aum_busi_unit_dim_id">{{ business.aum_busi_unit_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Lead Portfolio Mgmt Grp</mat-label>
                        <mat-select [(value)]= "leadPortfolioMgmtGrpid" [disabled]="!isEdit"  no-space>
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchLpmgt($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>              
                            <mat-option *ngFor="let lpmg of lpmgList | search : varLpmgtText : 'aum_lpmg_name'" 
                            [value] ="lpmg.aum_lpmg_dim_id">{{ lpmg.aum_lpmg_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Portfolio Mandate</mat-label>
                        <mat-select [(value)]= "portfolioMandateid" [disabled]="!isEdit"  no-space>    
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchPortman($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>                  
                            <mat-option *ngFor="let portman of portmanFilteredList | search : varPortmanText : 'aum_port_man_name'" 
                            [value] ="portman.aum_port_man_dim_id">{{ portman.aum_port_man_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Pvt Client Type 1 Parent</mat-label>
                        <mat-select [(value)]= "clientTypeParentid" [disabled]="!isEdit"  no-space>   
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchpct1p($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>                    
                            <mat-option *ngFor="let pctp of pctpList | search : varpct1pText : 'aum_pct1p_name'" 
                            [value] ="pctp.aum_pct1p_dim_id">{{ pctp.aum_pct1p_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Sales Office</mat-label>
                        <mat-select [(value)]= "salesOfficeid" [disabled]="!isEdit"  no-space>    
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchSalesOffice($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>                                                 
                          <mat-option *ngFor="let sales of salesOfficeList | search : varSalesOfficeText : 'aum_sale_off_name'" 
                            [value] ="sales.aum_sale_off_dim_id">{{ sales.aum_sale_off_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Crossholding Indicator</mat-label>
                        <mat-select [(value)]= "crossHoldingIndicatorid" [disabled]="!isEdit"  no-space>
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchIndicator($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>
                            <mat-option *ngFor="let xhld of xhldindFilterList | search : varIndicatorText : 'aum_xhld_indicator_name'" 
                            [value] ="xhld.aum_xhld_indicator_dim_id">{{ xhld.aum_xhld_indicator_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Product Type</mat-label>
                        <mat-select [(value)]= "productTypeid" [disabled]="!isEdit"  no-space>  
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchProductType($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>               
                            <mat-option *ngFor="let productype of producttypeList | search : varProdTypeText : 'aum_product_type_name'" 
                            [value] ="productype.aum_product_type_dim_id">{{ productype.aum_product_type_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Canadian Mandate</mat-label>
                        <mat-select [(value)]= "canadianMandateid" [disabled]="!isEdit"  no-space>
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchCanadian($event)" (keydown.escape)="clearsearch($event)">

                            <mat-icon class="search-icon">search</mat-icon>                
                            <mat-option *ngFor="let canada of canadamanList | search : varCanadianText : 'aum_canadian_mandate_name'" 
                            [value] ="canada.aum_canadian_mandate_dim_id">{{ canada.aum_canadian_mandate_name}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">               
                <div class="col-3">
                        <mat-form-field>
                            <mat-placeholder>First Effective Date</mat-placeholder>
                            <input matInput type="text" [(ngModel)] = "firstEffectiveDate" [disabled]="!isEdit">
                            <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                        <!-- <mat-label>First Effective Date</mat-label>
                        <input matInput [matDatepicker]="FirstEffectiveDatepicker"
                         [(ngModel)]="firstEffectiveDate" [disabled]="!isEdit">
                        
                        <mat-datepicker-toggle matSuffix [for]="FirstEffectiveDatepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #FirstEffectiveDatepicker></mat-datepicker> -->
                    </mat-form-field>
                </div>
                <div class="col-3" *ngIf="!enable">
                    <mat-form-field>
                        <mat-label>Last Effective Date</mat-label>
                        <input matInput type="text" [(ngModel)] = "lastEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                        <!-- <mat-label>Last Effective Date</mat-label>
                        <input matInput [matDatepicker]="LastEffectiveDatepicker"
                         [(ngModel)]="lastEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="LastEffectiveDatepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #LastEffectiveDatepicker></mat-datepicker> -->
                    </mat-form-field>
                </div>
            </div><br>
            <div class="row">
                <div class=" col-3">
                    <mat-form-field>
                        <mat-placeholder>Last Updated By</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="lastUpdatedBy" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field>
                        <mat-placeholder>Last Updated Date</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="lastUpdatedDate" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <button class="button btn btn-secondary" type="submit" [disabled]="!isEdit" (click)="saveAccountInfo()">Save</button>
                    <button  class="button" (click)="closePanel()">Cancel</button>
                </div>
            </div>
        </mat-card>

    </mat-expansion-panel>
</mat-accordion>