import { Component, Inject, OnInit, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributemanagementService } from 'src/app/services/attributemanagement.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { Attributes, AttributesGroup, SelectedAttributes } from 'src/app/models/attributes';
import { DialogService } from 'src/app/services/dialog.service';
import { Observable } from 'rxjs/internal/Observable';
import { ModalService } from '../../../services/modal.service';


@Component({
  selector: 'app-brand-management-dialog',
  templateUrl: './brand-management-dialog.component.html',
  styleUrls: ['./brand-management-dialog.component.css']
})
export class BrandManagementDialogComponent implements OnInit {
  message;
  title;
  addAttr: boolean;
  attr;
  aum_code;
  aum_dim_name;
  aum_dim_desc;
  aum_status;
  saveBrandAttr: any;
  editBrandAttr: any;
  BrandAttr: any;
  resultBrand: any;
  selectedAttr: SelectedAttributes[] = [];
  activatedSuccessfully: boolean;
  code; desc; name; status; action;
  isReadOnly: boolean;
  isActive: boolean = false;
  isDialogOpen: boolean = false;
  brandStatus;
  public pageDirty: boolean = false;
  public data:any;

  @Output() formResponse = new EventEmitter<any>();
  private elRef:ElementRef;

  constructor(
    // public dialogRef: MatDialogRef<BrandManagementDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private attributeService: AttributemanagementService,
    private toastr: ToasterService,
    private confirmDialog: DialogService,
    private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.title = this.data.activeAttr;
    this.message = this.data.message;
    this.addAttr = this.data.addAttr;
    this.attr = this.data.attr;
    this.code = this.data.code;
    this.desc = this.data.desc;
    this.name = this.data.name;
    this.action = this.data.action;
    this.status = this.data.status;
    if (this.action == "Edit") {
      this.isReadOnly = true;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    // console.log(this.code + " || " + this.desc + " ||" + this.name);
    // if (this.action == "Edit") {
    //   if (this.desc != this.data.desc || this.name != this.data.name) {
    //     this.pageDirty = true;
    //   }
    // }
    // else {
    //   if ((this.code != undefined || this.desc != undefined || this.name != undefined) &&
    //     (this.code != "" && this.desc != "" && this.name != "")) {
    //     this.pageDirty = true;
    //   }
    // }
    if (this.pageDirty) {
      return this.confirmDialog.confirm("Do you want to discard the changes?");
    }
    return true;
  }

  fromParent = (data) =>{
    this.title = data.activeAttr;
    this.message = data.message;
    this.addAttr = data.addAttr;
    this.attr = data.attr;
    this.code = data.code;
    this.desc = data.desc;
    this.name = data.name;
    this.action = data.action;
    this.status = data.status;
    this.isReadOnly = false
    if (this.action == "Edit") {
      this.isReadOnly = true;
    }
    
  }

  ClearControls() {
    this.aum_code = "";
    this.aum_dim_name = "";
    this.aum_dim_desc = "";
  }

  SaveAttributes() {
    // this.getAttributes();   
    this.selectedAttr = []
    if (this.action == "Edit") {
      this.editBrandAttr = {
        aum_code: this.code,
        aum_dim_name: this.name,
        aum_dim_desc: this.desc
      }
      // console.log(this.editBrandAttr);
      this.attributeService.updateBrandAttributes(this.editBrandAttr).subscribe(res => {
        if (res) {
          this.activatedSuccessfully = true;
          if (this.activatedSuccessfully == true) {
            this.toastr.success("Attribute Updated");
            this.ClearControls();
            this.formResponse.emit({pagedirty: false, msg: 'save'})
          }
        }
        // this.dialogRef.close(this.selectedAttr);
      },
        err => {
          this.activatedSuccessfully = false
          this.toastr.error(err.error.message);
        }
      );

    }
    else {
      this.saveBrandAttr = {
        aum_code: this.code,
        aum_dim_name: this.name,
        aum_dim_desc: this.desc,
        aum_sort_order: 0,
        parentid: null,
        levelno: 0,
        status: "Inactive"
      }
      this.attributeService.saveBrandAttributes(this.saveBrandAttr).subscribe(res => {
        this.toastr.success("Attribute Added");
        this.formResponse.emit({pagedirty: false, msg: 'save'})
        this.attributeService.getBrandList().subscribe(result => {
          this.resultBrand = result.data;
          for (let i = 0; i <= this.resultBrand.length - 1; i++) {
            this.selectedAttr.push({
              "value": this.resultBrand[i].aum_brand_code,
              "viewValue": this.resultBrand[i].aum_brand_name,
              "description": this.resultBrand[i].aum_brand_description,
              "active": this.resultBrand[i].aum_brand_status,
              "id": this.resultBrand[i].aum_brand_dim_id,
              "sortOrder": this.resultBrand[i].aum_sort_order
            });
          }
          /*
          //console.log(" Len12: " + this.saveBrandAttr["aum_code"]);
          for (let i = 0; i <= this.selectedAttr.length - 1; i++) {
            // console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value + "  --  " + this.saveBrandAttr["aum_code"]);
            if ((this.selectedAttr[i].active == "Active")
              && (this.selectedAttr[i].value.toLowerCase() != this.saveBrandAttr["aum_code"].toLowerCase())) {
              this.brandStatus =
              {
                dim_code: this.selectedAttr[i].value,
                status: "Inactive"
              }
              //console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value.toLowerCase() + "  --  " + this.saveBrandAttr["aum_code"].toLowerCase());
              this.attributeService.changeBrandStatus(this.brandStatus).subscribe(res => { },
                err => {
                  this.toastr.error(err.error.message);
                });
            }
          } */
          // this.dialogRef.close(this.selectedAttr);
        });
      }),
        err => {
          this.activatedSuccessfully = false
          this.toastr.error(err.error.message);
        }
    }
  }

  onNoClick() {
    this.isDialogOpen = false;
    const ans = this.canDeactivate();
    this.pageDirty=false;
    // if (ans) 
    {
      this.code = "";
      this.name = "";
      this.desc = "";
      this.formResponse.emit({pagedirty: false, msg: 'close'})
      // this.dialogRef.close(this.isDialogOpen);
    }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.pageDirty) event.returnValue = false;
  }
  // ngAfterViewInit(){
  //   this.elRef.nativeElement.querySelector('input#aum_code').addEventListener('input', (event)=>{
  //     console.log("event", event)
  //   })
  // }
  change(event){
    this.pageDirty = true;
    this.formResponse.emit({pagedirty: true, msg: 'form changed'})
  }
}
