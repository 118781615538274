import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CategoryModel, TemplateModel } from '../../models/dataloads';
import { MatTableDataSource } from '@angular/material/table';
import { DataloadService } from 'src/app/services/dataload.service';
import { MatSort, Sort } from '@angular/material/sort';
import { HttpClient, HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toastr.service';
import { HttpService } from '../../services/http.service';
import { Title } from '@angular/platform-browser';
import { URLHelper } from '../../services/url.helper';
import { DialogService } from '../../services/dialog.service';
import { Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-data-loads',
  templateUrl: './data-loads.component.html',
  styleUrls: ['./data-loads.component.css']
})
export class DataLoadsComponent implements OnInit {
  @ViewChild("tempsort", { static: true }) tempsort = new MatSort();
  isAutorefreshOn = true;
  // myControlData = new FormControl();
  public dataList: CategoryModel[];
  public templateList: TemplateModel[] = [];
  public templateList1: TemplateModel[] = [];
  public templateList2: TemplateModel[] = [];
  public templateList3: TemplateModel[] = [];
  public templates: any;
  selectedObject;
  loadTemplelatesColumns = ['list_desc', 'download', 'upload'];
  loadTemplateDatasource1: MatTableDataSource<any> = new MatTableDataSource(this.templateList1);
  loadTemplateDatasource2: MatTableDataSource<any> = new MatTableDataSource(this.templateList2);
  loadTemplateDatasource3: MatTableDataSource<any> = new MatTableDataSource(this.templateList3);
  uploadSuccess: boolean;
  private selectedFile: File;
  uploadListCode;
  public monthLoading:boolean = false;
  public months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  form: FormGroup;
  loadOnlyListCode;    
  isLoading: boolean = false;
  pageDirty: boolean = false;
  checkMonthEndLoading:boolean = false;
  monthEnd:boolean = false;
  monthEndMessage = '';
  checkInUseLoading:boolean = false;
  uploadItem:any;
  autoRefreshTimerSubscription: Subscription; 

  constructor(private dataloadService: DataloadService, 
              private http: HttpClient, 
              private toastr: ToasterService,
              private https: HttpService,
              private url: URLHelper,
              private titleService: Title,
              private confirmDialog: DialogService,
              private fb: FormBuilder) { 
                this.titleService.setTitle("CARS - Data Loads");

                let mn = new Date().getMonth();
                let yr = new Date().getFullYear();
                if(mn == 0){
                  mn = 11;
                  yr = yr - 1;
                }else{
                  mn = mn - 1;
                }

                this.form = this.fb.group({
                  rpt_month : [{value: mn, disabled: false}, [Validators.required]],
                  rpt_year  : [{value: yr, disabled:false}, [
                                      Validators.required, 
                                      Validators.minLength(4), 
                                      Validators.maxLength(4),
                                      Validators.pattern("^[0-9]*$")
                              ] ]
                });

                this.form.valueChanges.subscribe(res=>{
                  this.monthEnd = false;
                })
                this.autoRefreshTimerSubscription = timer(0, 60000).pipe( 
                  map(() => { 
                    if(this.isAutorefreshOn)
                      this.getTemplates();
                  }) 
                ).subscribe(); 
              }
  
  canDeactivate():Observable<boolean> | boolean{
    if(this.pageDirty){
      return this.confirmDialog.confirm("Discard changes for Data Loads?");
    }
    return true;
    
  }

  ngOnInit(): void {
    this.getTemplates();
    this.changeSortedColumn(); 
  }

  ngOnDestroy(): void { 
    this.autoRefreshTimerSubscription.unsubscribe(); 
  } 

  public getCategories() {
    this.dataloadService.getCategories()
      .subscribe(result => {
        this.dataList = result.data;
        //console.log(this.dataList);    
      }, err=>{
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });

  }
  
  public getTemplates() {
    let code: string = "";
    this.isLoading = true;  
    
    this.dataloadService.getTemplates(code).subscribe(
      (result) => {
        this.isLoading = false;
        this.templates = result.data;
        console.log(result.data);
        let thirdColumn = result.data.filter(i => i.load_only === 1);
        //console.log(thirdColumn.length)
        this.templateList1 = [];
        this.templateList2 = [];
        this.templateList3 = [];
        for (let i = 0; i <= thirdColumn.length - 1; i++) {
          this.templateList = [
            {
              extract_filename: thirdColumn[i]['list_type'],
              list_desc: thirdColumn[i]['list_desc'],
              load_only: thirdColumn[i]['load_only'],
              list_code: thirdColumn[i]['list_code'],
              list_id: thirdColumn[i]['value_list_id'],
              lock_status: thirdColumn[i]['lock_status'],
            },
          ];
          this.templateList3 = this.templateList3.concat(this.templateList);
        }
        let loadsresult = result.data.filter(i => i.load_only === 0);
        for (let i = 0; i <= loadsresult.length - 1; i++) {
          if (i < loadsresult.length/2) {
            this.templateList = [
              {
                extract_filename: loadsresult[i]['list_type'],
                list_desc: loadsresult[i]['list_desc'],
                load_only: loadsresult[i]['load_only'],
                list_code: loadsresult[i]['list_code'],
                list_id: loadsresult[i]['value_list_id'],
                lock_status: loadsresult[i]['lock_status'],
              },
            ];
            this.templateList1 = this.templateList1.concat(this.templateList);
          } else {
            this.templateList = [
              {
                extract_filename: loadsresult[i]['list_type'],
                list_desc: loadsresult[i]['list_desc'],
                load_only: loadsresult[i]['load_only'],
                list_code: loadsresult[i]['list_code'],
                list_id: loadsresult[i]['value_list_id'],
                lock_status:loadsresult[i]['lock_status'],
              },
            ];
            this.templateList2 = this.templateList2.concat(this.templateList);
          }
        }
        // console.log(result.data.length + " : " + this.templateList1.length + " : " + this.templateList2.length + " : " + this.templateList3.length);
        //console.log(result.data[0]["list_desc"]);
        this.loadTemplateDatasource1 = new MatTableDataSource(
          this.templateList1
        );
        this.loadTemplateDatasource1.sort = this.tempsort;
        this.loadTemplateDatasource2 = new MatTableDataSource(
          this.templateList2
        );
        this.loadTemplateDatasource2.sort = this.tempsort;
        this.loadTemplateDatasource3 = new MatTableDataSource(
          this.templateList3
        );
        this.loadTemplateDatasource3.sort = this.tempsort;
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(
          'Issue while retrieving data. Please try again or contact support.'
        );
      }
    );
  }


  changeSortedColumn() {
    const sortState: Sort = { active: 'list_desc', direction: 'asc' };
    this.tempsort.active = sortState.active;
    this.tempsort.direction = sortState.direction;
    this.tempsort.sortChange.emit(sortState);
  }

  onUpload(event){//}, itm: any = {}) {

    const item  = this.uploadItem;
    this.pageDirty = true;
    this.uploadListCode = item.list_code;
    this.selectedFile = event.target.files[0];
    const body = new FormData();
    body.append("report_id", item.list_code);
    body.append("file", event.target.files[0]);
    this.uploadSuccess = true;
    this.https.postData("dataloads/s3_upload", {}, body).subscribe((res:any)=>{
      this.pageDirty = false;
      this.uploadListCode = null;
      this.eref.nativeElement.value = "";
      if(res.status == 400){
        this.uploadSuccess = false;
        this.toastr.warning(res.message)
      }
      else if(res.status == 200){
          this.uploadSuccess = false;
          this.toastr.success("Processing has started...");
      }else{
        this.toastr.error("Upload error!")
      }
    }, (error: HttpErrorResponse)=>{
      this.pageDirty = false;
      this.uploadListCode = null;
      this.eref.nativeElement.value = "";
      this.uploadSuccess = false;
      // this.toastr.error('Uploaded file does not belong to same report ID.');
      if(error.error && error.error.message){
        this.toastr.error(error.error.message)
      }else{
        this.toastr.error("Upload error!")
      }
    })
  }

  download = (selectItem:any) =>{
    this.pageDirty = true;
    const fltr = this.templates.filter((item:any)=>{return item.value_list_id == selectItem.list_id})
    this.https.postData(this.url.data_loads_download, {}, {path: fltr[0].list_desc + '.xls'}).subscribe((res:any)=>{
      this.pageDirty = false;

      if(res.data == false){
        this.toastr.error('The template <' + fltr[0].list_desc + '.xls' + '> does not exist in S3 bucket');
      }else{
        const s = document.createElement("a");
        s.id = "dnld";
        s.href = res.data;
        document.getElementById("dwnld").innerHTML = "";
        document.getElementById("dwnld").appendChild(s);
        document.getElementById("dnld").click();
      }
    }, (error: HttpErrorResponse)=>{
      this.pageDirty = false;
      
      this.toastr.error('The template <' + fltr[0].list_desc + '.xls' + '> does not exist in S3 bucket');
    });
  }
  monthEndSnapshot = () =>{
    if(!this.form.valid){
      this.toastr.warning("Missing information");
      return;
    }
    this.pageDirty = true;
    let mn = this.form.controls["rpt_month"].value;
    let yr = this.form.controls["rpt_year"].value;

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  
    const body = {
      p_aum_rpt_month : months[mn],
      p_aum_rpt_year  : yr.toString()
    }
    this.monthLoading = true;
    this.isLoading = true;
    this.https.putData(this.url.data_loads_monthend_snapshot,{}, body).subscribe((res:any)=>{
      this.pageDirty = false;
      this.monthLoading = false;
      this.isLoading = false;
      this.toastr.success(res.message);
    }, err=>{
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  
  loadOnly =(item:any) =>{
    const body = {
      "report_id" : item.list_code
    }
    this.loadOnlyListCode = item.list_code;
    this.https.getData(this.url.data_loads_check_in_use + item.list_code).subscribe((res:any)=>{
        
        if(res.data.error_flag == true){
          this.toastr.error("This load type is currently locked by UserID(s): " + res.data.user);
          this.loadOnlyListCode = null;
          return;
        }else{
          this.https.postData(this.url.data_loads_load_only, {}, body).subscribe((res:any)=>{
            this.toastr.success('Processing has started...');
            this.loadOnlyListCode = null;
          });
        }
    }, (error: HttpErrorResponse)=>{
      this.loadOnlyListCode = null;
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });    
  }



  checkMeSnapshot = () =>{

    let mn = this.form.controls["rpt_month"].value;
    let yr = this.form.controls["rpt_year"].value;
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    
    var d = new Date (); 
    var curr_year = d.getFullYear ();
    var curr_month = d.getMonth ();
    this.monthEnd = false;
     if(parseInt(yr) > curr_year){
      this.form.controls["rpt_month"].setErrors({"futuremonth": "Future Month not allowed"});
      this.form.controls["rpt_year"].setErrors({"futureyear": "Future Year not allowed"});
      return;
    }else{
      if(parseInt(yr) == curr_year && mn > curr_month){
        this.form.controls["rpt_month"].setErrors({"futuremonth": "Future Month not allowed"});
        return;
      }
      this.form.controls["rpt_month"] .setErrors(null);
    }
  
    this.checkMonthEndLoading = true;
    const urlparam = this.url.data_loads_check_snapshot + months[mn] + "/" + yr;
    this.https.getData( urlparam).subscribe((res:any)=>{
      this.checkMonthEndLoading = false;
      if (res.data.out_rec_chk > 0){
        this.monthEnd = false;
        this.monthEndMessage = "Month end snapshot has been run on " + months[mn] + ", " + yr;
        this.toastr.error("Month-end snapshot has already been run on " + months[mn] + ", " + yr);
      }else{
        this.monthEnd = true;
        this.toastr.success("Month-end snapshot has not been run!")
      }
      setTimeout(()=>{
        this.monthEndMessage = '';
      }, 5000);
    }, err=>{
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    })
  }
  monthEndSnapshotOld = () =>{
    // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    // let mn = new Date().getMonth();
    // let yr = new Date().getFullYear();

    // if(mn == 0){
    //   mn = 11;
    //   yr = yr - 1;
    // }else{
    //   mn = mn - 1;
    // }
    // console.log(mn, months[mn], yr)
    // const body = {
    //   p_aum_rpt_month : months[mn],
    //   p_aum_rpt_year  : yr.toString()
    // }
    // this.monthLoading = true;
    // this.https.putData(this.url.data_loads_monthend_snapshot,{}, body).subscribe((res:any)=>{
    //   this.monthLoading = false;
    //   this.toastr.success(res.message);
    // });
  }
  @ViewChild('hiddenfileinput',{}) eref: ElementRef;
  checkInUse = (item:any) =>{
    this.uploadItem = item;
    this.checkInUseLoading = true;
    this.https.getData(this.url.data_loads_check_in_use + item.list_code).subscribe((res:any)=>{
      this.checkInUseLoading = false;
      if(res.data.error_flag == true){
        this.toastr.error("This load type is currently locked by UserID(s): " + res.data.user);
        this.getTemplates();
      }else{
        this.eref.nativeElement.click();
      }
    }, err=>{
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

}
