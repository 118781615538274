

<div>
    
    <mat-button-toggle-group value="SHR" name="attributes" aria-label="Font Style" #attributes="matButtonToggleGroup" (click)="onHideShow(attributes.value)">
      <mat-button-toggle *ngFor="let list of attributesList;" [value]="list.list_code">{{list.list_desc}}</mat-button-toggle>
  
    </mat-button-toggle-group>
  </div>

  
  <br><br>

<mat-card class="example-card" *ngIf="showAcct">  
    <mat-card-title>Select Account for Account Attributes</mat-card-title>       
    <div class="row">
        <div class="col-4">
            <mat-form-field class="width-100">
                <mat-placeholder>TIS Account Num</mat-placeholder>
                <input matInput type="search" [(ngModel)] = "accountNo" [disabled]="isReadOnly" >
            </mat-form-field>
        </div> 
        <div class="col-2">
            <button [disabled]="isReadOnly" mat-raised-button color="primary" (click)="loadClassComponents()">Show Record</button>            
        
            <button mat-raised-button color="primary" (click)="reSet()" >Reset</button>
        </div>
    </div>       
</mat-card>

<mat-card class="example-card1" *ngIf="showShr">  
    <mat-card-title>Select Account for Share Class Attributes</mat-card-title>          
    <div class="row">
        <div class="col-2">
            <mat-form-field>
                <mat-placeholder>TIS Account Num</mat-placeholder>
                <input matInput type="search" [(ngModel)] = "accountNo" [disabled]="isReadOnly">
            </mat-form-field>
        </div>
        <div class="col-2">
                <mat-form-field appearance="fill">
                <mat-label>Select Share Class</mat-label>
                <mat-select [(value)]="classcode" [disabled]="isReadOnly">
                    <input type="text" placeholder="search" class="search" 
                    [value]="varSearchTxt" 
                            (keyup)="varChange($event)" (keydown.escape)="clearsearch($event)">

                    <mat-icon class="search-icon">search</mat-icon>
                    <mat-option *ngFor="let shareclass of ShareClassList | valueSearch : varSearchTxt" 
                    [value] ="shareclass">{{ shareclass }} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <div class="col-2">

            <button mat-raised-button [disabled]="isReadOnly"  color="primary" (click)="loadClassComponents()">Show Record</button>            
        
            <button mat-raised-button color="primary" (click)="reSet()" >Reset</button>
        </div>
    </div>     
    
    
</mat-card>
