import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BaseService } from './base.service';
//import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AcctAumSdmService extends BaseService {

  constructor(private inject: HttpClient) {
    super(inject);
  }
  getAccountAcctSdmInfo(account_no:string): Observable<any> {
   
    let resource = "/accounts/get_aum_sdim_history/" + account_no;
   
    return this.getRequest<any>(resource);
  }
  copyHistoryAUMAcctRecord(accountinfo: any){
    let resource = "/accounts/copy_history_aum_acct_sdim";
    //console.log(resource);
    return this.postRequest(resource, accountinfo);
  }
  deleteHistoryAUMAcctRecord(account_no: string){
   let resource = "/accounts/delete_history_aum_acct_sdim/" + account_no;
   
   //return this.https.deleteData(resource, {}, accountinfo)
   return this.deleteRequest(resource);
  }
  saveAccountACCTAttributes(accountObject: any){
    let resource = "/accounts/update_history_aum_acct_sdim";
    //console.log(resource);
    return this.postRequest(resource, accountObject);
  }
  
  validateAUMACCTHistory(account_no:string): Observable<any> {
   
    let resource = "/accounts/validate_history_aum_acct_sdim?acct_number="+account_no;
   
    return this.getRequest<any>(resource);
  }
  // deleteHistoryAUMAcctRecord=(url:string, headers: any, body: any)=>{
  //   return this.http.delete(
  //     this.getServerUrl() + "/" + url,
  //     {
  //       headers:{
  //         'Content-Type': 'application/json'},
  //       body: body
  //     })
  // }
}
