import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { URLHelper } from '../../../services/url.helper';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-locks',
  templateUrl: './locks.component.html',
  styleUrls: ['./locks.component.css']
})
export class LocksComponent implements OnInit {
  locks = new MatTableDataSource<any>();
  report_id;
  constructor(
    private http: HttpService, 
    private url: URLHelper,
    private toastr: ToastrService
  ) { }
  loadTemplelatesColumns = ['report_name', 'in_use_by_userid', "icon"];

  getValueList (){
    this.http.getData(this.url.data_loads_locked_reports).subscribe((res:any)=>{
      this.locks.data = res.data;
      console.log(this.locks);
    });
  }

  unlockInUse(ele:any){
    this.report_id = ele.report_list_id;
    this.http.putData(this.url.data_loads_unlock_in_use, {}, {"reportid": ele.report_list_id}).subscribe(res=>{
      this.toastr.success("Unlock in Use successfully !")
      this.getValueList ();
      this.report_id = null;
    });
  }

  getSetInUse(ele:any){ 
    
    this.http.postData(this.url.data_loads_get_set_in_use, {}, {"reportid": ele.report_list_id}).subscribe(res=>{
      this.toastr.success("Get Set in Use successfully !");
      this.report_id = null
      this.getValueList ();
    });
  }

  ngOnInit(): void {
    this.getValueList();
  }

}



