import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { URLHelper } from '../../services/url.helper';
import { MatTableDataSource } from '@angular/material/table';
import { valueListModel } from '../../models/valuelists';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddListComponent } from '../value-list/add-list/add-list.component'
import { MatPaginator } from '@angular/material/paginator';
import { EditListComponent } from './edit-list/edit-list.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-value-list',
  templateUrl: './value-list.component.html',
  styleUrls: ['./value-list.component.css']
})
export class ValueListComponent implements OnInit {

  constructor(
    private http: HttpService,
    private url: URLHelper,
    private dialog: MatDialog
  ) { }
  pageDirty: boolean;
  // public lists:any = [];

  listColumns:any[] = ['list_id', 'list_code', 'list_type', 'list_desc', 'actions'];
  public lists = new MatTableDataSource<valueListModel>();
  public total = 0;
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  loading: boolean = false;
  types: any = [];
  list_type;
  typeSearchTxt;
  frm = 0;
  to = 100;

  getTemplates(frm = 0, to = 100, type=""){
    this.loading = true;
    const body = {
      from: frm,
      to: to,
      type: type
    }
    if(type == ""){
      this.lists.data = [] as valueListModel[];
      this.loading = false;
    }else{
      this.http.postData(this.url.value_list, {}, body).subscribe((res:any)=>{
        this.loading = false;
        this.lists.data = res.data.data as valueListModel[];
        this.total = res.data.total;
      });
    }
  }

  getTypes(){
    this.http.getData(this.url.value_list_types).subscribe((res:any)=>{
      this.types = res.data;     
    }, (error: HttpErrorResponse)=>{
      this.loading = false;
    })
  }

  addList = () =>{
    const dialogConfig          = new MatDialogConfig();
    dialogConfig.data           = {type: this.list_type};
    dialogConfig.width          = '70%';
    dialogConfig.height         = '100%';
    dialogConfig.maxHeight      = '50vw';
    dialogConfig.maxHeight      = '70vh';
    dialogConfig.panelClass     = 'full-screen-modal';
    dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    dialogConfig.hasBackdrop    = false;
    
    const dialogRef             = this.dialog.open(AddListComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(res=>{
      this.pageDirty = true;
    });
    dialogRef.afterClosed().subscribe(result => {
      this.pageDirty = false;
      this.getTemplates(0, this.to, this.list_type);
      
    });

    //get value from dialog component when the dialog form is dirty
    // dialogRef.componentInstance.onDirty.subscribe((res)=>{
    //   if(res) this.pageDirty = true;
    // });
  }

  editList = (row) =>{
    console.log(row)
    const dialogConfig          = new MatDialogConfig();
    dialogConfig.data           = {'list_id': row.id};
    dialogConfig.width          = '70%';
    dialogConfig.height         = '100%';
    dialogConfig.maxHeight      = '50vw';
    dialogConfig.maxHeight      = '70vh';
    dialogConfig.panelClass     = 'full-screen-modal';
    dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    dialogConfig.hasBackdrop    = false;
    
    const dialogRef             = this.dialog.open(EditListComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(res=>{
      this.pageDirty = true;
    });
    dialogRef.afterClosed().subscribe(result => {
      this.pageDirty = false;
      this.getTemplates(0, this.to, row.type);
      // console.log(`Dialog result: ${result}`);
    });
  }

  getListByType = (item) =>{
    this.list_type = item;
    if(item == ''){
      this.lists.data = [] as valueListModel[];
      this.total = 0;
    }else{
      
      this.paginator.pageIndex = 0;
      this.getTemplates(0, this.to, item);
    }    
  }

  typeChange = (event) =>{
    this.typeSearchTxt = event.target.value;
  }
  clearsearch = (event) =>{
    this.typeSearchTxt = "";
    event.target.value = "";
  }

  ngOnInit(): void {
    //this.getTemplates();
    this.getTypes();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(
        (event) => {
          const frm = event.pageIndex * event.pageSize;
          const to = event.pageSize;
          this.to = event.pageSize;
          this.getTemplates(frm, to, this.list_type);
        }
    )
    }

}
