<mat-card>
    <!-- <mat-card-title style="text-align:center;">Add Attributes </mat-card-title> -->
    <h2 align="center">{{message}}</h2>

    <div class="container">      
        <div class="row">
            <div>
                <mat-form-field>
                    <mat-placeholder>Brand Code</mat-placeholder>
                    <input matInput type="text"  id="aum_code" [(ngModel)]="code" #aumcode="ngModel" [readonly]="isReadOnly" (input)="change($event)">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-placeholder>Brand Name</mat-placeholder>
                    <input matInput type="text" [value]="name" id="aum_dim_name" [(ngModel)]="name" #aumname="ngModel" (input)="change($event)">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-placeholder>Brand Description</mat-placeholder>
                    <input matInput type="text" [value]="desc" id="aum_dim_desc" [(ngModel)]="desc" #aumdesc="ngModel" (input)="change($event)">
                </mat-form-field>
            </div>
           <!--  <div *ngIf="addAttr">
                <mat-form-field>
                    <mat-placeholder>Last Updated By</mat-placeholder>
                    <input matInput type="text" id="last_updated_by">
                </mat-form-field>
            </div>
            <div *ngIf="addAttr">
                <mat-form-field>
                    <mat-placeholder>Last Updated Date</mat-placeholder>
                    <input matInput type="text" id="last_updated">
                </mat-form-field>
            </div> -->
        </div>
        <div class="row">
            <div class="col-12 ">
                <button mat-button class="mat-raised-button mat-primary float-right btn-dialog" cdkFocusInitial
                  [disabled]="(!aumcode.touched && !aumname.touched && !aumdesc.touched)"  (click)="SaveAttributes()">Save</button>
                <button mat-button class="mat-stroked-button float-right btn-dialog " color="primary"
                    (click)="onNoClick()">Cancel</button>
            </div>
        </div>
    </div>
</mat-card>