import { Component, OnInit, Inject, EventEmitter, ViewChild, ElementRef, HostListener, Output, Renderer2 } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import {LayoutModule, Breakpoints} from '@angular/cdk/layout';
import {
  MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../services/toastr.service';
import { URLHelper } from '../../../services/url.helper';
import { EditCriteriaComponent } from '../edit-criteria/edit-criteria.component';



@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.css']
})
export class AddProfileComponent implements OnInit {

  form: FormGroup;
  // onDirty = new EventEmitter();
  // @ViewChild('var_value',{}) valRef: ElementRef;
  // @ViewChild('addBtn',{}) addBtnRef: ElementRef;
  @Output() onFormResponse = new EventEmitter<any>();

  constructor(
    private http: HttpService, 
    private fb: FormBuilder,
    private toastr: ToasterService,
    private _dialog: MatDialog,
    private url: URLHelper,
    private renderer: Renderer2
    // @Inject(MAT_DIALOG_DATA) public data:any
  ) { 

    this.form                   = this.fb.group({
      profile_name              : [null, Validators.required],
      profile_description       : [null, Validators.required], 
      profile_type_string       : ['', Validators.required],
      table_name                : [null, Validators.required],
      delimiter_profile_string  : [null],
      profile_type_id           : [null],
      var_variable              : [null],
      var_condition             : [null],
      var_value                 : [null]
    });
    //used for url redirection to sent when form is dirty
    this.form.valueChanges.subscribe((data:any)=>{
      
      if(this.form.dirty){
        this.onFormResponse.emit({pagedirty: true, msg:"test"});
        // this.onDirty.emit(true);
      }      

      //check validation for add button for add rule
      

    });
  }
  
  public profile_types    = []
  public variables        = []
  public valid_operators  = [];
  public var_values       = [];

  public modVariable      = '';
  public modCondition     = '';
  public modValue         = '';
  public modString        = '';
  
  public profile_strings  = [];
  public table_names      = [];
  public profile_loading:boolean = false;
  public operate_loading:boolean = false;
  public valid_variable_entry:string;
  varText;
  where_rule_strings: any = [];
  where_rule_strings_action: any = [];
  whereIdx;
  whereItem;
  viewquery:boolean = false;
  breakpoint;

  protected getData = () =>{
    
    // this.http.getData(this.url.busrules_show_type).subscribe((data: any) => {
    //   this.profile_types = data.data;
    // });

    this.http.getData(this.url.xhold_profile_attr).subscribe((data: any) => {
      this.variables = data.data;
    });

  }

  getValidOperators = (varntab) =>{
    this.operate_loading = true;
    const table_name      = varntab.split(".")[0];
    const var_name        = varntab.split(".")[1];
    const param           = '/' + table_name + '/' + var_name;
    //'?col_name=' + var_name + '&' + 'table_name=' + table_name;
    this.valid_variable_entry = "";
    if (var_name.indexOf("DATE")>=0){
      this.valid_variable_entry = "MM/DD/YYYY";
    }
    this.valid_operators  = [];
    this.var_values       = [];
    this.http.getData(this.url.xhold_profile_valid_operators + param).subscribe((data: any) => {
      this.valid_operators = data.data;
    });

    this.http.getData(this.url.xhold_profile_attr_values + param).subscribe((data: any) => {
      this.var_values = data.data;
      this.operate_loading = false;
    });
  }

  saveProfile = (frm) => {
    
    if(!this.form.valid){
      return
    }
    const profileData:any = JSON.stringify(frm.value, null, 4);
    const data = JSON.parse(profileData);


    data.delimiter_profile_string = "STR_START;" + this.profile_strings.join(";") + ";STR_END"
    
    this.http.postData(this.url.xhold_profile_save, {}, data).subscribe((res: any) => {
      
      if(parseInt(res.data.error_flag) == 1){
        this.toastr.error("RuleString provided may be invalid.!");
        this.form.controls["profile_type_string"].setErrors({invalidquery:"RuleString provided may be invalid."});
      }else{
        this.toastr.success("Crosshold profile added successfully !");
        this.form.reset();
        this.onFormResponse.emit({pagedirty: false, msg:"close"});
      }
      
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  addCondition = (frm) =>{
       
    const profileData:any = JSON.stringify(frm.value, null, 4);
    const dt = JSON.parse(profileData);
    let strn:string = '';

    if(dt.var_variable.indexOf("DATE")>=0){

      var re = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
      if(dt.var_condition.trim() != 'IS NULL' && !re.test(dt.var_value)) {
        this.toastr.warning("The date format should be : mm/dd/yyyy");
        return;
      }
    }

    if(dt.var_condition.trim() == "IS NULL"){
      strn = dt.var_variable + dt.var_condition
    }else{
      if(dt.var_value == '' || dt.var_value == null || dt.var_value == ' ') return;
      strn = dt.var_variable + dt.var_condition + "'" + dt.var_value + "'";
    }
    
    if (this.table_names.indexOf(this.form.controls["var_variable"].value.split(".")[0]) != 0){
      this.table_names.push(this.form.controls["var_variable"].value.split(".")[0])
    }

    this.profile_strings.push(strn)
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + strn);
    this.form.controls["table_name"].setValue(this.table_names.join(","));
    this.form.controls["var_variable"].setValue("");
    this.form.controls["var_condition"].setValue("");
    this.form.controls["var_value"].setValue("");

    this.valid_variable_entry = '';

    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: strn, action: 'add' });

    this.where_rule_strings.push(strn);
  }
  
  checkValue = (val) =>{
    // console.log(val)
    // if(val.trim() == 'IS NULL') {
    //   this.valRef.nativeElement.disabled = true;
    // }else{
    //   this.valRef.nativeElement.disabled = false;
    // }
  }

  addAnd = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' AND ', action: 'add' });
    this.where_rule_strings.push(' AND ');

    // this.profile_strings.push(' AND ')
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' AND ');
  }

  addOr = () => {
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' OR ', action: 'add' });
    this.where_rule_strings.push(' OR ');

    // this.profile_strings.push(' OR ');
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' OR ');
  }

  addLeftPar = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' ( ', action: 'add' });

    this.where_rule_strings.push(' ( ');

    // this.profile_strings.push(' ( ');
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' ( ');
  }

  addRgtPar = () =>{
    this.where_rule_strings_action.push({idx: this.where_rule_strings.length, value: ' ) ', action: 'add' });

    this.where_rule_strings.push(' ) ');
    // this.profile_strings.push(' ) ');
    this.form.controls["profile_type_string"].setValue(this.form.controls["profile_type_string"].value + ' ) ');
  }

  formUndo = () =>{
    if(this.where_rule_strings_action.length > 0){
      let unidx = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].idx;
      let unval = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].value;
      let unact = this.where_rule_strings_action[this.where_rule_strings_action.length - 1].action;

      this.where_rule_strings_action.pop();
      if(unact == 'del'){
          this.where_rule_strings.splice(unidx, 0, unval);        
          
      }else{
          this.where_rule_strings.pop();
      }
      this.form.controls["profile_type_string"].setValue(this.where_rule_strings.join(" "));
  }else{
    this.toastr.warning("No Undo Actions");
  }
    // this.profile_strings.pop();
    // this.form.controls["profile_type_string"].setValue(this.profile_strings.join(" "));
  }

  formClear = () =>{
    this.profile_strings = [];
    this.form.controls["profile_type_string"].setValue('');
    this.whereIdx = null;
    this.whereItem = '';
    this.where_rule_strings = [];    
  }

  editCriteria = () =>{

    const dialogConfig  = new MatDialogConfig();
    dialogConfig.data   = {'profile_string': this.profile_strings};
    dialogConfig.width  = '100%';
    dialogConfig.height = '100%';
    dialogConfig.maxHeight  = '100vw';
    dialogConfig.maxHeight  = '100vh';
    dialogConfig.panelClass = 'full-screen-modal';
   
    const dialogRef = this._dialog.open(EditCriteriaComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      this.form.controls["profile_type_string"].setValue(this.profile_strings.join(" "));
    });
  }

  checkName = (frm) =>{
    this.profile_loading = true;
    this.http.getData(this.url.xhold_profile_chk_save + this.form.controls["profile_name"].value).subscribe((res:any)=>{
      this.profile_loading = false;
      if(parseInt(res.data.error_flag) == 1){
        // this.toastr.warning("This profile name found");
        this.form.controls["profile_name"].setErrors({checkName:"Profile Name already exists"});
      }
    });
  }
  
  closeDialog = () =>{
    if (this.form.dirty){
      if(confirm("Discard changes for Profile?")){
        this.form.reset();
        this.onFormResponse.emit({pagedirty: false, msg:"close"});
      }
    }else  {
      this.form.reset();
      this.onFormResponse.emit({pagedirty: false, msg:"close"});
    }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.form.dirty) event.returnValue = false;
  }

  varChange = (event) =>{
    this.varText = event.target.value;
  }

  clearsearch = (event) =>{
    this.varText = "";
    event.target.value = "";
  }
  
  viewQuery = () =>{
    this.viewquery = !this.viewquery;
  }

  selectedWhere = (event, idx, item) =>{
    this.whereIdx = idx;
    this.whereItem = item;
    this.renderer.addClass(event.target,"selected");
    
  }

  deleteCriteria = (idx: any) =>{
    try{    
        let proc_rule = this.where_rule_strings[idx];
        
        this.where_rule_strings_action.push({idx: idx, value: proc_rule, action: 'del'});
        
        if(proc_rule.indexOf("(") >=0 ){

          let lft_par_count = (proc_rule.match(/\(/g) || []).length

          if(proc_rule.indexOf(")") >=0 ){
            if(proc_rule.indexOf("TRUNC") >=0 || proc_rule.indexOf("TRUNC(") >=0 || proc_rule.indexOf(" IN ") >=0 || proc_rule.indexOf("IN(") >=0 ){

            }else{
                let rgt_par_count = (proc_rule.match(/\)/g) || []).length;
                
                if(rgt_par_count > lft_par_count){
                  
                    this.where_rule_strings[idx] = ")".repeat(lft_par_count);

                }else{
                    this.where_rule_strings.splice(idx, 1);
                }
                
            }
          }else{
            this.where_rule_strings[idx] = proc_rule.replace(/[^(]/g, "")
          }
        }else if(proc_rule.indexOf(")") >= 0){
            this.where_rule_strings[idx] = proc_rule.replace(/[^)]/g, "")
        }else if(proc_rule.trim().toLowerCase() == 'and' || 
            proc_rule.trim().toLowerCase() == 'or'){
            this.where_rule_strings.splice(idx, 1);
            this.toastr.warning('Chek the conditon Carefully.')
        }else{
          
          this.where_rule_strings.splice(idx, 1);
        }
        this.form.controls["profile_type_string"].setValue(this.where_rule_strings.join(" "));
        this.form.markAsDirty();
    }
    catch(e){
      console.log(e)
    }
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 950) ? 2 : 4;
  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 950) ? 2 : 4;
    this.getData();    
  }

}
