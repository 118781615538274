import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class DialogService {
  confirm(message?: string): boolean{
    const confirmation = window.confirm(message || 'Are you sure?');

    return confirmation // Observable.apply(confirmation) //.of(confirmation);
  };
}  