<div class="row">
    <div class="col-lg-12">
        <h1>PMA Logs</h1>
    </div>
  </div>
  
  <div class="row" [ngClass] = "{'loading': loading}">
      <mat-card class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <table class="example-container" mat-table id="listData" #tempsort="matSort"
          [dataSource]="lists.data" matSort>
          
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="col-lg-2"> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.system_last_updated_date}} </td>
          </ng-container>
    
          <ng-container matColumnDef="process">
            <th mat-header-cell *matHeaderCellDef class="col-lg-2"> Process </th>
            <td mat-cell *matCellDef="let element"> {{element.process}} </td>
          </ng-container>
  
          <ng-container matColumnDef="text">
              <th mat-header-cell *matHeaderCellDef class="col-lg-7"> Text </th>
              <td mat-cell *matCellDef="let element"> {{element.text}} </td>
          </ng-container>
  
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef align="center" class="col-lg-1"> 
              
            </th>
            <td mat-cell *matCellDef="let element" align="center"> 
  
              <ng-container align="center">
               <mat-icon>view</mat-icon>
                
              </ng-container>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="listColumns; sticky: false"></tr>
          <tr mat-row *matRowDef="let row; columns: listColumns;" (click)="selectItem(row)"></tr>
    
        </table>
    
        <div *ngIf="lists.data.length === 0" class="no-records alert alert-info text-center">
          No records found
        </div>
        <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]" [length]="total" showFirstLastButtons></mat-paginator>
      </mat-card>
  </div>

  <ng-template #template>
    <h2 matDialogTitle>{{selectedItem.process}}</h2>
    <mat-dialog-content>
      <h3>
        {{selectedItem.text}}
      </h3>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="closeDialog()">Close</button>
    </mat-dialog-actions>
  </ng-template>