import { Component, Input, Output, OnInit, EventEmitter, HostListener } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ToasterService } from '../../../services/toastr.service';
import { URLHelper } from '../../../services/url.helper';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { forkJoin } from 'rxjs';
import { SPACE} from '@angular/cdk/keycodes';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'capital-activity',
  templateUrl: './capital-activity.component.html',
  styleUrls: ['./capital-activity.component.css']
})
export class CapitalActivityComponent implements OnInit {
  
  isReadOnly: boolean =false;
  constructor(
    private http: HttpService, 
    private toastr: ToasterService,
    private url: URLHelper,
    private fb: FormBuilder,
    private authservice: AuthService) { 

      this.form = this.fb.group({
        profile_id    : [null],
        rpt_style_id  : [null], 
        app_fld_id    : [null, Validators.required],
        cach_rule     : ['', Validators.required],
        cach_rule_str : ['']
      });
      this.form.valueChanges.subscribe((data)=>{
        if(this.form.dirty){
          this.pageDirty.emit({pagedirty: true, msg: 'Discard changes for Capital Activity?'});
        }else{
           this.pageDirty.emit({pagedirty: false, msg: ''});
        }  
      });
      this.isReadOnly = authservice.getUserGroups()?authservice.getUserGroups()['CARS-ReadOnly-Users']: false;
    }

  @Input() profileid:number;
  @Input() profile:any;
  @Output() pageDirty = new EventEmitter<{pagedirty: boolean, msg: string}>();

  
  rule_loading: boolean = false;
  rules:any         = [];
  xhldRules:any;
  reportStyles:any  = [];
  rulesData:any     = [];
  displayedColumns  = [
      "aum_rpt_style_sq_id", 
      "aum_rpt_style_desc", 
      "subscriptons",
      "redemptions",
      "exchanges_in",
      "exchanges_out",
      "reinvestments",
      "distribution"
    ]
  xhldRuleColumns   = [
    // "aum_rpt_style_sq_id",
    "aum_rpt_style_desc",
    "aum_xhld_profiles_name",
    "aum_xhld_holding_flag",
    "aum_xhld_undly_flag"
  ]
  dataSource: MatTableDataSource<any>;// = new MatTableDataSource(this.rulesData);
  xhldRulesSource: MatTableDataSource<any>;
  cap_acct_rules:any;
  cap_acct_vars: any;
  selected_rpt_style_id;
  selected_app_fld_id;
  selected_var;
  selected_sign;
  selected_variables:any;
  selected_variables_default:any;
  form: FormGroup;
  AUMXhldRules:boolean = false;
  selectItem: MatSelect; //want to remove selection from variable select
  varText;

  

  getRules = () =>{

    this.rule_loading = true;
    this.http.getData(this.url.xhold_cap_acct_rules).subscribe((res:any)=>{
      this.cap_acct_rules = res.data;
      this.cap_acct_rules.sort(function (a, b) {
        return (a['aum_appl_fld_sq_id'] > b['aum_appl_fld_sq_id'] ? 1 : -1);
      });
      this.cap_acct_rules.map(item=>{
        let elemnt = item.aum_appl_fld_desc.indexOf("_")>=0? item.aum_appl_fld_desc.split("_")[2]:item.aum_appl_fld_desc;
        item.aum_appl_fld_desc = elemnt;
      });
    });

    

    this.http.getData(this.url.xhold_cap_acct_vars).subscribe((res:any)=>{
      this.cap_acct_vars = res.data;
    });
    this.http.getData(this.url.xhold_rules_get_report_styles).subscribe((res:any)=>{
      this.reportStyles = res.data;
    });
    if(this.profile && this.profile.profileid){
      setTimeout(() => {
        this.http.getData(this.url.xhold_capital_rule_edit + this.profile.profileid).subscribe((res:any)=>{
          this.rule_loading = false;
          this.rules = res.data;
          this.buildData();
        },
        err => {
          this.toastr.error("Issue while retrieving data. Please try again or contact support.")
        });
      }, 100);
      
      this.http.getData(this.url.xhold_capital_rules_disp + this.profile.profileid).subscribe((res:any)=>{
        this.xhldRules = res.data;
        this.xhldRules.map((item:any)=>{
          let fltr = this.reportStyles.filter((rpt:any)=>{
            return rpt.aum_rpt_style_sq_id == item.aum_rpt_style_sq_id ;
          })
          item.aum_rpt_style_desc = fltr.length > 0?fltr[0]["aum_rpt_style_desc"]:"";
        });
        this.xhldRulesSource =  new MatTableDataSource(this.xhldRules);
      });
    }
  }

  buildData = () =>{
    this.rulesData = [];
    this.reportStyles.forEach(element => {
      let rest = {
        aum_rpt_style_sq_id : element.aum_rpt_style_sq_id,
        aum_rpt_style_desc  : element.aum_rpt_style_desc,
        subscriptons        : '',
        redemptions         : '',
        exchanges_in        : '',
        exchanges_out       : '',
        reinvestments       : '',
        distribution        : ''
      }
      
      this.rules.forEach(item=>{
        let crit_str = item.aum_xhld_rules_assoc_crit_str?item.aum_xhld_rules_assoc_crit_str.split(";"):[];
        crit_str.shift();
        crit_str.pop();

        crit_str = crit_str.join('');
        // console.log(item.aum_rpt_style_sq_id ,'== ', element.aum_rpt_style_sq_id, crit_str)
        if (item.aum_rpt_style_sq_id == element.aum_rpt_style_sq_id){
          if(item.aum_xhld_rules_assoc_criteria && item.aum_xhld_rules_assoc_criteria.indexOf('Subscriptions') >= 0){

            rest.subscriptons += item.aum_xhld_rules_assoc_criteria.replace("+ Subscriptions", "");
          }
          if(item.aum_xhld_rules_assoc_criteria && item.aum_xhld_rules_assoc_criteria.toUpperCase().indexOf('REDEMPTION') >= 0){
            rest.redemptions += item.aum_xhld_rules_assoc_criteria.replace("+ Redemptions", "");
          }
          if(item.aum_xhld_rules_assoc_criteria && item.aum_xhld_rules_assoc_criteria.indexOf('Exchanges In') >= 0){
            rest.exchanges_in += item.aum_xhld_rules_assoc_criteria.replace("+ Exchanges In", "");
          }
          if(item.aum_xhld_rules_assoc_criteria && item.aum_xhld_rules_assoc_criteria.indexOf('Exchanges Out') >= 0){
            rest.exchanges_out += item.aum_xhld_rules_assoc_criteria.replace("+ Exchanges Out", "");
          }
          if(item.aum_xhld_rules_assoc_criteria && item.aum_xhld_rules_assoc_criteria.indexOf('Reinvestments') >= 0){
            rest.reinvestments += item.aum_xhld_rules_assoc_criteria.replace("+ Reinvestments","");
          }
          if(item.aum_xhld_rules_assoc_criteria && item.aum_xhld_rules_assoc_criteria.indexOf('Reinvestment_Plus_Distribution') >= 0){
            rest.distribution += item.aum_xhld_rules_assoc_criteria.replace("+ Reinvestment_Plus_Distribution", "");
          }
        }

        
      });

      this.rulesData.push(rest)
      

    });
    this.dataSource = new MatTableDataSource(this.rulesData);
  }

  saveRule = (data) =>{
   
    if(this.form.invalid) return;
    this.rule_loading = true;
    let body = {
      profile_id    : this.profileid,
      rpt_style_id  : this.selected_rpt_style_id,
      app_fld_id    : this.selected_app_fld_id,
      cach_rule     : data.controls["cach_rule"].value,
      //this.selected_variables[0] + " " + this.selected_variables[1] + " " + data.controls["cach_rule"].value,
      cach_rule_str : 'STR_START;' + this.selected_variables.join(" ") + ';STR_END'
    }
    
    this.http.putData(this.url.xhold_cap_act_rule_update, {}, body).subscribe((res)=>{
      this.rule_loading = false;
      this.getRules();
      this.toastr.success("Rule updated!")
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }

  selectRule = (rec) =>{
    this.selected_rpt_style_id = rec.aum_rpt_style_sq_id;
    if (!this.selected_app_fld_id) {
      this.toastr.warning("Please select Capital Activity!")
      return
    }
    this.readRuleSelect()
  }

  appFldSelect = (car:any) =>{
    console.log(car)
    this.selected_app_fld_id = car.aum_appl_fld_sq_id;
    
    if (!this.selected_rpt_style_id) {
      this.toastr.warning("Please select Report style!")
      return
    }
    this.readRuleSelect()
  }
  readRuleSelect = () =>{

    
    let url = '?profile_id=' + this.profileid + '&rep_style_id=' + this.selected_rpt_style_id + '&app_fld_id=' + this.selected_app_fld_id;
    
    this.http.getData(this.url.xhold_cap_act_rule_select + url).subscribe((res:any)=>{
      console.log(res);
      let crit_str = res.data?res.data[0].aum_xhld_rules_assoc_crit_str.split(";"):[];
      console.log(crit_str)
      crit_str.pop(); //remove 'STR_END'
      crit_str.shift(); //remove 'STR_START'
      
      crit_str = crit_str.join("").split(" ");

      this.selected_variables = crit_str;
      this.selected_variables_default = JSON.parse(JSON.stringify(crit_str));

      let tmp_str = crit_str.map(x => x);
      console.log(tmp_str)
      
      // tmp_str.shift();  //remove front +
      // tmp_str.shift();  //remove "Subscriptions"
      // console.log(tmp_str)
      // if (tmp_str.length>0 && (tmp_str[0].toLowerCase() == 'out' || tmp_str[0].toLowerCase() == 'in')){
      //   tmp_str.shift();  //remove "in or out" from "Exchanges In and Exchanges Out"
      // } 
       
      
      this.form.controls["cach_rule"].setValue(tmp_str.join(" "));
      
    },
    err => {
      this.toastr.error("Issue while retrieving data. Please try again or contact support.")
    });
  }
  
  addCachStr = () =>{
    if(!this.selected_sign || !this.selected_var){
      return;
    }
    if(!this.selected_rpt_style_id){
      this.toastr.warning("Please Select Reporting Style")
      return;
    }
    if(!this.selected_app_fld_id){
      this.toastr.warning("Please Select Capital Activity")
      return;
    }
    

    this.selected_variables.push(this.selected_sign);
    this.selected_variables.push(this.selected_var);
    const msg = this.form.controls["cach_rule"].setValue(this.form.controls["cach_rule"].value + " " + this.selected_sign + " " + this.selected_var);
    this.selectItem.writeValue(null);
    // this.selected_sign = null;
    this.selected_var = null;
  }

  critStrSign = (sign) =>{
    this.selected_sign = sign;
  }
  
  addVariable = (val:MatSelectChange) =>{
    this.selected_var = val.value;
    this.selectItem = val.source;    
  }
  
  undoCachStr = () =>{
    console.log(this.selected_variables_default, this.selected_variables)
    if(this.selected_variables.length <= 2){
      return;
    }else if(this.selected_variables.length <= 3){
      if(this.selected_variables[2] == "In" || this.selected_variables[2] == "Out" ){
        return;
      }
    }
     

    this.selected_variables.pop();
    this.selected_variables.pop();
    let tmp = this.selected_variables.map(x => x);
    // tmp.shift();
    // tmp.shift();
    this.form.controls["cach_rule"].setValue(tmp.join(" "));
  }

  clearCachStr = () =>{
    
   
    if(this.selected_variables[2] && (this.selected_variables[2] == "In" || this.selected_variables[2] == "Out" )){
      this.selected_variables = this.selected_variables.slice(0,3);
    }else{
      this.selected_variables = this.selected_variables.slice(0,2);
    }
    // this.selected_variables = this.selected_variables.slice(0,2);
    this.form.controls["cach_rule"].setValue(this.selected_variables.join(" "));
  }

  cancelCachStr = () =>{
    this.form.reset();
    this.pageDirty.emit({pagedirty: false, msg: '0'});
  }
  
  showHide = () =>{
    this.AUMXhldRules = !this.AUMXhldRules;
  }

  searchVariable = (event) =>{
    console.log("press", event.keyCode)
    if (event.keyCode==SPACE)
        return
    this.varText = event.target.value;
  }
  clearsearch = (event) =>{
    this.varText = "";
    event.target.value = "";
  }

  spacedown = (event) =>{
    console.log(event.keyCode, "cpace")
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    
    if (this.form.dirty) event.returnValue = false;
  }

  ngOnInit(): void {
    this.getRules();
    this.selected_sign = "+";
  }
    
}
