<mat-card class="text-left">
    <mat-card class="example-card text-center">
        <h3>Historical AUM SDIM Attributes</h3>
    </mat-card>
    <mat-card class="example-card">
        <div class="row">           
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToSharePage()">Show Current Share Class</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="loadDetailsPanel()">View</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="redirectToXHLDProfile()">Show
                    XHLD Profile Assoc SDIM</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="copyAsNewRecord()">Copy As New Record</button>
            </div>
            <div class=" col-3" style="width:fit-content;">
                <button  class="button" (click)="deleteRecord()">Delete Record</button>
            </div>
        </div>
        <br>
        <div class="row">
            <br><br>
            <div class="component data component-card "> 
                <div *ngIf="isLoading" >         
                    <mat-spinner class="loader"></mat-spinner>
                      </div>
                <table border="0" width="100%" cellpadding="0" cellspacing="0" mat-table [dataSource]="loadTemplateDatasource"
                    class="example-containe" matSort>
                    <!-- Template Name Column -->
                    <ng-container matColumnDef="radio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-width"></th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">
                    
                             <mat-radio-button color="primary" [checked]="element.aum_sdim_key == selectedrecord"
                             [value]="element" (change)="viewAttribute($event)">
                            </mat-radio-button> 
                        </td> 
                    </ng-container>
                    <ng-container matColumnDef="firstEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element.first_effective_date | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lastEffectiveDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Effective Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2"> {{element.last_effective_date | date: 'MM/dd/yyyy hh:mm:ss a'}} </td>
                    </ng-container>

             
                <ng-container matColumnDef="reportEligible">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Eligiblity </th>
                    <td mat-cell *matCellDef="let element" class="custom-width2">{{element.report_eligibility_flag}} </td>
                </ng-container>
                <!-- Template Download Column -->
                <ng-container matColumnDef="acctStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acct Staus </th>
                    <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_acct_status}} </td>
                </ng-container>
                

                  
                    <ng-container matColumnDef="inceptDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Incept Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_incept_date | date: 'MM/dd/yyyy'}}</td>
                    </ng-container>

                     
                    <ng-container matColumnDef="termDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Termination Date </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_termination_date | date: 'MM/dd/yyyy'}}</td>
                    </ng-container>

                     
                    <ng-container matColumnDef="billingId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Billing Ind ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_billing_indicator_dim_id}}</td>
                    </ng-container>

                    <ng-container matColumnDef="billingName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Billing Ind Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_billing_indicator_name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="ApprGrpID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Approval Group ID </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_apprvl_group_dim_id}}</td>
                    </ng-container>

                    <ng-container matColumnDef="ApprGrpName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Approval Group Name </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_apprvl_group_name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="UpdateFrq">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Update Frequency </th>
                        <td mat-cell *matCellDef="let element" class="custom-width2">{{element.aum_update_freq_code}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

                </table>
                <mat-paginator [pageSize]="10"
                showFirstLastButtons>
 </mat-paginator>
                <div *ngIf="loadTemplateDatasource.data.length === 0" class="no-records alert alert-info text-center">
                    No records found
                </div>

            </div>
        </div>
    </mat-card>
    <mat-card class="example-card text-center">
        <h3>Historical AUM SDIM Attributes Account Validation Results</h3>
        <div class="row">                     
            <div [innerHTML] = "validateinfo">          
             </div>
         </div>
    </mat-card>

</mat-card>
<mat-accordion class="example-headers-align prevent-click" >
    <mat-expansion-panel #panel1 [hideToggle]="true">
        <mat-expansion-panel-header *ngIf="showView">
            <mat-panel-title style="font-size:20px;" >
                Historical Account SDIM Attributes Details
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-4">
                    <mat-form-field>
                        <mat-placeholder>TIS Account Num</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="accountNo" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>Share Class</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="classcode" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class=" col-4">
                    <mat-form-field>
                        <mat-placeholder>Account Name</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="accountName" [disabled]="true">
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        <mat-card class="example-card text-center authorize-click">
            <div class="row">
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Account Status</mat-label>
                        <mat-select [(value)]="accountStatus" [disabled]="!isEdit">
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchStatus($event)" (keydown.escape)="clearsearch($event)">

                            <mat-option *ngFor="let acctstaus of AccountstatusList | search : varStatusText : 'p_Acct_Status_List_Code' "
                                [value]="acctstaus.p_Acct_Status_List_Code">{{
                                acctstaus.p_Acct_Status_List_Code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Update Frequency</mat-label>
                        <mat-select [(value)]="freqCode" [disabled]="!isEdit">
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchFreq($event)" (keydown.escape)="clearsearch($event)">
                            <mat-option *ngFor="let freq of freqCodeList | search : varFreqText : 'p_Acct_Status_List_Code'" [value]="freq.p_update_freq_code">{{
                                freq.p_update_freq_code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Inception Date</mat-label>
                        <input matInput [matDatepicker]="Inceptionpicker" [(ngModel)]="inceptDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="Inceptionpicker"></mat-datepicker-toggle>
                        <mat-datepicker #Inceptionpicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Termination Date</mat-label>
                        <input matInput [matDatepicker]="Terminationpicker" [(ngModel)]="terminateDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="Terminationpicker"></mat-datepicker-toggle>
                        <mat-datepicker #Terminationpicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Approval Group</mat-label>
                        <mat-select [(value)]="approvalgrpid" [disabled]="!isEdit">
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchApproval($event)" (keydown.escape)="clearsearch($event)">

                            <mat-option *ngFor="let applgrp of ApprovalgrpList | search : varApprovalText : 'aum_apprvl_group_name'" [value]="applgrp.aum_apprvl_group_dim_id">{{
                                applgrp.aum_apprvl_group_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=" col-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Billing Indicator</mat-label>
                        <mat-select [(value)]="billingindiId" [disabled]="!isEdit">
                            <input type="text" placeholder="search" class="search" 
                            (keyup)="searchBill($event)" (keydown.escape)="clearsearch($event)">
                            <mat-option *ngFor="let billingInd of BillingFilteredIndList | search : varBillText : 'aum_billing_indicator_name'"
                                [value]="billingInd.aum_billing_indicator_dim_id">{{
                                billingInd.aum_billing_indicator_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                  
                        <mat-form-field>
                            <mat-placeholder>First Effective Date</mat-placeholder>
                            <input matInput type="text" [(ngModel)] = "firstEffectiveDate" [disabled]="!isEdit">
                            <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                        <!-- <mat-label>First Effective Date</mat-label>
                        <input matInput [matDatepicker]="FirstEffectivepicker" [disabled]="!isEdit" [(ngModel)]="firstEffectiveDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="FirstEffectivepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #FirstEffectivepicker></mat-datepicker> -->
                    </mat-form-field>
                </div>
                <div class="col-3" *ngIf="!enable">
                    <mat-form-field>
                        <mat-label>Last Effective Date</mat-label>
                        <input matInput type="text" [(ngModel)] = "lastEffectiveDate" [disabled]="!isEdit">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                        <!-- <mat-label>Last Effective Date</mat-label>
                        <input matInput [matDatepicker]="LastEffectivepicker" [disabled]="!isEdit" [(ngModel)]="lastEffectiveDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="LastEffectivepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #LastEffectivepicker></mat-datepicker> -->
                    </mat-form-field>
                </div>
               
            </div>

            <div class="row">
                <div class="col-6 text-left">
                    <div class="col-3">
                        <section class="example-section">
                            <mat-checkbox class="example-margin" color="primary" [disabled]="!isEdit" [checked]="AumFlag" [(ngModel)]="isChecked"
                                (change)="checkValue(isChecked)"> AUM Report Flag </mat-checkbox>
                        </section>
                    </div>
                    <div class="col-9 example-margin">
                        Note: Changes to the flag field will affect only the specified fund class
                        within the specified account above.
                    </div>
                </div>
                <div class="col-3 text-center">
                    <mat-form-field>
                        <mat-placeholder>Last Updated By</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="lastUpdatedBy" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="col-3 text-center">
                    <mat-form-field>
                        <mat-placeholder>Last Updated Date</mat-placeholder>
                        <input matInput type="text" [(ngModel)]="lastUpdatedDate" [disabled]="true">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-9"></div>
                <div class="col-3 row">
                    <div class="col-6 text-left" style="padding:0;">
                        <button class="button btn btn-secondary" type="submit" [disabled]="!isEdit"
                            (click)="saveAccountInfo()">Save</button>
                    </div>
                    <div class="col-6 text-right" style="padding:0;">
                        <button class="button"
                            (click)="closePanel()">Cancel</button>
                    </div>
                </div>
            </div>
        </mat-card>

    </mat-expansion-panel>
</mat-accordion>