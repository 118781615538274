
<mat-dialog-content>
<mat-card>
    <mat-card-subtitle>Crossholding Profile Name --> {{profile_name}} </mat-card-subtitle>
    <!-- <mat-card-actions>
            
            <div class="rule-list mat-card-height"  > 
                <h3>Profile Query</h3>
                <div class="row" *ngFor="let rpt of updatedCriteria; index as idx">
                    <div class="rule-box active col-10 cur-pointer"
                        (click)="selectCriteria(idx, rpt)"
                        [ngClass]="{selected: selectedIdx == idx}"
                    >
                    {{rpt}}
                    </div>
                    <div class="rule-box col-2 text-center">
                        <mat-icon class="cur-pointer" (click)="deleteCriteria(idx)">delete</mat-icon>
                    </div>
                </div>
            </div>
    </mat-card-actions> -->
</mat-card>
<mat-card >
    <mat-card-subtitle>Account Criteria</mat-card-subtitle>
    <mat-card-actions>
        <div class="row">
            <div class="col-4">
                <mat-form-field [ngClass]="{'operate-loading': operate_loading}">
                    <!-- [(ngModel)]="modVariable"  (selectionChange)="getValidOperators($event.value)" -->
                    <mat-select [(ngModel)]="mod_variable" placeholder="Variable">
                        <mat-option *ngFor="let vls of variables" [value]="vls.var_ref_tab + '.' + vls.var_name">
                            {{vls.var_ref_tab}}.{{ vls.var_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <!-- [(ngModel)]="modCondition"  -->
                    <mat-select [(ngModel)]="mod_operator" placeholder="Condition"
                        (selectionChange)="setValidValue($event.value)">
                        <mat-option *ngFor="let opt of valid_operators" value={{opt}}>{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput type="text" [(ngModel)]="mod_value" placeholder="Value"
                        [disabled]="rule_val_status">
                    <!-- <mat-select [(ngModel)]="mod_value" placeholder="Value">
                        <mat-option *ngFor="let vlu of var_values" value="{{vlu}}">{{vlu}}</mat-option>
                    </mat-select> -->
                </mat-form-field>
            </div>
            <!-- <div class="col-2">
                <button [disabled]="selectedReletive" type="button" mat-raised-button color="primary" (click)="updateCriteria($event)">Update Criteria</button>
            </div> -->
        </div>
    </mat-card-actions>
</mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
    
    <button (click)="updateCriteria($event)" mat-raised-button color="primary" type="button">Update</button>
    <button mat-raised-button type="button" (click)="cancelCriteria()">Cancel</button>
</mat-dialog-actions>