<mat-card class="example-card text-center">
    <h3 class="title"><b>Capital Activity Crossholding Rules Management</b></h3>

    <h4 class="col-12 color-999 text-left">Crossholding Profile Name -->  {{profile && profile.profilename}}</h4>
</mat-card>
<br>
<div class="row rule-row">

    <div *ngIf="rule_loading" class="spinner">
        <mat-spinner class="loader"></mat-spinner>
    </div>
    
        <mat-card>
            <mat-card-subtitle>AUM Crossholding Rules <span (click)="showHide()" class="span-link">{{AUMXhldRules?"Hide":"Show"}}</span></mat-card-subtitle>
            <mat-card-actions *ngIf="AUMXhldRules">
                <div class="xhld-rule-table"> 
                    <table mat-table [dataSource]="xhldRulesSource" class="mat-elevation-z8">
                        <!-- <ng-container matColumnDef="aum_rpt_style_sq_id">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_rpt_style_sq_id }} </td>
                        </ng-container> -->
                        <ng-container matColumnDef="aum_rpt_style_desc">
                            <th mat-header-cell *matHeaderCellDef>Reporting Style</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_rpt_style_desc }} </td>
                            </ng-container>
                        <ng-container matColumnDef="aum_xhld_profiles_name">
                            <th mat-header-cell *matHeaderCellDef>Crossholding profile</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_xhld_profiles_name }} </td>
                        </ng-container>
                        <ng-container matColumnDef="aum_xhld_holding_flag">
                            <th mat-header-cell *matHeaderCellDef>Deduct from Top/Holding</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_xhld_holding_flag }} </td>
                        </ng-container>
                        <ng-container matColumnDef="aum_xhld_undly_flag">
                            <th mat-header-cell *matHeaderCellDef>Deduct from Underlying</th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_xhld_undly_flag }} </td>
                        </ng-container>
                        

                        <tr mat-header-row *matHeaderRowDef="xhldRuleColumns; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: xhldRuleColumns;"></tr>
                        
                    </table>
                    <div *ngIf="rules.length === 0"
                        class="no-records alert alert-info text-center">
                        No rules found
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
   

    <form [formGroup]="form" (ngSubmit)="saveRule(form)" novalidate>
        
            <mat-card><div class="col-12">
                <mat-card-subtitle>Capital Activity Crossholding Rules</mat-card-subtitle>
                <mat-card-actions>
                    <div class="xhld-table"> 
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                            [ngClass]="{loading:rule_loading}"
                            >
                            <ng-container matColumnDef="aum_rpt_style_sq_id">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">{{ element.aum_rpt_style_sq_id }} </td>
                            </ng-container>
                            <ng-container matColumnDef="aum_rpt_style_desc">
                                <th mat-header-cell *matHeaderCellDef>Reporting Styles</th>
                                <td mat-cell *matCellDef="let element">{{ element.aum_rpt_style_desc }} </td>
                            </ng-container>
                            <ng-container matColumnDef="subscriptons">
                                <th mat-header-cell *matHeaderCellDef>Subscriptons</th>
                                <td mat-cell *matCellDef="let element">{{ element.subscriptons }} </td>
                            </ng-container>
                            <ng-container matColumnDef="redemptions">
                                <th mat-header-cell *matHeaderCellDef>Redemptions</th>
                                <td mat-cell *matCellDef="let element">{{ element.redemptions }} </td>
                            </ng-container>
                            <ng-container matColumnDef="exchanges_in">
                                <th mat-header-cell *matHeaderCellDef>Exchanges In</th>
                                <td mat-cell *matCellDef="let element">{{ element.exchanges_in }} </td>
                            </ng-container>
                            <ng-container matColumnDef="exchanges_out">
                                <th mat-header-cell *matHeaderCellDef>Exchanges Out</th>
                                <td mat-cell *matCellDef="let element">{{ element.exchanges_out }} </td>
                            </ng-container>
                            <ng-container matColumnDef="reinvestments">
                                <th mat-header-cell *matHeaderCellDef>Reinvestments</th>
                                <td mat-cell *matCellDef="let element">{{ element.reinvestments }} </td>
                            </ng-container>
                            <ng-container matColumnDef="distribution">
                                <th mat-header-cell *matHeaderCellDef>Distribution</th>
                                <td mat-cell *matCellDef="let element">{{ element.distribution }} </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
                                (click)="selectRule(row)"
                                [ngClass]="{'selected': selected_rpt_style_id == row.aum_rpt_style_sq_id}"
                            ></tr>
                            
                        </table>
                        <div *ngIf="rules.length === 0"
                            class="no-records alert alert-info text-center">
                            No rules found
                        </div>
                    </div>
                </mat-card-actions> </div>
            </mat-card>   
    
        <div class="col-12">
            <mat-card>
                Capital Activity Field: 
                
                    <mat-radio-group matInput aria-label="Select an option" formControlName="app_fld_id">
                        <mat-radio-button *ngFor="let car of cap_acct_rules" (click)="appFldSelect(car)" value="{{car.aum_appl_fld_sq_id}}">{{car.aum_appl_fld_desc}}</mat-radio-button>
                    </mat-radio-group>
                
            </mat-card>
        </div>
        <div class="col-12 select">
            <mat-card>
                <mat-card-subtitle>Capital Activity Crossholding Rules Definition</mat-card-subtitle>
                <div class="row">
                    <div class="col-3">
                        Condition:
                        <mat-form-field appearance="fill">
                            <mat-label>Condition</mat-label>
                            <mat-select [(value)]="selected_sign" (selectionChange)="critStrSign($event.value)" >
                                <mat-option value="+">+</mat-option>
                                <mat-option value="-">-</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-7">
                        Variable: 
                        <mat-form-field appearance="fill">
                            <mat-label>Variable</mat-label>
                            <mat-select (selectionChange)="addVariable($event)" #selvar no-space >
                                <input type="text" placeholder="search" class="search"
                                    (keyup)="searchVariable($event)"  (keydown.escape)="clearsearch($event)">

                                <mat-icon class="search-icon">search</mat-icon> 
                                <mat-option *ngFor="let topping of cap_acct_vars | search : varText : 'aum_cap_act_xhld_code' : 'aum_cap_act_xhld_desc'" [value]="topping.aum_cap_act_xhld_code">{{topping.aum_cap_act_xhld_code + " - " + topping.aum_cap_act_xhld_desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 vertical-center ">
                        <button type="button" class="add" color="primary" [disabled]="!selected_var || !selected_sign ||  isReadOnly" 
                            mat-raised-button (click)="addCachStr()">
                                Add
                        </button>
                    </div>
                </div>
                    
                    
                    
                <div class="row">
                    <mat-form-field appearance="legacy">
                        <mat-label></mat-label>
                        <textarea class="textarea" matInput readonly rows="5" formControlName="cach_rule"></textarea>
                        
                        <button type="button" (click)="undoCachStr()" mat-mini-fab color="primary" matTooltip="Undo Last Action" aria-label="Undo Last Action" class="undo-button">
                            <mat-icon>undo</mat-icon>
                        </button>
                        <button type="button" (click)="clearCachStr()" mat-mini-fab color="primary" matTooltip="Clear All" aria-label="Clear All" class="clear-button">
                            <mat-icon>delete</mat-icon>
                        </button>
                        
                        <mat-hint>
                            <div class="error" *ngIf="form.controls['cach_rule'].invalid && (form.controls['cach_rule'].dirty)">
                            <div class="error" *ngIf="form.controls['cach_rule'].errors.required">
                                Please enter Redemptions
                            </div>
                        </div>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </mat-card>

            
        </div>
        <div class="col-12 margin-top-btm">
            <!-- <button type="button" mat-raised-button (click)="undoCachStr()">Undo</button> -->
            <button type="button" mat-raised-button (click)="cancelCachStr()">Cancel</button>
            <button type="submit" class="add" color="primary"  [disabled]= "isReadOnly" mat-raised-button >Save</button>
        </div>
    </form>
</div>