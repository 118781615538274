<mat-card class="text-center">
    <mat-card-title>AUM Account Level Attributes</mat-card-title>

    <mat-card class="example-card text-center">
        <div class="row centered">
            <div class="col-4">
                <mat-form-field>
                    <mat-placeholder>TIS Account Num</mat-placeholder>
                    <input matInput type="text" name="accountNo" [(ngModel)]="accountNo">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-placeholder>Account Name</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="accountName" [disabled]="true" [readonly]="true">
                </mat-form-field>
            </div>
            <div class=" col-4">
                <mat-form-field>
                    <mat-placeholder>TIS Account Type</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="accountType" [disabled]="true" [readonly]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="button-row centered">
            <button class="button" (click)="getAccountInformation()"> Show Record</button>
            &nbsp;
            <button class="button" *ngIf="isShowButton" (click)="displayAumAcctSDIM()">Show AUM ACCT SDIM</button>
            &nbsp;
            <button class="button" *ngIf="isShowButton" (click)="displayAcctSDIM()">Show ACCT SDIM</button>
            &nbsp;
            <button class="button" (click)="clearinfo()">Select New Record</button>
            &nbsp;

            <button class="button" *ngIf="isShowButton" (click)="getNextAccountNo()">Next Record</button>


        </div>
    </mat-card>
    <div *ngIf="isLoading">
        <mat-spinner class="loader"></mat-spinner>
    </div>
    <mat-card class="example-card text-center">
        <div class="row centered">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Investment Adv</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="investmentAdvname" [disabled]="true">
                    <!-- <mat-label>Investment Adv</mat-label>
                    <mat-select [(value)]="default" [disabled]="true">
                    <mat-option [value]="1"> {{investmentAdvname}} </mat-option>
                </mat-select> -->

                    <!-- <mat-select [(value)]="investmentAdvid">
                        <mat-option *ngFor="let invstadv of invstAdvList" [value]="invstadv.aum_invst_adv_dim_id">{{
                            invstadv.aum_invst_adv_name}}</mat-option>
                    </mat-select> -->
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Sub Advisor</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="subAdvisor" #subAdvisor1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Sub-Sub Advisor</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="subSubAdvisor" #subSubAdvisor1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>

            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Fiscal Year End [MM/YY]</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="fiscalYearEnd" #fiscalYearEnd1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Fiscal Year End [MM/YY]</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="fiscalYearEnd" #fiscalYearEnd1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Transfer Agent</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="transferAgent" #transferAgent1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Business Manager</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="businessManager" #businessManager1="ngModel"
                        [disabled]="true">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Taxability</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="taxbility" #taxbility1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>Last Updated Date</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="lastUpdatedDate" [disabled]="true">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Admin Location</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="adminLocation" #adminLocation1="ngModel" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field>
                    <mat-placeholder>ICI Investment Objective</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="iciInvestmentObjective" #iciInvestmentObjective1="ngModel"
                        [disabled]="true">
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field>
                    <mat-placeholder>Last Updated By</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="lastUpdatedBy" [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Backup Portfolio Manager</mat-label>
                    <select matNativeControl [disabled]="true">
                        <option value="1"> {{backupPortfoliomgmt}} </option>
                    </select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Domicile</mat-label>
                    <select matNativeControl [disabled]="true">
                        <option value="1"> {{domicile}} </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col-6 d-flex d-nowrap">
                <mat-form-field appearance="fill" *ngIf="editGrpmgr" [ngClass]="{loading:groupmgrLoading}">
                    <mat-label> Group Manager
                    </mat-label>
                    <mat-select [(value)]="groupManagerid" [disabled]="false"  no-space>

                         <!-- <input type="text" placeholder="search" class="search" 
                        (keyup)="vargrpmgrChange($event)" (keydown.escape)="clearsearch($event)"> 

                        <mat-icon class="search-icon">search</mat-icon>
                        
                         <mat-option *ngFor="let grpmgr of grpmgrList | search : vargrpmgrText : 'mgr_last_name' " [value]="grpmgr.mgr_dim_id">
                            {{ grpmgr.mgr_last_name + ', ' + grpmgr.mgr_first_name}}
                        </mat-option>  -->
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="!editGrpmgr">
                    <mat-label>Group Manager</mat-label>
                    <select matNativeControl [disabled]="true">
                        <option value="1"> {{groupManagername}} </option>
                    </select>
                </mat-form-field>
                <!-- <button mat-icon-button class="material-icons app-toolbar-menu save-button" matTooltip="Edit Attributes" [disabled]= "isReadOnly"
                    color="primary" (click)="getgroupmgrList()">
                    <mat-icon>edit</mat-icon>
                </button> -->
            </div>
        </div>
    </mat-card>
    <mat-card class="example-card text-center">
        <div class="row centered">
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Investment Objective</mat-label>
                    <mat-select   [(value)]="investmentObjectid" [(ngModel)]="investmentObjectid" #invstObj="ngModel"  [disabled]= "isReadOnly" no-space>
                        
                        <input type="text" placeholder="search" class="search" 
                            (keyup)="varChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let invstobj of invstObjList | search : varInvestText : 'aum_invst_obj_name'" [value]="invstobj.aum_invst_obj_dim_id">{{
                            invstobj.aum_invst_obj_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Brand</mat-label>
                    <mat-select [(value)]="brandid" [(ngModel)]="brandid" #brand="ngModel"  [disabled]= "isReadOnly" no-space>

                        <input type="text" placeholder="search" class="search" 
                            (keyup)="varBrandChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>


                        <mat-option *ngFor="let brand of brandfilterList | search : varBrandText : 'aum_brand_name'" [value]="brand.aum_brand_dim_id">{{
                            brand.aum_brand_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select [(value)]="vehicleTypeid" [(ngModel)]="vehicleTypeid" #vehicle="ngModel"  no-space [disabled]= "isReadOnly">
                        
                        <input type="text" placeholder="search" class="search" 
                            (keyup)="varVechChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        
                        <mat-option *ngFor="let vehicle of vehicletypelist | search : varVechText : 'aum_vehicle_type_name'" [value]="vehicle.aum_vehicle_type_dim_id">{{
                            vehicle.aum_vehicle_type_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Pvt Client Type 1 Parent</mat-label>
                    <mat-select [(value)]="clientTypeParentid" [(ngModel)]="clientTypeParentid"
                        #clientTypeParent="ngModel"  no-space [disabled]= "isReadOnly">

                        <input type="text" placeholder="search" class="search" 
                            (keyup)="varPvtClientChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>


                        <mat-option *ngFor="let pctp of pctpList | search : varpctpText : 'aum_pct1p_name'" [value]="pctp.aum_pct1p_dim_id">{{
                            pctp.aum_pct1p_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Business Unit</mat-label>
                    <mat-select [(value)]="businessUnitid" [(ngModel)]="businessUnitid" #businessUnit="ngModel"  [disabled]= "isReadOnly" no-space>
                       
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varBussChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let business of businessList | search : varBussText : 'aum_busi_unit_name' " [value]="business.aum_busi_unit_dim_id">{{
                            business.aum_busi_unit_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Lead Portfolio Mgmt Grp</mat-label>
                    <mat-select [(value)]="leadPortfolioMgmtGrpid" [(ngModel)]="leadPortfolioMgmtGrpid"  [disabled]= "isReadOnly" no-space
                        #leadPortfolioMgmt="ngModel">

                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varlpmgChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let lpmg of lpmgList | search : varlpmgText : 'aum_lpmg_name' " [value]="lpmg.aum_lpmg_dim_id">{{
                            lpmg.aum_lpmg_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Portfolio Mandate</mat-label>
                    <mat-select [(value)]="portfolioMandateid" [(ngModel)]="portfolioMandateid"  [disabled]= "isReadOnly" no-space
                        #portfolioMandate="ngModel">

                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varportmanChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>

                        <mat-option *ngFor="let portman of portmanFilteredList | search : varportmanText : 'aum_port_man_name' " [value]="portman.aum_port_man_dim_id">{{
                            portman.aum_port_man_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Region</mat-label>
                    <mat-select [(value)]="regionid" [(ngModel)]="regionid" #region="ngModel"  [disabled]= "isReadOnly" no-space>
                        
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varRegionChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>
                        
                        <mat-option *ngFor="let region of regionList | search : varRegionText : 'aum_region_name' " [value]="region.aum_region_dim_id">{{
                            region.aum_region_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row centered">
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Sales Office</mat-label>
                    <mat-select [(value)]="salesOfficeid" [(ngModel)]="salesOfficeid" #salesOffice="ngModel"  [disabled]= "isReadOnly" no-space>
                        
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varSalesChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>
                        
                        <mat-option *ngFor="let sales of salesOfficeList | search : varSalesText : 'aum_sale_off_name' " [value]="sales.aum_sale_off_dim_id">{{
                            sales.aum_sale_off_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Crossholding Indicator</mat-label>
                    <mat-select [(value)]="crossHoldingIndicatorid" [(ngModel)]="crossHoldingIndicatorid"  [disabled]= "isReadOnly" no-space
                        #crossHoldingIndicator="ngModel" [disabled]= "isReadOnly">
                        
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varIndChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>
                        
                        <mat-option *ngFor="let xhld of xhldindFilterList | search : varIndText : 'aum_xhld_indicator_name' " [value]="xhld.aum_xhld_indicator_dim_id">{{
                            xhld.aum_xhld_indicator_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Product Type</mat-label>
                    <mat-select [(value)]="productTypeid" [(ngModel)]="productTypeid" #productType="ngModel"  [disabled]= "isReadOnly" no-space>
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varProdChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>
                        
                        <mat-option *ngFor="let productype of producttypeList | search : varProdText : 'aum_product_type_name' "
                            [value]="productype.aum_product_type_dim_id">{{
                            productype.aum_product_type_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-3">
                <mat-form-field appearance="fill">
                    <mat-label>Canadian Mandate</mat-label>
                    <mat-select [(value)]="canadianMandateid" [(ngModel)]="canadianMandateid"  [disabled]= "isReadOnly" no-space
                        #canadianMandate="ngModel">
                        
                        <input type="text" placeholder="search" class="search" 
                        (keyup)="varCanadianChange($event)" (keydown.escape)="clearsearch($event)">

                        <mat-icon class="search-icon">search</mat-icon>
                        
                        <mat-option *ngFor="let canada of canadamanList | search : varCanadianText : 'aum_canadian_mandate_name' " [value]="canada.aum_canadian_mandate_dim_id">{{
                            canada.aum_canadian_mandate_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row centered">
            <div class="col-12">
                <section class="example-section">
                    <mat-checkbox class="example-margin" color="primary" [checked]="xaumFlag" [(ngModel)]="isChecked" [disabled]= "isReadOnly"
                        #xamFlag="ngModel" (change)="checkValue(isChecked)"> XAUM Flag 
                    </mat-checkbox>
                </section>
            </div>
        </div>
        <div class="row centered">
            <div class="col-9"></div>
            <div class="col-3 row centered">
                <div class="col-6 text-left" style="padding:0;">
                    <button class="button2 btn btn-secondary" type="submit" [disabled]="(!invstObj.touched && !editGrpmgr
                        && !brand.touched && !vehicle.touched && !region.touched 
                        && !businessUnit.touched && !leadPortfolioMgmt.touched 
                        && !clientTypeParent.touched && !portfolioMandate.touched
                        && !salesOffice.touched && ! crossHoldingIndicator.touched 
                        && !productType.touched && !canadianMandate.touched 
                        && !subAdvisor1.touched && !subSubAdvisor1.touched 
                        && !fiscalYearEnd1.touched && !transferAgent1.touched 
                        && !businessManager1.touched && !taxbility1.touched 
                        && !adminLocation1.touched && !iciInvestmentObjective1.touched && !xamFlag.touched) || isReadOnly"
                        (click)="saveAccountInfo()">Save</button>
                </div>
                <div class="col-6 text-right" style="padding:0;">
                    <button class="button2" (click)="resetAccountInfo()">Reset</button>
                </div>
            </div>
        </div>
    </mat-card>
</mat-card>