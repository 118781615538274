import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrossholdingProfileAttributes, UnAssignedAccounts } from 'src/app/models/attributes';
import { HttpService } from '../../services/http.service';
import { URLHelper } from '../../services/url.helper';
import { Profiles, Accts } from '../../models/crossholding';
import { MatTabGroup, MatTabHeader, MatTab } from '@angular/material/tabs';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddProfileComponent } from '../crossholding-rules-management/add-profile/add-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ToasterService } from '../../services/toastr.service';
import { Title } from '@angular/platform-browser';
import { DialogService } from '../../services/dialog.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-crossholding-rules-management',
  templateUrl: './crossholding-rules-management.component.html',
  styleUrls: ['./crossholding-rules-management.component.css']
})
export class CrossholdingRulesManagementComponent implements OnInit {
  @ViewChild(MatTabGroup) tabs: MatTabGroup;
  profileAttr;
  profile;
  profileId;
  profileName;
  show: boolean = false;
  resAction: string;
  //UnAssignedAcctsList : UnAssignedAccounts[] =[];
  loadUnAssignedAcctColumns = ['AccountDim', 'AumDim', 'AccountNumber', 'ShareClass', 'AccountName', 'ReportEligibility'];
  loadUnAssignedAcctDatasource: MatTableDataSource<any>;
  profileList;
  loading:boolean = false;
  selectedIndex;
  pageDirty:boolean = false;
  dirtyMsg = "Discard changes for Rule?";
  private currentTabIndex = 0;
  
  accts = new MatTableDataSource<Accts>();
  acctsColumns = ['acct_dim_id', 'aum_dim_id', 'aum_acct_num', 'aum_share_class_code', 'acct_name', 'report_eligibility_flag'];
  constructor(
    public dialog: MatDialog,
    public toastr: ToasterService,
    private http: HttpService,
    private url: URLHelper ,
    private titleService: Title,
    private confirmDialog: DialogService
  ) { 
    this.titleService.setTitle("CARS - Crossholding Rules Management");
  }

  canDeactivate():Observable<boolean> | boolean{
    console.log('CHRM', this.pageDirty)
    if(this.pageDirty){
      return this.confirmDialog.confirm(this.dirtyMsg);
    }
    return true;
  }


  get_profile_names(){
    this.http.getData(this.url.xhold_profile_get).subscribe((res:any)=>{
      this.profileAttr = res.data as Profiles[];
      this.profileList = res.data as Profiles[];
    });
  }
  ngOnInit(): void {
    this.get_profile_names();
    // this.tabs._handleClick = this.interceptTabChange.bind(this);
  }
  ngAfterViewInit():void{
    // const hc = this.tabs._handleClick
    

    // this.tabs._handleClick = (tab, header, index) => {
    //   console.log(this.profileId)
    //   if(this.profileId == '') {
    //     this.toastr.error("Please select Profile !");
    //     hc.apply(this.tabs, [tab, header, index]);
    //   }
    // }
  }

  tabClick =(event)=>{
    console.log(this.pageDirty)
    if(event.target.className == 'mat-tab-label-content'){
      if(this.pageDirty){ 
        const ok = confirm("Changes you have made are not saved. Changes would be discarded, do you want to continue?")
        if(!ok){
          this.selectedIndex = this.currentTabIndex;
        }else{
          this.pageDirty = false;
        }
      }
      
      if(this.selectedIndex > 0 && !this.profileId){
        this.toastr.warning("Please select Profile!")
        this.selectedIndex = 0;
      }
      this.currentTabIndex = this.selectedIndex;
    }
  }
  
  profileFilter(value: string) {
    if (value) {
      value = value.toLowerCase();
      console.log(this.profileAttr, this.profileList)
      this.profileAttr = this.profileList.filter(x => x.aum_xhld_profiles_name.toLowerCase().includes(value) || x.aum_xhld_profiles_name.toLowerCase().includes(value))
    } else {
      this.profileAttr = this.profileList;
    }
  }

  // add 
  openAddDialog() {
    const dialogConfig          = new MatDialogConfig();
    dialogConfig.width          = '100%';
    dialogConfig.height         = '100%';
    dialogConfig.maxHeight      = '100vw';
    dialogConfig.maxHeight      = '100vh';
    dialogConfig.panelClass     = 'full-screen-modal';
    dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    dialogConfig.hasBackdrop    = false;
    const dialogRef             = this.dialog.open(AddProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log("closed after")
      this.pageDirty = false;
      this.get_profile_names();
    });
    dialogRef.afterOpened().subscribe(res=>{
      // this.pageDirty = true;
    })
    dialogRef.beforeClosed().subscribe(res=>{
      console.log('closed', this.pageDirty)
    })
  }
  openAddDialog1(row: any, action: string): void {
    this.show = false;
    this.resAction= action;
    this.profileId = "";
    /*  const dialogRef = this.dialog.open(ReportsetupDialogComponent, {});
     dialogRef.afterClosed().subscribe(result => { this.ReloadReportPackages() }); */
     console.log(" +Add+ " + this.resAction);
  }

  //edit
  openEditDialog(row: any, action: string): void {
    this.show = false;
    this.resAction= action;
    
    const dialogConfig          = new MatDialogConfig();
    dialogConfig.data           = {'profile_id': row.aum_xhld_profiles_sq_id};
    dialogConfig.width          = '100%';
    dialogConfig.height         = '100%';
    dialogConfig.maxHeight      = '100vw';
    dialogConfig.maxHeight      = '100vh';
    dialogConfig.panelClass     = 'full-screen-modal';
    dialogConfig.backdropClass  = 'cdk-overlay-transparent-backdrop';
    dialogConfig.hasBackdrop    = false;

    if(row.aum_xhld_profiles_sq_id == ''){
      this.toastr.error("Need to select any one Rule from the list.");
    }else{
      const dialogRef = this.dialog.open(EditProfileComponent, dialogConfig);
    
      dialogRef.afterOpened().subscribe(res=>{
        this.pageDirty = true;
      })
      dialogRef.afterClosed().subscribe(result => {
        this.pageDirty = false;
        this.get_profile_names();
      });
    }

  }

  onProfileChange(action, id, value) {

    this.resAction= action;
    this.profileId = id;    
    this.loading = true;

    if (this.resAction == "clicked") {      
      this.show = true;
      
      this.http.getData(this.url.xhold_profile_accts + '/' + this.profileId).subscribe((res: any)=>{
        if (res && res.status == 200){
          this.accts.data = res.data as Accts[];
        }else{
          this.accts.data = [];
        }
        this.loading = false;
      },(err:any)=>{
        this.accts.data = [];
        this.loading = false;
        this.toastr.error("Issue while retrieving data. Please try again or contact support.")
      });
      
      // if (id == 1 || id == 5) {
      //   this.loadUnAssignedAcctDatasource = new MatTableDataSource(this.UnAssignedAcctsList);
      // }
      // else {
      //   this.loadUnAssignedAcctDatasource = new MatTableDataSource(this.UnAssignedAcctsList1);
      // }

    }
  }

  onProfileSelected = (eventData: {profileid:number, profilename: string})=>{
    this.profileId  = eventData.profileid;
    this.profileName= eventData.profilename;
    this.profile    = eventData;
  }

  onPageDirty = (eventData: {pagedirty: boolean, msg: string})=>{
    console.log("bef emit", eventData.pagedirty, this.pageDirty)
    if (parseInt(eventData.msg) == 0){
      this.selectedIndex = 0;
    }else{
      this.pageDirty = eventData.pagedirty;
      this.dirtyMsg = eventData.msg;
      this.http.setGoBack(eventData.pagedirty);
    }
    console.log("emit", this.pageDirty)
  }

  saveChanges(e) {
    if (e["active"] == false) {
      e["active"] = true;
    }
    else {
      e["active"] = false;
    }
    //console.log(e);
  }


  
}
