<h2>AUM Reporting Rules Management</h2>
<p>Use Add/Edit/Delete to manipulate the Reporting Rule.</p>

<mat-list>
    
    <div cdkDropList class="rule-list"  (cdkDropListDropped)="dropRue($event)" > 
        <h3>Reporting Rules Management</h3>
        <div class="rule-box active" *ngFor="let rule of rules" cdkDrag (click)="selected(rule)"
        [ngClass]="{highlighted: highlightOption=='hover' && highlighdRowIndex == rule.rule_id && highlighdRowIndex != selectedRowIndex, selected: selectedOption=='select' && selectedRowIndex == rule.rule_id, active: rule.status_flag == 'T', 'inactive': rule.status_flag=='F'}"
            >
            {{rule.rule_name}}
        </div>
    </div>

        <!-- <table mat-table [dataSource]="rules" class="mat-elevation-z8">
            <ng-container matColumnDef="rule_name">
              <th mat-header-cell *matHeaderCellDef>Reporting Rules Management</th>
              <td mat-cell *matCellDef="let element"><mat-icon>label</mat-icon> {{ element.rule_name }} </td>
            </ng-container>
          
            
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="highlight(row)"
                (click)="selected(row)"
                [ngClass]="{highlighted: highlightOption=='hover' && highlighdRowIndex == row.rule_id && highlighdRowIndex != selectedRowIndex, selected: selectedOption=='select' && selectedRowIndex == row.rule_id, active: row.status_flag == 'T', 'inactive': row.status_flag=='F'}"
            ></tr>
          
          </table>
         -->
        
          

</mat-list>
<mat-grid-list cols="1" rowHeight="10:1">
    <mat-grid-tile>
        <div class="button-row">
            
            <button mat-raised-button color="primary" class="add" [disabled]= "isReadOnly" (click)="openAddDialog()" >Add</button>

            
            <button [disabled]="!selectedRowIndex" mat-raised-button color="primary" class="edit" [disabled]= "isReadOnly" (click)="openEditDialog()">Edit</button>
            <button [disabled]="!selectedRowIndex" mat-raised-button color="primary" class="deactivate" [disabled]= "isReadOnly" (click)="deActivate()">{{statusFlagLabel}}</button>
            <button [disabled]="!selectedRowIndex" mat-raised-button color="warn" class="delete" [disabled]= "isReadOnly" (click)="delete()">Delete</button>
            <!-- <button mat-raised-button (click)="sortRuleDialog()">Sort</button> (click)="openAddDialog()"-->
        </div>
    </mat-grid-tile>
</mat-grid-list>
<div *ngIf="isLoading" class="spinner">
    <mat-spinner class="loader"></mat-spinner>
</div>








<!-- **************************************************************************** -->
<!-- ************************** Add Rule Management ***************************** -->
<!-- **************************************************************************** -->








<modal id="add_modal">
    <form [formGroup]="form" (ngSubmit)="saveRule(form)">
        <div class="modal-header">
            <h2 mat-dialog-title>Add Rule Management</h2>
            <button type="button" class="btn-close" (click)="cancelDialog(form)"></button>
        </div>
        <div class="modal-body">
            
                <mat-dialog-content class="mat-typography">
        
                    <mat-form-field appearance="legacy">
                        <mat-label>Rule Name</mat-label>
                        <input matInput placeholder="Rule Name" formControlName="rule_name" (blur)="checkName()">
                        <div *ngIf="rule_loading" class="rule_loading">
                            <mat-spinner diameter="20" color="warn"></mat-spinner>
                        </div>
                        <mat-hint>
                            <div class="error" *ngIf="form.controls['rule_name'].invalid && (form.controls['rule_name'].dirty || form.controls['rule_name'].touched)" class="alert">
                                <div  class="error" *ngIf="form.controls['rule_name'].errors.required">
                                Please enter Profile Name
                                </div>
                            
                                <div  class="error" *ngIf="form.controls['rule_name'].errors?.duplicate">
                                    Rule Name already exists
                                </div>
                            </div>
                        </mat-hint>
                    </mat-form-field>
                            
                    <mat-form-field appearance="legacy">
                        <mat-label>Description</mat-label>
                        <textarea matInput placeholder="Description" rows="3" formControlName="rule_description"></textarea>
                        
                        <!-- <mat-hint>Hint</mat-hint> -->
                    </mat-form-field>
                            
                    <mat-form-field>
                    <mat-select placeholder="Rule Type" formControlName="rule_type_id">
                        <mat-option *ngFor="let type of rule_types" [value]="type.rule_type_id">
                            {{ type.rule_type_name }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                        
                        
                    
            
                    <mat-grid-list cols="4" rowHeight="15:5">
                        <mat-grid-tile>
                            <mat-form-field [ngClass]="{hidden: show_select}">
                                <!-- [(ngModel)]="modVariable"  -->
                                <mat-select #inptCondition [ngClass]="{select:selectLoading}" formControlName="var_variable" no-space
                                    placeholder="Variable" (selectionChange)="getValidOperators($event.value)">
                                    
                                    <input type="text" placeholder="search" class="search" 
                                        (keyup)="varChange($event)" (keydown.escape)="clearsearch($event)">
            
                                    <mat-icon class="search-icon">search</mat-icon>
                                    
                                    <mat-option *ngFor="let vls of variables | search : varSearchTxt : 'var_ref_tab' : 'var_name'" [value]="vls.var_ref_tab + '.' + vls.var_name">
                                        {{vls.var_ref_tab}}.{{ vls.var_name }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint>
                                    <div class="error" *ngIf="var_sign_val">
                                        Select variable
                                    </div>
                                </mat-hint>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <mat-form-field>
                                <!-- [(ngModel)]="modCondition"  -->
                                <mat-select formControlName="var_condition" placeholder="Condition"
                                    (selectionChange)="checkValidCondition($event.value)">
                                    <mat-option *ngFor="let opt of valid_operators" value={{opt}}>{{opt}}</mat-option>
                                </mat-select>
                                <mat-hint>
                                    <div class="error" *ngIf="var_sign_val">
                                        Select sign
                                    </div>
                                </mat-hint>
                            </mat-form-field>
            
                        </mat-grid-tile>
                        
                        <mat-grid-tile>
                            <!-- <mat-form-field [ngClass]="{hidden: show_text}">
                                
                                <input matInput type="text" [(ngModel)]="searchTextValue" value="{{searchTextValue}}">
                                <mat-placeholder >Value</mat-placeholder>
                            </mat-form-field> -->
                            <mat-form-field [ngClass]="{hidden: show_select}" >
                                <mat-select #valueSelect formControlName="var_value" 
                                        placeholder="Value" 
                                        (openedChange)="onValueChange($event)"
                                        [disabled]="var_values_status" no-space>
                                        <div class="drop-down-add">
                                            <input type="text" placeholder="search" class="search" 
                                                [value]="valueSearchTxt" 
                                                (keyup)="valueChange($event)"  (keydown.escape)="clearsearch($event)">
                                            
                                            <mat-icon class="search-icon">search</mat-icon>
                                            <mat-icon class="add-icon" (click)="addCondition(form)">add</mat-icon>
                                        </div>
                                    <mat-option *ngFor="let vlu of var_values | valueSearch : valueSearchTxt" value="{{vlu}}">{{vlu}}</mat-option>
                                </mat-select>
                                <mat-hint>
                                    <div class="error" *ngIf="var_sign_val">
                                        {{error_condition}}
                                    </div>
                                </mat-hint>
            
                            </mat-form-field>
            
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <button type="button" mat-raised-button color="primary" class="add" (click)="addCondition(form)">Add</button>
                        </mat-grid-tile>    
                    </mat-grid-list>
            
                    <div class="row">
                        <div class="col-2">
                            <button type="button" mat-raised-button (click)="addAnd()">And</button>
                        </div>
                        <div class="col-2">
                            <button type="button" mat-raised-button (click)="addOr()">Or</button>
                        </div>
                        <div class="col-2">
                            <button type="button" mat-raised-button (click)="addLeftPar()">(</button>
                        </div>
                        <div class="col-2">
                            <button type="button" mat-raised-button (click)="addRgtPar()">)</button>
                        </div>
                    </div>
                    <mat-label>Rules</mat-label>
                    <mat-form-field appearance="legacy" class=" rules-text margin-top-bottom-10">
                        
                    
                        <!-- [(ngModel)] = 'modString' -->
                        <textarea [ngClass]="{'viewquery': viewquery}" class="input-text-icon" readonly matInput  rows="7" formControlName="rule_type_string"></textarea>
                        
                        <button type="button" (click)="viewQuery()" mat-mini-fab color="primary" matTooltip="View Query" aria-label="View Query" class="view-button">
                        
                        </button>

                        <button type="button" (click)="editCriteria()" mat-mini-fab color="primary" matTooltip="Edit Rules" aria-label="Undo Last Action" class="edit-button">
                            
                        </button>
            
                        
                        <button type="button" (click)="formUndo()" mat-mini-fab color="primary" matTooltip="Undo Last Action" aria-label="Undo Last Action" class="undo-button">
                            
                        </button>
                        <button type="button" (click)="formClear()" mat-mini-fab color="primary" matTooltip="Clear All" aria-label="Clear All" class="clear-button">
                            
                        </button>
                        <!-- <mat-hint>Hint</mat-hint> -->
                    </mat-form-field>
                    <div class="where">
                        <span 
                            [ngClass]="{'selected': idx == whereIdx}" 
                            (click)="selectedWhere($event, idx, item)" 
                            *ngFor="let item of where_rule_strings; index as idx">
                                {{ item }}
                            <button type="button" class="del-btn" matTooltip="Delete criteria" >clear</button>
                        </span>
                    </div>
                    
                
            </mat-dialog-content>
            
            
        </div>
        <div class="modal-footer">
            <button mat-raised-button color="primary" class="add" [disabled]="!form.dirty" type="submit">Save</button>
            <button type="button" class="btn btn-secondary" (click)="cancelDialog(form)">Cancel</button>
        </div>
    </form>
</modal>








<!-- **************************************************************************** -->
<!-- ************************** Edit Rule Management **************************** -->
<!-- **************************************************************************** -->








<modal id="edit_modal" class="loading">
    
    
    <form [formGroup]="form" (ngSubmit)="editSaveRule(form)">
        <div class="modal-header">
            <h2 mat-dialog-title>Edit Rule Management</h2>
            <button type="button" class="btn-close" (click)="cancelDialog(form)"></button>
        </div>
        <div class="modal-body" [ngClass]="{'loading': editRowLoading}">
            
            <mat-dialog-content class="mat-typography">
                <mat-form-field appearance="legacy">
                    <mat-label>Rule Name</mat-label>
                    <input formControlName="rule_name" matInput placeholder="Rule Name" value="{{rules_row.rule_name}}" (keyup)="editCheckName($event)" >
                    <div *ngIf="rule_loading" class="rule_loading">
                        <mat-spinner diameter="20" color="warn"></mat-spinner>
                    </div>
                    <mat-hint>
                        <div class="error" *ngIf="form.controls['rule_name'].invalid && (form.controls['rule_name'].dirty || form.controls['rule_name'].touched)" class="alert">
                            <div  class="error" *ngIf="form.controls['rule_name'].errors.required">
                            Please enter Profile Name
                            </div>
                        
                            <div  class="error" *ngIf="form.controls['rule_name'].errors?.duplicate">
                                Rule Name already exists
                            </div>
                        </div>
                    </mat-hint>
                </mat-form-field>
                        
                <mat-form-field appearance="legacy">
                    <mat-label>Description</mat-label>
                    <textarea formControlName="rule_description" matInput placeholder="Description">{{ rules_row.rule_description }}</textarea>
                    
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
                        
                <mat-form-field>
                <mat-select formControlName="rule_type_id" placeholder="Rule Type" [(value)] = 'selected_rule_type'>
                    <mat-option *ngFor="let type of rule_types" [value]="type.rule_type_id" >
                        {{ type.rule_type_name }}
                    </mat-option>
                </mat-select>
                </mat-form-field>
                    
                <mat-grid-list cols="4" rowHeight="15:5">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-select #inptCondition [ngClass]="{select:selectLoading}" formControlName="var_variable" 
                                placeholder="Variable" (selectionChange)="getValidOperators($event.value)" no-spae>
                                
                                <input type="text" placeholder="search" class="search" (keyup)="varChange($event)"  (keydown.escape)="clearsearch($event)" >
        
                                <mat-icon class="search-icon">search</mat-icon>
                                
                                <mat-option *ngFor="let vls of variables | search : varSearchTxt : 'var_ref_tab' : 'var_name'" [value]="vls.var_ref_tab + '.' + vls.var_name">
                                    {{vls.var_ref_tab}}.{{ vls.var_name }}
                                </mat-option>
                            </mat-select>
                            <mat-hint>
                                <div class="error" *ngIf="var_sign_val">
                                    Select variable
                                </div>
                            </mat-hint>
                        </mat-form-field>

                    </mat-grid-tile>
                    <mat-grid-tile>
                        
                        <mat-form-field>
                            <mat-select formControlName="var_condition" placeholder="Condition"
                                (selectionChange)="checkValidCondition($event.value)">
                                <mat-option *ngFor="let opt of valid_operators" value="{{opt}}">{{opt}}</mat-option>
                            </mat-select>
                            <mat-hint>
                                <div class="error" *ngIf="var_sign_val">
                                    Select sign
                                </div>
                            </mat-hint>
                        </mat-form-field>
        
                    </mat-grid-tile>
                    <mat-grid-tile>
                        
                        <mat-form-field>
                            <mat-select [ngClass]="{selectvalue:valueLoading}" #valueSelect formControlName="var_value" 
                                placeholder="Value"  no-space
                                [disabled]="var_values_status"
                                (openedChange)="onValueChange($event)">
                                <div class="drop-down-add">
                                    <input type="text" placeholder="search" class="search" 
                                            value="{{searchTextValue}}"
                                            (keyup)="valueChange($event)" (keydown.escape)="clearsearch($event)">
                                    
                                    <mat-icon class="search-icon">search</mat-icon>
                                    <mat-icon class="add-icon" (click)="addCondition(form)">add</mat-icon>
                                </div>
                                <mat-option *ngFor="let vlu of var_values | valueSearch : valueSearchTxt" value="{{vlu}}">{{vlu}}</mat-option>
                            </mat-select>
                            <mat-hint>
                                <div class="error" *ngIf="var_sign_val">
                                    {{error_condition}}
                                </div>
                            </mat-hint>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <button #rulesbtn type="button" mat-raised-button color="primary" class="add" (click)="addCondition(form)">Add</button>
                    </mat-grid-tile>    
                </mat-grid-list>
        
                <div class="row">
                    <div class="col-2">
                        <button type="button" mat-raised-button (click)="addAnd()">And</button>
                    </div>
                    <div class="col-2">
                        <button type="button" mat-raised-button (click)="addOr()">Or</button>
                    </div>
                    <div class="col-2">
                        <button type="button" mat-raised-button (click)="addLeftPar()">(</button>
                    </div>
                    <div class="col-2">
                        <button type="button" mat-raised-button (click)="addRgtPar()">)</button>
                    </div>
                </div>
                <div>&nbsp;</div>
                <mat-form-field appearance="legacy" class="rules-text">
                    <mat-label>Rules</mat-label>
                    
                    <textarea  [ngClass]="{'viewquery': viewquery}" class="input-text-icon" readonly formControlName="rule_type_string" matInput placeholder="Condition" rows="10">{{ rules_row.rule_string }}</textarea>
                    
                    <button type="button" (click)="viewQuery()" mat-mini-fab color="primary" matTooltip="View Query" aria-label="View Query" class="view-button">
                        
                    </button>
                    <button type="button" (click)="editCriteria()" mat-mini-fab color="primary" matTooltip="Edit Rules" aria-label="Edit Criteria" class="edit-button">
                        
                    </button>
        
                    <button type="button" (click)="formUndo()" mat-mini-fab color="primary" matTooltip="Undo Last Action" aria-label="Undo Last Action" class="undo-button">
                        
                    </button>
        
                    <button type="button" (click)="formClear()" mat-mini-fab color="primary" matTooltip="Clear All" aria-label="Clear All" class="clear-button">
                        
                    </button>
                    <mat-hint>
                        <div class="error" *ngIf="form.controls['rule_type_string'].invalid && (form.controls['rule_type_string'].dirty || form.controls['rule_type_string'].touched)" class="alert">
                            <div  class="error" *ngIf="form.controls['rule_type_string'].errors.required">
                            Please enter Rule
                            </div>
                        </div>
                    </mat-hint>
                </mat-form-field>
                <div class="where">
                    <div *ngFor="let item of where_rule_strings; index as idx">
                        <span [ngClass]="{'selected': idx == whereIdx}" 
                        (click)="selectedWhere($event, idx, item)" >
                            {{ item }}
                        </span>
                        <button (click)="deleteCriteria(idx)" type="button" class="del-btn" matTooltip="Delete criteria" >clear</button>
                    </div>
                </div>
                <!-- <div #textRuleString class="editor" [innerHtml]="display_rule_strings| spaning :'yellow'"></div>
                <div>{{ display_rule_strings }}</div> -->
                
            </mat-dialog-content>
        
        </div>
        <div class="modal-footer">
            <button mat-raised-button color="primary" [disabled]="!form.dirty" class="add" type="submit">Save</button>
            <button type="button" class="btn btn-secondary" (click)="cancelDialog(form)">Cancel</button>
        </div>
    </form>

</modal>