
<div class="d-flex justify-content-between">
  <h1>Data Loads & Templates<mat-icon (click)="getTemplates()" class="clickable p-1">refresh</mat-icon></h1>
  <span>
  <label></label>
  <mat-slide-toggle color="primary" [(ngModel)]="isAutorefreshOn">Auto-Refresh</mat-slide-toggle> 
  </span>
</div>
<div class="row data-loads" [ngClass] = "{loading: checkInUseLoading}">
  <mat-card class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
    <table class="example-container" mat-table id="loadTemplelates1" #tempsort="matSort"
      [dataSource]="loadTemplateDatasource1" matSort>
      <ng-container matColumnDef="list_desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.list_desc}} </td>
      </ng-container>

      <!-- Template Download Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef text-align="left">Download</th>
        <td mat-cell *matCellDef="let element" align="center">
          <ng-container *ngIf="element.load_only==0">
            <mat-icon (click)="download(element)"  style="cursor: pointer;">download_icon</mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <!--Template Upload Column hiddenfileinput.click() -->
      <ng-container matColumnDef="upload">
        <th mat-header-cell *matHeaderCellDef>Upload/ Process</th>
        <td mat-cell *matCellDef="let element" align="center"> 
          <ng-container *ngIf="element.lock_status == 1" matTooltip="Locked">
            <mat-icon style="cursor: not-allowed;">lock</mat-icon>
          </ng-container>
          <ng-container *ngIf="element.load_only==1 && element.lock_status == 0">
            <span [ngClass]="{loading:loadOnlyListCode == element.list_code}" class="material-symbols-outlined" style="cursor: pointer;" (click)="loadOnly(element)">
              start
            </span>
          </ng-container>
          <ng-container *ngIf="element.load_only==0 && element.lock_status == 0" align="center">
            <mat-icon [ngClass]="{loading:uploadListCode == element.list_code}" (click)="checkInUse(element)" style="cursor: pointer;">upload_file</mat-icon>
            
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="loadTemplelatesColumns; sticky: false"></tr>
      <tr mat-row *matRowDef="let row; columns: loadTemplelatesColumns;"></tr>

    </table>

    <div *ngIf="loadTemplateDatasource1.data.length === 0" class="no-records alert alert-info text-center">
      No records found
    </div>
  </mat-card>

  <!-- ******************column 2 *********************---->
  <mat-card  class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
    <table class="example-container" mat-table id="loadTemplelates2" #tempsort="matSort"
      [dataSource]="loadTemplateDatasource2" matSort>
      <ng-container matColumnDef="list_desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.list_desc}} </td>
      </ng-container>

      <!-- Template Download Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>Download</th>
        <td mat-cell *matCellDef="let element" align="center">
          <ng-container *ngIf="element.load_only==0">
            <mat-icon (click)="download(element)" style="cursor: pointer;">download_icon</mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <!--Template Upload Column -->
      <ng-container matColumnDef="upload">
        <th mat-header-cell *matHeaderCellDef>Upload/ Process</th>
        <td mat-cell *matCellDef="let element" align="center">
          <ng-container *ngIf="element.lock_status == 1" matTooltip="Locked">
            <mat-icon style="cursor: not-allowed;">lock</mat-icon>
          </ng-container>
          <ng-container *ngIf="element.load_only == 1 && element.lock_status == 0">
            <span [ngClass]="{loading:loadOnlyListCode == element.list_code}" class="material-symbols-outlined" style="cursor: pointer;" (click)="loadOnly(element)">
              start
            </span>
          </ng-container>
          <ng-container *ngIf="element.load_only == 0 && element.lock_status == 0" align="center">
            <mat-icon [ngClass]="{loading:uploadListCode == element.list_code}" (click)="checkInUse(element)" style="cursor: pointer;">upload_file</mat-icon>
            <!-- <input type="file" (change)="onUpload($event, element)" style="display:none;cursor: pointer;" #hiddenfileinput>hiddenfileinput.click() -->
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="loadTemplelatesColumns; sticky: false"></tr>
      <tr mat-row *matRowDef="let row; columns: loadTemplelatesColumns;"></tr>

    </table>

    <div *ngIf="loadTemplateDatasource2.data.length === 0" class="no-records alert alert-info text-center">
      No records found
    </div>
  </mat-card>

  <!-- ***************** column 3 *********************---->

  <mat-card  class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
    <table class="example-container" mat-table id="loadTemplelates3" #tempsort="matSort"
      [dataSource]="loadTemplateDatasource3" matSort>
      <ng-container matColumnDef="list_desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.list_desc}} </td>
      </ng-container>

      <!-- Template Download Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>Download</th>
        <td mat-cell *matCellDef="let element" align="center">
          <ng-container *ngIf="element.load_only==0">
            <mat-icon (click)="download(element)" style="cursor: pointer;">download_icon</mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <!--Template Upload Column -->
      <ng-container matColumnDef="upload">
        <th mat-header-cell *matHeaderCellDef>Upload/ Process</th>
        <td mat-cell *matCellDef="let element" align="center">
          <ng-container *ngIf="element.lock_status == 1" matTooltip="Locked">
            <mat-icon style="cursor: not-allowed;">lock</mat-icon>
          </ng-container>
          <ng-container *ngIf="element.load_only==1 && element.lock_status == 0">
            <span [ngClass]="{loading:loadOnlyListCode == element.list_code}" class="material-symbols-outlined" style="cursor: pointer;" (click)="loadOnly(element)">
              start
            </span>
          </ng-container>
          <ng-container *ngIf="element.load_only==0 && element.lock_status == 0" align="center">
            <mat-icon [ngClass]="{loading:uploadListCode == element.list_code}" (click)="checkInUse(element)" style="cursor: pointer;">upload_file</mat-icon>
            <!-- <input type="file" (change)="onUpload($event, element)" style="display:none;cursor: pointer;" #hiddenfileinput> -->
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="loadTemplelatesColumns; sticky: false"></tr>
      <tr mat-row *matRowDef="let row; columns: loadTemplelatesColumns;"></tr>

    </table>

    <div *ngIf="loadTemplateDatasource3.data.length === 0" class="no-records alert alert-info text-center">
      No records found
    </div>
  </mat-card>
</div>



<br/>
<mat-card>
  <form [formGroup]="form" (ngSubmit)="monthEndSnapshot()" novalidate>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 text-center">
      <mat-form-field appearance="fill">
        <mat-label>Month</mat-label>
        <mat-select formControlName="rpt_month" >
          <!-- <mat-select-trigger>(selectionChange)="checkMeSnapshot()" (blur)="checkMeSnapshot()"
            {{toppings.value ? toppings.value[0] : ''}}
            <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
              (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger> -->
          <mat-option *ngFor="let month of months; index as i" [value]="i">{{month}}</mat-option>
        </mat-select>
        <mat-hint>
          <div class="error">
            <span *ngIf="form.controls['rpt_month'].errors?.futuremonth">
              {{form.controls['rpt_month'].errors.futuremonth}}
            </span>
          </div>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 text-center">
      <mat-form-field class="example-full-width " appearance="fill" [ngClass]="{loading: checkMonthEndLoading}">
        <mat-label>Year</mat-label>
        <input  matInput formControlName="rpt_year">
        <mat-hint>
          <div class="error" *ngIf="form.controls['rpt_year'].invalid && (form.controls['rpt_year'].dirty || form.controls['rpt_year'].touched)" class="alert">
              <span  class="error" *ngIf="form.controls['rpt_year'].errors.required">
                Please enter Year.
              </span>
              <span class="text-danger" *ngIf="form.controls['rpt_year'].errors.minlength">
                Year must be at least 4 digit long.
              </span> 
              <span class="text-danger" *ngIf="form.controls['rpt_year'].errors.maxlength"> 
                Year can be max 4 digit long.
              </span>	
              <span *ngIf="form.controls['rpt_year'].errors.pattern" class="text-danger">Number Only</span>
              <span *ngIf="form.controls['rpt_year'].errors?.futureyear" class="text-danger">
                {{form.controls['rpt_year'].errors.futureyear}}
              </span>
          </div>
        </mat-hint>
      </mat-form-field>
      
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 text-center">
      <button type="button" [ngClass]="{loading: checkMonthEndLoading}" class="month red margin-top-bototm deactivate" color="primary" mat-raised-button (click)="checkMeSnapshot()">Check Month-End Snapshot</button>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 text-center">
        <button type="button" [disabled]="!monthEnd" [ngClass]="{loading: monthLoading}" class="month margin-top-bototm" color="primary" mat-raised-button (click)="monthEndSnapshot()">Month End Snapshot</button>
        <div class="row error min-height-30">{{monthEndMessage}}</div>
    </div>
  </div>
  </form>
</mat-card>

<!-- upload -->
<input type="file" (change)="onUpload($event)" style="display:none;cursor: pointer;" #hiddenfileinput>
          
<div id="dwnld"></div>
<div *ngIf="isLoading" class="spinner">
  <mat-spinner class="loader"></mat-spinner>
</div>