<mat-card class="example-card text-center">
    <h3 class="title"><b>AUM Crossholding Rules Management</b></h3>

    <h4 class="col-12 color-999 text-left">Crossholding Profile Name -->  {{profile && profile.profilename}}</h4>
</mat-card>
<br>
    <div class="row" [ngClass]="{editing: editing}">
        <div class="col-4">
            
            <div class="rule-list"  > 
                <h3>Reporting Styles</h3>
                <div class="rule-box active" *ngFor="let rpt of reportStyles"
                    (click)="selecteStyle(rpt)"
                    [ngClass]="{'selected': selectedStyleId == rpt.aum_rpt_style_sq_id }"
                >
                    {{rpt.aum_rpt_style_desc}}
                </div>
                <!-- <mat-hint>
                    <div class="text-danger"*ngIf="!selectedStyleId">
                        Please select Reporting Style
                    </div>
                </mat-hint> -->
            </div>

            <mat-card>
                <div class="row">
                
                    <mat-form-field>
                        <mat-label>Crossholding Profile :</mat-label>
                        <mat-select [(ngModel)] = "selectedAssocProfileId" no-space>
                            <input type="text" placeholder="search" class="search"
                                    (keyup)="searchProfile($event)"  (keydown.escape)="clearsearch($event)">

                                <mat-icon class="search-icon">search</mat-icon> 
                            <mat-option [disabled]="prof.aum_xhld_profiles_sq_id == profileid"  
                                *ngFor="let prof of profiles | search : varText : 'aum_xhld_profiles_name'" [value]="prof.aum_xhld_profiles_sq_id">
                                {{prof.aum_xhld_profiles_name}}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-hint>
                            <div class="text-danger" *ngIf="!selectedAssocProfileId">
                                Please select Profile
                            </div>
                        </mat-hint> -->
                    </mat-form-field>
                
                </div>
            </mat-card>
            <mat-card class="text-center button-container">
                
                <button mat-raised-button color="primary" class="add" [disabled]= "isReadOnly"  (click)="addRule()">Add</button>
                <!-- <button mat-raised-button color="primary" type="button" class="edit" 
                   [disabled]="!selectedRowProfileId" (click)="editRule()">{{caption_edit}}</button> -->
                <!-- <button [disabled]="!selectedRowProfileId" mat-raised-button color="primary" type="button" (click)="deleteRule()" class="delete">
                    {{caption_del}}
                </button> -->
                <button [disabled]="!selectedRowProfileId" mat-raised-button color="primary" type="button" class="edit" 
                    (click)="saveRule()">Save</button>
                <button mat-button (click)="cancelActions()">Cancel</button>
            </mat-card>
            <br>
        </div>
        <div class="col-8">
            <div class="xhld-table"> 
                <mat-card-title>Add/Edit Deductions</mat-card-title>
                <table mat-table [dataSource]="rules" class="mat-elevation-z8"
                    [ngClass]="{loading:rule_loading}"
                    >
                    <ng-container matColumnDef="aum_xhld_profiles_name">
                    <th mat-header-cell *matHeaderCellDef>Crossholding Profile</th>
                    <td mat-cell *matCellDef="let element">{{ element.aum_xhld_profiles_name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="aum_xhld_holding_flag">
                        <th mat-header-cell *matHeaderCellDef>Deduct from Top/Holding</th>
                        <td mat-cell *matCellDef="let element">{{ element.aum_xhld_holding_flag }} </td>
                    </ng-container>
                    <ng-container matColumnDef="aum_xhld_undly_flag">
                        <th mat-header-cell *matHeaderCellDef>Deduct from Underlying</th>
                        <td mat-cell *matCellDef="let element">{{ element.aum_xhld_undly_flag }} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row" class="text-right">
                            <button (click)="editRule(row.aum_xhld_assoc_profile_id)" mat-mini-fab color="primary"  [disabled]= "isReadOnly"  aria-label="Example icon button with a menu icon">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button class="del" (click)="deleteRule(row.aum_xhld_assoc_profile_id)" mat-mini-fab color="primary"  [disabled]= "isReadOnly" aria-label="Example icon button with a menu icon">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
                        (click)="selectProfile(row)"
                        [ngClass]="{'selected': selectedRowProfileId == row.aum_xhld_assoc_profile_id, 'deleted': row.action == 'delete', 'edit': row.action == 'edit'}"
                    ></tr>
                    
                </table>
                <div *ngIf="rules.length === 0"
                    class="no-records alert alert-info text-center">
                    No rules found
                </div>
            </div>

        </div>
</div>
