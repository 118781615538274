import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueSearch'
})
export class ValueSearchPipe implements PipeTransform {

  transform(items: any[], searchTxt: string): any[] {
    searchTxt = searchTxt.trim();
    if(!items || !items.length) return items;
    if(!searchTxt || !searchTxt.length) return items;
    // return items.filter(item => {
      
    //   return item.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
    // });
    return items.filter(item => {
      // console.log(searchTxt.indexOf("%"), "iiiiiiiiiiiiiiiii", searchTxt.length, item)
      if (searchTxt.indexOf("%") === -1) {
        // return item.toString().toLowerCase() == searchTxt.toString().toLowerCase();
        return item.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      }else if(searchTxt.indexOf("%") == 0){  //search from right
        
        let srchTxt = searchTxt.substring(1)
        // console.log(item, "-------------", srchTxt)
        if(item.length >= srchTxt.length){
          let ss = item.substring(item.length - srchTxt.length)
          // console.log("22222222222222222222", ss, "3333333", srchTxt,ss == srchTxt )
          return ss.toString().toLowerCase() == srchTxt.toString().toLowerCase();
        }
        // return item.var_name.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      }else{
        if(searchTxt.indexOf("%") == (searchTxt.length-1)){
          // console.log(searchTxt, 'inner')
          let srchTxt = searchTxt.substring(0, searchTxt.length - 1);
          // console.log(srchTxt,srchTxt.length, "------------------------------------------------------", item.substring(0, srchTxt.length))
          return item.trim().substring(0, srchTxt.length).toString().toLowerCase() == srchTxt.toString().toLowerCase();
          // return item.toString().toLowerCase().indexOf(srchTxt.toLowerCase()) > -1
        }
      }

      // return item.var_name.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
    });



  }

}
