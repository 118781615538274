import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-month-end-snapshot',
  templateUrl: './month-end-snapshot.component.html',
  styleUrls: ['./month-end-snapshot.component.css']
})
export class MonthEndSnapshotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
