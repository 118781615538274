import { Component, Inject, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributemanagementService } from 'src/app/services/attributemanagement.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { Attributes, AttributesGroup, SelectedAttributes } from 'src/app/models/attributes';
import { Observable } from 'rxjs/internal/Observable';
import { DialogService } from 'src/app/services/dialog.service';
@Component({
  selector: 'app-portmandate-management-dialog',
  templateUrl: './portmandate-management-dialog.component.html',
  styleUrls: ['./portmandate-management-dialog.component.css']
})
export class PortmandateManagementDialogComponent implements OnInit {
  message;
  title;
  addAttr: boolean;
  attr;
  aum_code;
  aum_dim_name;
  aum_dim_desc;
  aum_status;

  savePortfolioAttr: any;
  editPortfolioAttr: any;
  BrandAttr: any;
  resultBrand: any;
  resultPortfolio: any;
  selectedAttr: SelectedAttributes[] = [];
  selectedPortfolioAttr: SelectedAttributes[] = [];
  activatedSuccessfully: boolean;
  code; desc; name; status; action;
  isReadOnly: boolean;
  isActive: boolean = false;
  isDialogOpen: boolean = false;
  pageDirty: boolean = false;
  data: any;

  @Output() formResponse = new EventEmitter<any>();

  constructor(
    // public dialogRef: MatDialogRef<PortmandateManagementDialogComponent>,
    // @Inject(MAT_DIALOG_DATA)
    // public data: any,
    private attributeService: AttributemanagementService,
    private toastr: ToasterService,
    private confirmDialog: DialogService
  ) {
  }

  ngOnInit(): void {
    // console.log(this.data);
    this.title = this.data.activeAttr;
    this.message = this.data.message;
    this.addAttr = this.data.addAttr;
    this.attr = this.data.attr;
    this.code = this.data.code;
    this.desc = this.data.desc;
    this.name = this.data.name;
    this.action = this.data.action;
    this.status = this.data.status;
    console.log(this.data);
    if (this.action == "Edit") {
      this.isReadOnly = true;
    }
  }
  canDeactivate(): Observable<boolean> | boolean {
    
    if (this.action == "Edit") {
      if (this.desc != this.data.desc || this.name != this.data.name) {
        this.pageDirty = true;
      }
    }
    else {
      if ((this.code != undefined || this.desc != undefined || this.name != undefined) &&
        (this.code != "" && this.desc != "" && this.name != "")) {
        this.pageDirty = true;
      }
    }
    if (this.pageDirty) {
      console.log(this.code + " || " + this.desc + " ||" + this.name);
      return this.confirmDialog.confirm("Do you want to discard the changes?");
    }
    return true;
  }


  fromParent = (data) =>{
    this.data = data;
    this.title = data.activeAttr;
    this.message = data.message;
    this.addAttr = data.addAttr;
    this.attr = data.attr;
    this.code = data.code;
    this.desc = data.desc;
    this.name = data.name;
    this.action = data.action;
    this.status = data.status;
    this.isReadOnly = false
    if (this.action == "Edit") {
      // this.id = data.id;
      // this.mpar = data.mpar
      this.isReadOnly = true;
    }
    console.log(this, data)
  }

  ClearControls() {
    this.aum_code = "";
    this.aum_dim_name = "";
    this.aum_dim_desc = "";
  }

  SaveAttributes() {
    // this.getAttributes();   
    this.savePortfolioAttr = []
    if (this.action == "Edit") {
      this.editPortfolioAttr = {
        aum_code: this.code,
        aum_dim_name: this.name,
        aum_dim_desc: this.desc
      }
      console.log("Edit: " + this.action);
      this.attributeService.updatePortfolioAttributes(this.editPortfolioAttr).subscribe(res => {
        if (res) {
          this.activatedSuccessfully = true;
          if (this.activatedSuccessfully == true) {
            this.toastr.success("Attribute Updated");
            this.ClearControls();
          }
        }
        this.formResponse.emit({pagedirty: false, msg: 'save'})
        // this.dialogRef.close(this.selectedPortfolioAttr);
      },
        err => {
          this.activatedSuccessfully = false
          this.toastr.error(err.error.message);
        }
      );
    }
    else {
      console.log("Add: " + this.action);
      this.savePortfolioAttr = {
        aum_code: this.code,
        aum_dim_name: this.name,
        aum_dim_desc: this.desc,
        aum_sort_order: 0,
        levelno: 0,
        parentid: null,
        status: "Inactive"
      }
      // console.log(this.savePortfolioAttr);
      this.attributeService.savePortfolioAttributes(this.savePortfolioAttr).subscribe(res => {
        this.toastr.success("Attribute Added");
        this.attributeService.getPortfolioMandateList().subscribe(result => {
          this.resultPortfolio = result.data;
           for (let i = 0; i <= this.resultPortfolio.length - 1; i++) {
            this.selectedPortfolioAttr.push({
              "value": this.resultPortfolio[i].aum_port_man_code,
              "viewValue": this.resultPortfolio[i].aum_port_man_name,
              "description": this.resultPortfolio[i].aum_port_man_desc,
              "active": this.resultPortfolio[i].aum_port_man_status,
              "id": this.resultPortfolio[i].aum_port_man_dim_id,
              "sortOrder": this.resultPortfolio[i].aum_sort_order
            });
          }
          this.formResponse.emit({pagedirty: false, msg: 'save'})
          /*
          for (let i = 0; i <= this.selectedPortfolioAttr.length - 1; i++) {
            // console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value + "  --  " + this.saveBrandAttr["aum_code"]);
            if ((this.selectedPortfolioAttr[i].active == "Active")
              && (this.selectedPortfolioAttr[i].value.toLowerCase() != this.savePortfolioAttr["aum_code"].toLowerCase())) {
              //console.log(this.selectedAttr[i].active + " -- " + this.selectedAttr[i].value.toLowerCase() + "  --  " + this.saveBrandAttr["aum_code"].toLowerCase());
              let portStatus =
              {
                dim_code: this.selectedPortfolioAttr[i].value,
                status: "Inactive"
              }
              this.attributeService.activatePortAttStatus(portStatus).subscribe(res => { },
                err => {
                  this.toastr.error(err.error.message);
                });
            }
          } */
          // this.dialogRef.close(this.selectedPortfolioAttr);
        });
      }),
        err => {
          // this.activatedSuccessfully = false
          this.toastr.error(err.error.message);
        }
    }

  }

  change(event){
    this.pageDirty = true;
    this.formResponse.emit({pagedirty: true, msg: 'form changed'})
  }

  onNoClick() {
    this.isDialogOpen = false;
    const ans = this.canDeactivate();
    //console.log(ans);
    this.pageDirty=false;
    if (ans) {      
      this.formResponse.emit({pagedirty: false, msg: 'close'})
      // this.dialogRef.close(this.isDialogOpen);
      
    }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("port unload", this.pageDirty)
    if (this.pageDirty) event.returnValue = false;
  }

}
