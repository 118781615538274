import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment, API_BASE_URL, UPLOAD_FILE_BASE_URL } from '../../environments/environment';
import { base64UrlDecode } from '@okta/okta-auth-js/types/lib/crypto';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }
  protected baseUrl;
  protected fileuploadurl;
  protected goBack: true;

  getServerUrl(): string {
    // if (environment.development){
    //     this.baseUrl = API_BASE_URL.development;
    //     this.fileuploadurl = UPLOAD_FILE_BASE_URL.development
    //   }else if(environment.local){
    //     this.baseUrl= API_BASE_URL.local;
    //     this.fileuploadurl = UPLOAD_FILE_BASE_URL.local
    //   }
      this.baseUrl = API_BASE_URL
      
      return this.baseUrl;
  }

  getData=(url)=>{
    return this.http.get(this.getServerUrl() + "/" + url);
  }

  postData = (url:string, headers: any, body: any) => {
    return this.http.post(this.getServerUrl() + "/" + url, body);
  }
  
  putData = (url:string, headers: any, body: any) => {
    return this.http.put(this.getServerUrl() + "/" + url, body);
  }
  deleteData=(url:string, headers: any, body: any)=>{
    return this.http.delete(
      this.getServerUrl() + "/" + url,
      {
        headers:{
          'Content-Type': 'application/json'},
        body: body
      })
  }

  setGoBack(opt){
    console.log("setGoBack", opt)
    this.goBack = opt?opt:false;
  }

  isGoBack(){
    return this.goBack;
  }
}
