import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SideNavModel } from './models/sidenav';
import { animateText, onSideNavChange } from 'src/app/animations/animations';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { GlobalConstants } from '../appconstants';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { async } from 'rxjs/internal/scheduler/async';
import { HttpService } from './services/http.service';
import { URLHelper } from './services/url.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [onSideNavChange, animateText],
})
export class AppComponent implements OnInit {
  title = 'CARS - Consolidated AUM Reporting System';
  tokenRefreshTimeInMilliSeconds = 10 * 60 * 1000;
  public isAuthenticated$!: Observable<boolean>;
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  public environ;
  public group: boolean = false;
  public groupInfo;
  public mainTuser;
  
  private isRefreshSessionPopOverShown = false;

  folders: any = [
    { name: 'list1', id: '1' },
    { name: 'list1', id: '2' },
  ];
  isReadOnly: boolean = false;
  private tokenRefreshTimer:any;
  constructor(
    public sideNav: SideNavModel,
    private _router: Router,
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private authService: AuthService,
    private userIdle: UserIdleService,
    private dialog: MatDialog,
    private http: HttpService, 
    private url: URLHelper,
    ) {
      this.isReadOnly = authService.getUserGroups()
        ? authService.getUserGroups()[GlobalConstants.readOnlyUser]
        : false;       
    }

  public ngOnInit(): void {
    this.getCurrentUserDetails();
    this.environ = environment.production
      ? 'Production'
      : environment.development
      ? 'Dev'
      : environment.test
      ? 'Test/Stage'
      : 'UAT';
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    this.tokenRefreshTimer = setInterval(()=> this.GetNewToken(), this.tokenRefreshTimeInMilliSeconds);
    // //Start watching for user inactivity.
    // this.userIdle.startWatching();
    
    // // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().toPromise();
    
    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() => {
    //   this.userIdle.stopTimer();
    //   this.showRefreshSessionPopOver();
    // });
  }
  // showRefreshSessionPopOver() {
  //   if(this.isRefreshSessionPopOverShown == false){
  //     this.isRefreshSessionPopOverShown = true;
  //     this.userIdle.stopTimer();
  //     const dialogRef = this.dialog.open(TimeoutDialogComponent);
  //     dialogRef.afterClosed().subscribe(() => {
  //       this.userIdle.resetTimer();
  //       this.isRefreshSessionPopOverShown = false;
  //     });
  //   }
  // }
  GetNewToken = async() =>{
    this.http.getData(this.url.timeouturl).toPromise();
  }

  public async signIn(): Promise<void> {
    await this._oktaAuth
      .signInWithRedirect()
      .then((_) => this._router.navigate(['/profile']));
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  onSideNavChange = () => {};

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200);
  }

  getCurrentUserDetails() {
    this.authService.getCurrentUserDetails();

    this.authService.getGroup().subscribe((res: any) => {
      this.groupInfo = res.data;
      
      let fltr = this.groupInfo.filter(
        (rec: any) => rec.groupname.indexOf('CARS-AUM-Users') >= 0
      );

      sessionStorage.setItem('group', fltr.length > 0 ? fltr[0].groupname : '');
      this.group = fltr.length > 0 ? true : false;

      let fltr1 = this.groupInfo.filter(
        (rec: any) => rec.groupname.indexOf('CARS-Maint-Users') >= 0
      );

      this.mainTuser = fltr1.length > 0 ? true : false;
    });
  }
  adminExpanded: boolean = false;
  adminMenu = () => {
    this.adminExpanded = !this.adminExpanded;
  };

  // canActivate(): boolean{
  //   console.log("activeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
  //   if (this.authService.checkExpired){
  //     this._router.navigate(['/'])
  //     return false;
  //   }
  //   return true;
  // }

  ngOnDestroy() {
    if (this.tokenRefreshTimer) {
      clearInterval(this.tokenRefreshTimer);
    }
  }
}

